import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";
import titleLoanStores from "../data/titleLoanStores";
import personalLoanStores from "../data/personalLoanStores";

const Store = props => {
	return (
		<div className="modal fade" id={props.type + props.id} tabIndex="-1" role="dialog" aria-labelledby="Store" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">
							{props.name}
						</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">{props.description}</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						<div className="container">
							<h3 className="section-title">{this.props.messages.markets.textN01}</h3>
							<br />
							<p>
								{this.props.messages.markets.textN02}
								<br />
								<br />
								{this.props.messages.markets.textN03}
								<br />
								<br />
							</p>
							<ul
								style={{
									listStyleType: "disc",
									fontFamily: "brandon_textregular",
									fontSize: 15,
									lineHeight: "24px",
								}}>
								<li>
									<span data-toggle="modal" data-target="#personal3" className={"link"}>
										{this.props.messages.markets.textN04}
									</span>{" "}
									- {this.props.messages.markets.textN05}
								</li>
								<li>
									<span data-toggle="modal" data-target="#personal1" className={"link"}>
										{this.props.messages.markets.textN06}
									</span>{" "}
									- {this.props.messages.markets.textN07}
								</li>
								<li>
									<span data-toggle="modal" data-target="#personal0" className={"link"}>
										{this.props.messages.markets.textN06}
									</span>{" "}
									- {this.props.messages.markets.textN08}
								</li>
								<li>
									<span data-toggle="modal" data-target="#personal2" className={"link"}>
										{this.props.messages.markets.textN06}
									</span>{" "}
									- {this.props.messages.markets.textN09}
								</li>

								<li>
									<span data-toggle="modal" data-target="#title8" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN11}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title5" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN12}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title3" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN13}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title9" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN14}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title4" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN15}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title0" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN16}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title1" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN17}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title2" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN18}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title6" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN19}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title7" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN20}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title14" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN21}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title12" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN22}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title13" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN23}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title10" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN24}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title11" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN25}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title15" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN26}
								</li>
								<li>
									<span data-toggle="modal" data-target="#title16" className={"link"}>
										{this.props.messages.markets.textN10}
									</span>{" "}
									- {this.props.messages.markets.textN27}
								</li>
							</ul>
							<br />
							<br />
							<br />
							<br />
							{titleLoanStores.map((store, index) => {
								return <Store type={"title"} name={store.name} description={store.description} id={index} key={index} />;
							})}
							{personalLoanStores.map((store, index) => {
								return <Store type={"personal"} name={store.name} description={store.description} id={index} key={index} />;
							})}
						</div>
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
