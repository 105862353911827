import { UPDATE_LOAN } from "../actions/updateLoan";

export default (state = 0, { type, payload }) => {
	switch (type) {
		case UPDATE_LOAN:
			return payload;
		default:
			return state;
	}
};
