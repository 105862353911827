import React, { PureComponent } from "react";
import Step from "../components/step";
import Page from "../components/page";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { Row, Col, Button } from "antd";
import Document from "../components/document";

export default class extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,

			id: [],
			residence: [],
			title: [],
			registration: [],
			vehicle: [],
			selfie: [],
			bankStatement: [],
			additional: [],
			incomeProofFiles: [],
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.uid) {
			this._loadData();
			navigator.geolocation.getCurrentPosition(location => {
				firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.update({
						location: {
							latitude: location.coords.latitude,
							longitude: location.coords.longitude,
							accuracy: location.coords.accuracy,
							timestamp: moment(location.timestamp).toDate(),
						},
					});
			});
		} else {
			this.props.history.push("/login");
		}
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	_loadData = () => {
		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.onSnapshot(app => {
				return this.setState(
					{
						id: app.data().id ? app.data().id : [],
						residence: app.data().residence ? app.data().residence : [],
						title: app.data().title ? app.data().title : [],
						registration: app.data().registration ? app.data().registration : [],
						vehicle: app.data().vehicle ? app.data().vehicle : [],
						selfie: app.data().selfie ? app.data().selfie : [],
						additional: app.data().additional ? app.data().additional : [],
						bankStatement: app.data().bankStatement ? app.data().bankStatement : [],
						incomeProofFiles: app.data().incomeProofFiles ? app.data().incomeProofFiles : [],
					},
					this.checkLimit
				);
			});
	};

	checkLimit = () => {
		if (this.props.loan.type === "Personal Loan") {
			if (this.state.id.length && this.state.residence.length && this.state.incomeProofFiles.length) {
				if (this.props.loan.vehicle) {
					if (this.state.title.length > 1 && this.state.vehicle.length > 7) {
						this.setState({ ready: true });
					} else {
						this.setState({ ready: false });
					}
				} else {
					this.setState({ ready: true });
				}
			} else {
				this.setState({ ready: false });
			}
		} else if (this.props.loan.type === "Title Loan") {
			if (this.state.id.length && this.state.residence.length && this.state.title.length > 1 && this.state.vehicle.length > 7) {
				this.setState({ ready: true });
			} else {
				this.setState({ ready: false });
			}
		}
	};

	_save = () => {
		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				timestamp: moment().toDate(),
				lastUpdate: "User",
			})
			.then(async () => {
				window.gtag("event", "conversion", {
					send_to: "AW-974126037/G7TNCJaeq6gBENX3v9AD",
				});
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.update({
						status: 4,
					});
				this.props.onUpdateLoan({
					status: 4,
					vehicle: this.props.loan.vehicle,
					type: this.props.loan.type,
					term: this.props.loan.term,
				});
				return this.props.history.push("/status");
			});
	};

	render() {
		return (
			<Page {...this.props}>
				<Step title={this.props.messages.documents.textN43} subtitle={this.props.messages.documents.textN44} />
				<div className="container pt-1 pb-3">
					{this.state.loading ? <div className="loading">Loading&#8230;</div> : null}
					{this.props.loan.type === "Title Loan" ? <p>{this.props.messages.documents.textN13}</p> : <p>{this.props.messages.documents.textN14}</p>}
					<br />
					<br />
					<div className="yseparator center" />
					<br />
					<h3>{this.props.messages.documents.textN15}</h3>
					<br />
					<Document
						needs={1}
						id={"id"}
						images={this.state.id}
						messages={this.props.messages}
						title={this.props.messages.documents.textN16}
						subtitle={
							this.props.messages.documents.textN17 + " " + (this.props.loan.type === "Title Loan" ? this.props.messages.documents.textN18 : "")
						}
					/>
					<Document
						needs={1}
						id={"residence"}
						images={this.state.residence}
						messages={this.props.messages}
						title={this.props.messages.documents.textN19}
						subtitle={this.props.messages.documents.textN20}
					/>
					{this.props.loan.type === "Personal Loan" ? (
						<Document
							needs={1}
							id={"incomeProofFiles"}
							images={this.state.incomeProofFiles}
							messages={this.props.messages}
							title={this.props.messages.documents.textN21}
							subtitle={this.props.messages.documents.textN22}
						/>
					) : null}
					{this.props.loan.type === "Title Loan" || (this.props.loan.type === "Personal Loan" && this.props.loan.vehicle) ? (
						<>
							<Document
								needs={2}
								id={"title"}
								images={this.state.title}
								messages={this.props.messages}
								title={this.props.messages.documents.textN23}
								subtitle={this.props.messages.documents.textN24}
							/>
							<Document
								needs={1}
								id={"registration"}
								images={this.state.registration}
								messages={this.props.messages}
								title={this.props.messages.documents.textN25}
								subtitle={this.props.messages.documents.textN26}
							/>
							<Document
								needs={8}
								id={"vehicle"}
								images={this.state.vehicle}
								messages={this.props.messages}
								title={this.props.messages.documents.textN27}
								subtitle={this.props.messages.documents.textN28}
							/>
						</>
					) : null}

					{this.props.loan.type === "Personal Loan" ? (
						<>
							<Document
								needs={1}
								id={"selfie"}
								images={this.state.selfie}
								messages={this.props.messages}
								title={this.props.messages.documents.textN33}
								subtitle={
									this.props.messages.documents.textN34 +
									" " +
									this.props.messages.documents.textN35 +
									" " +
									this.props.messages.documents.textN36
								}
							/>
							<Document
								id={"bankStatement"}
								accept={"application/pdf,image/*"}
								images={this.state.bankStatement}
								messages={this.props.messages}
								title={this.props.messages.documents.bankStatement.title}
								subtitle={this.props.messages.documents.bankStatement.description}
							/>
						</>
					) : null}

					<Document
						needs={this.props.loan.type === "Personal Loan" ? 0 : 1}
						id={"additional"}
						images={this.state.additional}
						messages={this.props.messages}
						title={
							this.props.messages.documents.textN46 +
							" " +
							(this.props.loan.type === "Title Loan" ? this.props.messages.documents.textN37 : "") +
							" " +
							this.props.messages.documents.textN45
						}
						subtitle={
							this.props.messages.documents.textN42 +
							" " +
							(this.props.loan.type === "Title Loan" ? this.props.messages.documents.textN39 : this.props.messages.documents.textN40)
						}
					/>

					<Row justify={"center"}>
						<Col xs={24} lg={6}>
							<Button type={"primary"} block disabled={!this.state.ready} onClick={this._save}>
								{this.props.messages.documents.textN41}
							</Button>
						</Col>
					</Row>
				</div>
			</Page>
		);
	}
}
