import React, { Component } from "react";
import Step from "../../../components/step";
import Page from "../../../components/page";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import Swal from "sweetalert2";
import Carform from "../../../components/carForm";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Employment from "../../../components/employment";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 2,
			hasVehicle: false,
			titleBoost: false,
			mileage: null,
			uvc: null,
			vin: null,

			employment: null,
			employmentActive: null,
			employmentMonths: null,
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (!this.props.uid) {
			return this.props.history.push("/login");
		} else {
			return this._loadData();
		}
	}

	_loadData = async () => {
		const res = await firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.get();

		if (res.data().vin) {
			const blackbook = await axios.get(
				"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/VIN/" + res.data().vin + "?customerid=website",
				{
					headers: {
						Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
					},
				}
			);
			const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
			if (vehicle) {
				this.setState({
					vMake: vehicle.make,
					vModelYear: vehicle["model_year"],
					vModel: vehicle.model,
					vSeries: vehicle.series,
				});
			} else {
				if (res.data().uvc) {
					const blackbook = await axios.get(
						"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" + res.data().uvc + "?customerid=website",
						{
							headers: {
								Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
							},
						}
					);
					const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
					if (vehicle) {
						this.setState({
							vMake: vehicle.make,
							vModelYear: vehicle["model_year"],
							vModel: vehicle.model,
							vSeries: vehicle.series,
						});
					}
				}
			}
		}

		this.setState({
			hasVehicle: res.data().hasVehicle,
			titleBoost: res.data().titleBoost,
			vin: res.data().using === "uvc" ? "" : res.data().vin,
			uvc: res.data().uvc,
			mileage: res.data().mileage,
			reliability: res.data().reliability,
			condition: res.data().condition,
			employment: res.data().employment,
			employmentActive: res.data().employmentActive,
		});
	};

	submitForm = event => {
		event.preventDefault();
		if (this.state.titleBoost) {
			return axios
				.get(
					"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/VIN/" +
						this.state.vin +
						"?mileage=" +
						this.state.mileage +
						"&customerid=website",
					{
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					}
				)
				.then(res => {
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "vin");
				})
				.catch(() => {
					return this._checkUCV();
				});
		} else {
			return this._saveToFirebase(null, null);
		}
	};

	_checkUCV = () => {
		if (this.state.uvc) {
			return axios
				.get(
					"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
						this.state.uvc +
						"?mileage=" +
						this.state.mileage +
						"&customerid=website",
					{
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					}
				)
				.then(res => {
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
				})
				.catch(() => {
					return this._findManually();
				});
		} else {
			return this._findManually();
		}
	};

	_findManually = () => {
		return withReactContent(Swal)
			.fire({
				title: `<h4>${this.props.messages.credit.textN01}</h4>`,
				html: <Carform uvc={e => this.getCarValue(e)} {...this.props} />,
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: true,
				reverseButtons: true,
				confirmButtonText: "Done",
				cancelButtonText: "Cancel",
			})
			.then(async result => {
				if (result.value) {
					if (this.state.uvc) {
						const url =
							"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
							this.state.uvc +
							"?mileage=" +
							this.state.mileage +
							"&customerid=website";
						const res = await axios.get(url, {
							headers: {
								Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
							},
						});
						return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
					} else {
						return Swal.fire(this.props.messages.commText.oops, this.props.messages.credit.textN02, "warning");
					}
				}
				return null;
			});
	};

	_changeVehicle = () => {
		return withReactContent(Swal)
			.fire({
				title: `<h4>${this.props.messages.credit.textN03}</h4>`,
				html: <Carform uvc={e => this.getCarValue(e)} props={this.props} />,
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: true,
				reverseButtons: true,
				confirmButtonText: "Done",
				cancelButtonText: "Cancel",
			})
			.then(async result => {
				if (result.value) {
					if (this.state.uvc) {
						const url =
							"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" + this.state.uvc + "?mileage=" + this.state.mileage
								? this.state.mileage
								: 100000 + "&customerid=website";
						const res = await axios.get(url, {
							headers: {
								Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
							},
						});
						return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
					} else {
						return Swal.fire(this.props.messages.commText.oops, this.props.messages.credit.textN04, "warning");
					}
				}
				return null;
			});
	};

	getCarValue = e => {
		this.setState({
			uvc: e,
		});
	};

	_saveToFirebase = async (carPrice, using) => {
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fourMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fiveMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/sixMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/nineMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/twelveMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fifteenMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/eighteenMonths")
			.delete();

		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 2,
				lastUpdate: "User",
				returning: false,

				hasVehicle: this.state.hasVehicle,
				titleBoost: this.state.titleBoost ? this.state.titleBoost : false,
				vin: this.state.vin,
				uvc: this.state.uvc ? this.state.uvc : null,
				using: using,
				reliability: this.state.reliability,
				condition: this.state.condition,
				mileage: this.state.mileage,
				carPrice: carPrice ? carPrice : null,

				employmentActive: this.state.employmentActive ?? null,
				timestamp: moment().toDate(),
			})
			.then(() => {
				this.props.onUpdateLoan({
					status: 2,
					type: this.props.loan.type,
					term: this.props.loan.term,
					vehicle: this.state.titleBoost,
				});
				return this.props.history.push("/offer");
			});
	};

	_handleCheckChange = e => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};

	_handleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	render() {
		return (
			<Page {...this.props}>
				<Step title={this.props.messages.credit.textN39} subtitle={this.props.messages.credit.textN40} />
				<div className="container pt-1 pb-5 text-center" id="discounts">
					<form onSubmit={this.submitForm} id="application">
						{this.state.employment ? (
							<Employment
								name={this.state.employment}
								active={this.state.employmentActive}
								title={this.props.messages.credit.textN42}
								subtitle={this.props.messages.credit.textN43}
								onChange={e => this.setState({ employmentActive: e })}
							/>
						) : null}
						<h6>
							{this.props.messages.credit.textN12} <b>{this.props.messages.credit.textN13}</b> {this.props.messages.credit.textN14}{" "}
							<u>{this.props.messages.credit.textN15}</u> {this.props.messages.credit.textN16}
						</h6>
						<br />
						<br />
						<div className="yseparator center" />
						<br />
						<h3>{this.props.messages.credit.textN17}</h3>
						<div className="text-center row mt-2 justify-content-center">
							<div className="row justify-content-center mb-4">
								<div className="col-6 text-left">{this.props.messages.credit.textN18}</div>
								<div className="col-4">
									<label className="switch ml-0">
										<input type="checkbox" name="titleBoost" checked={this.state.titleBoost} onChange={this._handleCheckChange} />
										<span className="slider round" />
									</label>
								</div>
							</div>
						</div>
						<div className={!this.state.titleBoost ? "hidden" : null}>
							<p>{this.state.vModel ? `${this.state.vModelYear} ${this.state.vMake} ${this.state.vModel} ${this.state.vSeries}` : null}</p>
							<p>
								{this.state.vModel ? (
									<span className="link" onClick={this._changeVehicle}>
										{this.props.messages.credit.textN19}
									</span>
								) : null}
							</p>
							<br />
							<br />
							<div className="row">
								<div className="col-lg-6 cool-form-group row align-items-center">
									<input
										type="text"
										defaultValue={this.state.vin}
										placeholder={this.props.messages.credit.textN20}
										className="col"
										name="vin"
										id="vinInput"
										onChange={this._handleTextChange}
									/>
									<i
										className="fas fa-question-circle"
										onClick={() => Swal.fire(this.props.messages.credit.textN21, this.props.messages.credit.textN22, "question")}
									/>
								</div>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<input
										type="text"
										required={this.state.titleBoost}
										defaultValue={this.state.mileage}
										placeholder={this.props.messages.credit.textN23}
										className="col"
										name="mileage"
										onChange={this._handleTextChange}
									/>
									<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.credit.textN24, "", "question")} />
								</div>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<select
										name="reliability"
										required={this.state.loanType === "Title Loan" || this.state.titleBoost}
										value={this.state.reliability}
										className="col"
										onChange={this._handleTextChange}>
										<option value="">{this.props.messages.credit.textN25}</option>
										<option>{this.props.messages.credit.textN26}</option>
										<option>{this.props.messages.credit.textN27}</option>
										<option>{this.props.messages.credit.textN28}</option>
									</select>
									<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.credit.textN29, "", "question")} />
								</div>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<select
										name="condition"
										required={this.state.loanType === "Title Loan" || this.state.titleBoost}
										value={this.state.condition}
										className="col"
										onChange={this._handleTextChange}>
										<option value="">{this.props.messages.credit.textN35}</option>
										<option>{this.props.messages.credit.textN36}</option>
										<option>{this.props.messages.credit.textN37}</option>
										<option>{this.props.messages.credit.textN38}</option>
									</select>
									<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.credit.textN30, "", "question")} />
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							<button type="submit" className="btn btn--sm btn--color btn--button col-12 col-md-4 mt-5">
								{this.props.messages.credit.textN34}
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}
}
