const faq = [
	{
		question: "¿Cómo configuro los pagos automáticos?",
		answer:
			"Los pagos automáticos son la forma más fácil (¡y gratis!) De hacer los pagos de su préstamo a tiempo. Inscríbase hoy llamando a Discount Car Title Loan Online (dtloans.com). Necesitará su cuenta bancaria o de la cooperativa de crédito y el número de ruta (consulte a continuación) para la configuración única.",
	},
	{
		question: "¿Cómo puedo pagar mi préstamo personal o el préstamo sobre el título del auto?",
		answer: "Los clientes siempre pueden realizar pagos en cualquier ubicación física en línea o con tarjeta de débito en línea o con tarjeta de débito.",
	},
	{
		question: "¿Puedo cancelar mi préstamo después de ser aprobado?",
		answer:
			"El préstamo de título de automóvil con descuento en línea (dtloans.com) permitirá a los solicitantes cancelar sus préstamos dentro de los 3 días posteriores a la recepción de su anticipo. Si el capital original se paga antes del período de gracia de 3 días, generalmente no será responsable de ningún cargo por intereses.",
	},
	{
		question: "¿Cuánto tiempo tengo para pagar mi préstamo personal o el préstamo sobre el título del automóvil?",
		answer:
			"Los plazos y las fechas de vencimiento de los préstamos personales y del título de automóvil varían según el estado. Discount Car Title Loan Online (dtloans.com) le proporcionará esta información después de que haya sido aprobado completamente para un préstamo personal o un préstamo sobre el título del automóvil.",
	},
	{
		question: "¿Hay una multa por pago anticipado?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) no cobra multas por pago anticipado. Una multa por pago anticipado es una tarifa a la que se accede cuando paga su préstamo antes de la fecha de vencimiento.",
	},
	{
		question: "¿Qué sucede si llego tarde a mi pago?",
		answer:
			"Si se atrasa con el pago de su préstamo personal o del título de su automóvil, es posible que se le cobren tarifas adicionales. El número de tarifas variará según el estado y el prestamista. Además de cobrarle una tarifa, puede haber otras consecuencias si el préstamo no se paga. Algunos prestamistas pueden optar por informar su morosidad a todas o una de las tres agencias de crédito, enviar su información a un servicio de cobro o confiscar su vehículo y venderlo a un tercero.",
	},
	{
		question: "¿Cómo recupero mi título? Cuánto tiempo se tarda?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) le devolverá el título del vehículo una vez que haya terminado de pagar el préstamo completo. Si completa su préstamo de título con un prestamista que tiene una ubicación física, generalmente recibirá su título de inmediato. Si su préstamo sobre el título se completó en línea, puede demorar algunas semanas recibir su título nuevamente en el correo.",
	},
	{
		question: "¿Alguien más puede hacer un pago de préstamo por mí?",
		answer:
			"Si. Sin embargo, las leyes de privacidad limitan la información que los prestamistas pueden compartir sobre el préstamo. Por lo tanto, si desea que alguien realice un pago en su nombre, proporcione la autorización de Discount Car Title Loan Online (dtloans.com) para realizar transacciones con esa persona en su préstamo, o asegúrese de que tenga la siguiente información para hacer un pago: el nombre y el número de teléfono del préstamo y el monto del pago que desea realizar.",
	},
];

export default faq;
