import React, { Component } from "react";
import { Select } from "antd";

export default class CarForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			years: props.blackbook["year_list"],
			makes: props.blackbook["year_list"][0]["make_list"],
			models: props.blackbook["year_list"][0]["make_list"][0]["model_list"],
			trims: props.blackbook["year_list"][0]["make_list"][0]["model_list"][0]["series_list"],
			styles: props.blackbook["year_list"][0]["make_list"][0]["model_list"][0]["series_list"][0]["style_list"],
			uvc: props.blackbook["year_list"][0]["make_list"][0]["model_list"][0]["series_list"][0]["style_list"][0]["uvc"],
		};
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: any): void {
		this.props.uvc(this.state.uvc);
	}

	_changeYear = e => {
		this.setState(
			{
				year: e,
				make: "",
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeMake = e => {
		this.setState(
			{
				make: e,
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeModel = e => {
		this.setState(
			{
				model: e,
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeTrim = e => {
		this.setState(
			{
				trim: e,
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_reloadVehicles = async () => {
		await this.setState({
			makes: this.state.year ? this.state.years.find(x => x.name === this.state.year)["make_list"] : this.state.years[0]["make_list"],
		});
		await this.setState({
			models: this.state.make ? this.state.makes.find(x => x.name === this.state.make)["model_list"] : this.state.makes[0]["model_list"],
		});
		await this.setState({
			trims: this.state.model ? this.state.models.find(x => x.name === this.state.model)["series_list"] : this.state.models[0]["series_list"],
		});
		await this.setState({
			styles: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim)["style_list"] : this.state.trims[0]["style_list"],
		});
		await this.setState({
			uvc: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim)["style_list"][0]["uvc"] : this.state.styles[0]["uvc"],
		});
	};
	render() {
		return (
			<div>
				{this.props.messages.carForm.textn01}:
				<Select style={{ width: "100%", marginBottom: 12 }} onChange={this._changeYear}>
					{this.state.years.map(item => {
						return (
							<Select.Option key={item.name} value={item.name}>
								{item.name}
							</Select.Option>
						);
					})}
				</Select>
				{this.props.messages.carForm.textn02}:
				<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.year} onChange={this._changeMake}>
					{this.state.makes.map(item => {
						return (
							<Select.Option key={item.name} value={item.name}>
								{item.name}
							</Select.Option>
						);
					})}
				</Select>
				{this.props.messages.carForm.textn03}:
				<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.make} onChange={this._changeModel}>
					{this.state.models.map(item => {
						return (
							<Select.Option key={item.name} value={item.name}>
								{item.name}
							</Select.Option>
						);
					})}
				</Select>
				{this.props.messages.carForm.textn04}:
				<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.model} onChange={this._changeTrim}>
					{this.state.trims.map(item => {
						return (
							<Select.Option key={item.name} value={item.name}>
								{item.name}
							</Select.Option>
						);
					})}
				</Select>
			</div>
		);
	}
}
