import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Select, Tooltip, Modal } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import findVehicle from "../../helpers/findVehicle";
import CarForm from "../carForm";

class Vehicle extends PureComponent {
	state = {
		loading: false,
		modal: false,

		mileage: null,
		reliability: null,
		condition: null,

		model: null,
		year: null,
		series: null,

		uvc: null,
		vin: null,
		price: null,
	};

	changeVin = async e => {
		const vin = e.target.value;
		await this.setState({ vin });
		if (vin.length === 17) {
			const vehicle = await findVehicle("VIN", vin, this.state.mileage);
			return this.setState({
				price: vehicle["adjusted_tradein_avg"],
				make: vehicle["make"],
				model: vehicle["model"],
				year: vehicle["model_year"],
				series: vehicle["series"],
			});
		} else {
			return false;
		}
	};

	getInfo = async () => {
		const uvc = this.state.uvc;
		if (uvc) {
			await this.setState({ loading: true });
			const vehicle = await findVehicle("UVC", this.state.uvc, this.state.mileage);
			return this.setState({
				price: vehicle["adjusted_tradein_avg"],
				make: vehicle["make"],
				model: vehicle["model"],
				year: vehicle["model_year"],
				series: vehicle["series"],
				modal: false,
				loading: false,
			});
		} else {
			return false;
		}
	};

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: any): void {
		this.props.send({
			vin: this.state.vin,
			uvc: this.state.uvc,
			price: this.state.price,

			mileage: this.state.mileage,
			reliability: this.state.reliability,
			condition: this.state.condition,
		});
	}

	render() {
		return (
			<>
				<br />
				<br />
				<div className="yseparator center" />
				<br />
				<h3>{this.props.messages.aplication.textN25}</h3>
				<p>{this.props.messages.aplication.textN26}</p>
				<p>{this.state.model ? `${this.state.year} ${this.state.make} ${this.state.model} ${this.state.series}` : null}</p>
				<span className="link" onClick={() => this.setState({ modal: true })}>
					{this.state.model ? this.props.messages.aplication.textN68 : this.props.messages.aplication.textN68b}
				</span>
				<br />
				<br />

				<Row gutter={[0, 10]}>
					<Col xs={24} lg={12}>
						<Input
							required={!this.state.uvc}
							placeholder={this.props.messages.aplication.textN27}
							defaultValue={this.state.vin}
							onChange={this.changeVin}
							suffix={
								<Tooltip title={this.props.messages.aplication.textN29}>
									<QuestionCircleFilled />
								</Tooltip>
							}
						/>
					</Col>
					<Col xs={24} lg={12}>
						<Input
							required
							type={"number"}
							placeholder={this.props.messages.aplication.textN30}
							defaultValue={this.state.mileage}
							onChange={e => this.setState({ mileage: parseFloat(e.target.value) })}
							suffix={
								<Tooltip title={this.props.messages.aplication.textN30}>
									<QuestionCircleFilled />
								</Tooltip>
							}
						/>
					</Col>
					<Col xs={24} lg={12}>
						<Select
							value={this.state.reliability}
							style={{ width: "100%" }}
							placeholder={this.props.messages.aplication.textN31}
							onChange={e => this.setState({ reliability: e })}>
							<Select.Option value={"Very Reliable"}>{this.props.messages.aplication.textN32}</Select.Option>
							<Select.Option value={"Somewhat Reliable"}>{this.props.messages.aplication.textN33}</Select.Option>
							<Select.Option value={"Not Very Reliable"}>{this.props.messages.aplication.textN34}</Select.Option>
						</Select>
					</Col>
					<Col xs={24} lg={12}>
						<Select
							value={this.state.condition}
							style={{ width: "100%" }}
							placeholder={this.props.messages.aplication.textN35}
							onChange={e => this.setState({ condition: e })}>
							<Select.Option value={"Looks Great"}>{this.props.messages.aplication.textN36}</Select.Option>
							<Select.Option value={"Looks OK"}>{this.props.messages.aplication.textN37}</Select.Option>
							<Select.Option value={"Not Super Pretty"}>{this.props.messages.aplication.textN38}</Select.Option>
						</Select>
					</Col>
				</Row>

				<Modal
					onOk={this.getInfo}
					confirmLoading={this.state.loading}
					onCancel={() => {
						this.setState({ modal: false });
					}}
					visible={this.state.modal}>
					<CarForm uvc={e => this.setState({ uvc: e })} messages={this.props.messages} blackbook={this.props.blackbook} />
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		blackbook: state.blackbook,
	};
};

export default connect(mapStateToProps)(Vehicle);
