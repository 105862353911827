import React, { Component } from "react";
import { Modal, Radio } from "antd";
import Step from "../components/step";
import queryString from "query-string";
import Separator from "../components/separator";
import Page from "../components/page";
import { firestore } from "../firebase";
import moment from "moment";
import Swal from "sweetalert2";
import Carform from "../components/carForm";
import axios from "axios";

import { Tooltip } from "antd";

import { osName } from "react-device-detect";
import assignStore from "../utils/assignStore";
import CreditHistory from "./application/personal/creditHistory";

///Monthly Household Income
function isWeird(myString) {
	const hasNumber = /\d/g.test(myString);
	const hasSign = /@/g.test(myString);
	return hasNumber || hasSign;
}

export default class extends Component {
	constructor(props) {
		super(props);
		const newAccount = queryString.parse(this.props.location.search).c;
		this.state = {
			step: 2,
			newAccount: newAccount,
			loanType: "Title Loan",
			ssn: null,
			hasVehicle: false,
			differentAddress: false,
			utilityBill: false,
			mortgageBill: false,
			uvc: null,
			vin: null,
			reliability: "",
			condition: "",
			living: 0,

			homeState: "TX",
			mailingState: "TX",

			vMake: null,
			vModelYear: null,
			vModel: null,
			vSeries: null,
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		document.querySelector("#vinInput").addEventListener("keypress", function(evt) {
			if (evt.key === "i" || evt.key === "I" || evt.key === "o" || evt.key === "O") {
				evt.preventDefault();
				return Swal.fire(this.props.messages.commText.oops, this.props.messages.aplication.textN03, "warning");
			} else {
				return null;
			}
		});

		if (!this.props.uid) {
			return this.props.history.push("/login");
		} else {
			this.checkPageType();
			return this._loadData();
		}
	}

	_loadData = async () => {
		const res = await firestore()
			.doc("applications/" + this.props.uid)
			.get();

		if (res.data().vin && res.data().using === "vin") {
			const blackbook = await axios.get(
				"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/VIN/" + res.data().vin + "?customerid=website",
				{
					headers: {
						Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
					},
				}
			);
			const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
			if (vehicle) {
				this.setState({
					vMake: vehicle.make,
					vModelYear: vehicle["model_year"],
					vModel: vehicle.model,
					vSeries: vehicle.series,
				});
			}
		} else if (res.data().uvc && res.data().using === "uvc") {
			const blackbook = await axios.get(
				"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" + res.data().uvc + "?customerid=website",
				{
					headers: {
						Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
					},
				}
			);
			const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
			if (vehicle) {
				this.setState({
					vMake: vehicle.make,
					vModelYear: vehicle["model_year"],
					vModel: vehicle.model,
					vSeries: vehicle.series,
				});
			}
		}
		this.setState({
			uvc: res.data().uvc ? res.data().uvc : null,
			mileage: res.data().mileage ? res.data().mileage : null,
		});
		if (res.data().loanType) {
			const user = await firestore()
				.doc("users/" + this.props.uid)
				.get();
			this.setState(
				{
					ssn: user.data().ssn ? user.data().ssn : null,
					loanType: res.data().loanType,
					hasVehicle: res.data().hasVehicle,
					income: res.data().income,
					household: res.data().household,
					householdIncome: res.data().householdIncome,
					maritalStatus: res.data().maritalStatus,
					mortgage: res.data().mortgage,
					debtPayments: res.data().debtPayments,
					payrollType: res.data().payrollType,

					vin: res.data().using === "uvc" ? "" : res.data().vin,
					mileage: res.data().mileage,
					reliability: res.data().reliability,
					condition: res.data().condition,

					home: res.data().homeAddress,
					homeCity: res.data().homeCity,
					homeState: res.data().homeState,
					homeZip: res.data().homeZip,
					differentAddress: res.data().differentAddress,
					mailing: res.data().mailingAddress ? res.data().mailingAddress : res.data().homeAddress,
					mailingCity: res.data().mailingCity ? res.data().mailingCity : res.data().homeCity,
					mailingState: res.data().mailingState ? res.data().mailingState : res.data().homeState,
					mailingZip: res.data().mailingZip ? res.data().mailingZip : res.data().homeZip,
					living: res.data().living,
				},
				() => {
					this.checkPageType();
				}
			);
		}
	};

	checkPageType = () => {
		if (process.env.REACT_APP_TYPE === "title") {
			this.setState({ loanType: "Title Loan" });
		} else if (process.env.REACT_APP_TYPE === "finance") {
			this.setState({ loanType: "Personal Loan" });
		}
	};

	submitForm = event => {
		event.preventDefault();
		if (!this.state.loanType) {
			return Swal.fire(this.props.messages.aplication.textN04, this.props.messages.aplication.textN05, "warning");
		} else if (this.state.loanType === "Title Loan") {
			return axios
				.get(
					"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/VIN/" +
						this.state.vin +
						"?mileage=" +
						this.state.mileage +
						"&customerid=website",
					{
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					}
				)
				.then(res => {
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "vin");
				})
				.catch(() => {
					return this._checkUCV();
				});
		} else {
			return this._saveToFirebase(null, "uvc");
		}
	};

	_checkUCV = () => {
		if (this.state.uvc) {
			return axios
				.get(
					"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
						this.state.uvc +
						"?mileage=" +
						this.state.mileage +
						"&customerid=website",
					{
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					}
				)
				.then(res => {
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
				})
				.catch(() => {
					return this._findManually();
				});
		} else {
			return this._findManually();
		}
	};

	_findManually = () => {
		return Modal.confirm({
			title: <h4>{this.props.messages.aplication.textN06}</h4>,
			icon: null,
			content: <Carform uvc={e => this.getCarValue(e)} {...this.props} />,
			onOk: async () => {
				if (this.state.uvc) {
					const url =
						"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
						this.state.uvc +
						"?mileage=" +
						(this.state.mileage ? this.state.mileage : 100000) +
						"&customerid=website";
					const res = await axios.get(url, {
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					});
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
				} else {
					return Modal.error({
						title: this.props.messages.commText.oops,
						content: this.props.messages.aplication.textN08,
					});
				}
			},
		});
	};

	_changeVehicle = () => {
		return Modal.confirm({
			icon: null,
			content: <Carform uvc={e => this.getCarValue(e)} {...this.props} />,
			onOk: async () => {
				if (this.state.uvc) {
					const url =
						"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
						this.state.uvc +
						"?mileage=" +
						(this.state.mileage ? this.state.mileage : 100000) +
						"&customerid=website";
					const res = await axios.get(url, {
						headers: {
							Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
						},
					});
					return this._saveToFirebase(res.data["used_vehicles"]["used_vehicle_list"][0]["adjusted_tradein_avg"], "uvc");
				} else {
					return Modal.error({
						title: this.props.messages.commText.oops,
						content: this.props.messages.aplication.textN08,
					});
				}
			},
		});
	};

	getCarValue = e => {
		this.setState({
			uvc: e,
		});
	};

	_saveToFirebase = async (carPrice, using) => {
		if (isWeird(this.state.homeCity)) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.aplication.textN09, "warning");
		}

		const re = /[0-9]+/g;
		if (this.state.loanType === "Personal Loan" && !re.test(this.state.ssn)) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.aplication.textN10, "warning");
		}

		const store = await assignStore(this.state.homeZip, this.state.loanType);
		await firestore()
			.doc("applications/" + this.props.uid + "/data/fourMonths")
			.delete();
		await firestore()
			.doc("applications/" + this.props.uid + "/data/fiveMonths")
			.delete();
		await firestore()
			.doc("applications/" + this.props.uid + "/data/sixMonths")
			.delete();
		await firestore()
			.doc("users/" + this.props.uid)
			.update({
				ssn: this.state.ssn,
			});
		await firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 1,
				store: store,
				lastUpdate: "User",
				loanType: this.state.loanType,
				hasVehicle: this.state.loanType === "Title Loan" || this.state.uvc,

				income: this.state.income,
				household: this.state.loanType === "Title Loan" ? this.state.household : null,
				householdIncome: this.state.loanType === "Personal Loan" ? this.state.householdIncome : null,
				maritalStatus: this.state.loanType === "Personal Loan" ? this.state.maritalStatus : null,
				mortgage: this.state.loanType === "Title Loan" ? this.state.mortgage : null,
				debtPayments: this.state.debtPayments,
				payrollType: this.state.loanType === "Title Loan" ? this.state.payrollType : null,

				vin: this.state.vin,
				uvc: this.state.uvc ? this.state.uvc : null,
				using: this.state.loanType === "Title Loan" ? using : null,
				reliability: this.state.reliability,
				condition: this.state.condition,
				mileage: this.state.mileage,
				carPrice: carPrice ? carPrice : null,

				homeAddress: this.state.home,
				homeCity: this.state.homeCity,
				homeState: this.state.homeState,
				homeZip: this.state.homeZip,
				differentAddress: this.state.differentAddress,
				mailingAddress: this.state.differentAddress ? this.state.mailing : this.state.home,
				mailingCity: this.state.differentAddress ? this.state.mailingCity : this.state.homeCity,
				mailingState: this.state.differentAddress ? this.state.mailingState : this.state.homeState,
				mailingZip: this.state.differentAddress ? this.state.mailingZip : this.state.homeZip,
				living: this.state.loanType === "Title Loan" ? this.state.living : null,

				appleDevice: osName === "Mac OS" || osName === "iOS",
				timestamp: moment().toDate(),
			});
		window.gtag("event", "conversion", {
			send_to: "AW-974126037/2oUuCLGYsqgBENX3v9AD",
		});
		window.gtag("event", "conversion", {
			send_to: "AW-761506876/CDMmCPOAuqwBELzYjusC",
		});
		this.props.onUpdateLoan({
			status: 1,
			vehicle: this.props.loan.vehicle,
			type: this.state.loanType,
			term: this.props.loan.term,
		});
		if (this.state.loanType === "Title Loan") {
			return this.props.history.push("/discounts");
		} else {
			return this.props.history.push("/offer");
		}
	};

	_handleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	_handleNumberChange = e => {
		this.setState({
			[e.target.name]: Number(e.target.value),
		});
	};

	render() {
		return (
			<Page {...this.props}>
				<Step title={this.props.messages.aplication.textN80} subtitle={this.props.messages.aplication.textN81} />
				<div className="container pt-1 pb-5" id="application">
					{process.env.REACT_APP_TYPE === "all" ? (
						<div style={{ textAlign: "center" }}>
							<h4>{this.props.messages.aplication.textN13}</h4>
							<br />
							<Radio.Group
								options={[
									{
										value: "Personal Loan",
										label: this.props.messages.aplication.textN14t + " " + this.props.messages.aplication.textN14,
									},
									{ value: "Title Loan", label: this.props.messages.aplication.textN15t + " " + this.props.messages.aplication.textN15 },
								]}
								value={this.state.loanType}
								onChange={e => this.setState({ loanType: e.target.value })}
								optionType="button"
								buttonStyle="solid"
							/>
							<br />
							<Separator />
						</div>
					) : null}

					<br />
					<p>{this.props.messages.aplication.textN16}</p>
					<form className="container" onSubmit={this.submitForm} id="application" autoComplete="off">
						<br />
						<br />
						<div className="yseparator center" />
						<br />
						<h3>{this.props.messages.aplication.textN17}</h3>
						<br />
						<br />
						<div className="row">
							{this.state.loanType === "Title Loan" ? (
								<Tooltip placement="top" title={"Don’t forget to include income from EVERYONE living in your household"}>
									<div className="col-lg-6 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="number"
											placeholder={"$ " + this.props.messages.aplication.textN82}
											defaultValue={this.state.income}
											className="col"
											name="income"
											required
											onChange={this._handleNumberChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() =>
												Swal.fire(this.props.messages.aplication.textN82, this.props.messages.aplication.textN83, "question")
											}
										/>
									</div>
								</Tooltip>
							) : (
								<div className="col-lg-6 cool-form-group row align-items-center">
									<input
										autoComplete="off"
										type="number"
										placeholder={"$ " + this.props.messages.aplication.textN82}
										defaultValue={this.state.income}
										className="col"
										name="income"
										required
										onChange={this._handleNumberChange}
									/>
									<i
										className="fas fa-question-circle"
										onClick={() => Swal.fire(this.props.messages.aplication.textN82, this.props.messages.aplication.textN83, "question")}
									/>
								</div>
							)}
							<div className="col-lg-6 cool-form-group row align-items-center">
								{this.state.loanType === "Title Loan" ? (
									<React.Fragment>
										<select name="household" required value={this.state.household} className="col" onChange={this._handleNumberChange}>
											<option value=""># {this.props.messages.aplication.textN18}</option>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option value="5">{this.props.messages.aplication.textN19}</option>
										</select>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN62, "", "question")}
										/>
									</React.Fragment>
								) : (
									<React.Fragment>
										<input
											autoComplete="off"
											type="number"
											placeholder={"$ " + this.props.messages.aplication.textN84}
											defaultValue={this.state.householdIncome}
											className="col"
											name="householdIncome"
											onChange={this._handleNumberChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() =>
												Swal.fire(this.props.messages.aplication.textN84, this.props.messages.aplication.textN85, "question")
											}
										/>
									</React.Fragment>
								)}
							</div>
							{this.state.loanType === "Title Loan" ? (
								<div className="col-lg-6 cool-form-group row align-items-center">
									<input
										autoComplete="off"
										type="number"
										placeholder={this.props.messages.aplication.textN63}
										defaultValue={this.state.mortgage}
										className="col"
										name="mortgage"
										required
										onChange={this._handleNumberChange}
									/>
									<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN64, "", "question")} />
								</div>
							) : null}
							<div className="col-lg-6 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="number"
									placeholder={this.props.messages.aplication.textN65}
									defaultValue={this.state.debtPayments}
									className="col"
									name="debtPayments"
									required
									onChange={this._handleNumberChange}
								/>
								<i
									className="fas fa-question-circle"
									onClick={() => Swal.fire(this.props.messages.aplication.textN66, this.props.messages.aplication.textN67, "question")}
								/>
							</div>
							{this.state.loanType === "Personal Loan" ? (
								<div className="col-lg-6 cool-form-group row align-items-center">
									<select
										name="maritalStatus"
										placeholder="Marital Status"
										required
										value={this.state.maritalStatus}
										className="col"
										onChange={this._handleTextChange}>
										<option value="">Marital Status</option>
										<option value="Unmarried">Unmarried</option>
										<option value="Married">Married</option>
										<option value="Separated">Separated</option>
									</select>
								</div>
							) : null}
						</div>
						{this.state.loanType === "Title Loan" ? (
							<div className="row">
								<div className="col-lg-12 cool-form-group row align-items-center">
									<select
										name="payrollType"
										placeholder="How do you get paid? (Select One)"
										required
										value={this.state.payrollType}
										className="col"
										onChange={this._handleTextChange}>
										<option value="">{this.props.messages.aplication.textN20}</option>
										<option>{this.props.messages.aplication.textN21}</option>
										<option>{this.props.messages.aplication.textN22}</option>
										<option value="DirectDeposit">{this.props.messages.aplication.textN23}</option>
									</select>
									<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN24, "", "question")} />
								</div>
							</div>
						) : null}
						{this.state.loanType === "Title Loan" ? (
							<div>
								<br />
								<br />
								<div className="yseparator center" />
								<br />
								<h3>{this.props.messages.aplication.textN25}</h3>
								<p style={{ color: "#000" }}>{this.props.messages.aplication.textN26}</p>
								<p>{this.state.vModel ? `${this.state.vModelYear} ${this.state.vMake} ${this.state.vModel} ${this.state.vSeries}` : null}</p>
								<p>
									{this.state.vModel ? (
										<span className="link" onClick={this._changeVehicle}>
											{this.props.messages.aplication.textN68}
										</span>
									) : null}
								</p>
								<br />
								<br />
								<div className="row">
									<div className="col-lg-6 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="text"
											defaultValue={this.state.vin}
											placeholder={this.props.messages.aplication.textN27}
											className="col"
											name="vin"
											id="vinInput"
											onChange={this._handleTextChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() =>
												Swal.fire(this.props.messages.aplication.textN28, this.props.messages.aplication.textN29, "question")
											}
										/>
									</div>
									<div className="col-lg-6 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="text"
											required={this.state.loanType === "Title Loan" || this.state.hasVehicle}
											defaultValue={this.state.mileage}
											placeholder={this.props.messages.aplication.textN30}
											className="col"
											name="mileage"
											onChange={this._handleTextChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN30, "", "question")}
										/>
									</div>
									<div className="col-lg-6 cool-form-group row align-items-center">
										<select
											name="reliability"
											required={this.state.loanType === "Title Loan" || this.state.hasVehicle}
											value={this.state.reliability}
											className="col"
											onChange={this._handleTextChange}>
											<option value="">{this.props.messages.aplication.textN31}</option>
											<option>{this.props.messages.aplication.textN32}</option>
											<option>{this.props.messages.aplication.textN33}</option>
											<option>{this.props.messages.aplication.textN34}</option>
										</select>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN69, "", "question")}
										/>
									</div>
									<div className="col-lg-6 cool-form-group row align-items-center">
										<select
											name="condition"
											required={this.state.loanType === "Title Loan" || this.state.hasVehicle}
											value={this.state.condition}
											className="col"
											onChange={this._handleTextChange}>
											<option value="">{this.props.messages.aplication.textN35}</option>
											<option>{this.props.messages.aplication.textN36}</option>
											<option>{this.props.messages.aplication.textN37}</option>
											<option>{this.props.messages.aplication.textN38}</option>
										</select>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN70, "", "question")}
										/>
									</div>
								</div>
							</div>
						) : null}

						<br />
						<br />
						<div className="yseparator center" />
						<br />
						<h3>{this.props.messages.aplication.textN39}</h3>
						<br />
						<br />
						<div className="row">
							<div className="col-lg-4 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="text"
									name="home"
									placeholder={this.props.messages.aplication.textN71}
									className="col"
									required
									defaultValue={this.state.home}
									onChange={this._handleTextChange}
								/>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN72, "", "question")} />
							</div>
							<div className="col-lg-3 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="text"
									name="homeCity"
									placeholder={this.props.messages.aplication.textN73}
									className="col"
									required
									defaultValue={this.state.homeCity}
									onChange={this._handleTextChange}
								/>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN74, "", "question")} />
							</div>
							<div className="col-lg-3 cool-form-group row align-items-center">
								<select
									name="homeState"
									className="col"
									placeholder={this.props.messages.aplication.textN75}
									required
									value={this.state.homeState}
									onChange={this._handleTextChange}>
									<option value="TX">Texas</option>
								</select>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN76, "", "question")} />
							</div>
							<div className="col-lg-2 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="number"
									name="homeZip"
									placeholder={this.props.messages.aplication.textN77}
									className="col"
									required
									defaultValue={this.state.homeZip}
									onChange={this._handleNumberChange}
								/>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN78, "", "question")} />
							</div>
						</div>
						<div className="row justify-content-center">
							{this.state.loanType === "Title Loan" ? (
								<div className="col-12 col-lg-6">
									<div className="row">
										<div className="col-7 pt-0 pt-lg-3 pl-0">{this.props.messages.aplication.textN40}</div>
										<div className="col-5 cool-form-group">
											<select name="living" required value={this.state.living} onChange={this._handleNumberChange}>
												<option value="0">{this.props.messages.aplication.textN41}</option>
												<option value="1">{this.props.messages.aplication.textN42}</option>
												<option value="2">{this.props.messages.aplication.textN43}</option>
											</select>
										</div>
									</div>
								</div>
							) : null}
							<div className="col-12 col-lg-6">
								<div className="row">
									<div className="col-7 pt-0 pt-xl-3 pl-0 pl-lg-3">{this.props.messages.aplication.textN44}</div>
									<div className="col-5 cool-form-group">
										<select name="differentAddress" required value={this.state.differentAddress} onChange={this._handleNumberChange}>
											<option value="true">{this.props.messages.aplication.textN45}</option>
											<option value="false">{this.props.messages.aplication.textN46}</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						{this.state.differentAddress ? (
							<div>
								<div className="row">
									<div className="col-lg-4 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="text"
											name="mailing"
											placeholder={this.props.messages.aplication.textN47}
											className="col"
											required
											defaultValue={this.state.mailing}
											onChange={this._handleTextChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN48, "", "question")}
										/>
									</div>
									<div className="col-lg-3 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="text"
											name="mailingCity"
											placeholder={this.props.messages.aplication.textN49}
											className="col"
											required
											defaultValue={this.state.mailingCity}
											onChange={this._handleTextChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN50, "", "question")}
										/>
									</div>
									<div className="col-lg-3 cool-form-group row align-items-center">
										<select
											name="mailingState"
											placeholder={this.props.messages.aplication.textN51}
											className="col"
											required
											value={this.state.mailingState}
											onChange={this._handleTextChange}>
											<option value="TX">Texas</option>
										</select>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN76, "", "question")}
										/>
									</div>
									<div className="col-lg-2 cool-form-group row align-items-center">
										<input
											autoComplete="off"
											type="number"
											name="mailingZip"
											placeholder={this.props.messages.aplication.textN52}
											className="col"
											required
											defaultValue={this.state.mailingZip}
											onChange={this._handleNumberChange}
										/>
										<i
											className="fas fa-question-circle"
											onClick={() => Swal.fire(this.props.messages.aplication.textN53, "", "question")}
										/>
									</div>
								</div>
							</div>
						) : null}
						{this.state.loanType === "Personal Loan" ? (
							<CreditHistory ssn={this.state.ssn} onChange={ssn => this.setState({ ssn })} messages={this.props.messages} />
						) : null}
						<div className="row justify-content-center">
							<button type="submit" className="btn btn--sm btn--color btn--button col-12 col-md-5 mt-5">
								{this.props.messages.aplication.textN61}
							</button>
						</div>
					</form>
				</div>

				{/*	Trackers */}
				{this.props.pixels[1] ? <img alt={"uberAd"} src={"https://serve.uberads.com/convert/" + this.props.pixels[1]} width="1" height="1" /> : null}
				{/*Facebook Pixel Code*/}
				<img alt="pixel" height="1" width="1" src="https://www.facebook.com/tr?id=460486821484380&ev=PageView&noscript=1" />
			</Page>
		);
	}
}
