import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { HashLink } from "react-router-hash-link";
import { Link as SmoothLink } from "react-scroll";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapse: false,
		};
	}

	toggle = () => {
		this.setState({
			collapse: !this.state.collapse,
		});
	};

	render() {
		return (
			<div>
				{process.env.REACT_APP_TYPE === "all" ? (
					<div className="footerWrapper py-3">
						<div className="container footer">
							<div className="footerSeparator" />
							<div className="row">
								<div className="col-12 col-md">
									{this.props.home ? (
										<SmoothLink smooth={true} className="link" to="top">
											{this.props.messages.footer.textN01}
										</SmoothLink>
									) : (
										<HashLink to={this.props.match.url}>{this.props.messages.footer.textN02}</HashLink>
									)}
								</div>
								<div className="col-12 col-md">
									{this.props.home ? (
										<SmoothLink smooth={true} className="link" to="how">
											{this.props.messages.footer.textN03}
										</SmoothLink>
									) : (
										<HashLink to={this.props.match.url + "#how"}>{this.props.messages.footer.textN04}</HashLink>
									)}
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/faq"}>{this.props.messages.footer.textN05}</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/about-us"}>{this.props.messages.footer.textN06}</HashLink>
								</div>
								<div className="col-12 col-md">
									<div className={"d-none d-md-block"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN07}</HashLink>
									</div>
									<div className={"d-block d-md-none"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN08}</HashLink>
									</div>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/contact"}>{this.props.messages.footer.textN09}</HashLink>
									{/* { this.props.uid ? <HashLink to={"/status"}>Application Status</HashLink> : <HashLink to={"/login">Log In</HashLink>} */}
								</div>
							</div>
							<div className="footerSeparator" />

							<strong>{this.props.messages.footer.textN10} </strong>
							{" " + this.props.messages.footer.textN11}
							<br />
							<br />
							{this.props.messages.footer.textN12}
							<br />
							<br />
							{this.props.messages.footer.textN13}
							<br />
							<br />
							<Collapse isOpen={this.state.collapse}>
								<strong>{this.props.messages.footer.textN14}</strong>
								{" " + this.props.messages.footer.textN15}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN16}</strong>
								{" " + this.props.messages.footer.textN17}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN18}</strong>
								{" " + this.props.messages.footer.textN19}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN20}</strong>
								{" " + this.props.messages.footer.textN21}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN22} </strong>
								{" " + this.props.messages.footer.textN23}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN24}</strong>
								{" " + this.props.messages.footer.textN25}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN26}</strong>
								{" " + this.props.messages.footer.textN27}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN28}</strong>
								{" " + this.props.messages.footer.textN29}
								<br />
								<br />
							</Collapse>
							<span style={{ cursor: "pointer" }} onClick={this.toggle}>
								<u>
									<b>
										{this.props.messages.footer.textN30}{" "}
										{this.state.collapse ? this.props.messages.footer.textN31 : this.props.messages.footer.textN32}
									</b>
								</u>
							</span>
						</div>
					</div>
				) : null}
				{process.env.REACT_APP_TYPE === "title" ? (
					<div className="footerWrapper py-3" style={{ backgroundColor: "#ff0000" }}>
						<div className="container footer" style={{ color: "#FFF" }}>
							<div className="footerSeparator" />
							<div className="row">
								<div className="col-12 col-md">
									{this.props.home ? (
										<SmoothLink smooth={true} className="link" to="#top"></SmoothLink>
									) : (
										<HashLink to="/">{this.props.messages.footer.textN02}</HashLink>
									)}
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/howitworks"}>{this.props.messages.footer.textN03}</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/faq"}>{this.props.messages.footer.textN05}</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/aboutus"}>{this.props.messages.footer.textN06}</HashLink>
								</div>
								<div className="col-12 col-md">
									<div className={"d-none d-md-block"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN07}</HashLink>
									</div>
									<div className={"d-block d-md-none"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN08}</HashLink>
									</div>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/contact"}>{this.props.messages.footer.textN09}</HashLink>
									{/* { this.props.uid ? <HashLink to={"/status"}>Application Status</HashLink> : <HashLink to={"/login">Log In</HashLink>} */}
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/careers"}>{this.props.messages.footer.textN33}</HashLink>
								</div>
							</div>
							<div className="footerSeparator" />

							<strong>{this.props.messages.footer.textN10T} </strong>
							{" " + this.props.messages.footer.textN11T}
							<br />
							<br />
							{this.props.messages.footer.textN12T}
							<HashLink to={"/scheduleOfAllFees"}>{" " + this.props.messages.footer.textN26T + " / "}</HashLink>
							<HashLink to={"/occcNotice"}>{this.props.messages.footer.textN27T}</HashLink>
							<br />
							<br />
							<Collapse isOpen={this.state.collapse}>
								<strong>{this.props.messages.footer.textN14T}</strong>
								{" " + this.props.messages.footer.textN15T}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN16T}</strong>
								{" " + this.props.messages.footer.textN17T}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN18T}</strong>
								{" " + this.props.messages.footer.textN19T}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN20T}</strong>
								{" " + this.props.messages.footer.textN21T}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN22T} </strong>
								{" " + this.props.messages.footer.textN23T}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN24T}</strong>
								{" " + this.props.messages.footer.textN25T}
								<br />
								<br />
							</Collapse>
							<span style={{ cursor: "pointer" }} onClick={this.toggle}>
								<u>
									<b>
										{this.props.messages.footer.textN30}{" "}
										{this.state.collapse ? this.props.messages.footer.textN31 : this.props.messages.footer.textN32}
									</b>
								</u>
							</span>
						</div>
					</div>
				) : null}
				{process.env.REACT_APP_TYPE === "finance" ? (
					<div className="footerWrapper py-3" style={{ backgroundColor: "#ffc63d" }}>
						<div className="container footer" style={{ color: "#FFF" }}>
							<div className="footerSeparator" />
							<div className="row">
								<div className="col-12 col-md">
									{this.props.home ? (
										<SmoothLink smooth={true} className="link" to="#top"></SmoothLink>
									) : (
										<HashLink to="/">{this.props.messages.footer.textN02}</HashLink>
									)}
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/howitworks"}>{this.props.messages.footer.textN03}</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/faq"}>{this.props.messages.footer.textN05}</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/home/aboutus"}>{this.props.messages.footer.textN06}</HashLink>
								</div>
								<div className="col-12 col-md">
									<div className={"d-none d-md-block"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN07}</HashLink>
									</div>
									<div className={"d-block d-md-none"}>
										<HashLink to={"/markets"}>{this.props.messages.footer.textN08}</HashLink>
									</div>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/contact"}>{this.props.messages.footer.textN09}</HashLink>
									{/* { this.props.uid ? <HashLink to={"/status"}>Application Status</HashLink> : <HashLink to={"/login">Log In</HashLink>} */}
								</div>
								{/*<div className="col-12 col-md">*/}
								{/*	<HashLink to={"/careers"}>{this.props.messages.footer.textN33}</HashLink>*/}
								{/*</div>*/}
								<div className="col-12 col-md">
									<HashLink to={"/privacy-notice.pdf"} target={"_blank"}>
										Privacy Notice
									</HashLink>
								</div>
								<div className="col-12 col-md">
									<HashLink to={"/sms-terms"}>SMS Terms</HashLink>
								</div>
							</div>
							<div className="footerSeparator" />

							<strong>{this.props.messages.footer.textN10F} </strong>
							{" " + this.props.messages.footer.textN11F}
							<br />
							<br />

							<Collapse isOpen={this.state.collapse}>
								<strong>{this.props.messages.footer.textN14F}</strong>
								{" " + this.props.messages.footer.textN15F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN16F}</strong>
								{" " + this.props.messages.footer.textN17F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN18F}</strong>
								{" " + this.props.messages.footer.textN19F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN20F}</strong>
								{" " + this.props.messages.footer.textN21F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN22F} </strong>
								{" " + this.props.messages.footer.textN23F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN24F}</strong>
								{" " + this.props.messages.footer.textN25F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN26F}</strong>
								{" " + this.props.messages.footer.textN27F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN28F}</strong>
								{" " + this.props.messages.footer.textN29F}
								<br />
								<br />
								<strong>{this.props.messages.footer.textN30F}</strong>
								{" " + this.props.messages.footer.textN31F}
								<br />
								<br />
							</Collapse>
							<span style={{ cursor: "pointer" }} onClick={this.toggle}>
								<u>
									<b>
										{this.props.messages.footer.textN30}{" "}
										{this.state.collapse ? this.props.messages.footer.textN31 : this.props.messages.footer.textN32}
									</b>
								</u>
							</span>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default Footer;
