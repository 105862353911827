import axios from "axios";

export default function(type: "UVC" | "VIN", id: string, mileage?: string | number) {
	return new Promise(async resolve => {
		const pre = "https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/";
		const url = `${type}/${id}?mileage=${mileage ? mileage : 1000000}`;

		const res = await axios.get(`${pre + url}&customerid=price-helper`, {
			headers: {
				Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
			},
		});
		const vehicle = res["data"]["used_vehicles"]["used_vehicle_list"][0];
		resolve(vehicle);
	});
}
