import React, { Component } from "react";
import Page from "../components/page";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			passwordValidator: [false, false, false, false, false],
			firstName: null,
			lastName: null,
			phone: null,
			phoneAge: "",
			provider: null,
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (!this.props.uid) {
			return this.props.history.push("/login");
		} else {
			firebase.auth().onAuthStateChanged(user => {
				if (user) {
					this.setState({
						user: user.displayName,
						email: user.email,
						provider: user.providerData[0].providerId,
					});
				}
			});

			return firebase
				.firestore()
				.doc("users/" + this.props.uid)
				.get()
				.then(res => {
					return this.setState({
						firstName: res.data().firstName,
						lastName: res.data().lastName,
						phone: res.data().phone,
						phoneAge: res.data().phoneAge,
					});
				});
		}
	}

	_update = async e => {
		e.preventDefault();
		await firebase
			.firestore()
			.doc("users/" + this.props.uid)
			.update({
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				phone: this.state.phone,
				phoneAge: JSON.parse(this.state.phoneAge),
			});
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				phoneAge: JSON.parse(this.state.phoneAge),
			});
		if (this.state.password) {
			this._changePassword();
		} else {
			this.props.history.push("/status");
		}
	};

	_changePassword = () => {
		const parent = this;
		if (this.state.passwordValidator.filter(e => e === false).length > 10) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.profile.textN01, "warning");
		} else {
			return Swal.fire({
				title: `<h4>${this.props.messages.profile.textN02}</h4>`,
				input: "password",
				inputAttributes: {
					autocapitalize: "off",
					placeholder: this.props.messages.profile.textN03,
				},
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.props.messages.profile.textN04,
			}).then(async res => {
				if (res.value) {
					const credential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, res.value);
					try {
						await firebase.auth().currentUser.reauthenticateWithCredential(credential);
						await firebase.auth().currentUser.updatePassword(parent.state.password);
						return parent.props.history.push("/status");
					} catch (e) {
						return await Swal.fire(this.props.messages.commText.oops, e.message, "warning");
					}
				}
				return null;
			});
		}
	};

	_changeEmail = async () => {
		const parent = this;
		const res = await Swal.fire({
			title: `<h4>${this.props.messages.profile.textN05}</h4>`,
			input: "email",
			inputAttributes: {
				autocapitalize: "off",
			},
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: this.props.messages.profile.textN06,
		});

		if (res.value) {
			await this.setState({
				newEmail: res.value,
			});

			const res2 = await Swal.fire({
				title: `<h4>${this.props.messages.profile.textN07}</h4>`,
				input: "password",
				inputAttributes: {
					autocapitalize: "off",
					placeholder: this.props.messages.profile.textN08,
				},
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.props.messages.profile.textN09,
			});

			if (res2.value) {
				const credential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, res.value);
				try {
					await firebase.auth().currentUser.reauthenticateWithCredential(credential);
					await firebase.auth().currentUser.updateEmail(parent.state.newEmail);
					await firebase
						.firestore()
						.doc("users/" + firebase.auth().currentUser.uid)
						.update({
							email: parent.state.newEmail,
						});
					return parent.props.history.push("/status");
				} catch (e) {
					return await Swal.fire(this.props.messages.commText.oops, e.message, "warning");
				}
			}
		}

		return null;
	};

	_validatePassword1 = e => {
		const password = e.target.value;
		const number = /[0-9]/.test(password);
		const lowercase = /[a-z]/.test(password);
		const uppercase = /[A-Z]/.test(password);

		this.setState({
			passwordValidator: [password.length > 7, number, lowercase, uppercase, false],
			password: password,
		});
	};
	_validatePassword2 = e => {
		const password = e.target.value;
		const number = /[0-9]/.test(password);
		const lowercase = /[a-z]/.test(password);
		const uppercase = /[A-Z]/.test(password);
		const same = this.state.password === password;

		this.setState({
			passwordValidator: [password.length > 7, number, lowercase, uppercase, same],
			passwordConfirm: password,
		});
	};

	render() {
		return (
			<Page {...this.props}>
				<div className="container mt-5 py-3">
					<div className="page-title__holder text-center">
						<div>
							<h1 className="page-title__title">{this.props.messages.profile.textN10}</h1>
							<p className="mb-2 page-title__subtitle">
								{this.props.messages.profile.textN11} {this.state.user}!
							</p>
							<h6>{this.props.messages.profile.textN12}</h6>
						</div>
						<br />
					</div>
					<form className="col-10 mt-30 mb-30 mx-auto text-center" onSubmit={this._update}>
						<div className="row">
							<div className="col-lg-6 cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.profile.textN13}</span>
								<input
									type="text"
									className="col-8"
									required
									defaultValue={this.state.firstName}
									onChange={e =>
										this.setState({
											firstName: e.target.value,
										})
									}
								/>
							</div>
							<div className="col-lg-6 cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.profile.textN14}</span>
								<input
									type="text"
									className="col-8"
									required
									defaultValue={this.state.lastName}
									onChange={e =>
										this.setState({
											lastName: e.target.value,
										})
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.profile.textN15}</span>
								<input
									type="text"
									className="col-8"
									required
									defaultValue={this.state.phone}
									onChange={e => this.setState({ phone: e.target.value })}
								/>
							</div>
							<div className="col-lg-6 cool-form-group row align-items-center">
								<span className="col-6">{this.props.messages.profile.textN16}</span>
								<select
									className="col-6"
									required
									value={this.state.phoneAge}
									onChange={e =>
										this.setState({
											phoneAge: JSON.parse(e.target.value),
										})
									}>
									<option value="0">{this.props.messages.profile.textN17}</option>
									<option value="2">{this.props.messages.profile.textN18}</option>
								</select>
							</div>
						</div>
						{this.state.provider === "password" ? (
							<React.Fragment>
								<div className="row">
									<div className="col-lg-6 cool-form-group row align-items-center">
										<span className="col-4">{this.props.messages.profile.textN19}</span>
										<input
											type="password"
											placeholder={"(" + this.props.messages.commText.Optional + ")"}
											className="col-8"
											onChange={this._validatePassword1}
										/>
									</div>
									<div className="col-lg-6 cool-form-group row align-items-center">
										<span className="col-4">{this.props.messages.profile.textN20}</span>
										<input
											type="password"
											placeholder={"(" + this.props.messages.commText.Optional + ")"}
											className="col-8"
											onChange={this._validatePassword2}
										/>
									</div>
								</div>
								<p className="my-3 page-title__subtitle">
									<span className="link" onClick={this._changeEmail}>
										{this.props.messages.profile.textN21}
									</span>
								</p>
								<div className="col-12 row justify-content-md-center text-left no-gutters px-0">
									<div className="col-xs-12 col-md-6 row no-gutters">
										<div className="col-6">
											<div className="form-check">
												<input
													readOnly
													className="form-check-input"
													checked={this.state.passwordValidator[0]}
													type="checkbox"
													id="req0"
												/>
												<label className="form-check-label" htmlFor="req0">
													{this.props.messages.profile.textN22}
												</label>
											</div>
										</div>
										<div className="col-6">
											<div className="form-check">
												<input
													readOnly
													className="form-check-input"
													checked={this.state.passwordValidator[2]}
													type="checkbox"
													id="req2"
												/>
												<label className="form-check-label" htmlFor="req2">
													{this.props.messages.profile.textN23}
												</label>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-md-6 row no-gutters">
										<div className="col-6">
											<div className="form-check">
												<input
													readOnly
													className="form-check-input"
													checked={this.state.passwordValidator[3]}
													type="checkbox"
													id="req3"
												/>
												<label className="form-check-label" htmlFor="req3">
													{this.props.messages.profile.textN24}
												</label>
											</div>
										</div>
										<div className="col-6">
											<div className="form-check">
												<input
													readOnly
													className="form-check-input"
													checked={this.state.passwordValidator[1]}
													type="checkbox"
													id="req1"
												/>
												<label className="form-check-label" htmlFor="req1">
													{this.props.messages.profile.textN25}
												</label>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						) : null}
						<br />
						<br />
						<input type="submit" className="btn btn--color btn--button" value={this.props.messages.profile.textN26} id="submit-message" />
					</form>
				</div>
			</Page>
		);
	}
}
