import React, { Component } from "react";

import Menu from "../components/menu";
import Copy from "../components/copy";

export default class extends Component {
	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<div className="page pb-5">{this.props.children}</div>
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up"></i>
						</a>
					</div>
				</main>
			</div>
		);
	}
}
