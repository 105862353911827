import React from "react";
import { Tooltip, Form, Button, Row, Col } from "antd";
import Swal from "sweetalert2";

import firebase from "firebase";
import { osName } from "react-device-detect";
import Step from "./step";
import Vehicle from "./step/vehicle";

export default class Api extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			household: null,
			mortgage: null,
			debtPayments: null,
			payrollType: null,

			utilityBill: false,
			mortgageBill: false,
			incomeProof: false,
		};
	}

	_handleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	_handleNumberChange = e => {
		this.setState({
			[e.target.name]: JSON.parse(e.target.value),
		});
	};

	_handleCheckChange = e => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};

	_saveToFirebase = () => {
		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				income: this.state.income,
				household: this.state.household,
				mortgage: this.state.mortgage,
				debtPayments: this.state.debtPayments,
				payrollType: this.state.payrollType,
				utilityBill: this.state.utilityBill,
				mortgageBill: this.state.mortgageBill,
				incomeProof: this.state.incomeProof,
				returning: false,

				vin: this.state.vin,
				uvc: this.state.uvc,
				carPrice: this.state.price,
				mileage: this.state.mileage,
				reliability: this.state.reliability,
				condition: this.state.condition,

				appleDevice: osName === "Mac OS" || osName === "iOS",
			})
			.then(() => {
				return this.props.history.push("offer");
			});
	};

	render() {
		return (
			<React.Fragment>
				<Step title={"Congratulations"} subtitle={""} start={true} />
				<Form>
					<div className="container py-2 text-center" id="start">
						<div className={"row justify-content-center"}>
							<div className={"col-3 col-xl-1"}>
								<i className="far fa-check-circle" style={{ color: "#55B045", fontSize: 68 }} />
							</div>
							<div className={"col-9 col-xl-4 text-left"}>
								<h5>
									<b>
										{this.props.messages.apiJs.textN01 + " "}
										<u>{this.props.messages.apiJs.textN02}</u>
										{" " + this.props.messages.apiJs.textN03}
									</b>
								</h5>
							</div>
						</div>
						<div className={"row justify-content-center"}>
							<div className={"col-xl-7"}>
								<br />
								<br />
								<p style={{ lineHeight: 1.2 }}>{this.props.messages.apiJs.textN04}</p>
								<p style={{ lineHeight: 1.2 }}>
									<b>{this.props.messages.apiJs.textN05}</b>
								</p>
								<p style={{ lineHeight: 1.2 }}>{this.props.messages.apiJs.textN06}</p>
							</div>
						</div>

						<Vehicle
							messages={this.props.messages}
							send={e => {
								this.setState({
									uvc: e.uvc,
									vin: e.vin,
									price: e.price,
									mileage: e.mileage,
									reliability: e.reliability,
									condition: e.condition,
								});
							}}
						/>

						<br />
						<br />
						<div className="yseparator center" />
						<br />
						<h3>{this.props.messages.aplication.textN17}</h3>
						<br />
						<br />
						<div className="row">
							<Tooltip placement="top" title={"Don’t forget to include income from EVERYONE living in your household"}>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<input
										autoComplete="off"
										type="number"
										placeholder="$ Monthly Household Income"
										defaultValue={this.state.income}
										className="col"
										name="income"
										required
										onChange={this._handleNumberChange}
									/>
									<i
										className="fas fa-question-circle"
										onClick={() => Swal.fire(this.props.messages.aplication.textN82, this.props.messages.aplication.textN83, "question")}
									/>
								</div>
							</Tooltip>
							<div className="col-lg-6 cool-form-group row align-items-center">
								<select name="household" required value={this.state.household} className="col" onChange={this._handleNumberChange}>
									<option value=""># {this.props.messages.aplication.textN18}</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option value="5">{this.props.messages.aplication.textN19}</option>
								</select>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN62, "", "question")} />
							</div>
							<div className="col-lg-6 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="number"
									placeholder={this.props.messages.aplication.textN63}
									defaultValue={this.state.mortgage}
									className="col"
									name="mortgage"
									required
									onChange={this._handleNumberChange}
								/>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN64, "", "question")} />
							</div>
							<div className="col-lg-6 cool-form-group row align-items-center">
								<input
									autoComplete="off"
									type="number"
									placeholder={this.props.messages.aplication.textN65}
									defaultValue={this.state.debtPayments}
									className="col"
									name="debtPayments"
									required
									onChange={this._handleNumberChange}
								/>
								<i
									className="fas fa-question-circle"
									onClick={() => Swal.fire(this.props.messages.aplication.textN66, this.props.messages.aplication.textN67, "question")}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12 cool-form-group row align-items-center">
								<select
									name="payrollType"
									placeholder="How do you get paid? (Select One)"
									required
									value={this.state.payrollType}
									className="col"
									onChange={this._handleTextChange}>
									<option value="">{this.props.messages.aplication.textN20}</option>
									<option>{this.props.messages.aplication.textN21}</option>
									<option>{this.props.messages.aplication.textN22}</option>
									<option value="DirectDeposit">{this.props.messages.aplication.textN23}</option>
								</select>
								<i className="fas fa-question-circle" onClick={() => Swal.fire(this.props.messages.aplication.textN24, "", "question")} />
							</div>
						</div>
						<br />
						<br />
						<div className="container">
							<br />
							<div className="yseparator center" />
							<br />
							<h3>{this.props.messages.discounts.textN04}</h3>
							<br />
							<br />
							<h5>{this.props.messages.discounts.textN08}</h5>
							<br />
							<br />
							<div className="row text-left justify-content-center switches">
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN09}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input type="checkbox" name="utilityBill" checked={this.state.utilityBill} onChange={this._handleCheckChange} />
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN10}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input
													type="checkbox"
													name="mortgageBill"
													checked={this.state.mortgageBill}
													onChange={this._handleCheckChange}
												/>
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN13}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input type="checkbox" name="incomeProof" checked={this.state.incomeProof} onChange={this._handleCheckChange} />
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<Row type={"flex"} justify={"center"}>
								<Col xs={24} lg={8}>
									<Button type={"primary"} htmlType={"submit"} block>
										{this.props.messages.discounts.textN14}
									</Button>
								</Col>
							</Row>
							<p
								className="text-left pt-5"
								style={{
									fontSize: 12,
									textAlign: "left",
									lineHeight: 1.2,
								}}>
								{this.props.messages.discounts.textN15}
							</p>
						</div>
					</div>
				</Form>
			</React.Fragment>
		);
	}
}
