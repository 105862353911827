import { firestore } from "../firebase";
import Store from "../redux/storeConfig";

export default async (zipcode: number, loanType: string) => {
	let store;

	// Title Loans
	if (loanType === "Title Loan") {
		const storeCollection = await firestore()
			.collection("title-stores")
			.get();
		const stores = storeCollection.docs.map(x => ({ ...x.data(), id: x.id }));
		const possibleStore = stores.filter(x => x.zips).find(e => e["zips"].indexOf(zipcode) >= 0);

		if (possibleStore) {
			store = possibleStore;
		} else {
			store = stores.find(e => e["prismID"] === 112);
		}
	}

	// Personal Loans
	if (loanType === "Personal Loan") {
		const storesCollection = await firestore()
			.collection("finance-stores")
			.get();
		const stores = storesCollection.docs.map(x => ({ ...x.data(), id: x.id }));
		const possibleStore = stores.find(e => e["zips"]?.indexOf(zipcode) >= 0);

		if (possibleStore) {
			store = possibleStore;
		} else {
			store = stores.find(e => e["prismID"] === 0);
		}
	}

	// Update Store
	const currentLoan = Store().store.getState().loan;
	const currentStore = Store().store.getState().store;

	if (!currentStore || loanType !== currentLoan?.type) {
		Store().store.dispatch({
			type: "updateStore",
			payload: store,
		});
		return store;
	} else {
		return currentStore;
	}
};
