import { UPDATE_STORE } from "../actions/store";

export default (state = null, { type, payload }) => {
	if (type === UPDATE_STORE) {
		if (payload) {
			return payload;
		} else {
			return null;
		}
	} else {
		return state;
	}
};
