const es = {
	commText: {
		oops: "Oops…",
		salvado: "¡Salvado!",
		calculate: "Calcular",
		reset: "Reiniciar",
		Success: "¡Éxito!",
		MissingFiles: "Archivos perdidos",
		Upload: "Subir",
		Months: "Meses",
		Optional: "Opcional",
	},
	homepage: {
		title01: "DESCUBRA CUANTO",
		title02: "PUEDE OBTENER HOY MISMO",
		text01: "Préstamos de $125 a $10,000",
		text02: "Pagos fijos que se ajustan a su presupuesto",
		text03: "Fondos disponibles el mismo día",
		text04: "Todo hecho desde su teléfono",
		textbutt01: "Descubre lo que puede conseguir",
		title03: "SIN COMPROMISO",
		title04: "¡COTICE EN SEGUNDOS!",
		textL01:
			"Al responder algunas preguntas, nuestros prestamistas pueden compartir lo que otros clientes con su perfil han tomado prestado recientemente. Si está dentro de lo que usted necesita, tómese unos minutos más y obtenga una ¡oferta pre-certificada ahora mismo!",
		textbutt02: "DESCUBRA LO QUE OTROS OBTIENEN ",
		title05: "3 PASOS SIMPLES ",
		title06: "PARA OBTENER SU PRÉSTAMO ",
		suTitle01: "APLICA EN LÍNEA ",
		textL02:
			"Complete una breve solicitud y obtenga una oferta inmediata pre-certificada. Si le gusta la oferta, suba algunas fotos a su celular, envíelas para su revisión final, verificación y aprobación del préstamo.",
		suTitle02: "GÁNESE SUS DESCUENTOS",
		textL03:
			"¡Entre más información pueda proporcionarle a nuestros prestamistas, mayores serán los descuentos que puede obtener! PERO el descuento mas grande que ofrecen nuestros prestamistas es el descuento de pago automático: ¡es ENORME! Todo lo que necesita es una tarjeta de débito bancaria para programar sus pagos mensuales.",
		suTitle03: "REVISIÓN DEL CONTRATO",
		textL04:
			"Revise el contrato del préstamo con un representante de servicio al cliente, firme electrónicamente y obtenga instrucciones para presentar el Formulario 130U (para los préstamos del título).",
		suTitle04: "APLIQUE YA",
		suTitle05: "EN LÍNEA SIGNIFICA",
		suTitle06: "AHORRARSE TIEMPO",
		suTitle07: "Y DINERO",
		suTitle08: "AHORROS",
		textL05:
			"Los prestamistas de Discount Loans Online ofrecen préstamos totalmente amortizables, con o sin garantías de $ 125 a $ 10,000, con pagos mensuales iguales, de 3 meses hasta un plazo máximo de 18 meses. Además de los descuentos, estos préstamos se ofrecen sin penalizaciones por pagar su préstamo antes de tiempo o por realizar pagos anticipados parciales.",
		suTitle09: "ABONO A CAPITAL",
		textL06:
			"Cada pago mensual incluye una parte de capital (principal) que disminuye las tarifas que usted debe al mes siguiente. Los préstamos totalmente amortizados significan que terminara de pagar el préstamo por completo al final del plazo del préstamo. No hay sorpresas. Todos nuestros prestamistas ofrecen descuentos cuando los clientes pagan a tiempo.",
		suTitle10: "PRECIOS COMPETITIVOS",
		textL07:
			"Discount Loans en línea es una manera rápida y fácil de comparar opciones, encontrar una buena oferta y obtener lo que necesita. Obtendrá acceso a una variedad de opciones de préstamos y le ahorraremos la molestia de tener que investigar todo por su cuenta.",
	},
	homepageOter: {
		title01: "EXPERIENCIA",
		title02: "DISCOUNT TITLE LOAN EN LINEA!",
		title02F: "DISCOUNT FINANCE EN LINEA!",
		text01: "Prestamos de $125 a $10,000",
		text01F: "Préstamos de $ 60 a $ 1,420",
		text02: "Pagos fijos que se ajustan a su presupuesto",
		text03: "Ahorre un 50% frente a nuestros competidores *",
		text03F: "Ahorre $ 100 cuando refinancie*",
		text04: "Todo hecho desde su teléfono",
		text04ex: "Obtenga su Efectivo Hoy!",
		textbutt01: "Descubre Lo Que Puedes Conseguir",
		title03: "SIN COMPROMISO",
		title04: "COTICE EN SEGUNOS!",
		textL01:
			"Al responder algunas preguntas, podemos compartir lo que otros clientes con su perfil han tomado prestado recientemente. Si está dentro de lo que usted necesita, tómese unos minutos más y obtenga una ¡oferta pre-certificada ahora mismo!",
		textbutt02: "Descubra lo que otros obtienen",
		title05: "3 PASOS SIMPLES",
		title06: "PARA OBTENER SU PRÉSTAMO",
		suTitle01: "APLICA EN LÍNEA",
		textL02:
			"Complete una breve solicitud y obtenga una oferta inmediata pre-certificada. Si le gusta la oferta, suba algunas fotos a su celular, envíelas para su revisión final, verificación y aprobación del préstamo.",
		suTitle02: "GÁNESE SUS DESCUENTOS",
		textL03:
			"¡Entre más información pueda proporcionarle a nuestros prestamistas, mayores serán los descuentos que puede obtener! PERO el descuento mas grande que ofrecen nuestros prestamistas es el descuento de pago automático: ¡es ENORME! Todo lo que necesita es una tarjeta de débito bancaria para programar sus pagos mensuales.",
		suTitle03: "REVISIÓN DEL CONTRATO",
		textL04:
			"Revise el contrato del préstamo con un representante de servicio al cliente, firme electrónicamente y obtenga instrucciones para presentar el Formulario 130U (para los préstamos del título).",
		suTitle04: "APLIQUE YA",
		suTitle05: "EN LÍNEA SIGNIFICA",
		suTitle06: "AHORRARSE TIEMPO",
		suTitle07: "Y DINERO",
		suTitle08: "AHORROS",
		textL05:
			"Los prestamistas de Discount Loan ofrecen préstamos totalmente amortizables, no garantizados y garantizados de $ 125 a $ 10,000, con pagos mensuales iguales, un período mínimo de reembolso de 3 meses y un período máximo de reembolso de 6 meses. Junto con las tasas de descuento, estos préstamos se ofrecen sin penalizaciones por pagar su préstamo antes de tiempo o por realizar pagos anticipados parciales.",
		textL05F:
			"¡Nuestros clientes reciben toneladas de regalos! Discount Finance ofrece a los clientes en regla dos informes de crédito gratuitos por año, pagos automáticos gratuitos y financiación instantánea gratuita. ¡Nunca pague una tarifa de cambio de cheques nuevamente! ¡Llévate más dinero a casa hoy!",
		suTitle09: "ABONO A CAPITAL",
		suTitle09F: "PRINCIPAL PAYMENTS",
		textL06F:
			"Cada pago mensual incluye una parte principal que disminuye las tarifas que debe al mes siguiente. Los préstamos totalmente amortizados significan que ha terminado de pagar el préstamo por completo al final del plazo del préstamo. No hay sorpresas.",
		textL06:
			" Cada pago mensual incluye una parte de capital (principal) que disminuye las tarifas que usted debe al mes siguiente. Los préstamos totalmente amortizados significan que terminara de pagar el préstamo por completo al final del plazo del préstamo. No hay sorpresas. Nuestros clientes obtienen descuentos cuando pagan a tiempo.",
		suTitle10: "PRECIOS COMPETITIVOS",
		textL07:
			"Discount Loans en línea es una manera rápida y fácil de comparar opciones, encontrar una buena oferta y obtener lo que necesita. Obtendrá acceso a una variedad de opciones de préstamos y le ahorraremos la molestia de tener que investigar todo por su cuenta.",
		suTitle10F: "TÉRMINOS DE PRÉSTAMO FLEXIBLES",
		textL07F:
			"En Discount Finance, siempre recomendamos el plazo de préstamo óptimo para nuestros clientes. Los plazos de los préstamos pueden variar entre 3 y 12 meses, dependiendo del monto de su préstamo.",
		textPersonal01: "APLIQUE EN LÍNEA",
		textPersonal02:
			"Complete una breve solicitud y obtenga una oferta inmediata pre-certificada. Si le gusta la oferta, suba algunas fotos a su celular, envíelas para su revisión final, verificación y aprobación del préstamo",
		textPersonal03: "AUMENTA TU LÍMITE DE CRÉDITO",
		textPersonal04:
			"¡Entre más información pueda proporcionarle a nuestros prestamistas, mayores serán los descuentos que puede obtener! PERO el descuento mas grande que ofrecen nuestros prestamistas es el descuento de pago automático: ¡es ENORME! Todo lo que necesita es una tarjeta de débito bancaria para programar sus pagos mensuales.",
		textPersonal05: "REVISIÓN DEL CONTRATO",
		textPersonal06: " Revise el contrato del préstamo con un representante de servicio al cliente, firme electrónicamente desde su telefono!",
	},
	menu: {
		textN01: "LLAMAR",
		textN02: "APLICAR",
		textN03: "CUENTA",
		textN04: "LOG IN",
		textN05: "MENÚ",
		textN06: "Inicio",
		textN07: "Inicio",
		textN08: "Como funciona",
		textN09: "Como funciona",
		textN10: "Preguntas ",
		textN11: "Acerca de",
		textN12: "Contacto",
		textN13: "Cuenta",
		textN14: "Estatus",
		textN15: "Perfil",
		textN16: "Solicitud",
		textN17: "Descuentos",
		textN18: "Límite de crédito",
		textN19: "Oferta",
		textN20: "Documentos",
		textN21: "Referencias",
		textN22: "Cerrar sesión",
		textN23: "Log In",
		textN24: "¡Aplique ya!",
		textN25: "TIENDAS",
		textN26: "Tiendas",
		textN27: "Empleos",
	},
	quickstart: {
		textN01: "AVERIGÜE CUANTO PUEDE PEDIR PRESTADO ",
		textN02: "SIN PROBLEMA",
		textN03: "¿Cuánto dinero está buscando?",
		textN04: "¿Cual es su puntaje de crédito personal",
		textN05: "Seleccione uno",
		textN06: "Sin puntaje de crédito/ No lo sé ",
		textN07: "Menos de 520",
		textN08: "520 - 620",
		textN09: "Más de 620",
		textN10: "¿Tiene un vehículo con título libre de gravámenes?",
		textN11: "Si",
		textN12: "No",
		textN13: "Año",
		textN14: "Seleccione uno",
		textN15: "Marca",
		textN16: "Seleccione uno",
		textN17: "Modelo",
		textN18: "Seleccione uno",
		textN19: "Condicion",
		textN20: "Seleccione uno",
		textN21: "Kilometraje",
		textN22: "Anterior",
		textN23: "Siguiente",
		textN24: "No afectará su puntaje crediticio FICO",
	},
	carForm: {
		textn01: "Seleccione año",
		textn02: "Seleccione marca",
		textn03: "Seleccione modelo",
		textn04: "Seleccione estilo",
	},
	copy: {
		textN01: "2019 Discount Loans Online.",
	},
	footer: {
		textN01: "Inicio",
		textN02: "Inicio",
		textN03: "Cómo funciona",
		textN04: "Cómo funciona",
		textN05: "Preguntas",
		textN06: "Acerca de",
		textN07: "Mercados",
		textN08: "Mercados que servimos",
		textN09: "Contacto",
		textN10: "Discount-loans.com funciona como un tercero pero no es el prestamista actual.",
		textN11:
			"Nuestros prestamistas participantes ofrecen préstamos personales y préstamos sobre títulos de automóviles entre $125 y $ 10,000, con un período mínimo de reembolso de 3 meses y un reembolso máximo de 18 meses.",
		textN12:
			"Las Tasas de porcentaje anual (APR), los términos del préstamo, los montos del préstamo, las tarifas de originación y otros términos proporcionados en este sitio web se estiman en función de la información que proporcionó, los datos ofrecidos por los prestamistas y la información disponible públicamente. Toda la información se presenta sin garantía, y el APR estimado, los términos y otras características no son vinculantes de ninguna manera. Los prestamistas ofrecen una variedad de APR dependiendo de su historial crediticio, ingresos y otros factores.",
		textN13:
			"Los préstamos ofrecidos a través de este sitio con una tasa de porcentaje anual (APR) máxima del 28.7% están disponibles para clientes recurrentes calificados con un plazo de préstamo de 6 meses sobre montos de préstamos que comienzan en $ 7,500. Un ejemplo del monto total pagado en un préstamo de $ 6,000 reembolsable en 77 pagos quincenales de $ 117 a una tasa de porcentaje anual del 28.7% equivale a aproximadamente $ 8,902. Solo los prestatarios con excelente crédito califican para las tasas más bajas. Su APR real dependerá de su puntaje de crédito, monto del préstamo, plazo, ingresos, historial crediticio y el estado en el que se originó el préstamo. Todos los préstamos deben ser revisados y aprobados por los prestamistas. No disponible en todos los estados.",
		textN14: "Mercado en línea.",
		textN15:
			"Nuestro objetivo es conectarlo con un prestamista aprobado de nuestra red de préstamos personales y préstamos de títulos de automóviles. También hicimos el proceso simple. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN16: "Obtenga dinero rápido.",
		textN17:
			"Nuestro servicio de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de calidad o un préstamo sobre el título del automóvil. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada a un prestamista autorizado para su aprobación. Una vez que envíe su información, será redirigido al sitio web del prestamista, donde podrá revisar los términos del préstamo personal o del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, ¡el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN18: "Divulgaciones importantes.",
		textN19:
			"Por favor lea cuidadosamente. El propósito de los préstamos de menor duración es proporcionar al prestatario un alivio financiero temporal. Dichos préstamos no son una solución financiera a largo plazo. Las personas que enfrentan serias dificultades financieras deben considerar otras alternativas o buscar asesoramiento financiero profesional.",
		textN20: "Este sitio web no es una oferta de préstamo.",
		textN21:
			"Discount Loans Online no es un prestamista y no toma decisiones de préstamo o crédito. Conectamos a las personas interesadas con un prestamista de nuestra red aprobada de préstamos personales y préstamos para títulos de automóviles. No controlamos ni somos responsables de las acciones o inacciones de ningún prestamista, no somos un agente, representante o corredor de ningún prestamista. Nuestro servicio de referencia siempre es gratis para usted.",
		textN22: "Este servicio no está disponible en todas las áreas.",
		textN23:
			"Si solicita un préstamo personal o un préstamo sobre el título de un automóvil en un área particular donde dichos préstamos están prohibidos, o en un lugar donde no tenemos un prestamista disponible, no estará conectado a un prestamista. Se le recomienda que lea y comprenda los términos de cualquier préstamo personal o préstamo sobre el título de automóvil ofrecido por cualquier prestamista y que rechace cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN24: "Verificaciones de crédito para préstamos personales.",
		textN25:
			"Al enviar su información a través de este sitio web, usted autoriza a los discount loans y a nuestros prestamistas iniciar una verificación de crédito (solo para préstamos personales), que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza que discount loans pueda compartir su información e historial de crédito con una red de prestamistas aprobados.",
		textN26: "Divulgación de términos del prestamista",
		textN27:
			"Los prestamistas proporcionarán documentos que contienen todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. No tenemos conocimiento ni control sobre los términos del préstamo ofrecidos por un prestamista. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN28: "Los pagos atrasados pueden dañar su puntaje de crédito.",
		textN29:
			"Tenga en cuenta que al no realizar un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
		textN10T: "*Para ser aprobado para un préstamo de título.",
		textN11T: "Debe tener al menos 18 años de edad. La aprobación del préstamo está sujeta a inspección y evaluación del vehículo.",
		textN12T:
			"**En Texas, Vin-Title, LLC (d / b / a Discount Title Loan) actuará como una organización de servicios de crédito para ayudarlo a obtener un préstamo. Su préstamo puede ser realizado por un prestamista externo no afiliado. Consulte al asociado de la tienda para obtener más detalles.",
		textN13T: "",
		textN14T: " Este servicio no está disponible en todas las áreas. ",
		textN15T:
			"Si solicita un préstamo sobre el título de un automóvil en un área en particular donde dichos préstamos están prohibidos, o en un lugar donde no tenemos un prestamista disponible, no se lo conectará con un prestamista. Se le insta a leer y comprender los términos de cualquier préstamo de título de automóvil ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN16T: "Verificaciones de crédito.",
		textN17T:
			"Al enviar su información a través de este sitio web, usted autoriza al Préstamo de Título de Descuento a iniciar una verificación de crédito, que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia. Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza a Discount Title Loan para compartir su información e historial crediticio con nuestros prestamistas aprobados.",
		textN18T: "Divulgación de términos del prestamista.",
		textN19T:
			"Los prestamistas proporcionarán documentos que contengan todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN20T: "Los pagos atrasados pueden dañar su puntaje de crédito.",
		textN21T:
			"Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
		textN22T: "Que hacemos.",
		textN23T:
			"Discount Title Laon no es un prestamista y no toma decisiones de préstamo o crédito. Conectamos a las personas interesadas con los prestamistas de nuestra red aprobada de préstamos personales y préstamos sobre títulos de automóviles. No controlamos ni somos responsables de las acciones o inacciones de ningún prestamista, no somos un agente, representante o corredor de ningún prestamista. Nuestro servicio de referencia siempre es gratis para usted.",
		textN24T: "Mercado en línea.",
		textN25T:
			"Nuestro objetivo es obtener las mejores tasas de préstamo de título y términos de préstamo de título en el mercado. También hemos simplificado el proceso. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN26T: "PROGRAMA DE TARIFAS",
		textN27T: "AVISO OCCC",
		textN10F: "*Para ser aprobado para un préstamo personal,",
		textN11F:
			"debe ser residente del estado de Texas y tener al menos 18 años de edad. Actualmente no proporcionamos préstamos a miembros militares activos.",
		textN12F: "",
		textN13F: "",
		textN14F: "Mercado en línea.",
		textN15F:
			"Nuestro objetivo es obtener las mejores tasas de préstamos personales y los términos de préstamos personales del mercado. También hemos simplificado el proceso. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN16F: "Obtenga dinero rápido.",
		textN17F:
			"Nuestro servicio de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de automóvil de calidad. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada para que la aprobemos. Una vez que envíe su información, podrá revisar los términos del préstamo personal, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, ¡twe puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN18F: "Divulgaciones importantes.",
		textN19F:
			"Por favor lea detenidamente. El propósito de los préstamos de menor duración es proporcionar al prestatario un alivio financiero temporal. Dichos préstamos no son una solución financiera a largo plazo. Las personas que enfrentan serias dificultades financieras deben considerar otras alternativas o buscar asesoramiento financiero profesional.",
		textN20F: "Este servicio no está disponible en todas las areas.",
		textN21F:
			"Si solicita un préstamo personal en un área en particular donde dichos préstamos están prohibidos, o en un lugar donde no tenemos un prestamista disponible, es posible que no esté conectado a un prestamista. Se le recomienda leer y comprender los términos de cualquier préstamo personal ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN22F: "Verificaciones de crédito.",
		textN23F:
			"Al enviar su información a través de este sitio web, autoriza a Discount Finance & Online Personal Loans a iniciar una verificación de crédito, que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia. Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza a Discount Finance & Online Personal Loans para compartir su información e historial de crédito con nuestros prestamistas aprobados.",
		textN24F: "Divulgación de términos del prestamista.",
		textN25F:
			"Los prestamistas proporcionarán documentos que contengan todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN26F: "Los pagos atrasados ​​pueden dañar su puntaje de crédito.",
		textN27F:
			"Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
		textN28F: "Lo que hacemos",
		textN29F:
			"Discount Finance & Online Personal Loans conecta a las personas interesadas con los prestamistas de nuestra red aprobada de préstamos personales y préstamos para títulos de automóviles. Nuestro servicio de referencia siempre es gratis para usted.",
		textN30F: "Mercado en línea.",
		textN31F:
			"Nuestro objetivo es brindarle las mejores tasas de préstamo personal y los términos del préstamo del título en el mercado. También hemos simplificado el proceso. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN30: "Ver",
		textN31: "menos",
		textN32: "más",
		textN33: "Empleos",
	},
	contact: {
		textN01: "CONTÁCTANOS",
		textN02: "Lunes a Viernes de 9 am a 6 am ",
		textN03: "Sábado de 10 am a 6 pm",
		textN04: "Si necesita ayuda para navegar por el sitio o tiene alguna pregunta",
		textN05: "Envíenos un correo electrónico a:",
	},
	discounts: {
		textN01: "El descuento más grande que ofrecen nuestros prestamistas (por lejos) es el",
		textN02: "Descuento de pago automático",
		textN03: "todo lo que necesita es una tarjeta de débito para configurar sus pagos mensuales.",
		textN04: "Sus descuentos",
		textN05: "¿Desea obtener el mayor descuento disponible y suscribirse a pagos automáticos?",
		textN06: "NO",
		textN07: "SI",
		textN08: "Aquí hay algunos descuentos adicionales para los que pudiera calificar.",
		textN09: "¿Están los servicios públicos a su nombre (luz, agua, etc)?",
		textN10: "¿Tiene una hipoteca a su nombre?",
		textN11: "¿Ha tenido tu trabajo más de 2 años?",
		textN12: "¿Es un cliente nuestro por primera vez?",
		textN13: "¿Puede proporcionar comprobante de ingresos?",
		textN14: "Ver oferta",
		textN15:
			"Los solicitantes deben cumplir con todos los requisitos de elegibilidad, incluida la inspección del vehículo y la autenticación de documentos. Ciertos términos y condiciones aplican. Debe tener 18 años de edad o más. En Texas, Vin-Title, LLC (d / b / a Discount Title Loan) actúa como una Organización de Servicios de Crédito / Negocio de Acceso al Crédito para ayudar a los clientes a obtener préstamos de título a través de un prestamista externo no afiliado..",
		textN16: "2. Descuentos",
		textN17: "Paso 2 de 3",
	},
	faqPage: {
		textN01: "Preguntas frecuentes ",
		textN02: "Preguntas generales",
		textN03: "Solicitar un préstamo personal o un préstamo sobre el título",
		textN04: "Haciendo pagos",
		textN05: "Preguntas sobre informes de crédito",
		textN06: "Modificando una oferta pre-certificada",
		textN07: "Ofertas pre-certificadas",
		textN08: "Requerimientos",
		textN09: "Preguntas más frecuentes e historia de préstamos personales",
	},
	markets: {
		textN01: "Mercados a los que servimos",
		textN02:
			"Areas de servicio. Este servicio no está disponible en todas las areas. Si solicita un préstamo personal en línea o un préstamo sobre el título de un automóvil en un área particular donde dichos préstamos están prohibidos, o en un lugar donde no tenemos un prestamista disponible, no seestará conectado a un prestamista. Se le recomienda que lea y comprenda los términos de cualquier préstamopersonal o préstamo sobre el título de automóvil ofrecido por cualquier prestamista y que rechace cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN03: "Actualmente tenemos contratos con prestamistas de préstamos personales y prestamos de título de automóvil en los siguientes lugaresmercados:",
		textN04: "Discount Loans",
		textN05: "Prestamos de titulo y prestamos personales- San Antonio Texas",
		textN06: "Discount Finance & Personal Loans",
		textN07: "Brownsville Texas",
		textN08: "Mission Texas",
		textN09: "McAllen Texas",
		textN10: "Discount Car Title Loan",
		textN11: "Brownsville Texas",
		textN12: "Harlingen Texas",
		textN13: "Weslaco Texas",
		textN14: "Alamo TX",
		textN15: "Pharr Texas",
		textN16: "McAllen Texas",
		textN17: "McAllen Texas",
		textN18: "McAllen Texas",
		textN19: "Edinburg Texas",
		textN20: "Mission Texas",
		textN21: "Rio Grande City Texas",
		textN22: "Corpus Christi Texas",
		textN23: "Corpus Christi Texas",
		textN24: "Laredo Texas",
		textN25: "Laredo Texas",
		textN26: "Eagle Pass Texas",
		textN27: "Beeville Texas",
	},
	personalLoan: {
		textN01: "¡Lo sentimos!",
		textN02: "Tenemos problemas con su solicitud, contáctenos para obtener más información.",
		textN03: "Hable con un experto",
		textN04: "Felicidades",
		textN05: "TIENE UNA",
		textN06: "OFERTA PRE-CERTIFICADA",
		textN07: " HASTA POR",
		textN08: "PAGOS MENSUALES",
		textN08b: "PAGOS BIMENSUALES",
		textN09: "PLAZO DEL PRÉSTAMO VISUALIZADO",
		textN09b: "PLAZO DEL PRÉSTAMO",
		textN10: "PERSONALIZE LA DURACIÓN DEL PRÉSTAMO",
		textN11: "Mes",
		textN12: "Pago ",
		textN13: "Principal",
		textN14: "Honorarios e intereses",
		textN15: "Sus pagos mensuales podrían ser tan bajos como ",
		textN15b: "Sus pagos bimensuales podrían ser tan bajos como ",
		textN16: "y no hay penalidad si paga antes.",
		textN17: "La tabla de amortización anterior es solo para fines ilustrativos. ",
		textN18: "No incluye la tarifa estándar de gravamen del título de $ 28 y supone que obtendrá su tarifa con descuento cada mes pagando a tiempo.",
		textN19:
			"Una vez que el prestamista apruebe su solicitud, se le proporcionará una divulgación completa de TILA junto con todos los hechos asociados con el préstamo. ",
		textN20:
			"Incluirá la tasa de porcentaje anual sin descuento (para que pueda ver cuáles serían las tarifas si no ganara la tasa de descuento todos los meses), ",
		textN21: "todos los cargos financieros relacionados con el préstamo y todos los términos que aceptará.",
		textN22: "Asegúrese de leer toda la información que le proporcionó el prestamista para asegurarse de tomar una decisión informada",
		textN23: "antes de firmar el contrato final.",
		textN24: "Divulgación APR",
		textN25: "HABLE CON UN EXPERTO",
		textN26: "SIGUIENTE PASO (3 DE 3)",
		textN27: "Su oferta lista pre-certificada es una estimación razonable basada en la información que proporcionó, pero no está garantizada.",
		textN28:
			"Tener una oferta pre-certificada significa que puede ser aprobado para un préstamo con cualquiera de nuestros prestamistas participantes, sujeto a verificar su identidad,",
		textN29:
			"y confirmacion de la información en la documentación que nos proporciona tales como referencias, información de empleo, número de seguro social e información de tarjeta de débito.",
		textN30: "Puede tener una oferta certificada y aun asi ser rechazado por un prestamo",
	},
	start: {
		textN01: "lo que otros están recibiendo",
		textN02: "Basado en su información",
		textN03: "¡Bienvenidos!",
		textN04: "Préstamo sobre Título:",
		textN05: "Préstamo Personal:",
		textN06: "Averigüe cuánto puede pedir prestado.",
		textN07: "Obtenga un presupuesto",
		textN08: "No afectará su puntaje FICO",
	},
	welcome: {
		textN01: "Oops..",
		textN02: "Tu contraseña es muy débil.",
		textN04: "Lo sentimos",
		textN05: "Nuestros productos de préstamos actualmente no están disponibles para miembros militares activos.",
		textN07: "OBTENER SU",
		textN08: "¡PRÉSTAMO ES FÁCIL!",
		textN09: "APLICA EN LÍNEA",
		textN10: "Complete una breve solicitud y obtenga una oferta inmediata certificada lista pre-certificada.",
		textN11: "Si le gusta la oferta, suba algunas fotos a su celular, envíelas para su revisión final, verificación y aprobación del préstamo.",
		textN12: "¿Puedo obtener un préstamo si estoy en servicio militar activo?",
		textN13: "Si",
		textN14: "No",
		textN15: "APLIQUE YA",
		textN16: "DESCUBRA",
		textN17: "DISCOUNT LOANS ",
		textN18: "EN LÍNEA ",
		textN19: "Préstamos de $125 a $10,000",
		textN20: "Pagos fijos que se ajustan a su presupuesto",
		textN21: "Fondos disponibles el mismo día",
		textN22: "Todo hecho desde su teléfono",
		textN23: "COMENTARIOS DE LOS CLIENTES",
		textN24: "“Gran servicio al cliente. Todo el personal de la oficina es increíble y muy servicial ”",
		textN25: "Shevawn Chacon",
		textN26:
			"“Excelente servicio al cliente, ¡Mayra es increíble! Gran lugar para obtener un préstamo sobre el título, rápido y fácil. Muy recomendable ... '",
		textN27: "Melinda Lamas",
		textN28:
			"Tuve antes una mala experiencia con préstamos de título de auto. Pero discount title loan me ayudó cuando realmente lo necesitaba. Los pagos son mejores de lo esperado '.",
		textN29: "Sandra Baron",
		textN30: "PREGUNTAS FRECUENTES",
		textN31: "¿Qué tan rápido es el proceso de solicitud?",
		textN32:
			"El proceso de solicitud suele tardar entre 10 y 15 minutos en completarse. Si puede proporcionar toda la documentación correcta al prestamista, se puede tomar una decisión el mismo día de la solicitud.",
		textN33: "¿Sus prestamistas ofrecen préstamos de título de vehiculo para personas con mal crédito?",
		textN34:
			"Sí, nuestros prestamistas de Discount-Loans.com de préstamos personales y de prestamos de título de automóvil tienen solicitantes con mal crédito o sin crédito. Entienden que las personas cometen errores y creen que los puntajes FICO no deberían evitar que las personas reciban ayuda cuando más la necesitan.",
		textN35: "¿Qué necesito para solicitar un préstamo personal o un préstamo sobre el título de un automóvil?",
		textN36:
			"Nuestros prestamistas de Discount-Loans.com necesitarán su información personal (nombre, teléfono, correo electrónico y código postal) y la información de su vehículo (marca, modelo, año y millaje estimado) y requerirán que tenga una identificación válida emitida por el gobierno, un derecho de retención. título y comprobante de ingresos.",
		textN37: "¿Necesito un trabajo para obtener la aprobación de un préstamo? ¿Qué sucede si estoy discapacitado o estoy jubilado?",
		textN38:
			"No, no necesita ser empleado para obtener un préstamo personal o un prestamo de título de automóvil. Sin embargo, necesita una fuente de ingresos regulares. Se aceptan otras formas de ingresos, como discapacidad, seguridad social, manutención de los hijos, pensión alimenticia, desempleo y trabajo por cuenta propia, siempre que pueda proporcionar pruebas.",
		textN39: "¿Cómo obtengo mi préstamo personal o los fondos del préstamo sobre el título del automóvil?",
		textN40:
			"Puede obtener los fondos de su préstamo, ya sea en forma de un cheque físico (en una de las tiendas de nuestros prestamistas) o mediante un cheque electrónico que se le puede enviar por correo electrónico. Todo lo que necesita es la capacidad de imprimir ese cheque electrónico en papel normal y cobrarlo en cualquier banco o centro de cambio de cheques.",
		textN41: "Estamos aquí para ayudar",
		textN42: "CONTACTENOS",
		textN43: "Si tiene alguna pregunta sobre su",
		textN44: "oferta de préstamo pre-certificado, llámenos al",
		textN45: "(210) 741 8021",
		textN46: "Lunes a Viernes de 9 am a 6 am ",
		textN47: "Sábado de 10 am a 6 pm",
		textN48: "Su contraseña es muy débil",
		textN49: "Lo sentimos",
		textN50: "Nuestros productos de préstamos actualmente no están disponibles para miembros militares activos",
	},
	aboutUS: {
		textN01: "Acerca de nosotros",
		textN02:
			"Este sitio web no es una oferta de préstamo. Discount Loans Online no es un prestamista y no toma decisiones de préstamo o crédito. Conectamos a las personas interesadas con un prestamista de nuestra red aprobada de préstamos personales y préstamos para títulos de automóviles. No controlamos ni somos responsables de las acciones o inacciones de ningún prestamista, no somos un agente, representante o corredor de ningún prestamista. Nuestro servicio de referencia siempre es gratis para usted.",
		textN03:
			"Mercado en línea. Nuestro objetivo es conectarlo con un prestamista aprobado de nuestra red de préstamos personales y préstamos de títulos de automóviles. También hicimos el proceso simple. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN04:
			"Obtenga dinero rápido. Nuestro servicio de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de calidad o un préstamo sobre el título del automóvil. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada a un prestamista autorizado para su aprobación. Una vez que envíe su información, será redirigido al sitio web del prestamista, donde podrá revisar los términos del préstamo personal o del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, ¡el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN05:
			"Verificaciones de crédito para préstamos personales. Al enviar su información a través de este sitio web, usted autoriza a los préstamos con descuento y a nuestros prestamistas de referencia a iniciar una verificación de crédito (solo para préstamos personales), que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza préstamos con descuento para compartir su información e historial de crédito con una red de prestamistas aprobados.",
		textN06:
			"Divulgación de términos del prestamista. Los prestamistas proporcionarán documentos que contienen todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. No tenemos conocimiento ni control sobre los términos del préstamo ofrecidos por un prestamista. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN07:
			"Los pagos atrasados pueden dañar su puntaje de crédito. Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
		textN01T: "Acerca de nosotros",
		textN02T:
			"Discount Car Title Loan es una financiera que presta dinero con el aval de un título de auto, brindando a sus clientes de Texas acceso a crédito de corto plazo a las tasas más competitivas en el mercado.",
		textN03T:
			"Nuestra misión es proporcionar préstamos más seguros y accesibles. Con rapidez. Los clientes son recompensados por pagar a tiempo. En Discount Car Title Loan, los clientes pueden obtener hasta un 50% de ahorro en las tarifas de sus préstamos cuando pagan a tiempo. Esto no es una promoción, es cómo hacemos negocios.",
		textN04T:
			"Los clientes pueden decidir cuánto dinero recibir y cuánto tiempo devolverlo. El núcleo del modelo de negocio de Préstamo de título de automóvil con descuento es nuestro software propietario Loan Optimizer, una herramienta de suscripción que ayuda a los clientes a seleccionar el monto ideal del préstamo y los términos de pago. Discount Car Title Loan ofrece préstamos de título de automóvil amortizados de 4 a 6 meses en el rango de $ 125- $ 10,000.",
		textN05T:
			"Obtenga dinero rápido. Nuestra plataforma de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo de título de automóvil de calidad. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada para que la aprobemos. Una vez que envíe su información, podrá revisar los términos del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN06T:
			"Verificaciones de crédito. Al enviar su información a través de este sitio web, usted autoriza al Préstamo de Título de Descuento a iniciar una verificación de crédito, que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia. Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza a Discount Title Loan para compartir su información e historial de crédito con nuestros prestamistas externos.",
		textN07T:
			"Divulgación de términos del prestamista. Nuestros prestamistas externos proporcionarán documentos que contienen todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o reinvierta su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN08T:
			"Los pagos atrasados pueden dañar su puntaje de crédito. Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede realizar un pago a tiempo, debe comunicarse con un representante de Discount Title Loan de inmediato y analizar cómo manejar los pagos atrasados.",
		textN01F: "Acerca de nosotros",
		textN02F:
			"Discount Finance & Online Personal Loans es una compañía de financiamiento al consumidor que origina y presta servicios a pequeños préstamos tradicionales a plazos, brindando a los consumidores de Texas acceso a crédito no garantizado a corto plazo a las tasas de mercado más competitivas.",
		textN03F: "Nuestra misión es proporcionar préstamos más seguros y asequibles. Con rapidez.",
		textN04F:
			"¡En Discount Finance & Online Personal Loans, los clientes obtienen todo tipo de cosas gratis! Informes de puntaje de crédito gratuitos, pagos automáticos gratuitos y financiación instantánea gratuita. ¡Nunca pague una tarifa de cambio de cheques nuevamente!",
		textN05F:
			"El núcleo del modelo de negocio de Financiación de descuentos y préstamos personales en línea es nuestro software patentado Loan Optimizer, una herramienta de suscripción que ayuda a los clientes a seleccionar el monto ideal del préstamo y los términos de pago. Discount Finance & Online Personal Loans ofrece préstamos personales de 3 a 12 meses en el rango de $ 60- $ 1,420 sin ningún requisito de garantía. Los préstamos garantizados de hasta $ 10,000 están disponibles a través de nuestra red de préstamos de referencia.",
		textN06F: "¿Estás listo para ahorrar algo de dinero? Danos la oportunidad de dejarnos ganar tu negocio. ¡No tiene nada que perder y mucho que ahorrar!",
		textN07F:
			"Obtenga dinero rápido. Nuestra plataforma de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de calidad. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada para que la aprobemos. Una vez que envíe su información, podrá revisar los términos del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, ¡el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN08F:
			"¡Los préstamos personales amortizados (préstamos tradicionales a plazos) son el camino a seguir! Los mejores préstamos que otorgamos son aquellos que los clientes pueden terminar de pagar a tiempo y es por eso que en Discount Finance & Online Personal Loans ofrecemos exclusivamente préstamos personales amortizados (préstamos a plazos tradicionales) a nuestros clientes. ¡Deje de pasar por encima de esos costosos préstamos de interés solamente y obtenga una hoja de ruta de la deuda hoy!",
		textN09F:
			"Verificaciones de crédito. Al enviar su información a través de este sitio web, autoriza a Discount Finance & Online Personal Loans a iniciar una verificación de crédito, que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia. Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza a Discount Finance & Online Personal Loans para compartir su información e historial de crédito con nuestros prestamistas externos.",
		textN10F:
			"Divulgación de términos del prestamista. Nuestros prestamistas externos proporcionarán documentos que contienen todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o reinvierta su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. Se le recomienda leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN11F:
			"Los pagos atrasados pueden dañar su puntaje de crédito. Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con un representante de Discount Finance & Online Personal Loans de inmediato y analizar cómo manejar los pagos atrasados.",
	},
	aplication: {
		textN01: "Préstamo Personal",
		textN02: "Préstamo sobre Título ",
		textN03: "Los números VIN no incluyen 'i' u 'o'. Intenta usar 1 o 0",
		textN04: "Falta seleccionar Tipo de Prestamo",
		textN05: "Por favor seleccione el tipo de préstamo que le interesa",
		textN06: "No pudimos encontrar su número de VIN. Intente encontrar la información de su vehículo manualmente",
		textN07: "Intente encontrar la información de su vehículo manualmente",
		textN08: "No pudimos encontrar su vehículo",
		textN09: "Su ciudad no parece correcta",
		textN10: "Su número de seguro social no parece ser correcto",
		textN11: "Perdone la inconveniencia",
		textN12: "Actualmente no estamos sirviendo su área en línea, pero siempre puede intentar solicitar un préstamo personal",
		textN13: "Cual tipo de préstamo le interesa mas?",
		textN14t: "PRÉSTAMO ",
		textN15t: "PRÉSTAMO POR",
		textN14: "PERSONAL",
		textN15: "TÍTULO DEL COCHE",
		textN16:
			"Solo tome unos minutos para llenar el breve cuestionario y obtener una oferta de préstamo certificada. Llenar esta solicitud no afectará su puntaje de crédito.",
		textN17: "Sus finanzas",
		textN18: "Numero de personas que viven en su hogar",
		textN19: "5 o más",
		textN20: "Como le pagan?",
		textN21: "Efectivo",
		textN22: "Cheque",
		textN23: "Deposito directo",
		textN24: "Como le pagan?",
		textN25: "Su vehículo",
		textN26: "DEBE SER DUENO Y CON UN TÍTULO LIMPIO",
		textN27: "Ingrese su NÚMERO DE VIN",
		textN28: "Ingrese su VIN (número de identificación del vehículo)",
		textN29:
			"Necesitamos un VIN preciso para darle el valor real de nuestro automóvil. Una vez que tengamos su VIN, podremos proporcionarle su oferta preparada vertical.",
		textN30: "Ingrese el kilometraje de su vehículo",
		textN31: "Ingrese la confiabilidad del vehículo (seleccione uno)",
		textN32: "Muy Fiable",
		textN33: "Algo Confiable",
		textN34: "No muy Confiable",
		textN35: "Ingrese la condición del vehículo (seleccione uno)",
		textN36: "Se ve Muy Bien",
		textN37: "Se ve Bien",
		textN38: "No Super Bonita",
		textN39: "Su hogar",
		textN40: "¿CUANTO TIEMPO HA VIVIDO AQUI?",
		textN41: "Menos de 1 año",
		textN42: "Entre 1 y 2 años.",
		textN43: "Más de 2 años",
		textN44: "¿ES SU DIRECCIÓN DE CORREO DIFERENTE?",
		textN45: "Si",
		textN46: "No",
		textN47: "Dirección de Envio",
		textN48: "Ingrese su Dirección de Correo",
		textN49: "Ciudad",
		textN50: "Ingrese su Ciudad",
		textN51: "Estado",
		textN52: "Código Postal",
		textN53: "Ingrese su codigo postal",
		textN54: "Su Historial Crediticio",
		textN55: "Ingrese su número de seguro social",
		textN56: "Ingrese su número de seguro social",
		textN57: "Al ingresar su SSN, usted acepta que un prestamista referido por discount-loans.com revise su ",
		textN58: "crédito en forma ",
		textN59: "gratuita.",
		textN60: "Esto no afectará su puntaje crediticio",
		textN61: "Siguiente paso (2 de 3)",
		textN62: "Numero de personas que viven en su hogar",
		textN63: "$ Renta mensual o monto de su Hipoteca",
		textN64: "Renta mensual o monto de su hipoteca",
		textN65: "Todo lo que debe por mes (Pagos Mínimos)",
		textN66: "Todo lo que debe por mes ",
		textN67: "Ingrese la suma de todos sus pagos mensuales de deuda, como otros préstamos, tarjetas de crédito, muebles y pagos de automóviles.",
		textN68: "Cambiar Vehículo",
		textN68b: "Elegir Vehículo",
		textN69: "Confiabilidad del Vehículo",
		textN70: "Condición del Vehículo",
		textN71: "Direccion de Casa",
		textN72: "Ingrese su Domicilio",
		textN73: "Ciudad",
		textN74: "Ingrese su Ciudad",
		textN75: "Estado",
		textN76: "Seleccione su Estado",
		textN77: "Código Postal",
		textN78: "Ingrese su codigo postal",
		textN79: "Este tipo de verificación de crédito no afectará su puntaje FICO.",
		textN80: "1. Solicitud",
		textN81: "Paso 1 de 3",
		textN82: "Ingresos mensuales",
		textN83: "Añadir el Ingreso bruto mensual antes de pagar cualquier impuesto.",
		textN84: "Ingresos adicionales de miembros del hogar",
		textN85: "Añadir el Ingreso bruto mensual de otros miembros del hogar como su pareja.",
		textN86: "Estado Civil",
		textN87: "No Casado(a)",
		textN88: "Casado(a)",
		textN89: "Separado(a)",
	},
	credit: {
		textN01: "No pudimos encontrar su número de VIN. Intente encontrar la información de tu vehículo manualmente",
		textN02: "No pudimos encontrar su vehículo",
		textN03: "Intente encontrar la información de tu vehículo manualmente",
		textN04: "No pudimos encontrar su vehículo",
		textN05: "Una opción que puede aumentar significativamente su límite crediticio es suscribirse para",
		textN06: "Pagos Automáticos",
		textN07: ".  Todo lo que necesita es una tarjeta de débito para configurar sus pagos mensuales.",
		textN08: "Su Límite Crediticio",
		textN09: "¿Desea aumentar su límite crediticio y registrarse para Pagos automáticos?",
		textN10: "NO",
		textN11: "SI",
		textN12: "Otra opción que puede",
		textN13: "aumentar ",
		textN14: "su límite crediticio es registrarse para un",
		textN15: "Title Boost",
		textN16: "y garantizar su préstamo con el título de su vehiculo.",
		textN17: "Su vehículo",
		textN18: "¿LE GUSTARÍA AUMENTAR SU LÍMITE CREDITICIO CON UN TITLE BOOST?",
		textN19: "Cambiar vehículo",
		textN20: "Ingrese su NÚMERO DE VIN",
		textN21: "Ingrese su VIN (número de identificación del vehículo)",
		textN22: "Necesitamos un numero VIN para darle el valor real. Una vez que tengamos su VIN, podremos proporcionarle su oferta.",
		textN23: "Ingrese el Kilometraje de su vehículo",
		textN24: "Ingrese el Kilometraje de su vehículo",
		textN25: "Ingrese la confiabilidad de su vehículo (seleccione uno)",
		textN26: "Muy Fiable",
		textN27: "Algo Confiable",
		textN28: "No muy Confiable",
		textN29: "Confiabilidad del Vehículo",
		textN30: "Condición del Vehículo",
		textN31: "Aquí hay algunas preguntas adicionales que podrían aumentar su límite crediticio.",
		textN32: "¿Es un cliente nuestro por primera vez?",
		textN33: "¿Puede proporcionar prueba del seguro de responsabilidad civil del automóvil?",
		textN34: "Siguiente paso (3 de 3)",
		textN35: "Ingrese la condición del vehículo (seleccione uno)",
		textN36: "Se ve Muy Bien",
		textN37: "Se ve Bien",
		textN38: "No Super Bonita",
		textN39: "2. Límite de crédito",
		textN40: "Paso 2 de 3",
		textN41: "¿Tienes una hipoteca a tu nombre?",
		textN42: "Empleo",
		textN43: "¿Aún trabajas en ",
	},
	login: {
		textN01: "Tu contraseña es muy débil",
		textN02: "Tus contraseñas no coinciden",
		textN03: "Lo sentimos",
		textN04: "Nuestros productos de préstamo actualmente no están disponibles para miembros militares activos",
		textN05: "Tendremos que enviarle un correo electrónico de verificación para restablecer su contraseña",
		textN06: "Por favor ingrese su correo electrónico",
		textN07: "Debería recibir un correo electrónico con los pasos para restablecer su contraseña",
		textN08: "Bienvenido",
		textN09: "¿No tienes una cuenta?",
		textN10: "Regístrate",
		textN11: "Cree una cuenta para obtener su oferta de préstamo personalizado",
		textN12: "¿Ya tienes una cuenta?",
		textN13: "Inicie sesión en su lugar",
		textN14: "Email",
		textN15: "Contraseña",
		textN16: "¿Se te olvidó tu contraseña?",
		textN17: "Primer nombre",
		textN18: "Apellido",
		textN19: "Teléfono móvil",
		textN20: "¿Eres un miembro activo del ejército?",
		textN21: "Si",
		textN22: "No",
		textN23: "Dirección de correo electrónico (será su ID de inicio de sesión)",
		textN24: "Contraseña",
		textN25: "Confirmar contraseña",
		textN26: "8+ Caracteres",
		textN27: "1 letra minúscula",
		textN28: "1 letra mayúscula",
		textN29: "1 numero",
		textN30:
			"Al hacer clic en Siguiente paso, acepta nuestros Términos de servicio y Política de privacidad y autoriza a los prestamistas de referencia de Discount-Loans.com a realizar llamadas y mensajes de texto con fines de telemercadeo a los números de teléfono proporcionados en la solicitud.",
		textN31: "Enviar Email",
		textN32: "Próximo paso",
		textN33: "Iniciar sesión",
		textN34: "Inicia con Facebook",
		textN35: "Inicia con Google",
		textN36:
			"Al hacer clic en Siguiente paso, acepta nuestros Términos de servicio y nuestra Política de privacidad y autoriza a los prestamistas de referencia de dtloans.com a realizar llamadas y mensajes de texto con fines de tele mercadeó a los números de teléfono proporcionados en la solicitud.",
		textN37:
			"Al hacer clic en Siguiente paso, acepta nuestros Términos de servicio y nuestra Política de privacidad y autoriza a los prestamistas de referidos de discount-finance.com/ para realizar llamadas y mensajes de texto con fines de telemercadeo a los números de teléfono provistos en la solicitud.",
	},
	profile: {
		textN01: "Su contraseña es demasiado débil o no coincide",
		textN02: "Por favor confirme su cambio",
		textN03: "Introduzca su contraseña actual",
		textN04: "Actualice contraseña",
		textN05: "Por favor ingrese su nuevo correo electrónico",
		textN06: "Cambiar Email",
		textN07: "Por favor confirme su cambio",
		textN08: "Introduzca su contraseña actual",
		textN09: "Confirme Cambio",
		textN10: "Su perfil",
		textN11: "Bienvenido",
		textN12: "Siéntase libre de cambiar su información cuando lo necesite",
		textN13: "Primer nombre",
		textN14: "Apellido",
		textN15: "Teléfono móvil",
		textN16: "¿Cuánto tiempo ha tenido este número?",
		textN17: "Menos de 2 años",
		textN18: "Mas de 2 años",
		textN19: "Nueva contraseña",
		textN20: "Confirmar contraseña",
		textN21: "Cambiar mi dirección de correo electrónico",
		textN22: "8+ Caracteres",
		textN23: "1 letra minúscula",
		textN24: "1 letra mayúscula",
		textN25: "1 numero",
		textN26: "Subir Perfil",
	},
	references: {
		textN01: "Parece que algunos números de teléfono están incompletos.",
		textN02: "Tus referencias se han guardado correctamente",
		textN03: "¡Casi termino!",
		textN04: "Lo último que necesitamos son referencias.",
		textN05: "Puede agregar familiares, amigos, colegas y vecinos.",
		textN06: "Guardar",
		textN07: "Primera Referencia",
		textN08: "Segunda Referencia",
		textN09: "Tercera Referencia",
		textN10: "Cuarta Referencia",
		textN10a: "Quinta Referencia",
		textN11: "Nombre *",
		textN12: "Apellido *",
		textN13: "Relación *",
		textN14: "Teléfono *",
		textN15: "Dirección*",
		textN16: "Ciudad*",
		textN17: "Estado*",
		textN18: "CÓDIGO POSTAL*",
	},
	status: {
		textN01: "¡Lo sentimos!",
		textN02: "Por favor seleccione una hora durante el horario comercial normal.",
		textN03: "Lunes a viernes de 9 a.m. a 6 p.m.",
		textN04: "Sábado de 10 a.m. a 6 p.m.",
		textN05: "ESTADO DE LA APLICACIÓN",
		textN06: "Bienvenido a Discount Loans Online",
		textN07: "Parece que no tiene una solicitud pendiente. Tómese unos minutos y comience.",
		textN08: "Cerrar sesión",
		textN09: "Empezar ahora",
		textN10: "Gracias por enviar su información,",
		textN11: "veamos si califica para algunos descuentos.",
		textN12: "Encuentre Descuentos",
		textN13: "Gracias por enviar su información,",
		textN14: "veamos si podemos aumentar su límite crediticio.",
		textN15: "Aumentar Limite Crediticio",
		textN16: "Casi terminamos. Continuemos.",
		textN17: "Subir Documentos",
		textN18:
			"Hemos recibido sus documentos y haremos todo lo posible para que su préstamo sea aprobado lo antes posible durante el horario comercial normal:",
		textN19: "Lunes a Viernes de 9 a.m. a 6 p.m.",
		textN20: "Sábado 10 am-6pm",
		textN21: "Nos esforzamos por aprobar la mayoría de los préstamos en menos de 2 horas.",
		textN22: "¡Su préstamo ha sido aprobado!",
		textN23:
			"El paso final es obtener cualquier información que no se completó en la solicitud, revisar su contrato por teléfono (revisar todos los datos requeridos) y responderle a cualquier pregunta que pueda tener.",
		textN24: "Favor de prepararse para proporcionar cinco referencias.",
		fillReferences: "Llenar Referencias",
		textN25: "¿Cuándo está disponible para hablar?",
		textN26: "Programar una cita",
		textN27: "Llámenos ahora",
		textN28: "Gracias por agendar una cita.",
		textN29: "Será llamado:",
		textN30: "Confirmar / Salir",
		textN31: "Fecha de cambio",
		textN32: "Llámanos ahora",
		textN33: "Ya casi hemos terminado con el proceso.",
		textN34: "Proporcione 5 referencias para continuar.",
		textN35: "Continuar",
		textN36: "Felicidades",
		textN37: "Su préstamo será financiado en breve.",
		textN38: "Gracias por elegir Discount Loans Online.",
		textN39: "Bienvenido a Discount Loans Online",
		textN40: "Parece que su aplicación expiró. Tómese unos minutos y comience.",
		textN41: "Cerrar sesión",
		textN42: "Empezar ahora",
		textN44: "Tiene una oferta pre-certificada lista.",
		textN45: "El último paso es firmar el acuerdo.",
		textN46: "No te quitaremos mucho tiempo.",
		textN47: "Ver acuerdo",
		textN48: "Deja un review Google",
	},
	titleLoanOffer: {
		textN01: "Ingrese su pago mensual ideal",
		textN02: "No podemos procesar su préstamo en este momento. Nuestro especialista en préstamos lo contactará pronto.",
		textN03: "Modificar Solicitud",
		textN04: "El pago que solicitó es demasiado bajo.",
		textN05: "Elija otro o reinícielo.",
		textN06: "Restablecer Pago",
		textN07: "Seleccione un pago más alto",
		textN08: "Felicidades",
		textN09: "TIENE UNA",
		textN10: "OFERTA",
		textN11: "LISTA PRE-CERTIFICADA HASTA POR",
		textN12: "USTED HA",
		textN13: "CALIFICADO PARA",
		textN14: "DESCUENTOS",
		textN15:
			"Discount Title Loan recomienda la opción amortizada de 6 meses porque le dará suficiente tiempo para pagar el préstamo y porque cada pago disminuye el capital (lo cual reduce sus comisiones e intereses).",
		textN16:
			"La tarifa para registrar un gravamen sobre el título varía según el condado, pero no excederá los $ 33, y se agregará a su oferta de préstamo final.",
		textN17: "Mostrando un pago de",
		textN18: "Restablecer pago mensual",
		textN19: "Mes",
		textN20: "Pago",
		textN21: "Principal",
		textN22: "Tasas e intereses",
		textN23: "Sus pagos mensuales (con descuentos) podrían ser tan bajos como",
		textN24: "y no hay penalidad si paga antes.",
		textN25: "Divulgación APR",
		textN26: "La tabla de amortización anterior es solo para fines ilustrativos.",
		textN27:
			"No incluye el costo tipico de $28 para el gravamen sobre el titulo del vehiculo y contempla que va obtener la tarifa con descuento cada mes al pagar a tiempo.",
		textN28:
			"Una vez que el prestamista apruebe su solicitud, se le proporcionará una divulgación completa de TILA junto con todos los hechos asociados con el préstamo.",
		textN29: "Incluirá el APR sin descuento (para que pueda ver cuáles serían las tarifas si no ganara la tarifa con descuento cada mes),",
		textN30: "todos los cargos financieros relacionados con el préstamo y todos los términos que aceptará.",
		textN31: "Asegúrese de leer toda la información que le proporcionó el prestamista para asegurarse de tomar una decisión informada",
		textN32: "antes de firmar el contrato final.",
		textN33: "Divulgación APR",
		textN34: "Tamaño de pago más bajo",
		textN35: "Hable con un experto",
		textN36: "Siguiente Paso (3 de 3)",
		textN37: "Su oferta pre-certificada es una estimación razonable basada en la información que proporcionó, pero no está garantizada.",
		textN38:
			"Tener una oferta pre-certificada significa que puede ser aprobado para un préstamo con cualquiera de nuestros prestamistas participantes, sujeto a verificar su identidad,",
		textN39:
			"y confirmacion de la información en la documentación que nos proporciona. Puede tener una oferta certificada y aún así ser rechazado para un préstamo.",
		textN40:
			"Si recibe una oferta pre-certificada y continúa con el proceso, nuestros prestamistas completarán su solicitud por teléfono y solicitarán información adicional,",
		textN41: "tales como referencias, información de empleo, número de seguro social e información de tarjeta de débito.",
		textN42: "¡Lo sentimos!",
		textN43: "Title Loan Oferta",
		textN44: "Válido hasta",
	},
	resetpassword: {
		textN01: "Debería recibir un correo electrónico con los pasos para restablecer su contraseña",
		textN02: "No hay una cuenta con este correo electrónico.",
		textN03: "¡Éxito!",
		textN04: "Su contraseña ha sido cambiada",
		textN05: "No se preocupe. Estamos aquí para ayudar.",
		textN06: "Por favor teclee su nueva contraseña",
		textN07: "Necesitamos enviarle un correo electrónico de verificación para restablecer su contraseña.",
		textN08: "Nueva contraseña",
		textN09: "Email",
	},
	documents: {
		textN01: "Cargue una Identificación con foto emitida por el Gobierno",
		textN02: "Cargue un comprobante de Residencia",
		textN03: "Tome una foto selfie mostrando su identificación ",
		textN04: "Por favor cargue una prueba de ingresos",
		textN05: "Cargue dos imágenes del título del vehículo. Frente y detrás",
		textN06:
			"Cargue ocho fotos del vehículo. Odometro (kilometraje), frente, derecho, izquierdo, trasero, asientos delanteros interiores, asientos traseros interiores y número VIN",
		textN07: "Cargue una identificación con foto emitida por el Gobierno",
		textN08: "Cargue un comprobante de Residencia",
		textN09: "Cargue fotos del título del vehículo.",
		textN10: "Por favor cargue fotos del vehículo",
		textN11: "Cargue dos imágenes del título del vehículo. Frente y detrás",
		textN12:
			"Cargue ocho fotos del vehículo. Odometro (kilometraje), frente, derecho, izquierdo, trasero, asientos delanteros interiores, asientos traseros interiores y número VIN",
		textN13: "Cargue algunos documentos para confirmar la información que ha ingresado y asegurarse de obtener sus descuentos.",
		textN14: "Cargue algunos documentos para confirmar la información que ingresó y asegurarse de maximizar su límite crediticio",
		textN15: "Sus fotos",
		textN16: "Identificación oficial con foto",
		textN17: "Por favor, cargue una identificación con foto",
		textN18: "para cada propietario que figura en el título",
		textN19: "Prueba de Residencia",
		textN20: "Factura de servicios públicos, extracto bancario, hipoteca, etc.",
		textN21: "Prueba de Ingresos",
		textN22: "Cargue un cheque o declaración bancaria reciente",
		textN23: "2 imágenes del título del vehículo",
		textN24: "Cargue el frente y revez",
		textN25: "Registro",
		textN26: "Cargue fotos de la calcomania de registro en el vehículo o el recibo de registro",
		textN27: "8 fotos del vehículo",
		textN28:
			"Cargue fotos del vehículo. Odometro (kilometraje), frente, derecho, izquierdo, trasero, asientos delanteros interiores, asientos traseros interiores y número VIN (ubicado en una calcomania en la puerta o en el tablero)",
		textN29: "Título del vehiculo",
		textN30: "Cargue el frente y vuelta. Esto es opcional pero puede obtener al menos $ 500 más",
		textN31: "8 Imágenes del vehículo",
		textN32:
			"Cargue fotos de: cuentakilómetros, exterior del vehículo (delantero, trasero, lado izquierdo y derecho), 2 fotos del interior del vehículo (asientos delanteros y traseros) y número VIN (ubicado en la etiqueta lateral de la puerta o en la placa de metal en tablero)",
		textN33: "Verificación Selfie",
		textN34: "Tómesee una foto selfie con su identificación",
		textN35: "Asegúrese de que tanto la identificación como su cara sean claramente visibles",
		textN36: "Su identificación debe ser la misma que está utilizando para la identificación de identidad.",
		textN37: "Adicionales",
		textN38: "Crédito",
		textN39: "factura de hipoteca, factura de servicios públicos y comprobante de ingresos",
		textN40: "los documentos adicionales requeridos",
		textN41: "Enviar Documentos",
		textN42: "Subir Documentos",
		textN43: "3. Suba fotos",
		textN44: "Paso 3 de 3",
		textN45: "(Opcional)",
		textN46: "Descuentos",
		bankStatement: {
			title: "Estado de Cuenta",
			description: "Favor de subir un estado de cuenta reciente",
		},
	},
	apiJs: {
		textN01: "Usted ha sido",
		textN02: "APROBADO",
		textN03: "para obtener una oferta de préstamo de título de automóvil precertificado de Discount Loans!",
		textN04:
			"Todo lo que necesitamos es un poco más de información sobre sus finanzas y su vehículo para obtener una oferta precertificada en este momento.",
		textN05: "Si le gusta nuestra oferta, todo lo que necesita hacer es cargar algunas fotos, revisar el contrato de préstamo y obtener fondos hoy.",
	},
	howItWorks: {
		textN01T: "Cómo funciona",
		textN02T:
			"Entendemos que la vida está llena de desafíos inesperados, muchos de los cuales solo pueden superarse si tiene acceso a efectivo rápido. En un mundo ideal, podría pasar por el banco de su vecindario, hablar con Roger, el banquero de toda la vida de su familia, completar un par de formularios y salir con el dinero que necesita, a una tasa de interés súper baja ... pero esa no es la forma en que Funciona en estos días. Los bancos del vecindario están cerrando, ya nadie conoce a sus banqueros, el papeleo para los préstamos bancarios es interminable, sus verificaciones de crédito no son realistas y nunca saldrá de un banco con efectivo en mano (a menos que lo encuentre en el piso).",
		textN03T:
			"Es por eso que Discount Car Title Loan se especializa en préstamos de título de automóvil. El título de su automóvil puede convertirse en una fuente rápida de ingresos, y en Discount Car Title Loan, su préstamo a corto plazo puede ser más seguro, más económico y rápido.",
		textN04T:
			"Obtener un préstamo de título de automóvil con descuento es indoloro. Todo el proceso lleva menos de 30 minutos. Y la mejor parte es que obtiene el efectivo que necesita y puede pagar mientras continúa usando su vehículo. Los clientes tienen la opción de obtener su oferta certificada y financiar el 100% en línea o visitar su sucursal más cercana de Discount Car Title Loan y obtener su efectivo allí. También puede comenzar el proceso en línea y terminar el proceso en una tienda. Usted decide LO QUE sea más fácil para usted!",
		textN05T: "1.-Aplica hoy",
		textN05Ta:
			"Solicite en línea o visite nuestra tienda más cercana de Discount Car Title Loan. Asegúrese de tener: un título de automóvil libre de gravámenes y una identificación emitida por el gobierno, como una licencia de conducir.",
		textN05Tb: "2.-Obtenga la aprobación en 5 minutos",
		textN05Tc:
			"Un asesor de préstamos de préstamos para títulos de automóviles con descuento lo ayudará a determinar cuánto efectivo puede obtener, cuánto puede pagar de manera realista, calcular sus descuentos y recomendar el producto que le ahorre más dinero. También tiene la opción de ser optimizado con nuestra herramienta en línea",
		textN05Td: "3.-Obtenga su efectivo",
		textN05Te: "El préstamo de título de automóvil con descuento lo hace efectivo más seguro y asequible rápidamente.",
		textN05Tf: "4.-Recupere el título de su automóvil",
		textN05Tg:
			"Una vez que haya pagado su préstamo sobre el título de su automóvil, tiene la opción de recuperarlo o puede dejar su título en nuestros archivos para evitar tener que pagar la tarifa de gravamen TxDMV de $ 28 en futuros préstamos sobre el título del automóvil.",
		textN06T: "LOS REQUISITOS: ¿Qué necesito para que me aprueben un préstamo en efectivo con Discount Car Title Loan?",
		textN06Ta:
			"El proceso de obtener un préstamo de título de automóvil con descuento es bastante sencillo. Después de que nos brinde información básica sobre sus finanzas y su vehículo, usted y nuestro representante de servicio al cliente altamente capacitado decidirán el monto del préstamo que sea mejor para usted y el producto que le ahorre la mayor cantidad de dinero, tome su efectivo y siga adelante. tu día. Tu auto se queda contigo. Puede seguir conduciendo su vehículo durante toda la duración de su préstamo. Asegúrese de llevar los artículos requeridos como se enumeran a continuación a su ubicación local de Préstamo de título de automóvil con descuento y permítanos ayudarlo a obtener el efectivo que necesita y que puede pagar, en 30 minutos o menos.",
		textN06Tb: "Requisitos del préstamo de título de automóvil de Texas:",
		textN06Tc:
			"En el estado de Texas, debe tener al menos 18 años para obtener un préstamo sobre el título de su automóvil y debe presentar una identificación emitida por el gobierno, como una licencia de conducir. El único otro artículo que necesita para ser aprobado para un préstamo de título de automóvil con descuento en el título de automóvil de Texas es un título de automóvil claro y sin gravamen. Cuando traiga esos dos artículos a su tienda local de Préstamo de título de automóvil con descuento (o los cargue si usa nuestra plataforma en línea), nos llevará menos de 30 minutos obtener el dinero que necesita y que puede pagar.",
		textN07T:
			"LOS DESCUENTOS: EL DESCUENTO DEL PRÉSTAMO DEL TÍTULO DEL COCHE ofrece muchos descuentos de préstamos a corto plazo que pueden reducir aún más los costos de su préstamo cuando paga a tiempo. Averigüe para qué descuentos califica hoy. Podrían agregar fácilmente hasta un 50% de ahorro!",
		textN08T:
			"¿Necesita ayuda para determinar cuántos de nuestros descuentos en préstamos de título de automóvil se aplican a usted? Estamos aquí para ayudar. Comuníquese con nosotros y uno de nuestros profesionales de préstamos le explicará todos los descuentos en préstamos de título de automóvil y otros ahorros que disfrutará con el Préstamo de título de automóvil con descuento.",
		textN09T:
			" EL OPTIMIZADOR DE PRÉSTAMOS: Comprar préstamos a corto plazo puede llevar mucho tiempo y ser un poco confuso. Una vez que comience a comparar los requisitos legales, las tarifas de origen, los porcentajes de interés y los diferentes plazos, no siempre es fácil descubrir cómo elegir el mejor préstamo a corto plazo. Afortunadamente, Discount Car Title Loan ha desarrollado herramientas financieras de alta tecnología como el Loan Optimizer que hace todo el trabajo por usted. Todo lo que tiene que hacer es responder algunas preguntas básicas sobre su automóvil y sus finanzas, y el software Loan Optimizer recomienda el producto que le ahorre más dinero. ¡Asombroso! Lo mejor de todo es que lleva menos de 5 minutos completarlo y podría ahorrarle cientos de dólares este año.",
		textN01F: "Como funciona?",
		textN02F:
			"Entendemos que la vida está llena de desafíos inesperados, muchos de los cuales solo pueden superarse si tiene acceso a efectivo rápido. En un mundo ideal, podría pasar por el banco de su vecindario, hablar con Roger, el banquero de toda la vida de su familia, completar un par de formularios y salir con el dinero que necesita, a una tasa de interés súper baja ... pero esa no es la forma en que Funciona en estos días. Los bancos del vecindario están cerrando, ya nadie conoce a sus banqueros, el papeleo para los préstamos bancarios es interminable, sus verificaciones de crédito no son realistas y nunca saldrá de un banco con efectivo en mano (a menos que lo encuentre en el piso).",
		textN03F:
			"Es por eso que Discount Finance & Online Personal Loans se especializa en préstamos personales. Su solvencia crediticia puede convertirse en una fuente rápida de ingresos, y en Discount Finance & Online Personal Loans su préstamo a corto plazo puede ser más seguro, más económico y rápido.",
		textN04F:
			"Obtener un préstamo personal en línea con Discount Finance & Online Personal Loans es sencillo. Todo el proceso lleva menos de 30 minutos. Los clientes tienen la opción de obtener su oferta certificada y financiar el 100% en línea o visitar su sucursal más cercana de Discount Finance & Online Personal Loans y obtener su efectivo allí. También puede comenzar el proceso en línea y terminar el proceso en una tienda. ¡Usted decide LO QUE sea más fácil para usted!",
		textN05F: "1.-Aplica hoy",
		textN05Fa:
			"Solicite en línea o visite nuestra tienda más cercana de Discount Finance & Online Personal Loans. Asegúrese de tener: comprobante de ingresos y una identificación emitida por el gobierno, como una licencia de conducir.",
		textN06F: "2.-Obtenga la aprobación en 5 minutos",
		textN06Fa:
			'Un asesor de préstamos de préstamos financieros y préstamos personales en línea lo ayudará a determinar cuánto efectivo puede obtener, cuánto puede pagar de manera realista, calcular sus descuentos y recomendar el producto que le ahorre más dinero. También tiene la opción de "optimizarse" con nuestra herramienta en línea',
		textN07F: "3.-Obtenga su efectivo",
		textN07Fa: "Discount Finance & Online Personal Loans le brinda efectivo más seguro y más económico rápidamente.",
		textN08F: "LOS REQUISITOS: ¿Qué necesito para ser aprobado para un préstamo en efectivo con Discount Finance y préstamos personales en línea?",
		textN08Fa:
			"El proceso de obtener un Discount Finance & Online Personal Loans es bastante sencillo. Después de que nos brinde información básica sobre usted y sus finanzas, usted y nuestro representante de servicio al cliente altamente capacitado decidirán el monto del préstamo que sea mejor para usted y el producto que le ahorre la mayor cantidad de dinero, usted toma su efectivo y continúa con su día . Asegúrese de llevar los artículos requeridos como se enumeran a continuación a su ubicación local de Financiación con descuento y préstamos personales en línea y permítanos ayudarlo a obtener el efectivo que necesita y que puede pagar, en 30 minutos o menos.",
		textN09F: "Requisitos de préstamos personales de Texas:",
		textN09Fa:
			"En el estado de Texas, debe tener al menos 18 años para obtener un préstamo personal y debe presentar una identificación emitida por el gobierno, como una licencia de conducir. El único otro artículo que necesita para ser aprobado en Discount Finance & Online Personal Loans es un comprobante de ingresos. Cuando traiga esos dos artículos a su tienda local de Financiación de descuentos y préstamos personales en línea (o los cargue si usa nuestra plataforma en línea), nos tomará menos de 30 minutos obtener el dinero que necesita y puede pagar.",
		textN10F:
			"LA COSA GRATUITA: Discount Finance & Online Personal Loans ofrece las tasas de préstamos personales más competitivas y muchos obsequios. Nuestros clientes obtienen puntajes de crédito gratuitos, pagos automáticos gratuitos y financiación instantánea gratuita. ¡Nunca pague una tarifa de cambio de cheques nuevamente!",
		textN11F:
			"¿Necesita ayuda para calcular cuánto dinero puede obtener? Estamos aquí para ayudar. Contáctenos y uno de nuestros profesionales de préstamos lo ayudará a responder sus preguntas.",
		textN12F:
			"EL OPTIMIZADOR DE PRÉSTAMOS: Comprar préstamos a corto plazo puede llevar mucho tiempo y ser un poco confuso. Una vez que comience a comparar los requisitos legales, las tarifas de apertura, los porcentajes de interés y los diferentes plazos, no siempre es fácil descubrir cómo elegir el mejor préstamo a corto plazo. Afortunadamente, Discount Finance & Online Personal Loans ha desarrollado herramientas financieras de alta tecnología como el Loan Optimizer que hace todo el trabajo por usted. Todo lo que tiene que hacer es responder algunas preguntas básicas sobre usted y sus finanzas, y el software Loan Optimizer recomienda el producto que le ahorre más dinero. ¡Asombroso! Lo mejor de todo es que lleva menos de 5 minutos completarlo y podría ahorrarle cientos de dólares este año.",
	},
	scheduleOfAllFees: {
		textN01T: "Programa de tarifas",
		textN02T:
			"Lista de todas las tarifas que se cobrarán por los servicios prestados por el Credit Access Business (CAB) en relación con los préstamos de título de vehículos de motor, según corresponda en los siguientes ejemplos:",
		textN03T: "Título del automóvil Divulgación de pago único de 6 meses",
		textN04T: " Título del auto Divulgación a plazos de 4 meses",
		textN05T: " Título del auto Divulgación a plazos de 5 meses ",
		textN06T: " Título del auto Divulgación a plazos de 6 meses ",
		textN01F: "Acerca de nosotros",
		textN02F:
			"Lo que hacemos Discount Loans Online no es un prestamista y no toma decisiones de préstamo o crédito. Conectamos a las personas interesadas con los prestamistas de nuestra red aprobada de préstamos personales y préstamos sobre títulos de automóviles. No controlamos ni somos responsables de las acciones o inacciones de ningún prestamista, no somos un agente, representante o corredor de ningún prestamista. Nuestro servicio de referencia siempre es gratis para usted.",
		textN03F:
			"Mercado en línea. Nuestro objetivo es conectarlo con un prestamista aprobado de nuestra red de prestamistas de préstamos personales y de título de automóvil. Hemos simplificado el proceso. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN04F:
			"Obtenga dinero rápido. Nuestro servicio de referencia de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de calidad o un préstamo sobre el título del automóvil. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada a un prestamista autorizado para su aprobación. Una vez que envíe su información, será redirigido al sitio web del prestamista, donde podrá revisar los términos del préstamo personal o del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN05F:
			"Verificaciones de crédito para préstamos personales. Al enviar su información a través de este sitio web, usted autoriza a los préstamos con descuento y a nuestros prestamistas de referencia a iniciar una verificación de crédito (solo para préstamos personales), que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza préstamos con descuento para compartir su información e historial de crédito con una red de prestamistas aprobados.",
		textN06F:
			"Divulgación de términos del prestamista. Los prestamistas proporcionarán documentos que contengan todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. No tenemos conocimiento ni control sobre los términos del préstamo ofrecidos por un prestamista. Se le insta a leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y a rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN07F:
			"Los pagos atrasados pueden dañar su puntaje de crédito. Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede hacer un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
	},
	occcNotice: {
		textN01T: "Aviso OCCC",
		textN02T:
			"Este negocio tiene licencia y es examinado bajo la ley de Texas por la Oficina del Comisionado de Crédito al Consumidor (OCCC), una agencia estatal. Si una queja o pregunta no se puede resolver contactando a la empresa, los consumidores pueden contactar a OCCC para presentar una queja o hacer una pregunta general relacionada con el crédito. Dirección OCCC: 2601 N. Lamar Blvd., Austin, Texas 78705. Teléfono: (800) 538-1579. Fax: (512) 936-7610. Sitio web: occc.texas.gov. Correo electrónico: consumer.complaints@occc.texas.gov",
		textN03T: "Aviso:",
		textN04T:
			"Un adelanto de dinero obtenido a través de un préstamo de día de pago o préstamo de título de auto no está destinado a satisfacer necesidades financieras a largo plazo. Un préstamo de día de pago o un título de auto solo debe usarse para satisfacer las necesidades inmediatas de efectivo a corto plazo. Refinanciar el préstamo en lugar de pagar la deuda en su totalidad cuando sea necesario requerirá el pago de cargos adicionales.",
		textN05T: "Aviso a los residentes de Texas:",
		textN06T:
			"En el programa de tarifas provisto, y en muchas de nuestras tiendas de Texas, Vin-Title, LLC (d / b / a Discount Title Loan) opera como un negocio de acceso de crédito registrado (CAB). El prestamista real es un tercero no afiliado.",
		textN01F: "Acerca de nosotros",
		textN02F:
			"Lo que hacemos. Discount Loans Online no es un prestamista y no toma decisiones de préstamo o crédito. Conectamos a las personas interesadas con los prestamistas de nuestra red aprobada de préstamos personales y préstamos sobre títulos de automóviles. No controlamos ni somos responsables de las acciones o inacciones de ningún prestamista, no somos un agente, representante o corredor de ningún prestamista. Nuestro servicio de referencia siempre es gratis para usted.",
		textN03F:
			"Mercado en línea. Nuestro objetivo es conectarlo con un prestamista aprobado de nuestra red de prestamistas de préstamos personales y de título de automóvil. Hemos simplificado el proceso. No es necesario visitar cientos de sitios web y completar numerosos documentos.",
		textN04F:
			"Obtenga dinero rápido. Nuestro servicio de referencia de préstamos en línea satisface fácilmente su necesidad de conectarse con un préstamo personal de calidad o un préstamo sobre el título del automóvil. El proceso lleva unos minutos. Complete nuestro cuestionario seguro de préstamos en línea y le enviaremos una copia encriptada a un prestamista autorizado para su aprobación. Una vez que envíe su información, será redirigido al sitio web del prestamista, donde podrá revisar los términos del préstamo personal o del préstamo sobre el título del automóvil, incluidos los detalles sobre todas las tasas y tarifas aplicables. Si acepta estos términos, el prestamista puede depositar dinero directamente en su cuenta bancaria, en muchos casos, tan pronto como hoy!",
		textN05F:
			"Verificaciones de crédito para préstamos personales. Al enviar su información a través de este sitio web, usted autoriza a los préstamos con descuento y a nuestros prestamistas de referencia a iniciar una verificación de crédito (solo para préstamos personales), que puede incluir la verificación de su número de seguro social, número de licencia de conducir u otra identificación, y una revisión de su solvencia Las verificaciones de crédito generalmente son realizadas por una de las principales agencias de crédito como Experian, Equifax y TransUnion, pero también pueden incluir agencias de crédito alternativas como Teletrack, DP Bureau u otras. También autoriza préstamos con descuento para compartir su información e historial de crédito con una red de prestamistas aprobados.",
		textN06F:
			"Divulgación de términos del prestamista. Los prestamistas proporcionarán documentos que contengan todas las tarifas y la información de tasas relacionadas con el préstamo que se ofrece, incluidas las posibles tarifas por pagos atrasados y las reglas bajo las cuales se le puede permitir (si lo permite la ley aplicable) refinanciar, renovar o renovar su préstamo. Los honorarios del préstamo y las tasas de interés son determinados únicamente por el prestamista con base en las políticas internas del prestamista, los criterios de suscripción y la ley aplicable. No tenemos conocimiento ni control sobre los términos del préstamo ofrecidos por un prestamista. Se le insta a leer y comprender los términos de cualquier préstamo ofrecido por cualquier prestamista y a rechazar cualquier oferta de préstamo en particular que no pueda pagar o que incluya términos que no sean aceptables para usted.",
		textN07F:
			"Los pagos atrasados pueden dañar su puntaje de crédito. Tenga en cuenta que perder un pago o realizar un pago atrasado puede afectar negativamente su puntaje de crédito (para préstamos personales). Para protegerse y proteger su historial de crédito, asegúrese de aceptar solo los términos del préstamo que pueda pagar. Si no puede realizar un pago a tiempo, debe comunicarse con su prestamista de inmediato y analizar cómo manejar los pagos atrasados.",
	},
	locations: {
		textN01: "Información de contacto",
		textN02: "Ubicacion",
		textN03: "Teléfono",
		textN04: "Email",
		textN05: "Horario de la tienda",
		textN06: "Tiendas",
		textN07:
			"Discount Car Title Loan es una compañía de financiamiento de consumo que origina y presta servicios a préstamos para títulos de automóviles, brindando a los consumidores de Texas acceso a crédito garantizado a corto plazo a las tasas más competitivas del mercado.",
		textN07F:
			"Discount Finance es una financiera que presta dinero a clientes en base a su historial crediticio sin ninguna necesidad de un aval. Ofrece créditos a corto plazo a las tasas más competitivas en el mercado.",
		textN08: "Lunes - Viernes::",
		textN09: "Sábado:",
	},
	carrers: {
		textN01: "Empleos",
		textN02: "Disponible pronto",
	},
};

export default es;
