import React, { Component } from "react";
import queryString from "query-string";
import Page from "../components/page";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const mode = queryString.parse(this.props.location.search).mode;
		const actionCode = queryString.parse(this.props.location.search).oobCode;
		const continueUrl = queryString.parse(this.props.location.search).continueUrl;
		const lang = queryString.parse(this.props.location.search).lang ? queryString.parse(this.props.location.search).lang : "en";

		this.setState({
			mode: mode,
			actionCode: actionCode,
			continueUrl: continueUrl,
			lang: lang,
		});
	}

	_sendEmail = e => {
		e.preventDefault();
		if (this.state.email) {
			axios
				.get("https://us-central1-digital-loans.cloudfunctions.net/sendResetPasswordEmail?email=" + this.state.email)
				.then(() => {
					return Swal.fire(this.props.messages.commText.Success, this.props.messages.resetpassword.textN01, "success");
				})
				.catch(() => {
					return Swal.fire(this.props.messages.commText.oops, this.props.messages.resetpassword.textN02);
				});
		}
	};

	_resetPassword = e => {
		e.preventDefault();
		if (this.state.password) {
			firebase
				.auth()
				.confirmPasswordReset(this.state.actionCode, this.state.password)
				.then(async () => {
					const result = await Swal.fire({
						title: this.props.messages.resetpassword.textN03,
						type: "success",
						text: this.props.messages.resetpassword.textN04,
					});
					if (result.value) {
						return this.props.history.push("/login");
					}
					return null;
				})
				.catch(err => {
					return Swal.fire(this.props.messages.commText.oops, err.message);
				});
		}
	};

	render() {
		return (
			<Page {...this.props}>
				<div className="container mt-3 py-3">
					<div className="page-title__holder text-center">
						<div>
							<h1 className="page-title__title">{this.props.messages.resetpassword.textN05}</h1>
							<p className="page-title__subtitle">
								{this.state.mode ? this.props.messages.resetpassword.textN06 : this.props.messages.resetpassword.textN07}
							</p>
						</div>
						<br />
					</div>
					{this.state.mode ? (
						<form className="contact-form login mt-30 mb-30 mx-auto text-center" onSubmit={this._resetPassword}>
							<div className="cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.resetpassword.textN08}</span>
								<input
									type="password"
									className="col-8"
									required
									onChange={e =>
										this.setState({
											password: e.target.value,
										})
									}
								/>
							</div>
							<br />
							<br />
							<input type="submit" className="btn btn--color btn--button" value="Reset Password" id="submit-message" />
						</form>
					) : (
						<form className="contact-form login mt-30 mb-30 mx-auto text-center" onSubmit={this._sendEmail}>
							<div className="cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.resetpassword.textN09}</span>
								<input type="email" className="col-8" required onChange={e => this.setState({ email: e.target.value })} />
							</div>
							<br />
							<br />
							<input type="submit" className="btn btn--color btn--button" value="Send Email" id="submit-message" />
						</form>
					)}
				</div>
			</Page>
		);
	}
}
