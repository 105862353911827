import React, { Component } from "react";
import queryString from "query-string";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

import Page from "../components/page";
import Step from "../components/step";
import Api from "../components/api";
import users from "../assets/users.png";

export default class extends Component {
	constructor(props) {
		super(props);
		const price = queryString.parse(this.props.location.search).p;
		const loan = queryString.parse(this.props.location.search).l;
		const size = queryString.parse(this.props.location.search).s;
		const uvc = queryString.parse(this.props.location.search).u;
		const mileage = queryString.parse(this.props.location.search).m;
		const token = queryString.parse(this.props.location.search).token;
		this.state = {
			price: price ? JSON.parse(atob(price)) : 0,
			loan: loan ? JSON.parse(atob(loan)) : 0,
			size: size ? JSON.parse(atob(size)) : 0,
			uvc: uvc,
			mileage: mileage,
			loading: true,
			token: token,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.state.token) {
			return this.login(this.state.token);
		} else {
			return this.setState({
				loading: false,
			});
		}
	}

	login = token => {
		return firebase
			.auth()
			.signInWithCustomToken(token)
			.then(() => {
				return this.setState({
					loading: false,
				});
			});
	};

	startIt = () => {
		const parent = this;
		if (this.props.uid) {
			return firebase
				.firestore()
				.doc("applications/" + this.props.uid)
				.update({
					loanNeeded: this.state.size ? this.state.size : null,
					timestamp: moment().toDate(),
					lastUpdate: "User",
					status: 0,
				})
				.then(() => {
					return parent.props.history.push("/application");
				});
		} else {
			return this.props.history.push({
				pathname: "/login",
				search: "?s=" + btoa(this.state.size) + "&signup=true&u=" + this.state.uvc + "&m=" + this.state.mileage,
			});
		}
	};

	cleanNumber = num => {
		if (num < 10000) {
			return (
				"$" +
				parseFloat(num).toLocaleString("en-US", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})
			);
		} else if (num) {
			return "$10,000";
		} else {
			return 0;
		}
	};

	render() {
		return (
			<Page {...this.props}>
				{this.state.loading ? <div className="loading">Loading&#8230;</div> : null}
				{!this.state.loading ? (
					<React.Fragment>
						{this.state.token ? (
							<Api {...this.props} />
						) : (
							<React.Fragment>
								<Step title={this.props.messages.start.textN01} subtitle={this.props.messages.start.textN02} start={true} />
								<div className="container py-2 text-center" id="start">
									<img src={users} alt="Welcome!" />
									<br />
									<br />
									{this.state.price ? (
										<h4>
											{this.props.messages.start.textN04} <br />
											<h2>
												<b>{this.cleanNumber(this.state.price)}</b>
											</h2>
										</h4>
									) : null}
									{this.state.loan ? (
										<h4>
											{this.props.messages.start.textN05} <br />
											<h2>
												<b>{this.cleanNumber(this.state.loan)}</b>
											</h2>
										</h4>
									) : null}
									<br />
									<br />
									<h5>{this.props.messages.start.textN06}</h5>
									<br />
									<div className="row text-center justify-content-center px-5">
										<button type="button" onClick={this.startIt} className="btn btn--md btn--color btn--button col-12 col-md-4">
											{this.props.messages.start.textN07}
										</button>
									</div>
									<br />
									<p className="ficoScore">{this.props.messages.start.textN08}</p>
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				) : null}
			</Page>
		);
	}
}
