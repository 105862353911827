import React, { Component } from "react";
import { animateScroll } from "react-scroll";

export default class extends Component {
	_goToTop = () => {
		animateScroll.scrollToTop();
	};
	render() {
		return (
			<div className="footer__bottom top-divider">
				<div className="container text-center">
					<span className="copyright">&copy; {this.props.messages.copy.textN01}</span>
				</div>
				<div id="back-to-top" onClick={this._goToTop}>
					<i className="ui-arrow-up"></i>
				</div>
			</div>
		);
	}
}
