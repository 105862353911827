import React, { Component } from "react";
import headerImage from "../assets/slider.jpg";
import headerImageTitle from "../assets/sliderTitle.jpg";
import headerImagePersonal from "../assets/sliderPersonal.jpg";

export default class extends Component {
	render() {
		return (
			<div>
				{/* <p className="hero__title--boxed arrow">Welcome to the Discount Lending Marketplace.</p> */}

				{process.env.REACT_APP_TYPE === "all" ? <img src={headerImage} style={{ width: "100%" }} alt="Welcome!" /> : null}
				{process.env.REACT_APP_TYPE === "title" ? <img src={headerImageTitle} style={{ width: "100%" }} alt="Welcome!" /> : null}
				{process.env.REACT_APP_TYPE === "finance" ? <img src={headerImagePersonal} style={{ width: "100%" }} alt="Welcome!" /> : null}
			</div>
		);
	}
}
