const faq = [
	{
		question: "¿Cómo configuro los pagos automáticos?",
		answer:
			"Los pagos automáticos son la forma más fácil de hacer los pagos de su préstamo a tiempo. Inscríbase hoy llamando directamente a su prestamista. Necesitará su cuenta de banco o cooperativa de crédito y el número de ruta (consulte a continuación) para la configuración única.",
	},
	{
		question: "¿Cómo puedo pagar mi préstamo personal o el préstamo sobre el título del vehículo?",
		answer:
			"La forma en que paga su préstamo depende de la opción de pago que tenga disponible su prestamista. La mayoría de los prestamistas le permiten pagar en línea o por teléfono. Otros prestamistas con ubicaciones físicas le permitirán pagar su préstamo personal o el préstamo sobre el título del automóvil en persona. Su prestamista le proporcionará esta información después de que haya sido aprobado para un préstamo personal o un prestamosobreeltítulo de carro.",
	},
	{
		question: "¿Puedo cancelar mi préstamo después de ser aprobado?",
		answer:
			"En la mayoría de los casos, nuestros prestamistas de Discount-Loans.com permiten a los solicitantes cancelar sus préstamos dentro de los 3 días posteriores a la recepción de su anticipo. Si el capital original se paga antes del período de gracia de 3 días, por lo general no será responsable de ningún cargo por intereses.",
	},
	{
		question: "¿Cuánto tiempo tengo para pagar mi préstamo personal o el préstamo sobre el título del auto?",
		answer:
			"Los plazos y las fechas de vencimiento de los préstamos personales y del título de automóvil varían según el estado. Nuestros prestamistas de Discount-Loans.com le proporcionarán esta información después de que haya sido completamente aprobado para un préstamo personal o un préstamo sobre el título del automóvil.",
	},
	{
		question: "¿Hay una multa por pago anticipado?",
		answer:
			"La mayoría de nuestros prestamistas de Discount-Loans.com no cobran una multa por pago anticipado. Una multa por pago anticipado es una tarifa a la que se accede cuando paga su préstamo antes de la fecha de vencimiento.",
	},
	{
		question: "¿Qué sucede si me retraso con  mi pago?",
		answer:
			"Si se atrasa con el pago de su préstamo personal o prestamodel título de su automóvil, es posible que se le cobren tarifas adicionales. El número de tarifas variará según el estado y el prestamista. Además de cobrarle una tarifa, puede haber otras consecuencias si el préstamo no se paga. Algunos prestamistas pueden optar por informar sumorosidad a todas las oficinas de crédito, enviar su información a un servicio de cobranza o se confisque su vehículo y venderlo a un tercero.",
	},
	{
		question: "¿Cómo recupero mi título de vehículo? ¿Cuánto tiempo se tarda?",
		answer:
			"Su prestamista le devolverá el título del vehículo una vez que haya terminado de pagar el préstamo completo. Si completa su préstamo de título de auto con un prestamista que tiene una ubicación física, generalmente recibirá su título de vehículo de inmediato. Si su préstamo sobre el título del carro se completó en línea, puede demorar algunas semanas recibir su título de vehículo nuevamente en el correo.",
	},
	{
		question: "¿Alguien más puede hacer un pago del préstamo por mí?",
		answer:
			"Sí. Sin embargo, las leyes de privacidad limitan la información que los prestamistas de Discount-Loans.compueden compartir sobre el préstamo. Por lo tanto, si desea que alguien realice un pago en su nombre, proporcione la autorización de su prestamista para realizar transacciones con esa persona en su préstamo o asegúrese de que tenga la siguiente información para realizar un pago: el nombre y el teléfono, número del préstamo y la cantidad de pago que le gustaría hacer.",
	},
];

export default faq;
