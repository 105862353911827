import React, { Component } from "react";
import Swal from "sweetalert2";
import moment from "moment";

import Step from "../../../components/step";
import Page from "../../../components/page";

import { firestore } from "../../../firebase";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			expires: "-",
			loanSize: null,
			loanType: null,
			loading: true,
			amortized: [],
			principal: [],
			interests: [],
			terms: {},
			cab: false,
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (!this.props.uid) {
			this.props.history.push("/login");
		} else {
			this._loadApplication();
		}
	}

	_loadApplication = () => {
		firestore()
			.doc("applications/" + this.props.uid)
			.onSnapshot(res => {
				this.setState(
					{
						loanType: res.data().loanType,
						loan: res.data().loan,
						loading: false,
						denied: res.data().denied,
						deniedReason: res.data().deniedReason,
					},
					async () => {
						if (this.state.denied) {
							return this.setState({ loading: false });
						}
						return this._loadData();
					}
				);
			});
	};

	_loadData = () => {
		return firestore()
			.doc("applications/" + this.props.uid + "/data/loan")
			.get()
			.then(data => {
				if (data.data()) {
					const principals = [];
					const interests = [];
					return this.setState(
						{
							discount: data.data().discount,
							loanSize: data.data().loan,
							data: data.data(),
							amortized: data.data().amortized,
							cab: data.data().type === "CAB",
							principals: principals,
							interests: interests,
							fee: data.data().fee,
							expires: moment(data.data().timestamp.seconds, "X")
								.add(1, "week")
								.format("l"),
							loading: false,
						},
						() => {
							return this._getSums();
						}
					);
				} else {
					return this.setState({
						loading: true,
					});
				}
			});
	};

	_getSums = () => {
		let totalPayment = 0;
		let totalPrincipal = 0;
		let totalInterest = 0;
		let totalFee = 0;

		this.state.amortized.forEach(item => {
			totalPayment = totalPayment + this.state.data.payment;
			totalPrincipal = totalPrincipal + item.principal;
			totalInterest = totalInterest + item["standardRate"];
			totalFee = totalFee + this.state.fee;
		});

		let terms = {};
		if (this.state.loanSize > 999) {
			terms = {
				0: "6 MONTH TERM",
				100: "9 MONTH TERM",
			};
		}

		this.setState({
			totalPayment,
			totalPrincipal,
			totalInterest,
			totalFee,
			terms,
		});
	};

	application = async () => {
		await firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 2,
				lastUpdate: "User",
				timestamp: moment().toDate(),
			});
		this.props.onUpdateLoan({
			status: 2,
			type: this.props.loan.type,
			vehicle: this.props.loan.vehicle,
			term: this.props.loan.term,
		});
		return this.props.history.push("/application");
	};

	complete = async () => {
		await firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 3,
				lastUpdate: "User",
				term: this.props.loan.term,
				timestamp: moment().toDate(),
			});
		this.props.onUpdateLoan({
			status: 3,
			type: this.props.loan.type,
			vehicle: this.props.loan.vehicle,
			term: this.props.loan.term,
		});
		this.props.history.push("/documents");
	};

	cleanNumber = (num, digits) => {
		if (num) {
			return (
				"$" +
				JSON.parse(num).toLocaleString("en-US", {
					minimumFractionDigits: digits !== undefined ? digits : 2,
					maximumFractionDigits: digits !== undefined ? digits : 2,
				})
			);
		} else {
			return "$0";
		}
	};

	render() {
		const cab = this.state.cab;
		return (
			<Page {...this.props}>
				{this.state.loading ? <div className="loading">Loading&#8230;</div> : null}
				{this.state.denied || (this.state.loanSize < 60 && this.state.loanSize !== null) ? (
					<div className="container py-3">
						<h1 className="title-row text-center uppercase">
							<b>{this.props.messages.personalLoan.textN01}</b>
						</h1>
						<div className="offer my-5">
							<p>{this.props.messages.personalLoan.textN02}</p>
						</div>
						<div className="row justify-content-center">
							<a href="tel:+1 210 281 4435" className={"col-10 col-md-5"}>
								<button className="btn btn--sm btn--primary btn--button">{this.props.messages.personalLoan.textN03}</button>
							</a>
						</div>
					</div>
				) : null}
				{this.state.loanSize >= 60 && this.state.loanSize !== null ? (
					<div>
						<Step title="Personal Loan Offer" subtitle={"Valid thru " + this.state.expires} />
						<div className="container py-3">
							<h1 className="title-row text-center uppercase">
								<b>{this.props.messages.personalLoan.textN04}</b>
							</h1>
							<div className="offer mt-5 mb-4">
								<div className="row">
									<div className="col-8">
										<h5>
											{this.props.messages.personalLoan.textN05}
											<br />
											<b>{this.props.messages.personalLoan.textN06}</b>
											<br />
											{this.props.messages.personalLoan.textN07}
										</h5>
									</div>
									<div className="col-4 text-right">
										<h6>
											<b>{this.cleanNumber(this.state.loanSize, 0)}</b>
										</h6>
									</div>
									<div className="col-6 pt-2">
										<h5>{this.props.messages.personalLoan[cab ? "textN08b" : "textN08"]}</h5>
									</div>
									<div className="col-6 text-right">
										<h6>
											<b>{this.cleanNumber(cab ? this.state.data.payment / 2 : this.state.data.payment, 0)}</b>
										</h6>
									</div>
									<div className="col-6 pt-2">
										<h5>{this.props.messages.personalLoan[cab ? "textN09b" : "textN09"]}</h5>
									</div>
									<div className="col-6 text-right">
										<h6>
											<b>
												{this.state.data.amortized.length} {this.props.messages.commText.Months}
											</b>
										</h6>
									</div>
								</div>
							</div>
							<br />
							{/* Hide amortization table if CAB product */}
							{cab ? null : (
								<div className="row justify-content-md-center">
									<div className="col-12 col-md-8">
										<table className="table table-bordered text-center">
											<thead>
												<tr
													style={{
														background: "#009ACD",
														color: "#FFFFFF",
													}}>
													<th scope="col">{this.props.messages.personalLoan.textN11}</th>
													<th scope="col">{this.props.messages.personalLoan.textN12}</th>
													<th scope="col">{this.props.messages.personalLoan.textN13}</th>
													<th scope="col">{this.props.messages.personalLoan.textN14}</th>
												</tr>
											</thead>
											<tbody>
												{this.state.amortized.map((item, index) => {
													return (
														<tr key={index}>
															<th>{index + 1}</th>
															<td>{this.cleanNumber(this.state.data.payment)}</td>
															<td>{this.cleanNumber(item.principal)}</td>
															<td>{this.cleanNumber(item["standardRate"] + this.state.fee)}</td>
														</tr>
													);
												})}
											</tbody>
											<tfoot>
												<tr>
													<th>Total:</th>
													<th>{this.cleanNumber(this.state.totalPayment)}</th>
													<th>{this.cleanNumber(this.state.totalPrincipal)}</th>
													<th>{this.cleanNumber(this.state.totalInterest + this.state.totalFee)}</th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							)}
							<br />
							<p>
								{this.props.messages.personalLoan[cab ? "textN15b" : "textN15"]}
								<b> {this.cleanNumber(cab ? this.state.data.payment / 2 : this.state.data.payment, 0)}</b>{" "}
								{this.props.messages.personalLoan.textN16}
								<span
									className={"link"}
									onClick={() => {
										return Swal.fire({
											title: "APR Disclosure",
											html:
												this.props.messages.personalLoan.textN19 +
												this.props.messages.personalLoan.textN20 +
												this.props.messages.personalLoan.textN21 +
												"<br/><br/>" +
												this.props.messages.personalLoan.textN22 +
												this.props.messages.personalLoan.textN23,
										});
									}}>
									{" "}
									{this.props.messages.personalLoan.textN24}
								</span>
							</p>
							<br />
							<br />
							<br />
							<div className="row justify-content-md-center">
								<div className="col-12 col-md-6 col-lg-4 order-3 order-md-2">
									<a href="tel:+1 210 281 4435">
										<button className="btn btn--sm btn--secondary btn--button">{this.props.messages.personalLoan.textN25}</button>
									</a>
									<br />
									<br />
								</div>
								<div className="col-12 col-md-12 col-lg-4 order-1 order-md-3">
									<button onClick={this.complete} className="btn btn--sm btn--primary btn--button">
										{this.props.messages.personalLoan.textN26}
									</button>
									<br />
									<br />
								</div>
							</div>
							<p className="text-left py-3" style={{ fontSize: 12, textAlign: "left" }}>
								{this.props.messages.personalLoan.textN27}
								{this.props.messages.personalLoan.textN28}
								{this.props.messages.personalLoan.textN29}
								{this.props.messages.personalLoan.textN30}
							</p>
						</div>
					</div>
				) : null}
			</Page>
		);
	}
}
