const faq = [
	{
		question: "¿Cómo puedo construir mi crédito con un préstamo referido a través de dtloans.com?",
		answer:
			"Los prestamistas de préstamos personales de Discount Car Title Loan Online (dtloans.com) tienen la capacidad de informar cuentas a TransUnion, que es una importante agencia de informes de crédito. Los préstamos personales generalmente se informan y los prestamistas generalmente no informan los préstamos de título. Cuando se informan los pagos, se reflejarán positivamente en su informe de crédito si realiza pagos en la fecha de vencimiento o antes y paga el préstamo en su totalidad. En general, se necesitan al menos 6 meses de informes de pagos positivos para crear una calificación crediticia, aunque esto puede variar según la agencia y la calificación crediticia.",
	},
	{
		question: "¿Cómo se informará mi préstamo referido por dtloans.com a las agencias de informes de crédito?",
		answer:
			"Los productos de préstamos personales con descuento en préstamos para títulos de automóviles en línea (dtloans.com) informan cada mes, incluyendo pagos puntuales, incidentes de morosidad y el pago de un préstamo, a TransUnion, una importante agencia de informes crediticios, cada mes.",
	},
	{
		question:
			"Si tengo mal crédito, ¿puedo obtener un préstamo a través de un prestamista de descuento para el título de automóvil en línea (dtloans.com)?",
		answer:
			"¡El préstamo de título de automóvil con descuento en línea (dtloans.com) funciona con todo tipo de crédito! Los requisitos mínimos del préstamo de título de automóvil con descuento en línea (dtloans.com) para obtener un préstamo son los siguientes: tiene al menos 18 años, debe tener una fuente estable de pagos de ingresos recurrentes, debe residir en el estado que elija para su solicitud de préstamo y no tiene bancarrotas activas o actualmente tiene la intención de solicitar voluntariamente un alivio de bancarrota. Después de completar una solicitud de préstamo, que puede iniciarse aquí, obtendrá los términos de su préstamo personalizado directamente del prestamista que seleccione. Su contrato de préstamo le mostrará el pago mensual, sus intereses y tarifas, su tasa de descuento y su APR.",
	},
	{
		question: "¿Puedo obtener un préstamo si estoy en servicio militar activo?",
		answer:
			"El préstamo de título de automóvil con descuento en línea (dtloans.com) actualmente no presta servicio militar activo. Los consumidores no pueden ser un miembro regular o de reserva del Ejército, la Armada, el Cuerpo de Marines, la Fuerza Aérea o la Guardia Costera, sirviendo en servicio activo bajo una llamada u orden que no especifica un período de 30 días o menos o un dependiente de un miembro de las Fuerzas Armadas en servicio activo.",
	},
];

export default faq;
