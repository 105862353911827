import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import icon from "../assets/iconMapT.png";
import locationDataTitle from "../data/locationDataTitle";
import locationDataFinance from "../data/locationDataFinance";

class StoresMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			spanish: null,
			storesArrTitle: Object.values(locationDataTitle),
			storesArrFinance: Object.values(locationDataFinance),
		};
	}
	static defaultProps = {
		center: {
			lat: 27.592899,
			lng: -98.3092,
		},
		zoom: 7,
		key: "AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk",
	};

	render() {
		return (
			// Important! Always set the container height explicitly
			<div style={{ height: 400, width: "100%" }}>
				<GoogleMapReact bootstrapURLKeys={{ key: this.props.key }} defaultCenter={this.props.center} defaultZoom={this.props.zoom}>
					{this.state.storesArrTitle.map(data => (
						<AnyReactComponent key={data.bName} lat={data.lat} lng={data.lng} text={data.bName} urlStor={data.linkMap} />
					))}
				</GoogleMapReact>
			</div>
		);
	}
}

export default StoresMap;

const AnyReactComponent = ({ text, urlStor }) => (
	<div style={{ width: 80 }}>
		<a href={urlStor} target="blank">
			<img src={icon} alt="Logo" style={{ height: 30, width: 30 }} />
			{/*<h3 className="section-title" style={{ fontSize: 12, textAlign: "left", color: "#fe0022" }}>*/}
			{/*	{text}*/}
			{/*</h3>*/}
			<span style={{ color: "#000" }}></span>
		</a>
	</div>
);
