import React from "react";

export default class extends React.Component {
	componentDidMount() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
		window.location.href = "https://agreement.discount-loans.com/?uid=" + this.props.uid;
	}

	render() {
		return null;
	}
}
