const faq = [
	{
		question: "What is a traditional installment loan (bad credit loan)?",
		answer:
			"A traditional installment loan (bad credit loan) is a small-dollar loan that is repaid in equal monthly installments of principal and interest, with no balloon payments. In this sense, traditional installment loans, also known as TILs, are just like a standard mortgage or car loan that you get from a credit union or bank.",
	},
	{
		question: "Why is it referred to as a “traditional” installment loan?",
		answer:
			"Traditional installment loans (bad credit loans) have been a regulated credit option for consumers for over 100 years. They were created by a coalition of consumer advocacy groups and state legislative representatives and commissions in order to provide a safe option for small-dollar credit. These loans were established to offer a regulated and affordable lending option so that consumers would not have to resort to unregulated and illegal lenders known as “loan sharks.”  As long as installment loans (bad credit loans) were the only form of small-dollar credit available to consumers, there was no problem in society with “over-lending” or with consumers falling into a “cycle of debt.” This is because traditional installment lenders test the borrower’s ability to repay before approving a loan, and because the installment structure gives the borrower a disciplined debt repayment plan.",
	},
	{
		question: "Who uses traditional installment loans(bad credit loans)?",
		answer:
			"Generally, finance companies have made traditional installment loans (bad credit loans) to individual consumers in local communities, while commercial banks have made loans to businesses. Traditional installment lenders have served consumers this way for over 100 years. More recently, new lenders have appeared on the scene claiming to be installment lenders. However, they offer loans that do not resemble or represent traditional installment loans at all. Unlike traditional installment lenders, some of these new companies offer loans through the Internet; some require that borrowers provide the lender direct access to their bank accounts; and some are unregulated offshore lenders. Traditional installment lenders do not require direct access to a borrower’s bank account. TILs are made from brick-and mortar offices and feature fully amortizing terms. In addition, TIL payments are reported to credit bureaus, so borrowers can build good credit for larger purchases like a new vehicle or a home.",
	},
	{
		question: "How does a person get a traditional installment loan (bad credit loan)?",
		answer:
			"Borrowers apply for a traditional installment loan at their local consumer finance office, just as they would for an auto loan or mortgage at a credit union or bank. A borrower is required to submit a credit application to the lender. The lender then underwrites the loan according to established credit standards. The lender reviews the applicant’s credit history and requires verification of income, residency, and ability to repay the loan. If, after reviewing the borrower’s information and current budget obligations, the lender determines that the borrower can safely afford the repayment terms, the lender approves the loan. If the borrower’s current obligations are excessive, or if the borrower has insufficient income, the lender will decline the application, because it does not serve the consumer’s best interests, and there is no benefit to the lender to make a loan that a borrower cannot pay back.",
	},
	{
		question: "How do these traditional installment loans (bad credit loans) work?",
		answer:
			"The amount borrowed is paid off over the term of the loan with fixed, equal monthly payments that are structured to ensure that the loan fits the borrower’s monthly budget.",
	},
	{
		question: "Are traditional installment loans (bad credit loans) regulated?",
		answer:
			"Absolutely. Historically, these loans were created specifically to give consumers a regulated option for small-dollar credit. While each state has its own unique rules, regulations, and licensing requirements, all traditional installment loans fall under the same federal regulations that cover banks and credit unions, including the Truth in Lending Act, which requires detailed disclosures of costs.",
	},
	{
		question: "Aren’t traditional installment loans “high cost” credit?",
		answer:
			"No. When it comes to small-dollar loans, a “higher APR” and “high cost” are not the same thing. Because the Federal Truth in Lending Act requires an “annual” percentage rate, APR numbers on small-dollar loans can appear to be high, while the dollar cost in interest for the consumer is actually low. This fact was noted by Jennifer Tescher, Director of the Center for Financial Services Innovation, one of the country’s leading advocacy organizations for the unbanked and under-banked. In her letter to the FDIC concerning the need for protecting and providing small-dollar loans, she writes: “Double-digit interest rates in excess of 36 percent APR can provoke community outrage, yet over the course of a month, what may seem like an overly high interest rate may generate a relatively small cost to the consumer.” This statement is illustrated by considering the difference between a $500 loan with an 18% APR versus a $500 loan with a 69% APR. When paid over a seven-month period under a traditional installment monthly payment plan, the 18% loan carries a monthly payment of $76, compared to a monthly payment of $89 for the 69% loan. The difference is actually only 40 cents a day. The majority of a monthly payment on a traditional consumer installment loan is the repayment of principal. If someone cannot afford an $89 dollar-a-month payment, they most likely cannot afford a $76 dollar-a-month payment. And the ability of the lender to offer the loan often depends on that 40 cents a day.",
	},
	{
		question: "Shouldn’t we protect consumers by limiting interest rates on traditional installment loans (bad credit loans)?",
		answer:
			"For small-dollar loans, interest rate limits can actually work against consumers’ best interests, in part because they reduce consumers’ choices. Interest rates are a function of both the size and length of a loan. For example, a 30% rate would be exorbitant for a $200,000, 30-year mortgage, but would not even cover a lender’s costs on a $500 loan for six months.Arguments for across-the-board low rates miss the critical fact that for small-dollar loans, low rates can actually mean that credit becomes unavailable, because lenders simply cannot afford to offer the loans. In addition, loan products like credit cards, which can have relatively low rates compared to traditional installment loans, can often result in a significantly higher cost to the consumer, because credit cards are structured with minimum payments and therefore indefinite terms. Also, if a consumer only needs $500, but the smallest loan a lender can afford to make with a 36% APR is $5,000, the lower rate doesn’t help that consumer. The rate may be lower, but the consumer will have to borrow much more than he needs, pay far more in interest, and will be in debt for much longer. In addition, many consumers may not even qualify for a $5,000 loan. Thus, interest rate limits would deprive borrowers of access to the lowest-cost loans that meet their actual needs. Traditional installment loans are nominally higher-rate loans, but are, in fact, low cost for the consumer. Again, this is because these small-dollar loans are paid in fixed installments of mostly principal over a set period of time. Consumers reasonably want safe, responsible, low cost loans. Traditional installment loans satisfy that desire.",
	},
	{
		question: "Aren’t traditional installment loans (bad credit loans) like payday loans?",
		answer:
			"No. Traditional small-dollar installment loans are entirely different loan products, and more like credit union loans or bank mortgages. In addition, they are offered for longer terms and require equal monthly payments of principal and interest, which fully repay the loan at its maturity.Payday loans are short-term loans that are due in either two weeks or one month—the borrower’s payday cycle. Payday loans come with no payment plans, except for the requirement to pay the entire balance, known as a balloon payment, at maturity. This can be difficult for a borrower to manage.Applying for a payday loan does not include a budget review to determine if the borrower has the ability to repay the loan at the two-week or one-month maturity. Instead, to “guarantee” successful repayment of the loan, payday lenders require the consumer to relinquish a postdated check or to authorize the lender to withdraw money from the consumer’s checking account, regardless of the consumer’s financial situation. This may result in substantial overdraft fees for the consumer. Traditional installment loans generally require a loan application, budget review, and a credit check, and are underwritten by the lender to ensure that the monthly payment is affordable in the borrower’s budget. This helps ensure that the consumer can pay off the loan in a responsible and timely manner. TIL lenders never ask the borrower for a postdated check or access to the borrower’s bank account.",
	},
	{
		question: "Aren’t there “cheaper” alternatives to these traditional installment loans (bad credit loans)?",
		answer:
			"No. Because of the “high touch” relationship required in traditional installment loans, there are simply no other options that provide the same service and disciplined, responsible loan repayment terms. Only government subsidies would allow for lower interest rates on this type of loan, and often the difference in cost to the consumer is negligible, while the burden to taxpayers is extreme.",
	},
	{
		question: "Why can’t banks provide small-dollar loans?",
		answer:
			"Traditional installment loans require one-on-one service—a process of consideration that ensures that the loan fits the borrower’s budget. Banks rarely provide small-dollar consumer loans because of the significant overhead costs associated with servicing and underwriting installment loans and the relatively small amount of interest dollars earned to cover operating expenses.Recently, the FDIC attempted to encourage banks to explore the possibility of providing small-dollar installment loans through the FDIC’s Small-Dollar Loan Pilot Program. The results from the two-year study, which ended in June of 2010, demonstrated that it was not economically feasible for banks to provide and service these small-dollar installment loans without the support of taxpayer subsidies to cover losses. Even with taxpayer money being used to help cover defaulted loans, the banks’ operational costs could not be met by the relatively small amount of interest dollars earned.The FDIC pilot program was not a financial success. Traditional installment lenders have offered these small-dollar loans effectively and successfully for a century.",
	},
	{
		question: "Aren’t credit cards a better option for consumers?",
		answer:
			"While many credit cards certainly have an important role to play, and some carry interest rates lower than most small-dollar installment loans, credit cards are not the best fit for some consumers, as evidenced by the widespread problem of credit card debt. Their minimum-payment terms and the ability to instantly access additional credit can easily trap consumers.Paying minimum monthly payments has become the habit of a large majority of credit card borrowers, greatly increasing the cost of this form of credit. Traditional installment loans are more financially responsible and affordable because of their disciplined repayment schedules.Recent changes in the law require credit card companies to state how long it will take to pay off a balance with minimum payments and the total amount of interest that the consumer will pay. Since credit card terms lack the discipline of the fully amortized repayment schedule required with traditional installment loans, a loan amount that would take one year to repay using an installment loan could take 10 years or more on a lower-rate credit card, at a much greater cost to the consumer.",
	},
	{
		question: "Shouldn’t people have savings to use instead of borrowing?",
		answer:
			"Borrowing and saving are both useful and financially beneficial. Borrowing allows people to pay for large expenses that would deplete or wipe out their savings if used for that purpose. For example, most Americans could not purchase a car or furniture, pay for college, or even make certain car repairs if they had to pay for it out of savings. Responsible borrowing allows people to meet their needs and goals, as well as emergencies, in a measured way without seriously disrupting their ability to manage their household finances.",
	},
	{
		question: "Aren’t these loans sometimes given to people who can least afford them?",
		answer:
			"No. Traditional installment loans are made only to people who can afford to repay them. The lender carefully examines the consumer’s ability to repay the loan without undue strain on the consumer’s monthly budget. Traditional installment lenders require borrowers to submit a detailed credit application. The lender then underwrites the loan according to established credit standards. The lender reviews the applicant’s credit history and requires verification of income and residency. If the borrower’s current obligations are excessive, or if the borrower has insufficient income, the lender will decline the application. There is no benefit to either the consumer or the loan company to make a loan that a borrower cannot repay.",
	},
];

export default faq;
