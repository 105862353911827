const faq = [
	{
		question: "¿Qué necesito para solicitar un préstamo personal o un préstamo sobre el título de un automóvil?",
		answer:
			"Nuestros prestamistas de Discount-Loans.com necesitarán su información personal (nombre, teléfono, correo electrónico y código postal) y la información de su vehículo (marca, modelo, año y millaje estimado) y requerirán que tenga una identificación válida emitida por el gobierno, un derecho de retención. título y comprobante de ingresos.",
	},
	{
		question: "¿Necesito un trabajo para obtener la aprobación de un préstamo? ¿Qué sucede si estoy discapacitado o estoy jubilado?",
		answer:
			"No, no necesita ser empleado para obtener un préstamopersonal o unprestamode título de automóvil. Sin embargo, necesita una fuente de ingresos regulares. Se aceptan otras formas de ingresos, como discapacidad, seguridad social, manutención de los hijos, pensión alimenticia, desempleo y trabajo por cuenta propia, siempre que pueda proporcionar pruebas.",
	},
	{
		question: "¿Puedo obtener un préstamo de título de carro sin una cuenta bancaria?",
		answer:
			"Sí, puede calificar para un préstamo personal o un préstamo sobre el título del automóvil sin tener una cuenta bancaria. Nuestros prestamistas solo requerirán una cuenta bancaria si elige recibir su dinero a través de ACH. Aparte de eso, no debería tener problemas para calificar para un préstamo personal o un préstamo sobre el título del automóvil sin una cuenta bancaria.",
	},
	{
		question: "¿Puedo obtener un préstamo personal o un préstamo sobre el título del automóvil después de la bancarrota?",
		answer:
			"Sí, aún puede calificar para un préstamo personal o un préstamo sobre el título del vehiculodespués de declararse en quiebra. A diferencia de las opciones de préstamos tradicionales, los préstamos de título de carro no están limitados por su estado de bancarrota ya que los préstamos de título son préstamos basados en garantías que dependen del valor de su vehículo para garantizar un préstamo. En esencia, el valor de su automóvil es su solvencia. Nuestros prestamistas estarán pendientes para procesar su prestamode título de carro como cualquier otro solicitante.",
	},
	{
		question: "¿El título del auto debe estar a mi nombre? ¿Qué pasa si hay tiene dos nombres?",
		answer:
			"Sí, el título del vehículo debe estar a nombre de la persona que solicita el préstamo. Si su título tiene su nombre junto con otra persona, deberá analizarlo con su prestamista para determinar los procedimientos necesarios para obtener un préstamo de título de carro en su estado. Por lo general, los títulos con dos nombres se aceptan siempre que ambos nombres estén en la solicitud de préstamo de título de lvehiculo.",
	},
	{
		question: "¿Qué sucede si mi vehículo no está registrado en el estado en el que resido?",
		answer:
			"No hay problema. En la mayoría de los casos, nuestros prestamistas de Discount-Loans.com aún pueden procesar su solicitud incluso si su vehículoestá registrado en un estado en el que no reside. Sin embargo, hay instrucciones especiales que deben seguirse caso por caso. Su prestamista puede guiarlo a través de ellos.",
	},
];

export default faq;
