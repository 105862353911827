import { UPDATE_PIXELS } from "../actions/pixels";

export default (state = [null, null, null, null], { type, payload }) => {
	if (type === UPDATE_PIXELS) {
		if (payload) return payload;
		return [null, null, null, null];
	} else {
		return state;
	}
};
