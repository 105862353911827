import { BLACKBOOK } from "../actions/blackbook";

export default (state = null, { type, payload }) => {
	switch (type) {
		case BLACKBOOK:
			return payload;
		case "CLEAR_BLACKBOOK":
			return null;
		default:
			return state;
	}
};
