import React, { Component } from "react";

export default class extends Component {
	render() {
		return (
			<div className="accordion">
				<div className="accordion__panel">
					<div className="accordion__heading" id={"heading-" + this.props.id}>
						<a
							data-toggle="collapse"
							href={"#collapse-" + this.props.id}
							className="accordion__link"
							aria-expanded={this.props.active}
							aria-controls="collapse-1">
							<h4>{this.props.title}</h4>
							<span className="accordion__toggle"></span>
						</a>
					</div>
					<div
						id={"collapse-" + this.props.id}
						className={"collapse " + (this.props.active ? "show" : null)}
						data-parent="#accordion"
						role="tabpanel"
						aria-labelledby={"heading-" + this.props.id}>
						<div className="accordion__body">{this.props.children}</div>
					</div>
				</div>
			</div>
		);
	}
}
