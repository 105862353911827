import React from "react";
import LiveChat from "react-livechat";

function LiveChatComponent() {
	if (process.env.REACT_APP_TYPE === "title") {
		return <LiveChat license={"7176101"} />;
	}

	return <div />;
}

export default LiveChatComponent;
