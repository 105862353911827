const faq = [
	{
		question: "¿Qué tan rápido es el proceso de solicitud?",
		answer:
			"El proceso de solicitud suele tardar entre 10 y 15 minutos en completarse. Si puede proporcionar toda la documentación correcta al prestamista, se puede tomar una decisión el mismo día de la solicitud.",
	},
	{
		question:
			"¿Puedo solicitar un préstamo de Discount-Loans.com si vivo fuera de un mercado seleccionado (San Antonio MSA, Laredo MSA, Corpus Christi MSA, Rio Grande Valley MSA?",
		answer:
			"No en este momento solamente ofrecemos el servicio en línea en ciertos mercados. Sin embargo, estamos expandiendo nuestra red de préstamos préstamistasrápidamente. Nuestro mapa de cobertura se actualiza con frecuencia.",
	},
	{
		question: "¿Puedo cambiar el monto que estoy pidiendo prestado?",
		answer:
			"Sí, puede pedir prestado un monto hasta el préstamo personal o el monto del préstamo sobre el título del vehículopara el cual ha sido aprobado. Puede cambiar el monto que está pidiendo prestado desde el momento en que se le notifica su aprobación hasta que se le emite el préstamo.",
	},
	{
		question: "¿Cuánto serán mis pagos?",
		answer:
			"Los montos de los pagos se basan en el tamaño del préstamo, la tasa de interés, las tarifas y el plazo del préstamo. Los prestamistas de Discount-Loans.com calculan un monto de pago que creen que puede pagar en función de sus ingresos y deudas / gastos. También tiene la capacidad de cambiar el monto y / o plazo del préstamo, dentro de ciertos parámetros, antes de que se desembolse el préstamo si desea tener un pago más bajo. Los pagos se realizan en cuotas fijas, lo que significa que no cambian durante la vigencia del préstamo (excepto el último pago, que puede ser mayor o menor dependiendo de si realizó sus pagos a tiempo).",
	},
	{
		question: "¿Cómo sé que los documentos que he enviado se están procesando?",
		answer:
			"Puede llamar o visitar la tienda del prestamista para presentar la documentación requerida (prueba de identidad, ingresos y residencia). También puede enviar sus documentos usted mismo a través de nuestra aplicación en línea, y recibirá un aviso de confirmación por teléfono o correoelectrónico. En cualquier caso, uno de los agentes del prestamista le notificará sobre el estado de aprobación de su préstamo poco después de la presentación de su solicitud.",
	},
	{
		question: "¿Cuál es el estado de mi solicitud de préstamo personal o de préstamo de título del automóvil?",
		answer:
			"Puede estar seguro de que los agentes con experiencia y conocimiento de nuestro prestamista están procesando su solicitud lo más rápido posible. Si tiene consultas específicas, puede visitar una de las muchas ubicaciones de nuestros prestamistas o hablar con un agente por teléfono. Los agentes estarán encantados de ayudarlo.",
	},
	{
		question: "¿Cómo obtengo mi préstamo personal o los fondos del préstamo sobre el título del automóvil?",
		answer:
			"Puede obtener los ingresos de su préstamo, ya sea en forma de un cheque físico (en una de las tiendas minoristas de nuestros prestamistas) o mediante un cheque electrónico que se le puede enviar por correo electrónico. Todo lo que necesita es la capacidad de imprimir ese cheque electrónico en papel normal y cobrarlo en cualquier banco o centro de cambio de cheques.",
	},
	{
		question: "¿Los prestamistas inspeccionan los vehículos antes de aprobar un préstamo de título?",
		answer:
			"Realmente no. Los prestamistas del préstamo sobre el título del automóvil basarán la evaluación de su vehículo en su buena fe de que el automóvil está funcionando y que su condición está representada con precisión por las imágenes que envía a su prestamista. Sabemos que esto es muy inusual para cualquier prestamista de títulos de automóviles, pero nuestros prestamistas de referencia creen que todo lo que sucede se da.",
	},
	{
		question: "¿Sus prestamistas ofrecen préstamos de título de vehículo para personas con mal crédito?",
		answer:
			"Sí, nuestros prestamistas de Discount-Loans.com de préstamos personales y de prestamos de título de automóvil tienen solicitantes con mal crédito o sin crédito. Entienden que las personas cometen errores y creen que los puntajes FICO no deberían evitar que las personas reciban ayuda cuando más la necesitan.",
	},
	{
		question: "¿Cuándo recibiré mi dinero?",
		answer:
			"Nuestros prestamistas de Discount-Loans.com de préstamos personales y de prestamos de título de vehículoutilizan una variedad de métodos para obtener su dinero rápidamente. Dependiendo de cómo elija recibir su dinero, puede tomar desde unos minutos hasta algunos días hábiles. Si decide aceptar un cheque electrónico, recibirá su dinero en minutos. Si selecciona un depósito electrónico a través de ACH, recibirá su dinero dentro de dos o tres días hábiles.",
	},
	{
		question: "¿Qué pasa si ya tengo un préstamo de título de vehículo con otra compañía?",
		answer:
			"Esoestá perfectamente bien con nuestros prestamistas de de Discount-Loans.com de préstamos personales y prestamos de título de automóvil. Podemos conectarlo con prestamistas que pueden refinanciar su préstamo existente y ahorrarle dinero. Refinanciar un préstamopersonal o prestamode título de vehículo significa encontrar un nuevo prestamista que pagará la cantidad que le debe a un prestamista anterior. El saldo del antiguo préstamopersonal o préstamo sobre el título del automóvil se transferirá a un nuevo préstamo sobre el título del automóvil con menor interés.",
	},
	{
		question: "¿Cómo funciona el proceso de préstamo de título de vehículo en una tienda fisica?",
		answer:
			"Los prestamistas de Discount-Loans.com, como Discount Car Title Loan, simplifican el proceso de préstamo de título de carros y solo le llevará unos minutos obtener el efectivo que necesita. Esto es lo que debe hacer si prefiere obtener su préstamo en una tienda. ",
	},
	{
		question: "¿Cómo funciona el proceso de préstamo personal amortizado ?",
		answer:
			"Los prestamistas de de Discount-Loans.comnuestra red, como DiscountFinance& Personal Loan Company, simplifican el proceso de obtener un préstamopersonal amortizado (préstamo a plazos tradicional), y solo toma unos minutos obtener el efectivo que necesita. Esto es lo que debe hacer si prefiere obtener su préstamo en una tienda. 1. Complete una solicitud en línea o en una oficina local de Discount Finance & Personal Loan Company 2. Discount Finance & Personal Loan Company determinará el monto de una transacción en función de sus necesidades, su historial crediticio y lo que puede pagar de manera realista. 3. Una vez aprobado, recibirá el efectivo que necesita ese mismo día.",
	},
];

export default faq;
