// @flow

import React from "react";
import styled from "styled-components";

type Props = {
	title?: boolean,
};

const Separator = styled("div")`
	height: 8px;
	width: 120px;
	margin: 12px auto;
	background-color: #02a4d3;
`;

export default function Component(props: Props): React$Node {
	if (props.title) {
		return <Separator />;
	} else {
		return <div className="separator" />;
	}
}
