import { UPDATE_USER } from "../actions/updateUser";

export default (state = null, { type, payload }) => {
	switch (type) {
		case UPDATE_USER:
			return payload;
		default:
			return state;
	}
};
