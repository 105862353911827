import priceRanges from "../data/priceRanges";
import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			years: [],
			makes: [],
			models: [],
			trims: [],
			styles: [],
			mileage: 0,
			carTitle: true,
			creditScore: null,
			buildCredit: true,
			loanSize: null,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.blackbook) {
			this.setState({
				years: this.props.blackbook.year_list,
				makes: this.props.blackbook.year_list[0].make_list,
				models: this.props.blackbook.year_list[0].make_list[0].model_list,
				trims: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list,
				styles: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list[0].style_list,
				uvc: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list[0].style_list[0].uvc,
			});
		}
	}

	componentDidUpdate = prevProps => {
		if (prevProps !== this.props && this.props.blackbook) {
			this.setState({
				years: this.props.blackbook.year_list,
				makes: this.props.blackbook.year_list[0].make_list,
				models: this.props.blackbook.year_list[0].make_list[0].model_list,
				trims: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list,
				styles: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list[0].style_list,
				uvc: this.props.blackbook.year_list[0].make_list[0].model_list[0].series_list[0].style_list[0].uvc,
			});
		}
	};
	_changeYear = e => {
		this.setState(
			{
				year: e.target.value,
				make: "",
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeMake = e => {
		this.setState(
			{
				make: e.target.value,
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeModel = e => {
		this.setState(
			{
				model: e.target.value,
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeTrim = e => {
		this.setState(
			{
				trim: e.target.value,
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	// _changeStyle = (e) => {
	// 	this.setState({
	// 		style: e.target.value,
	// 	}, () => this._reloadVehicles())
	// }
	_changeMileage = e => {
		this.setState({
			mileage: JSON.parse(e.target.value),
		});
	};
	_reloadVehicles = async () => {
		await this.setState({
			makes: this.state.year ? this.state.years.find(x => x.name === this.state.year).make_list : this.state.years[0].make_list,
		});
		await this.setState({
			models: this.state.make ? this.state.makes.find(x => x.name === this.state.make).model_list : this.state.makes[0].model_list,
		});
		await this.setState({
			trims: this.state.model ? this.state.models.find(x => x.name === this.state.model).series_list : this.state.models[0].series_list,
		});
		await this.setState({
			styles: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim).style_list : this.state.trims[0].style_list,
		});
		await this.setState({
			uvc: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim).style_list[0].uvc : this.state.styles[0].uvc,
		});
	};
	_prevPage = () => {
		let page = this.state.page;
		$("#formSection" + page).animate(
			{
				left: "100%",
				opacity: "0",
			},
			"slow"
		);

		if (page === 5 && !this.state.carTitle) {
			page = 3;
		}
		$("#formSection" + (page - 1)).animate(
			{
				left: "0",
				right: "0",
				opacity: "1",
			},
			"slow"
		);

		this.setState({
			page: page - 1,
		});
	};
	_nextPage = () => {
		const page = this.state.page;
		if (page === 3 && this.state.uvc && this.state.mileage > 0) {
			const url =
				"https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" +
				this.state.uvc +
				"?mileage=" +
				this.state.mileage +
				"&customerid=website";
			return axios
				.get(url, {
					headers: {
						Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
					},
				})
				.then(res => {
					return this.setState(
						{
							vehiclePrice: res.data.used_vehicles.used_vehicle_list[0].adjusted_tradein_avg,
						},
						() => {
							return this._precheck();
						}
					);
				});
		}
		if (!this.state.carTitle) {
			this._precheck();
		}

		if (page < 3) {
			$("#formSection" + page).animate(
				{
					left: "-100%",
					opacity: "0",
				},
				"slow"
			);

			$("#formSection" + (page + 1))
				.animate(
					{
						left: "0",
						right: "0",
						opacity: "1",
					},
					"slow"
				)
				.promise();

			return this.setState({
				page: page + 1,
			});
		} else {
			return null;
		}
	};
	_precheck = () => {
		const range = priceRanges.find(obj => {
			return this.state.vehiclePrice >= obj.min && this.state.vehiclePrice <= obj.max;
		});
		let loan;
		if (this.state.creditScore === "0" || !this.state.creditScore) {
			loan = btoa(120);
		} else if (this.state.creditScore === "1") {
			loan = btoa(150);
		} else if (this.state.creditScore === "2") {
			loan = btoa(500);
		} else if (this.state.creditScore === "3") {
			loan = btoa(1000);
		}
		const price = this.state.vehiclePrice ? btoa(this.state.vehiclePrice * range.value) : 0;
		const loanSize = btoa(this.state.loanSize);
		const uvc = this.state.mileage > 0 ? this.state.uvc : "0";
		const mileage = this.state.mileage;

		if (this.state.carTitle) {
			this.props.history.push({
				pathname: "/start",
				search: "?p=" + price + "&l=" + loan + "&s=" + loanSize + "&u=" + uvc + "&m=" + mileage,
			});
		} else {
			this.props.history.push({
				pathname: "/start",
				search: "?l=" + loan + "&s=" + loanSize + "&u=" + uvc + "&m=" + mileage,
			});
		}
	};

	render() {
		return (
			<div className="container py-5" id="quickstart">
				<div className="row">
					<div className="col-12 py-md-5 text-center">
						<h1>{this.props.messages.quickstart.textN01}</h1>
						<h6>{this.props.messages.quickstart.textN02}</h6>
						<br />
						<div id="formSections" className="container py-2">
							<div id="formSection0" className="formSection form-group">
								<span>{this.props.messages.quickstart.textN03}</span>
								<div className="row justify-content-center">
									<input
										type="number"
										className="col-6 cool-form-group px-3 mt-2"
										id="loanSize"
										onChange={e =>
											this.setState({
												loanSize: JSON.parse(e.target.value),
											})
										}
									/>
								</div>
							</div>
							<div id="formSection1" className="formSection form-group">
								<span>{this.props.messages.quickstart.textN04}</span>
								<div className="row justify-content-center">
									<select
										className="col-6 cool-form-group px-3 mt-2"
										onChange={e =>
											this.setState({
												creditScore: e.target.value,
											})
										}>
										<option value="">{this.props.messages.quickstart.textN05}</option>
										<option value="0">{this.props.messages.quickstart.textN06}</option>
										<option value="1">{this.props.messages.quickstart.textN07}</option>
										<option value="2">{this.props.messages.quickstart.textN08}</option>
										<option value="3">{this.props.messages.quickstart.textN09}</option>
									</select>
								</div>
							</div>
							<div id="formSection2" className="formSection form-group">
								<div className="row justify-content-center">
									<span className="col-12 mb-3">{this.props.messages.quickstart.textN10}</span>
									<div className="col-3">
										<input
											className="form-check-input"
											type="radio"
											id="cleanCarTrue"
											checked={this.state.carTitle}
											onChange={e =>
												this.setState({
													carTitle: true,
												})
											}
										/>
										<label className="form-check-label" htmlFor="cleanCarTrue">
											{this.props.messages.quickstart.textN11}
										</label>
									</div>
									<div className="col-3 cool-check">
										<input
											className="form-check-input"
											type="radio"
											id="cleanCarFalse"
											checked={!this.state.carTitle}
											onChange={e =>
												this.setState({
													carTitle: false,
												})
											}
										/>
										<label className="form-check-label" htmlFor="cleanCarFalse">
											{this.props.messages.quickstart.textN12}
										</label>
									</div>
								</div>
							</div>

							<div id="formSection3" className="row align-items-center" style={{ margin: "0 auto" }}>
								<span className="col-4 text-left">{this.props.messages.quickstart.textN13}</span>
								<select type="text" className="col-8 align-self-center cool-form-group px-3 mt-2" onChange={this._changeYear}>
									<option value="">{this.props.messages.quickstart.textN14}</option>
									{this.state.years.map(item => {
										return <option key={item.name}>{item.name}</option>;
									})}
								</select>
								<span className="col-4 text-left">{this.props.messages.quickstart.textN15}</span>
								<select type="text" className="col-8 cool-form-group px-3 mt-2" disabled={!this.state.year} onChange={this._changeMake}>
									<option value="">{this.props.messages.quickstart.textN16}</option>
									{this.state.makes.map(item => {
										return <option key={item.name}>{item.name}</option>;
									})}
								</select>
								<span className="col-4 text-left">{this.props.messages.quickstart.textN17}</span>
								<select
									type="text"
									className="col-8 align-self-center cool-form-group px-3 mt-2"
									disabled={!this.state.make}
									onChange={this._changeModel}>
									<option value="">{this.props.messages.quickstart.textN18}</option>
									{this.state.models.map(item => {
										return <option key={item.name}>{item.name}</option>;
									})}
								</select>
								<span className="col-4 text-left">{this.props.messages.quickstart.textN19}</span>
								<select
									type="text"
									className="col-8 align-self-center cool-form-group px-3 mt-2"
									disabled={!this.state.model}
									onChange={this._changeTrim}>
									<option value="">{this.props.messages.quickstart.textN20}</option>
									{this.state.trims.map(item => {
										return <option key={item.name}>{item.name}</option>;
									})}
								</select>
								{/* <span className="col-4 text-left">Style</span>
								<select type="text" className="col-8 align-self-center cool-form-group px-3 mt-2" disabled={!this.state.trim} onChange={this._changeStyle}>
									<option value="">Select One</option>
									{
										this.state.styles.map(item => {
											return (<option key={item.name} >{item.name}</option>)
										})
									}
								</select> */}
								<span className="col-4 text-left">{this.props.messages.quickstart.textN21}</span>
								<input type="number" className="col-8 cool-form-group px-3 mt-2" required onChange={this._changeMileage} />
							</div>
						</div>
						{this.state.page > 2 ? (
							<div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						) : null}
						<div className="row justify-content-center">
							{this.state.page > 0 ? (
								<button onClick={this._prevPage} type="button" id="nextButton" className="btn btn--md btn--color btn--button">
									{this.props.messages.quickstart.textN22}
								</button>
							) : null}
							&nbsp;&nbsp;
							<button onClick={this._nextPage} type="button" id="nextButton" className="btn btn--md btn--color btn--button">
								{this.props.messages.quickstart.textN23}
							</button>
						</div>
						<p className="chiquita">{this.props.messages.quickstart.textN24}</p> <br /> <br />
					</div>
				</div>
			</div>
		);
	}
}
