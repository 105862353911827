import React, { Component } from "react";
import Page from "../components/page";
import Reference from "../components/reference";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import Swal from "sweetalert2";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ref1: {
				FirstName: "",
				LastName: "",
				Relation: "",
				Phone: "",
				Address: "",
				City: "",
				State: "",
				Zip: "",
			},
			ref2: {
				FirstName: "",
				LastName: "",
				Relation: "",
				Phone: "",
				Address: "",
				City: "",
				State: "",
				Zip: "",
			},
			ref3: {
				FirstName: "",
				LastName: "",
				Relation: "",
				Phone: "",
				Address: "",
				City: "",
				State: "",
				Zip: "",
			},
			ref4: {
				FirstName: "",
				LastName: "",
				Relation: "",
				Phone: "",
				Address: "",
				City: "",
				State: "",
				Zip: "",
			},
			ref5: {
				FirstName: "",
				LastName: "",
				Relation: "",
				Phone: "",
				Address: "",
				City: "",
				State: "",
				Zip: "",
			},
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		if (!this.props.uid) {
			return this.props.history.push("/login");
		} else {
			return this._loadData();
		}
	}

	_loadData = () => {
		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.get()
			.then(res => {
				if (res.data().references) {
					return this.setState({
						ref1: res.data().references[0],
						ref2: res.data().references[1],
						ref3: res.data().references[2],
						ref4: res.data().references[3],
						ref5: res.data().references[4] ? res.data().references[4] : {},
					});
				}
				return null;
			});
	};

	submitForm = event => {
		event.preventDefault();
		const references = [this.state.ref1, this.state.ref2, this.state.ref3, this.state.ref4];
		if (this.props.loan.type === "Personal Loan") {
			references.push(this.state.ref5);
		}
		if (
			(this.state.ref1.Phone.length > 0 && this.state.ref1.Phone.length !== 12) ||
			(this.state.ref2.Phone.length > 0 && this.state.ref2.Phone.length !== 12) ||
			(this.state.ref3.Phone.length > 0 && this.state.ref3.Phone.length !== 12) ||
			(this.state.ref4.Phone.length > 0 && this.state.ref4.Phone.length !== 12)
		) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.references.textN01, "warning");
		} else {
			return firebase
				.firestore()
				.doc("applications/" + this.props.uid)
				.update({
					timestamp: moment().toDate(),
					references,
				})
				.then(() => {
					return Swal.fire(this.props.messages.commText.salvado, this.props.messages.references.textN02, "success");
				});
		}
	};

	_referenceChanged = e => {
		this.setState({
			[e.id]: {
				...this.state[e.id],
				[e.name]: e.value,
			},
		});
	};

	render() {
		return (
			<Page {...this.props}>
				<div className="container pt-1 pb-5">
					<div className="container text-center">
						<br />
						<br />
						<h2>{this.props.messages.references.textN03}</h2>
						<p>
							{this.props.messages.references.textN04} <br />
							{this.props.messages.references.textN05}
						</p>
						<br />
						<br />
						<br />
					</div>
					<form className="container" onSubmit={this.submitForm} id="application" autoComplete="off">
						<Reference
							{...this.props}
							title={this.props.messages.references.textN07}
							reference={this.state.ref1}
							onChanged={this._referenceChanged}
							id={"ref1"}
						/>
						<Reference
							{...this.props}
							title={this.props.messages.references.textN08}
							reference={this.state.ref2}
							onChanged={this._referenceChanged}
							id={"ref2"}
						/>
						<Reference
							{...this.props}
							title={this.props.messages.references.textN09}
							reference={this.state.ref3}
							onChanged={this._referenceChanged}
							id={"ref3"}
						/>
						<Reference
							{...this.props}
							title={this.props.messages.references.textN10}
							reference={this.state.ref4}
							onChanged={this._referenceChanged}
							id={"ref4"}
						/>
						{this.props.loan.type === "Personal Loan" ? (
							<Reference
								{...this.props}
								title={this.props.messages.references.textN10a}
								reference={this.state.ref5}
								onChanged={this._referenceChanged}
								id={"ref5"}
							/>
						) : null}
						<div className="row justify-content-center">
							<button type="submit" className="btn btn--sm btn--color btn--button col-12 col-md-5 mt-5">
								{this.props.messages.references.textN06}
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}
}
