import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";
import { Link } from "react-router-dom";
import StoresMap from "./storesMap";

import locationDataTitle from "../data/locationDataTitle";
import locationDataFinance from "../data/locationDataFinance";

class locations extends Component {
	// export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			spanish: null,
			storesArrTitle: Object.values(locationDataTitle),
			storesArrFinance: Object.values(locationDataFinance),
		};
	}

	buttonComp(data) {
		// import imageLocation from (data.image);
		return (
			<div className="row my-7 cool-form-group" key={data.linkURLstore}>
				<div className="col-4 col-md-4 my-2 my-md-10">
					{process.env.REACT_APP_TYPE === "title" ? (
						<div>
							<img
								src={require("../assets/title/" + data.image + ".jpg")}
								style={{
									width: "135px",
									display: "block",
									margin: "auto",
								}}
								alt=""
							/>
						</div>
					) : null}
					{process.env.REACT_APP_TYPE === "finance" ? (
						<div>
							<img
								src={require("../assets/finance/" + data.image + ".jpg")}
								style={{
									width: "135px",
									display: "block",
									margin: "auto",
								}}
								alt=""
							/>
						</div>
					) : null}
				</div>
				<div className="col-6 col-md-6 my-2 my-md-0">
					<Link to={data.linkURLstore}>
						<h3 className="section-title" style={{ fontSize: 20, textAlign: "left" }}>
							{data.bName}
						</h3>
						<span
							style={{
								fontSize: 12,
								textAlign: "left",
								color: "#000",
							}}>
							{data.badderssT}
							<br />
							{data.badderssB}
							<br />
						</span>
						<span
							style={{
								fontSize: 10,
								textAlign: "left",
								color: "#000",
							}}>
							{this.props.messages.locations.textN08 + " "}
							{data.storeHoursT}
							<br />
							{this.props.messages.locations.textN09 + " "}
							{data.storeHoursB}
						</span>
						<br />
					</Link>
				</div>
				<div className="col-1 col-md-1 my-3 my-md-0">
					<br />
					<Link to={data.linkURLstore}>
						<i className="fas fas fa-store-alt benefits__item-icon" style={{ color: "#fe0022" }} />
					</Link>
					<br />
					<br />
					<a href={"tel:" + data.bPhone}>
						<i className="fas fa-phone benefits__item-icon" style={{ color: "#fe0022" }} />
					</a>
				</div>
			</div>
		);
	}
	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />

					<section className="section-wrap margen_top">
						<div className="container">
							{process.env.REACT_APP_TYPE === "title" ? (
								<div className="title-row text-center">
									<h3 className="section-title">{this.props.messages.locations.textN06}</h3>
									<br />
									{this.props.messages.locations.textN07}
									<br />
								</div>
							) : null}
							{process.env.REACT_APP_TYPE === "finance" ? (
								<div className="title-row text-center">
									<h3 className="section-title">{this.props.messages.locations.textN06}</h3>
									<br />
									{this.props.messages.locations.textN07F}
									<br />
								</div>
							) : null}

							<div className="section-wrap margen_top">
								{process.env.REACT_APP_TYPE === "title" ? <div>{this.state.storesArrTitle.map(data => this.buttonComp(data))}</div> : null}
								{process.env.REACT_APP_TYPE === "finance" ? <div>{this.state.storesArrFinance.map(data => this.buttonComp(data))}</div> : null}
							</div>
						</div>
					</section>
					{process.env.REACT_APP_TYPE === "title" ? <StoresMap /> : null}
					{process.env.REACT_APP_TYPE === "finance" ? (
						<iframe
							src="https://www.google.com/maps/d/embed?mid=1sGYxo8ERMVHxuh8p8FFKim-5Bf0"
							title="mapFinance"
							width="100%"
							height="350"
							frameBorder="0"
						/>
					) : null}
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">{/*<a href="#top"><i className="ui-arrow-up"/></a>*/}</div>
				</main>
			</div>
		);
	}
}
export default locations;
