import React from "react";
import iconUS from "../../assets/iconUS.png";
import iconMX from "../../assets/iconMX.png";
import { changeLang } from "../../redux/actions/lang";
import { useDispatch, useSelector } from "react-redux";
import { Row, Button } from "antd";
import i18next from "i18next";

function LanguageSelection() {
	const dispatch = useDispatch();
	const lang = useSelector(state => state.lang);

	const toggleLang = () => {
		dispatch(changeLang(lang === "en" ? "es" : "en"));
		return i18next.changeLanguage(lang === "en" ? "es" : "en");
	};

	return (
		<Row justify={"end"}>
			<Button size={"small"} style={{ display: "flex", alignItems: "center" }} onClick={toggleLang}>
				<img style={{ height: 16, marginRight: 6 }} src={lang === "en" ? iconUS : iconMX} alt="Language" />
				{lang === "en" ? "ENGLISH" : "ESPAÑOL"}
			</Button>
		</Row>
	);
}

export default LanguageSelection;
