import React from "react";
import { useSelector } from "react-redux";

function CallButton(props) {
	const store = useSelector(state => state.store);
	return (
		<a href={store?.phone ? `tel:${store.phone}` : "tel:+1 210 281 4435"}>
			<button type="button" className="nav__icon-toggle accountWrapper">
				<span className="userIcon">
					<i className="fas fa-phone-alt" />
				</span>
				<span className="callWord">{props.messages.menu.textN01}</span>
			</button>
		</a>
	);
}

export default CallButton;
