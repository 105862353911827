import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import { Link as SmoothLink } from "react-scroll";

import firebase from "firebase/app";
import "firebase/auth";
import logo from "../assets/logo@2x.png";
import logoTitle from "../assets/logoTitle@2x.png";
import logoPersonal from "../assets/logoPersonal@2x.png";
import $ from "jquery";

import CallButton from "./menu/CallButton";
import LanguageSelection from "./menu/LanguageSelection";
import CallToAction from "./menu/CallToAction";
import LiveChat from "./menu/LiveChat";

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			active: false,
			spanish: null,
		};
	}

	logout = () => {
		firebase.auth().signOut();
	};
	toggleMenu = () => {
		$("#submenu").toggleClass("active");
	};
	toggleMainMenu = () => {
		$("#btn6").addClass("hidden");
		if (this.state.active) {
			$("#navbar-collapse").addClass("show");
			$("#btn1").removeClass("hidden");
			$("#btn2").removeClass("hidden");
			$("#btn3").removeClass("hidden");
			$("#btn4").removeClass("hidden");
			$("#btn5").removeClass("hidden");
			this.setState({
				active: false,
			});
		} else {
			$("#navbar-collapse").removeClass("show");
			this.setState({
				active: true,
			});
		}
	};
	toggleAccountMenu = () => {
		$("#btn6").removeClass("hidden");
		if (this.state.active) {
			$("#navbar-collapse").removeClass("show");
			$("#submenu").removeClass("active");
			$("#btn1").removeClass("hidden");
			$("#btn2").removeClass("hidden");
			$("#btn3").removeClass("hidden");
			$("#btn4").removeClass("hidden");
			$("#btn5").removeClass("hidden");
			this.setState({
				active: false,
			});
		} else {
			$("#navbar-collapse").addClass("show");
			$("#submenu").addClass("active");
			$("#btn1").addClass("hidden");
			$("#btn2").addClass("hidden");
			$("#btn3").addClass("hidden");
			$("#btn4").addClass("hidden");
			$("#btn5").addClass("hidden");
			this.setState({
				active: true,
			});
		}
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.match.params.locale === "es") {
			this.setState({ spanish: "es" });
		}
	}

	render() {
		const type = process.env.REACT_APP_TYPE;
		const appLogo = type === "title" ? logoTitle : type === "finance" ? logoPersonal : logo;
		return (
			<header className="nav sticky-col" id="top">
				<LiveChat />
				<LanguageSelection />

				<div className="innerMenu">
					<div className="container-fluid container-semi-fluid nav__container">
						<div className="flex-parent">
							<div className="nav__header">
								<HashLink to={"/"} className="logo-container flex-child">
									<img className="logo" style={{ height: 60 }} src={appLogo} alt="logo" />
								</HashLink>

								{/* Full Size Menu */}
								{this.props.loan.status > 2 ? (
									<CallButton {...this.props} />
								) : (
									<HashLink to={"/login?signup=true"}>
										<button type="button" className="nav__icon-toggle accountWrapper">
											<span className="userIcon">
												<i className="fas fa-check-circle" />
											</span>
											<span className="accountWord">{this.props.messages.menu.textN02}</span>
										</button>
									</HashLink>
								)}
								{type === "all" ? (
									this.props.uid ? (
										<span>
											<button type="button" className="nav__icon-toggle accountWrapper" onClick={this.toggleAccountMenu}>
												<span className="userIcon">
													<i className="fas fa-user-circle" />
												</span>
												<span className="accountWord">{this.props.messages.menu.textN03}</span>
											</button>
										</span>
									) : (
										<HashLink to={"/login"}>
											<button type="button" className="nav__icon-toggle accountWrapper">
												<span className="userIcon">
													<i className="fas fa-lock" />
												</span>
												<span className="accountWord">{this.props.messages.menu.textN04}</span>
											</button>
										</HashLink>
									)
								) : null}
								{type !== "all" ? (
									<span>
										<HashLink to={"/home/locations"}>
											<button type="button" className="nav__icon-toggle accountWrapper" style={{ color: "#414141" }}>
												<span className="userIcon">
													<i className="fas fa-store-alt" />
												</span>
												<span className="accountWord">{this.props.messages.menu.textN25}</span>
											</button>
										</HashLink>
									</span>
								) : null}
								<button type="button" className="nav__icon-toggle accountWrapper" onClick={this.toggleMainMenu}>
									<span className="userIcon">
										<i className="fas fa-bars" />
									</span>
									<span className="accountWord">{this.props.messages.menu.textN05}</span>
								</button>
							</div>

							{/* Mobile Menu*/}
							{type === "all" ? (
								<nav id="navbar-collapse" className="nav__wrap collapse navbar-collapse uppercase">
									<ul className="nav__menu">
										<li id="btn1">
											{this.props.home ? (
												<SmoothLink smooth={true} className="link" to="top">
													{this.props.messages.menu.textN06}
												</SmoothLink>
											) : (
												<HashLink to={"/"}>{this.props.messages.menu.textN07}</HashLink>
											)}
										</li>
										<li id="btn2">
											{this.props.home ? (
												<SmoothLink smooth={true} className="link" to="how">
													{this.props.messages.menu.textN08}
												</SmoothLink>
											) : (
												<HashLink to={"/#how"}>{this.props.messages.menu.textN09}</HashLink>
											)}
										</li>
										{/* <li>
										{ this.props.home ? <SmoothLink smooth={true} className="link" to="about">About</SmoothLink> : <HashLink to={"#about">About</HashLink> }
										</li> */}
										<li id="btn3">
											<HashLink to={"/faq"}>{this.props.messages.menu.textN10}</HashLink>
										</li>
										<li id="btn4">
											<HashLink to={type === "all" ? "/about-us" : "/home/aboutus"}>{this.props.messages.menu.textN11}</HashLink>
										</li>
										<li id="btn5">
											<HashLink to={"/contact"}>{this.props.messages.menu.textN12}</HashLink>
										</li>
										{this.props.uid ? (
											<li className="nav__dropdown" id="btn6" onClick={this.toggleMenu}>
												<HashLink to={"#"}>{this.props.messages.menu.textN13}</HashLink>
												<i className="ui-arrow-down nav__dropdown-trigger" id="submenu" />
												<ul className="nav__dropdown-menu">
													<li>
														<HashLink to={"/status"}>{this.props.messages.menu.textN14}</HashLink>
													</li>
													<li>
														<HashLink to={"/profile"}>{this.props.messages.menu.textN15}</HashLink>
													</li>
													<li>
														<HashLink to={"/application"}>{this.props.messages.menu.textN16}</HashLink>
													</li>
													{this.props.loan.type === "Title Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/discounts"}>{this.props.messages.menu.textN17}</HashLink>
														</li>
													) : null}
													{this.props.loan.type === "Personal Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/credit"}>{this.props.messages.menu.textN18}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/offer"}>{this.props.messages.menu.textN19}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/documents"}>{this.props.messages.menu.textN20}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 3 ? (
														<li>
															<HashLink to={"/references"}>{this.props.messages.menu.textN21}</HashLink>
														</li>
													) : null}
													<li onClick={this.logout}>
														<HashLink to={"#"}>{this.props.messages.menu.textN22}</HashLink>
													</li>
												</ul>
											</li>
										) : (
											<li id="btn6">
												<HashLink to={"/login"}>{this.props.messages.menu.textN04}</HashLink>
											</li>
										)}
									</ul>
								</nav>
							) : null}
							{type === "title" ? (
								<nav id="navbar-collapse" className="nav__wrap collapse navbar-collapse uppercase">
									<ul className="nav__menu">
										<li id="btn1">
											{this.props.home ? (
												<SmoothLink smooth={true} className="link" to="top">
													{this.props.messages.menu.textN06}
												</SmoothLink>
											) : (
												<HashLink to={"/"}>{this.props.messages.menu.textN07}</HashLink>
											)}
										</li>
										<li id="btn2">
											<HashLink to={"/home/howitworks"}>{this.props.messages.menu.textN09}</HashLink>
										</li>
										<li id="btn3">
											<HashLink to={"/home/faq"}>{this.props.messages.menu.textN10}</HashLink>
										</li>
										<li id="btn4">
											<HashLink to={"/home/aboutus"}>{this.props.messages.menu.textN11}</HashLink>
										</li>
										<li id="btn5">
											<HashLink to={"/contact"}>{this.props.messages.menu.textN12}</HashLink>
										</li>
										<li id="btn6">
											<HashLink to={"/login?signup=true"}>{this.props.messages.menu.textN24}</HashLink>
										</li>
										{/*<li id="btn6">*/}
										{/*	<HashLink to={this.toggleMenu}></HashLink>*/}
										{/*</li>*/}
										{this.props.uid ? (
											<li className="nav__dropdown" id="btn7" onClick={this.toggleMenu}>
												<HashLink to={"#"}>{this.props.messages.menu.textN13}</HashLink>
												<i className="ui-arrow-down nav__dropdown-trigger" id="submenu" />
												<ul className="nav__dropdown-menu">
													<li>
														<HashLink to={"/status"}>{this.props.messages.menu.textN14}</HashLink>
													</li>
													<li>
														<HashLink to={"/profile"}>{this.props.messages.menu.textN15}</HashLink>
													</li>
													<li>
														<HashLink to={"/application"}>{this.props.messages.menu.textN16}</HashLink>
													</li>
													{this.props.loan.type === "Title Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/discounts"}>{this.props.messages.menu.textN17}</HashLink>
														</li>
													) : null}
													{this.props.loan.type === "Personal Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/credit"}>{this.props.messages.menu.textN18}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/offer"}>{this.props.messages.menu.textN19}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/documents"}>{this.props.messages.menu.textN20}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 3 ? (
														<li>
															<HashLink to={"/references"}>{this.props.messages.menu.textN21}</HashLink>
														</li>
													) : null}
													<li onClick={this.logout}>
														<HashLink to={"#"}>{this.props.messages.menu.textN22}</HashLink>
													</li>
												</ul>
											</li>
										) : (
											<li id="btn6">
												<HashLink to={"/login"}>{this.props.messages.menu.textN04}</HashLink>
											</li>
										)}
									</ul>
								</nav>
							) : null}
							{type === "finance" ? (
								<nav id="navbar-collapse" className="nav__wrap collapse navbar-collapse uppercase">
									<ul className="nav__menu">
										<li id="btn1">
											{this.props.home ? (
												<SmoothLink smooth={true} className="link" to="top">
													{this.props.messages.menu.textN06}
												</SmoothLink>
											) : (
												<HashLink to={"/"}>{this.props.messages.menu.textN07}</HashLink>
											)}
										</li>
										<li id="btn2">
											<HashLink to={"/home/howitworks"}>{this.props.messages.menu.textN09}</HashLink>
										</li>
										<li id="btn3">
											<HashLink to={"/home/faq"}>{this.props.messages.menu.textN10}</HashLink>
										</li>
										<li id="btn4">
											<HashLink to={"/home/aboutus"}>{this.props.messages.menu.textN11}</HashLink>
										</li>
										<li id="btn5">
											<HashLink to={"/contact"}>{this.props.messages.menu.textN12}</HashLink>
										</li>
										<li id="btn6">
											<HashLink to={"/login?signup=true"}>{this.props.messages.menu.textN24}</HashLink>
										</li>
										{this.props.uid ? (
											<li className="nav__dropdown" id="btn7" onClick={this.toggleMenu}>
												<HashLink to={"#"}>{this.props.messages.menu.textN13}</HashLink>
												<i className="ui-arrow-down nav__dropdown-trigger" id="submenu" />
												<ul className="nav__dropdown-menu">
													<li>
														<HashLink to={"/status"}>{this.props.messages.menu.textN14}</HashLink>
													</li>
													<li>
														<HashLink to={"/profile"}>{this.props.messages.menu.textN15}</HashLink>
													</li>
													<li>
														<HashLink to={"/application"}>{this.props.messages.menu.textN16}</HashLink>
													</li>
													{this.props.loan.type === "Title Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/discounts"}>{this.props.messages.menu.textN17}</HashLink>
														</li>
													) : null}
													{this.props.loan.type === "Personal Loan" && this.props.loan.status > 0 ? (
														<li>
															<HashLink to={"/credit"}>{this.props.messages.menu.textN18}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/offer"}>{this.props.messages.menu.textN19}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 1 ? (
														<li>
															<HashLink to={"/documents"}>{this.props.messages.menu.textN20}</HashLink>
														</li>
													) : null}
													{this.props.loan.status > 3 ? (
														<li>
															<HashLink to={"/references"}>{this.props.messages.menu.textN21}</HashLink>
														</li>
													) : null}
													<li onClick={this.logout}>
														<HashLink to={"#"}>{this.props.messages.menu.textN22}</HashLink>
													</li>
												</ul>
											</li>
										) : (
											<li id="btn6">
												<HashLink to={"/login"}>{this.props.messages.menu.textN04}</HashLink>
											</li>
										)}
									</ul>
								</nav>
							) : null}

							<CallToAction />
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Menu;
