import React, { Component } from "react";
import MaskedInput from "react-text-mask";

export default class extends Component {
	_handleTextChange = e => {
		const { onChanged } = this.props;
		onChanged({
			id: this.props.id,
			name: e.target.name,
			value: e.target.value,
		});
	};

	render() {
		const { reference, loan } = this.props;
		return (
			<div>
				<h3>{this.props.title}</h3>
				<div className="yseparator center" />
				<br />
				<div className={"row"}>
					<div className={"cool-form-group col-lg-3"}>
						<input
							defaultValue={reference.FirstName}
							name={"FirstName"}
							onChange={this._handleTextChange}
							placeholder={this.props.messages.references.textN11}
						/>
					</div>
					<div className={"cool-form-group col-lg-3"}>
						<input
							defaultValue={reference.LastName}
							name={"LastName"}
							onChange={this._handleTextChange}
							placeholder={this.props.messages.references.textN12}
						/>
					</div>
					<div className={"cool-form-group col-lg-3"}>
						<input
							defaultValue={reference.Relation}
							name={"Relation"}
							onChange={this._handleTextChange}
							placeholder={this.props.messages.references.textN13}
						/>
					</div>
					<div className={"cool-form-group col-lg-3"}>
						<MaskedInput
							mask={[/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
							guide={false}
							defaultValue={reference.Phone}
							name={"Phone"}
							onChange={this._handleTextChange}
							placeholder={this.props.messages.references.textN14}
						/>
					</div>
				</div>
				{loan.type === "Title Loan" ? (
					<div className={"row"}>
						<div className={"cool-form-group col-lg-3"}>
							<input
								defaultValue={reference.Address}
								name={"Address"}
								onChange={this._handleTextChange}
								placeholder={this.props.messages.references.textN15}
							/>
						</div>
						<div className={"cool-form-group col-lg-3"}>
							<input
								defaultValue={reference.City}
								name={"City"}
								onChange={this._handleTextChange}
								placeholder={this.props.messages.references.textN16}
							/>
						</div>
						<div className={"cool-form-group col-lg-3"}>
							<input
								defaultValue={reference.State}
								name={"State"}
								onChange={this._handleTextChange}
								placeholder={this.props.messages.references.textN17}
							/>
						</div>
						<div className={"cool-form-group col-lg-3"}>
							<MaskedInput
								mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
								defaultValue={reference.Zip}
								name={"Zip"}
								onChange={this._handleTextChange}
								placeholder={this.props.messages.references.textN18}
							/>
						</div>
					</div>
				) : null}
				<br />
				<br />
			</div>
		);
	}
}
