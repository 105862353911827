const faq = [
	{
		question: "What do I need to apply for a personal loan or a car title loan?",
		answer:
			"Discount Car Title Loan Online (dtloans.com)  will need your personal information (name, phone, email, and zip code) and your vehicle information (make, model, year, and estimated mileage) and will require you to have a valid government-issued ID, a lien-free title, and proof of income.",
	},
	{
		question: "Do I need a job to get approved for a loan? What if I am on disability or retired?",
		answer:
			"No, you don’t need to be employed to get a personal or a car title loan. However, you do need a source of regular income. Other forms of income such as disability, social security, child support, alimony, unemployment, and self-employment are accepted as long as you can provide proof.",
	},
	{
		question: "Can I get a title loan without a bank account?",
		answer:
			"Yes, you can qualify for a personal loan or car title loan without having a bank account. Discount Car Title Loan Online (dtloans.com) will only require a bank account if you choose to receive your money via ACH.",
	},
	{
		question: "Can I get a personal loan or car title loan after bankruptcy?",
		answer:
			"Yes, you may still qualify for a personal loan or car title loan after filing for bankruptcy. Unlike traditional loan options, title loans are not limited by your bankruptcy status as title loans are collateral based loans that rely on the value of your vehicle to secure a loan. In essence, the value of your car is your creditworthiness. Discount Car Title Loan Online (dtloans.com) lenders will be more than happy to process your title advance just like any other applicant.",
	},
	{
		question: "Does the car title have to be in my name? What if there are two names?",
		answer:
			"Yes, the vehicle title does have to be in the name of the person who is requesting the loan. If your title has your name along with someone else, you will need to discuss this with us to determine the procedures required to obtain a title loan in your state. Typically, titles with two names are accepted as long as both names are on the title loan application.",
	},
	{
		question: "What if my vehicle is not registered in the state in which I reside?",
		answer:
			"No problem. In most cases, Discount Car Title Loan Online (dtloans.com) is still able to process your application even if your vehicle is registered in a state in which don’t reside. However, there are special instructions that need to be followed on a case by case basis. A Discount Car Title Loan Online (dtloans.com) representative can walk you through them.",
	},
];

export default faq;
