import React, { Component } from "react";
import { Layout, Typography } from "antd";
import { connect } from "react-redux";
import Menu from "../components/menu";
import Footer from "../components/footer";

class Terms extends Component {
	render() {
		return (
			<>
				<Menu {...this.props} />
				<Layout.Content style={{ maxWidth: 1000, margin: "0 auto", padding: "60px 0" }}>
					<Typography.Title align={"center"}>Terms of Service</Typography.Title>
					<Typography.Paragraph>
						Welcome to Discount Loans <br />
						These terms and conditions outline the rules and regulations for the use of Discount Loans's Website.
						<br />
						<br />
						By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Discount Loans's website if
						you do not accept all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions,
						Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this
						website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
						"Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer,
						acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate
						manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in
						respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of . Any use of the
						above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and
						therefore as referring to same.Cookies We employ the use of cookies. By using Discount Loans's website you consent to the use of cookies
						in accordance with Discount Loans's privacy policy.Most of the modern day interactive web sites use cookies to enable us to retrieve
						user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for
						those people visiting. Some of our affiliate / advertising partners may also use cookies.License Unless otherwise stated, Discount Loans
						and/or it's licensors own the intellectual property rights for all material on Discount Loans. All intellectual property rights are
						reserved. You may view and/or print pages from https://discount-loans.com for your own personal use subject to restrictions set in these
						terms and conditions. You must not:
						<br />
						<br />
						Republish material from https://discount-loans.com Sell, rent or sub-license material from https://discount-loans.com Reproduce,
						duplicate or copy material from https://discount-loans.com
						<br />
						<br />
						Redistribute content from Discount Loans (unless content is specifically made for redistribution). Hyperlinking to our Content
						<br />
						<br />
						The following organizations may link to our Web site without prior written approval:
						<br />
						<br />
						Government agencies; Search engines; News organizations; Online directory distributors when they list us in the directory may link to
						our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and Systemwide Accredited Businesses
						except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web
						site.
						<br />
						<br />
						These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any
						way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and
						(c) fits within the context of the linking party's site.
						<br />
						<br />
						We may consider and approve in our sole discretion other link requests from the following types of organizations:
						<br />
						<br />
						commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and
						Consumers Union; dot.com community sites; associations or other groups representing charities, including charity giving sites, online
						directory distributors; internet portals; accounting, law and consulting firms whose primary clients are businesses; and educational
						institutions and trade associations.
						<br />
						<br />
						We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our
						accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as
						work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the
						benefit to us from the visibility associated with the hyperlink outweighs the absence of Discount Loans; and (d) where the link is in
						the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product
						furthering the mission of the organization.
						<br />
						<br />
						These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any
						way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and it products or services; and
						(c) fits within the context of the linking party's site.
						<br />
						<br />
						If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by
						sending an e-mail to help@discount-loans.com. Please include your name, your organization name, contact information (such as a phone
						number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a
						list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.
						<br />
						<br />
						Approved organizations may hyperlink to our Web site as follows:
						<br />
						<br />
						By use of our corporate name; or By use of the uniform resource locator (Web address) being linked to; or By use of any other
						description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's
						site.
						<br />
						<br />
						No use of Discount Loans's logo or other artwork will be allowed for linking absent a trademark license agreement. Iframes Without prior
						approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way
						the visual presentation or appearance of our Web site. Reservation of Rights We reserve the right at any time and in its sole discretion
						to request that you remove all links or any particular link to our Web site. You agree to immediately remove all links to our Web site
						upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to
						link to our Web site, you agree to be bound to and abide by these linking terms and conditions. Removal of links from our website If you
						find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider
						requests to remove links but will have no obligation to do so or to respond directly to you. Whilst we endeavour to ensure that the
						information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website
						remains available or that the material on the website is kept up to date. Content Liability We shall have no responsibility or liability
						for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your
						Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be
						interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation
						of, any third party rights. Disclaimer To the maximum extent permitted by applicable law, we exclude all representations, warranties and
						conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect
						of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:
						<br />
						<br />
						limit or exclude our or your liability for death or personal injury resulting from negligence; limit or exclude our or your liability
						for fraud or fraudulent misrepresentation; limit any of our or your liabilities in any way that is not permitted under applicable law;
						or exclude any of our or your liabilities that may not be excluded under applicable law.
						<br />
						<br />
						The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding
						paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer,
						including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty. To the extent that the
						website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any
						nature.
					</Typography.Paragraph>
				</Layout.Content>
				<Footer {...this.props} />
			</>
		);
	}
}

function mapStateToProps() {
	return {};
}

export default connect(mapStateToProps)(Terms);
