import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";

export default class extends Component {
	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						<div className="container">
							<br />
							<div className="title-row text-center">
								<h3 className="section-title">{this.props.messages.contact.textN01}</h3>
								<br />
							</div>
							<br />
							<div className="row my-3 text-center">
								<div className="col-md-12">
									<p>
										<b>{this.props.messages.contact.textN02}</b>
										<br />
										<b>{this.props.messages.contact.textN03}</b>
									</p>
									<br />
									<p>
										{this.props.messages.contact.textN04} <br /> {this.props.messages.contact.textN05}
									</p>
									<a href="mailto:info@discount-loans.com">
										<h4>
											<u>info@discount-loans.com</u>
										</h4>
									</a>
								</div>
							</div>
							<br />
							<br />
							<br />
						</div>
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
