import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						<div className="container">
							<h3 className="section-title">{this.props.messages.carrers.textN01}</h3>
							<br />
							<p></p>
							<h3 className="section-title">{this.props.messages.carrers.textN02}</h3>
							<br />
							<br />
							<br />
							<br />
						</div>
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
