import React, { Component } from "react";
import Footer from "../components/footer";
import Copy from "../components/copy";
import "../assets/landing/css/style.css";
import "../assets/landing/css/fontello.css";
import "../styles/landing.css";

// import { Link } from "react-router-dom";
import firebase from "firebase";
import Swal from "sweetalert2";
import moment from "moment";
import * as groups from "../data/pixels";

export default class Welcome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordValidator: [false, false, false, false],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const url_string = window.location.href;
		const url = new URL(url_string);
		const g = url.searchParams.get("g");
		if (g) {
			this.props.onUpdatePixels(groups["g" + g]);
		} else {
			this.props.onUpdatePixels();
		}
	}

	_createUser = e => {
		const parent = this;
		e.preventDefault();
		if (this.state.passwordValidator.filter(e => e === false).length > 0) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.welcome.textN48, "warning");
		} else if (this.state.military) {
			return Swal.fire(this.props.messages.welcome.textN49, this.props.messages.welcome.textN50, "warning");
		} else {
			return firebase
				.auth()
				.createUserWithEmailAndPassword(this.state.email, this.state.password)
				.then(async user => {
					await firebase.auth().currentUser.updateProfile({
						displayName: parent.state.firstName + " " + parent.state.lastName,
					});
					await firebase
						.firestore()
						.doc("admin/" + user.user.uid)
						.set({
							timestamp: moment().toDate(),
						});
					await firebase
						.firestore()
						.doc("applications/" + user.user.uid)
						.set({
							status: 0,
							store: 0,
							active: 1,
							// phoneAge: parent.state.phoneAge,
							source: "Website",
							timestamp: moment().toDate(),
						});
					await firebase
						.firestore()
						.doc("users/" + user.user.uid)
						.set({
							uid: user.user.uid,
							firstName: parent.state.firstName,
							lastName: parent.state.lastName,
							email: parent.state.email,
							phone: parent.state.phone,
							// phoneAge: parent.state.phoneAge,
							referral: parent.props.referral,
							timestamp: moment().toDate(),
						});
					window.gtag("event", "conversion", {
						send_to: "AW-761506876/0dVwCJbQvawBELzYjusC",
					});
					return parent.props.history.push({
						pathname: "/application",
						search: "?c=true",
					});
				})
				.catch(error => {
					return Swal.fire(parent.props.messages.commText.oops, error.message, "warning");
				});
		}
	};

	_validatePassword = e => {
		const password = e.target.value;
		const number = /[0-9]/.test(password);
		const lowercase = /[a-z]/.test(password);
		const uppercase = /[A-Z]/.test(password);

		this.setState({
			passwordValidator: [password.length > 7, number, lowercase, uppercase],
			password: password,
		});
	};

	render() {
		return (
			<div id={"landing"}>
				<div className="hero">
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="hero-captions">
									<div className={"text-center"}>
										<img src={require("../assets/landing/images/logo.png")} alt="" />
									</div>
									<br />
									<h1 className="hero-title">
										{this.props.messages.welcome.textN07} <br /> {this.props.messages.welcome.textN08}
									</h1>
									<div className="linea" />
									<br />
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="feature-b feature-left mb40">
											<div className="feature-icon">
												<i className="fa fa-check-circle" />
											</div>
											<div className="feature-content">
												<h3 className="hero-sub ">{this.props.messages.welcome.textN09}</h3>
												<p className="hero-text">
													{this.props.messages.welcome.textN10}
													{this.props.messages.welcome.textN11}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-5 offset-md-1 col-md-5  col-sm-12 col-12">
								<div className="request-form">
									<form onSubmit={this._createUser}>
										<div className="cool-form-group">
											<input
												type="text"
												placeholder="First Name"
												className="form-control input-md"
												required={true}
												onChange={e =>
													this.setState({
														firstName: e.target.value,
													})
												}
											/>
										</div>
										<div className="cool-form-group">
											<input
												type="text"
												placeholder="Last Name"
												className="form-control input-md"
												required={true}
												onChange={e =>
													this.setState({
														lastName: e.target.value,
													})
												}
											/>
										</div>
										<div className="cool-form-group">
											<input
												type="text"
												placeholder="Cell Phone"
												className="form-control input-md"
												required={true}
												onChange={e =>
													this.setState({
														phone: e.target.value,
													})
												}
											/>
										</div>
										{/*<div className="cool-form-group">
											<select required defaultValue="" onChange={e => this.setState({phoneAge: JSON.parse(e.target.value)})}>
												<option value="" disabled>How long have you had this number?</option>
												<option value="0">Less than 2 years</option>
												<option value="2">More than 2 years</option>
											</select>
										</div>*/}
										<div className="cool-form-group">
											<select
												required
												defaultValue=""
												onChange={e =>
													this.setState({
														military: JSON.parse(e.target.value),
													})
												}>
												<option value="" disabled>
													{this.props.messages.welcome.textN12}
												</option>
												<option value="true">{this.props.messages.welcome.textN13}</option>
												<option value="false">{this.props.messages.welcome.textN14}</option>
											</select>
										</div>
										<div className="cool-form-group">
											<input
												type="email"
												placeholder="E-mail Address (will be your login ID)"
												className="form-control input-md"
												required={true}
												onChange={e =>
													this.setState({
														email: e.target.value,
													})
												}
											/>
										</div>
										<div className="cool-form-group">
											<input
												type="password"
												placeholder="Password"
												className="form-control input-md"
												required={true}
												onChange={this._validatePassword}
											/>
										</div>
										<div className="form-group">
											<button type="submit" className="btn" style={{ width: "100%" }}>
												{this.props.messages.welcome.textN15}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-space40">
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
								<iframe
									src="https://player.vimeo.com/video/351797149?title=0&byline=0&portrait=0"
									frameBorder="0"
									style={{ width: "100%", height: "100%" }}
									allow="autoplay; fullscreen"
									allowFullScreen
									title={"Discount Loans"}
								/>
								{/*<br/>*/}
							</div>
							<div className="col-md-offset-1 col-md-5">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="mb20 mt-5 mt-md-1">
											<h1>
												{this.props.messages.welcome.textN16}
												<br />
												{this.props.messages.welcome.textN17} <br />
												{this.props.messages.welcome.textN18}
											</h1>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="feature-b feature-left mb20">
											<div className="feature-icon-beto">
												<i className="fa fa-check" />
											</div>
											<div className="feature-content">
												<h4>{this.props.messages.welcome.textN19}</h4>
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="feature-b feature-left mb20">
											<div className="feature-icon-beto">
												<i className="fa fa-check" />
											</div>
											<div className="feature-content">
												<h4>{this.props.messages.welcome.textN20}</h4>
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="feature-b feature-left mb20">
											<div className="feature-icon-beto">
												<i className="fa fa-check" />
											</div>
											<div className="feature-content">
												<h4>{this.props.messages.welcome.textN21}</h4>
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="feature-b feature-left mb20">
											<div className="feature-icon-beto">
												<i className="fa fa-check" />
											</div>
											<div className="feature-content">
												<h4>{this.props.messages.welcome.textN22}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section-space100 bg-white">
					<div className="container">
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="section-title text-center mb60">
									<h1>{this.props.messages.welcome.textN23}</h1>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
								<div className="testimonial-simple text-center">
									<p className="testimonial-text">{this.props.messages.welcome.textN24}</p>
									<div>
										<div className="client-rating">
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
										</div>
										<span className="testimonial-name-inverse">{this.props.messages.welcome.textN25}</span>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
								<div className="testimonial-simple text-center">
									<p className="testimonial-text">{this.props.messages.welcome.textN26}</p>
									<div>
										<div className="client-rating">
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
										</div>
										<span className="testimonial-name-inverse">{this.props.messages.welcome.textN27}</span>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
								<div className="testimonial-simple text-center">
									<p className="testimonial-text">{this.props.messages.welcome.textN28}</p>
									<div>
										<div className="client-rating">
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
											<i className="fa fa-star rate-done" />
										</div>
										<span className="testimonial-name-inverse">{this.props.messages.welcome.textN29}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section-space80 imagen">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" />
							<div className="offset-xl-1 col-xl-7 offset-lg-1 col-lg-7 col-md-12 col-sm-12 col-12">
								<h1>{this.props.messages.welcome.textN30}</h1>
								<div className="linea_azul" />
								<div className="st-accordion">
									<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id="headingOne">
												<h4 className="panel-title">
													<a
														role="button"
														data-toggle="collapse"
														data-parent="#accordion"
														href="#collapseOne"
														aria-expanded="true"
														aria-controls="collapseOne">
														{this.props.messages.welcome.textN31}
													</a>
												</h4>
											</div>
											<div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
												<div className="panel-body">{this.props.messages.welcome.textN32}</div>
											</div>
										</div>
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id="headingTwo">
												<h4 className="panel-title">
													<a
														className="collapsed"
														role="button"
														data-toggle="collapse"
														data-parent="#accordion"
														href="#collapseTwo"
														aria-expanded="false"
														aria-controls="collapseTwo">
														{this.props.messages.welcome.textN33}
													</a>
												</h4>
											</div>
											<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
												<div className="panel-body">{this.props.messages.welcome.textN34}</div>
											</div>
										</div>
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id="headingThree">
												<h4 className="panel-title">
													<a
														className="collapsed"
														role="button"
														data-toggle="collapse"
														data-parent="#accordion"
														href="#collapseThree"
														aria-expanded="false"
														aria-controls="collapseThree">
														{this.props.messages.welcome.textN35}
													</a>
												</h4>
											</div>
											<div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
												<div className="panel-body">{this.props.messages.welcome.textN36}</div>
											</div>
										</div>
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id="headingFour">
												<h4 className="panel-title">
													<a
														className="collapsed"
														role="button"
														data-toggle="collapse"
														data-parent="#accordion"
														href="#collapseFour"
														aria-expanded="false"
														aria-controls="collapseFour">
														{this.props.messages.welcome.textN37}
													</a>
												</h4>
											</div>
											<div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
												<div className="panel-body">{this.props.messages.welcome.textN38}</div>
											</div>
										</div>
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id="headingFive">
												<h4 className="panel-title">
													<a
														className="collapsed"
														role="button"
														data-toggle="collapse"
														data-parent="#accordion"
														href="#collapseFive"
														aria-expanded="false"
														aria-controls="collapseFive">
														{this.props.messages.welcome.textN39}
													</a>
												</h4>
											</div>
											<div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
												<div className="panel-body">{this.props.messages.welcome.textN40}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section-space80 bg-white">
					<div className="container">
						<div className="row">
							<div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 offset-md-2 col-md-8  col-sm-12 col-12">
								<div className="mb60 text-left section-title">
									<h1>{this.props.messages.welcome.textN41}</h1>
									<div className="linea_azul" />
								</div>
							</div>
						</div>
						<div className={"text-center"}>
							<div className="mb40">
								<a href="tel:+12107418021">
									<i className="icon-phone-call icon-2x icon-default" />
								</a>
							</div>
							<h2 className="capital-title">{this.props.messages.welcome.textN42}</h2>
							<p>
								{this.props.messages.welcome.textN43} <br />
								{this.props.messages.welcome.textN44}
							</p>
							<br />
							<h1 className="text-big">
								<a href="tel:+12107418021">{this.props.messages.welcome.textN45}</a>
								<br />
								<br />
								{this.props.messages.welcome.textN46}
								<br />
								{this.props.messages.welcome.textN47}
							</h1>
						</div>
					</div>
				</div>

				{/*Trackers*/}
				{this.props.pixels[0] ? <img alt={"uberAd"} src={"https://serve.uberads.com/convert/" + this.props.pixels[0]} width="1" height="1" /> : null}
				{/*Facebook Pixel Code*/}
				<img alt="pixel" height="1" width="1" src="https://www.facebook.com/tr?id=714208988991416&ev=PageView&noscript=1" />

				<Footer {...this.props} />
				<Copy {...this.props} />
			</div>
		);
	}
}
