import React, { Component } from "react";

export default class Step extends Component {
	render() {
		if (this.props.start) {
			return (
				<div className="container-fluid">
					<div className="row align-items-center steps text-center">
						<div className="container d-flex">
							<div className="col-12 stepTitleAlone">
								{this.props.title.toUpperCase()}
								<br />
								<span>{this.props.subtitle.toUpperCase()}</span>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (this.props.subtitle) {
			return (
				<div className="container-fluid">
					<div className="row align-items-center d-none d-md-flex steps">
						<div className="container d-flex">
							<div className="col-6 stepTitle">{this.props.title}</div>
							<div className="col-6 text-right stepSubtitle">{this.props.subtitle}</div>
						</div>
					</div>
					<div className="row align-items-center d-flex d-md-none steps">
						<div className="col-6 stepTitle">{this.props.title}</div>
						<div className="col-6 text-right stepSubtitle">{this.props.subtitle}</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="container-fluid">
					<div className="row align-items-center steps text-center">
						<div className="container d-flex">
							<div className="col-12 stepTitleAlone">{this.props.title.toUpperCase()}</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
