const faq = [
	{
		question: "How can I apply for a personal loan or a car title loan?",
		answer:
			"Applying for a personal loan or a car title loan through Discount Loans Online (discount-loans.com) is quick and easy. If you wish to apply in a store or over the phone, Discount Loans Online (discount-loans.com) referral lenders will be happy to guide you through the process. You can also use Discount Loans Online (discount-loans.com) online loan application, which is accessible from desktop, tablet or mobile.",
	},
	{
		question: "How can I apply to borrow?",
		answer:
			"Discount Loans Online (discount-loans.com)  lenders offer personal loans (installment loans) and car title loans that range from $125 to $10,000. The maximum amount you can borrow through Discount Loans Online (discount-loans.com) depends on several factors, including your income, existing debts and past payment history.",
	},
	{
		question: "How long will I have to repay my loan?",
		answer:
			"The term of the personal loan or a car title loan depends primarily on loan size, interest rate, fees and how much we think you can afford for a monthly payment. Discount Loans Online (discount-loans.com) lenders offer terms from 3 to 18 months with the smaller loans generally having shorter terms and the larger loans having longer terms.",
	},
	{
		question: "Who can I contact if I have questions about my loan?",
		answer: "For questions regarding your personal loan or a car title loan, you can contact the lender directly or visit one of their locations near you.",
	},
	{
		question: "What is the difference between a personal loan and a car title loan?",
		answer:
			"A title loan is a type of short-term loan that uses the title of your vehicle as collateral. Discount Loans Online (discount-loans.com) recommends car title loans for consumers who need quick cash but don’t qualify for traditional means of financing. Unlike bank loans, qualifying for a title loan is simple and doesn’t require a credit check. Best of all, you can continue to use your car while the loan is outstanding. A personal loan, also known as an installment loan, is a popular loan option for consumers that want to establish a credit history. No collateral is required for personal loans and the loan terms may be longer than 6 months.",
	},
	{
		question: "What does it mean to have a lien-free (clean) car title?",
		answer:
			"A lien-free car title is a title without any liens. A lien is a legal claim on a property by a creditor. Having a lien-free car title means that you own your vehicle outright without the threat of repossession or seizure.",
	},
	{
		question: "Can I still drive my car with a car title loan or a personal loan backed by a car title?",
		answer: "Yes, you can definitely drive your car during the course of your title loan.",
	},
];

export default faq;
