import ImageTools from "../utils/ImageTools";
import React, { PureComponent } from "react";
import { Row, Col, Button, Divider } from "antd";
import { connect } from "react-redux";
import EXIF from "exif-js/exif";
import propTypes from "prop-types";
import firebase from "firebase";
import moment from "moment";
import async from "async";

type Props = {
	id: string,
	uid: string,
	needs: number,
	images: Array<string>,
	updated: Function,
};

type State = {
	loading: boolean,
};

class Document extends PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	_uploadImage = async e => {
		e.persist();
		await this.setState({ loading: true });
		const images = e.target.files;
		return async.eachOfSeries(
			images,
			async (image, index, callback) => {
				let url;
				if (image.type === "application/pdf") {
					const storageRef = firebase.storage().ref("users/" + this.props.uid);
					const uploadTask = await storageRef.child(moment().valueOf() + ".pdf").put(image, {
						contentType: "application/pdf",
						customMetadata: {
							name: image.name,
							timestamp: image.lastModified,
						},
					});
					url = await uploadTask.ref.getDownloadURL();
				} else {
					url = await this.getImageData(image);
				}

				await firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.update({
						[this.props.id]: [...this.props.images, url],
					});
				return callback();
			},
			() => {
				this.setState({
					loading: false,
				});
			}
		);
	};

	getImageData = image => {
		const parent = this;
		return new Promise(resolve => {
			EXIF.getData(image, async function() {
				const myData = this;
				if (myData.exifdata["GPSLatitude"] && myData.exifdata["GPSLongitude"]) {
					// Latitude
					const latDegree = myData.exifdata["GPSLatitude"][0].numerator / myData.exifdata["GPSLatitude"][0].denominator;
					const latMinute = myData.exifdata["GPSLatitude"][1].numerator / myData.exifdata["GPSLatitude"][1].denominator;
					const latSecond = myData.exifdata["GPSLatitude"][2].numerator / myData.exifdata["GPSLatitude"][2].denominator;
					const latDirection = myData.exifdata["GPSLatitudeRef"];
					const latFinal = parent.ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);

					// Longitude
					const lonDegree = myData.exifdata["GPSLongitude"][0].numerator / myData.exifdata["GPSLongitude"][0].denominator;
					const lonMinute = myData.exifdata["GPSLongitude"][1].numerator / myData.exifdata["GPSLongitude"][1].denominator;
					const lonSecond = myData.exifdata["GPSLongitude"][2].numerator / myData.exifdata["GPSLongitude"][2].denominator;
					const lonDirection = myData.exifdata["GPSLongitudeRef"];
					const lonFinal = parent.ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);

					const url = await parent.resizeAndUpload(image, latFinal, lonFinal);
					resolve(url);
				} else {
					const url = await parent.resizeAndUpload(image, 0, 0);
					resolve(url);
				}
			});
		});
	};

	resizeAndUpload = (image, lat, long) => {
		const storageRef = firebase.storage().ref("users/" + this.props.uid);
		return new Promise(resolve => {
			ImageTools.resize(
				image,
				{
					width: 1000,
					height: 1000,
				},
				async blob => {
					const uploadTask = await storageRef.child(moment().valueOf() + ".jpg").put(blob, {
						contentType: "image/jpeg",
						customMetadata: {
							name: image.name,
							timestamp: image.lastModified,
							longitude: long,
							latitude: lat,
						},
					});
					const url = await uploadTask.ref.getDownloadURL();
					return resolve(url);
				}
			);
		});
	};

	_deleteImage = async index => {
		await firebase
			.storage()
			.refFromURL(this.props.images[index])
			.delete();
		const images = this.props.images.filter((_, i) => i !== index);
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				[this.props.id]: images,
			});
	};

	ConvertDMSToDD = (degrees, minutes, seconds, direction) => {
		let dd = degrees + minutes / 60 + seconds / 3600;
		if (direction === "S" || direction === "W") {
			dd = dd * -1;
		}
		return dd;
	};

	render() {
		return (
			<div style={{ marginTop: 60, marginBottom: 60 }}>
				<Row gutter={[20, 20]} justify={"center"} style={{ textAlign: "center" }}>
					<Col xs={24} lg={16}>
						<h5>{this.props.title.toUpperCase()}</h5>
						<p style={{ marginTop: -8 }}>{this.props.subtitle}</p>
					</Col>
					<Col xs={24} lg={16}>
						{this.props.images.map((downloadURL, i) => {
							return (
								<div
									key={i}
									style={{
										width: 120,
										height: 120,
										borderWidth: 3,
										borderColor: "#888",
										borderStyle: "solid",
										verticalAlign: "top",
										marginRight: "0.5em",
										marginBottom: "0.3em",
										display: "inline-block",
									}}>
									<div
										style={{
											height: "100%",
											overflow: "hidden",
											position: "relative",
										}}>
										<i
											className="fas fa-trash-alt"
											onClick={() => this._deleteImage(i)}
											style={{
												top: 0,
												right: 0,
												padding: 6,
												zIndex: 99,
												color: "#FFF",
												position: "absolute",
												backgroundColor: "#888",
												borderBottomLeftRadius: 10,
											}}
										/>
										{downloadURL.includes(".pdf") ? (
											<div>
												<i className={"fa fa-file"} style={{ marginTop: 50, fontSize: 20 }} />
											</div>
										) : (
											<img
												src={downloadURL}
												alt="ID"
												style={{
													display: "block",
													width: 120,
													maxWidth: 500,
													margin: "auto",
													position: "absolute",
													top: "-100%",
													right: "-100%",
													bottom: "-100%",
													left: "-100%",
												}}
											/>
										)}
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
				<Row gutter={[20, 20]} justify={"center"} style={{ textAlign: "center" }}>
					<Col xs={24} lg={5}>
						<Button
							block
							type={"primary"}
							loading={this.state.loading}
							onClick={() => this.input.click()}
							style={{
								backgroundColor: this.props.images.length >= this.props.needs ? "#009acd" : "#888",
							}}>
							{this.props.messages.commText.Upload} {this.props.needs ? this.props.images.length + "/" + this.props.needs : null}
						</Button>
					</Col>
				</Row>
				<input
					ref={e => (this.input = e)}
					style={{ display: "none" }}
					type={"file"}
					accept={this.props.accept ?? "image/*"}
					multiple
					onChange={this._uploadImage}
				/>
				<br />
				<Divider />
			</div>
		);
	}
}

Document.propTypes = {
	accept: propTypes.string,
};

const mapStateToProps = state => {
	return {
		uid: state.uid,
	};
};

export default connect(mapStateToProps)(Document);
