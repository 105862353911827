import React, { Component } from "react";
import Step from "../components/step";
import Page from "../components/page";
import firebase from "firebase/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import moment from "moment";
// var LineChart = require("react-chartjs").Line;alid

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			expires: "-",
			loanSize: null,
			loanType: null,
			loading: true,
			monthlyPaymentRequested: 0,
			amortized: [],
			principal: [],
			interests: [],
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentWillUnmount() {
		this.subscription();
	}

	subscription = firebase
		.firestore()
		.doc("applications/" + this.props.uid + "/data/sixMonths")
		.onSnapshot(async data => {
			if (data.data()) {
				const principals = [];
				const interestss = [];
				data.data().amortized.forEach(item => {
					principals.push(item.principal.toFixed(2));
					interestss.push(item.interests.toFixed(2));
				});
				const res = await firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.get();
				this.setState(
					{
						discount: data.data().discount,
						monthlyPaymentRequested: res.data().monthlyPaymentRequested,
						loanSize: data.data().loan,
						loanType: res.data().loanType,
						data: data.data(),
						amortized: data.data().amortized,
						principals: principals,
						interests: interestss,
						expires: moment(data.data().timestamp.seconds, "X")
							.add(1, "week")
							.format("l"),
						loading: false,
					},
					() => {
						this._getSums();
					}
				);
			} else {
				this.setState({
					loading: true,
				});
			}
		});

	_getSums = () => {
		let totalPayment = 0;
		let totalPrincipal = 0;
		let totalInterest = 0;

		this.state.amortized.forEach(item => {
			totalPayment = totalPayment + this.state.data.payment;
			totalPrincipal = totalPrincipal + item.principal;
			totalInterest = totalInterest + item.interests;
		});

		this.setState({
			totalPayment: totalPayment,
			totalPrincipal: totalPrincipal,
			totalInterest: totalInterest,
		});
	};

	modify = e => {
		e.preventDefault();
		return Swal.fire({
			title: "",
			html: `<h4><b>${this.props.messages.titleLoanOffer.textN01}</b></h4>`,
			input: "number",
			reverseButtons: true,
			showCancelButton: true,
			confirmButtonText: this.props.messages.commText.calculate,
			cancelButtonText: this.props.messages.commText.reset,
		}).then(async result => {
			if (result.value) {
				const payment = result.value ? JSON.parse(result.value) : null;
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/fourMonths")
					.delete();
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/fiveMonths")
					.delete();
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/sixMonths")
					.delete();
				return firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.update({
						timestamp: moment().toDate(),
						monthlyPaymentRequested: payment,
					});
			} else {
				const payment = 0;
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/fourMonths")
					.delete();
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/fiveMonths")
					.delete();
				await firebase
					.firestore()
					.doc("applications/" + this.props.uid + "/data/sixMonths")
					.delete();
				return firebase
					.firestore()
					.doc("applications/" + this.props.uid)
					.update({
						timestamp: moment().toDate(),
						monthlyPaymentRequested: payment,
					});
			}
		});
	};

	_resetPayment = async () => {
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fourMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fiveMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/sixMonths")
			.delete();
		firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				timestamp: moment().toDate(),
				monthlyPaymentRequested: 0,
			});
	};

	openChat = () => {};

	application = async () => {
		firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 2,
				lastUpdate: "User",
				timestamp: moment().toDate(),
			});
		this.props.onUpdateLoan({
			status: 2,
			type: this.props.loan.type,
			term: "sixMonths",
		});
		this.props.history.push("/application");
	};

	complete = async () => {
		window.gtag("event", "conversion", {
			send_to: "AW-974126037/1Cx7CLufq6gBENX3v9AD",
		});
		window.gtag("event", "conversion", {
			send_to: "AW-761506876/gQNiCOSJuqwBELzYjusC",
		});
		firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 3,
				lastUpdate: "User",
				term: "sixMonths",
				timestamp: moment().toDate(),
			});
		this.props.onUpdateLoan({
			status: 3,
			type: this.props.loan.type,
			term: "sixMonths",
		});
		this.props.history.push("/documents");
	};

	cleanNumber = (num, digits) => {
		if (num) {
			return (
				"$" +
				parseFloat(num).toLocaleString("en-US", {
					minimumFractionDigits: digits !== undefined ? digits : 2,
					maximumFractionDigits: digits !== undefined ? digits : 2,
				})
			);
		} else {
			return "$0";
		}
	};

	render() {
		return (
			<Page {...this.props}>
				{this.state.loading ? <div className="loading">Loading&#8230;</div> : null}
				{this.state.loanSize < 300 && this.state.loanSize !== null && !this.state.monthlyPaymentRequested ? (
					<div className="container py-3">
						<h1 className="title-row text-center uppercase">
							<b>{this.props.messages.titleLoanOffer.textN42}</b>
						</h1>
						<div className="offer my-5">
							<p>{this.props.messages.titleLoanOffer.textN02}</p>
						</div>
						<div className="row justify-content-center">
							<button onClick={this.application} className="col-10 col-md-5 btn btn--sm btn--primary btn--button">
								{this.props.messages.titleLoanOffer.textN03}
							</button>
						</div>
					</div>
				) : null}
				{this.state.loanSize < 300 && this.state.monthlyPaymentRequested ? (
					<div className="container py-3">
						<h1 className="title-row text-center uppercase">
							<b>{this.props.messages.titleLoanOffer.textN42}</b>
						</h1>
						<div className="offer my-5">
							<p>
								{this.props.messages.titleLoanOffer.textN04} <br />
								{this.props.messages.titleLoanOffer.textN05}
							</p>
						</div>
						<div className="row justify-content-center">
							<button onClick={this._resetPayment} className="btn btn--sm btn--secondary btn--button col-10 col-md-4 order-2 order-md-1 m-1">
								{this.props.messages.titleLoanOffer.textN06}
							</button>
							<button onClick={this.modify} className="btn btn--sm btn--primary btn--button col-10 col-md-4 order-1 order-md-2 m-1">
								{this.props.messages.titleLoanOffer.textN07}
							</button>
						</div>
					</div>
				) : null}
				{this.state.loanSize > 300 && this.state.loanSize !== null ? (
					<div>
						<Step
							title={this.props.messages.titleLoanOffer.textN43}
							subtitle={this.props.messages.titleLoanOffer.textN44 + " " + this.state.expires}
						/>
						<div className="container py-3">
							<h1 className="title-row text-center uppercase">
								<b>{this.props.messages.titleLoanOffer.textN08}</b>
							</h1>
							<div className="offer mt-5 mb-4">
								<div className="row">
									<div className="col-8">
										<h5>
											{this.props.messages.titleLoanOffer.textN09} <b>{this.props.messages.titleLoanOffer.textN10}</b>{" "}
											{this.props.messages.titleLoanOffer.textN11}
										</h5>
									</div>
									<div className="col-4 text-right">
										<h6>
											<b>{this.cleanNumber(this.state.loanSize, 0)}</b>
										</h6>
									</div>
									<div className="col-8 pt-3">
										<h5>
											{this.props.messages.titleLoanOffer.textN12} <b>{this.props.messages.titleLoanOffer.textN13}</b>{" "}
											{this.props.messages.titleLoanOffer.textN14}
										</h5>
									</div>
									<div className="col-4 pt-3 text-right">
										<h6>
											<b>{this.state.discount}%</b>
										</h6>
									</div>
								</div>
								<p
									className="mt-3 text-left"
									style={{
										fontSize: "12px",
										textAlign: "left",
									}}>
									{this.props.messages.titleLoanOffer.textN15}
									<br />
									<br />
									{this.props.messages.titleLoanOffer.textN16}
								</p>
							</div>
							{this.state.monthlyPaymentRequested ? (
								<p>
									{this.props.messages.titleLoanOffer.textN17} {this.cleanNumber(this.state.monthlyPaymentRequested, 0)}
									<br />
									<span className="link" onClick={this._resetPayment}>
										<b>{this.props.messages.titleLoanOffer.textN18}</b>
									</span>
								</p>
							) : null}
							<br />
							<div className="row justify-content-md-center">
								<div className="col-12 col-md-8">
									<table className="table table-bordered text-center">
										<thead>
											<tr
												style={{
													background: "#009ACD",
													color: "#FFFFFF",
												}}>
												<th scope="col">{this.props.messages.titleLoanOffer.textN19}</th>
												<th scope="col">{this.props.messages.titleLoanOffer.textN20}</th>
												<th scope="col">{this.props.messages.titleLoanOffer.textN21}</th>
												<th scope="col">{this.props.messages.titleLoanOffer.textN22}</th>
											</tr>
										</thead>
										<tbody>
											{this.state.amortized.map((item, index) => {
												return (
													<tr key={index}>
														<th>{index + 1}</th>
														<td>{this.cleanNumber(this.state.data.payment)}</td>
														<td>{this.cleanNumber(item.principal)}</td>
														<td>{this.cleanNumber(item.interests)}</td>
													</tr>
												);
											})}
										</tbody>
										<tfoot>
											<tr>
												<th>Total:</th>
												<th>{this.cleanNumber(this.state.totalPayment)}</th>
												<th>{this.cleanNumber(this.state.totalPrincipal)}</th>
												<th>{this.cleanNumber(this.state.totalInterest)}</th>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
							<br />
							<p>
								{this.props.messages.titleLoanOffer.textN23}
								<b>{this.cleanNumber(this.state.data.payment, 0)}</b> {this.props.messages.titleLoanOffer.textN24}
								<span
									className={"link"}
									onClick={() => {
										return Swal.fire({
											title: this.props.messages.titleLoanOffer.textN25,
											html:
												this.props.messages.titleLoanOffer.textN26 +
												this.props.messages.titleLoanOffer.textN27 +
												"<br/><br/>" +
												this.props.messages.titleLoanOffer.textN28 +
												this.props.messages.titleLoanOffer.textN29 +
												this.props.messages.titleLoanOffer.textN30 +
												"<br/><br/>" +
												this.props.messages.titleLoanOffer.textN31 +
												this.props.messages.titleLoanOffer.textN32,
										});
									}}>
									{" "}
									{this.props.messages.titleLoanOffer.textN33}
								</span>
							</p>
							<br />
							{/* <div className="text-center">
								{
									!this.state.loading ? <LineChart data={{
										labels: [1,2,3,4,5,6],
										datasets: [{
												label: "Principal",
												fillColor: "transparent",
												strokeColor: "#009ACD",
												data: this.state.principals,
											},
											{
												label: "Interest",
												fillColor: "transparent",
												strokeColor: "#0E4661",
												data: this.state.interests,
											}
										]
									}} width={window.innerWidth > 600 ? 500 : window.innerWidth - 520} height="220"/> : null
								}
							</div> */}
							<br />
							<br />
							<div className="row justify-content-md-center">
								<div className="col-12 col-md-6 col-lg-4 order-2 order-md-1 text-center">
									<button onClick={this.modify} className="btn btn--sm btn--secondary btn--button">
										{this.props.messages.titleLoanOffer.textN34}
									</button>
									<br />
									<br />
								</div>
								<div className="col-12 col-md-6 col-lg-4 order-3 order-md-2">
									<a href="tel:+1 210 741 8021">
										<button onClick={this.openChat} className="btn btn--sm btn--secondary btn--button">
											{this.props.messages.titleLoanOffer.textN35}
										</button>
									</a>
									<br />
									<br />
								</div>
								<div className="col-12 col-md-12 col-lg-4 order-1 order-md-3">
									<button onClick={this.complete} className="btn btn--sm btn--primary btn--button">
										{this.props.messages.titleLoanOffer.textN36}
									</button>
									<br />
									<br />
								</div>
							</div>
							<p className="text-left py-3" style={{ fontSize: 12, textAlign: "left" }}>
								{this.props.messages.titleLoanOffer.textN37}
								{this.props.messages.titleLoanOffer.textN38}
								{this.props.messages.titleLoanOffer.textN39}
								{this.props.messages.titleLoanOffer.textN40}
								{this.props.messages.titleLoanOffer.textN41}
							</p>
						</div>
					</div>
				) : null}

				{/*	Trackers*/}
				{this.props.pixels[3] ? <img alt={"uberAd"} src={"https://serve.uberads.com/convert/" + this.props.pixels[3]} width="1" height="1" /> : null}
				{/*Facebook Pixel Code*/}
				<img alt="pixel" height="1" width="1" src="https://www.facebook.com/tr?id=648707039392612&ev=PageView&noscript=1" />
			</Page>
		);
	}
}
