import React, { Component } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Copy from "../../components/copy";

import Accordion from "../../components/accordion";
import additional from "../../data/title-en/additional";
import cancellingquestions from "../../data/title-en/cancellingquestions";
import creditquestions from "../../data/title-en/creditquestions";
import generalquestions from "../../data/title-en/generalquestions";
import loanquestions from "../../data/title-en/loanquestions";
import offerquestions from "../../data/title-en/offerquestions";
import paymentsquestions from "../../data/title-en/paymentsquestions";
import requirements from "../../data/title-en/requirements";

import additionalES from "../../data/title-es/additional";
import cancellingquestionsES from "../../data/title-es/cancellingquestions";
import creditquestionsES from "../../data/title-es/creditquestions";
import generalquestionsES from "../../data/title-es/generalquestions";
import loanquestionsES from "../../data/title-es/loanquestions";
import offerquestionsES from "../../data/title-es/offerquestions";
import paymentsquestionsES from "../../data/title-es/paymentsquestions";
import requirementsES from "../../data/title-es/requirements";

export default class extends Component {
	section1 = generalquestions.map((item, key) => (
		<Accordion id={"1-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES1 = generalquestionsES.map((item, key) => (
		<Accordion id={"1-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section2 = loanquestions.map((item, key) => (
		<Accordion id={"2-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES2 = loanquestionsES.map((item, key) => (
		<Accordion id={"2-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section3 = paymentsquestions.map((item, key) => (
		<Accordion id={"3-" + key} key={key} title={item.question}>
			{item.answer}
			{/* {key === 1 ? <a rel="noopener noreferrer" href='http://www2.datatel-systems.com/ext/client%20forms/checkfreepay.aspx' target='_blank'> Find a CheckFreePay Location</a> : null} */}
		</Accordion>
	));
	sectionES3 = paymentsquestionsES.map((item, key) => (
		<Accordion id={"3-" + key} key={key} title={item.question}>
			{item.answer}
			{/* {key === 1 ? <a rel="noopener noreferrer" href='http://www2.datatel-systems.com/ext/client%20forms/checkfreepay.aspx' target='_blank'> Find a CheckFreePay Location</a> : null} */}
		</Accordion>
	));
	section4 = creditquestions.map((item, key) => (
		<Accordion id={"4-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES4 = creditquestionsES.map((item, key) => (
		<Accordion id={"4-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section5 = cancellingquestions.map((item, key) => (
		<Accordion id={"5-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES5 = cancellingquestionsES.map((item, key) => (
		<Accordion id={"5-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section6 = offerquestions.map((item, key) => (
		<Accordion id={"6-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES6 = offerquestionsES.map((item, key) => (
		<Accordion id={"6-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section7 = requirements.map((item, key) => (
		<Accordion id={"7-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES7 = requirementsES.map((item, key) => (
		<Accordion id={"7-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	section8 = additional.map((item, key) => (
		<Accordion id={"8-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	sectionES8 = additionalES.map((item, key) => (
		<Accordion id={"8-" + key} key={key} title={item.question}>
			{item.answer}
		</Accordion>
	));
	constructor(props) {
		super(props);
		this.state = {
			spanish: null,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.lang === "es") {
			this.setState({ spanish: "es" });
		}
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						<div className="container">
							<br />
							<br />
							<div className="title-row text-center">
								<h3 className="section-title">{this.props.messages.faqPage.textN01}</h3>
								<br />
							</div>
							<div className="row justificado">
								{this.props.lang === "es" ? (
									<div className="col-md-12">
										<h6 className="mb-3">{this.props.messages.faqPage.textN02}</h6>
										{this.sectionES1}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN03}</h6>
										{this.sectionES2}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN04}</h6>
										{this.sectionES3}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN05}</h6>
										{this.sectionES4}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN06}</h6>
										{this.sectionES5}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN07}</h6>
										{this.sectionES6}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN08}</h6>
										{this.sectionES7}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN09}</h6>
										{this.sectionES8}
									</div>
								) : (
									<div className="col-md-12">
										<h6 className="mb-3">{this.props.messages.faqPage.textN02}</h6>
										{this.section1}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN03}</h6>
										{this.section2}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN04}</h6>
										{this.section3}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN05}</h6>
										{this.section4}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN06}</h6>
										{this.section5}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN07}</h6>
										{this.section6}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN08}</h6>
										{this.section7}
										<br />
										<br />
										<h6 className="mb-3">{this.props.messages.faqPage.textN09}</h6>
										{this.section8}
									</div>
								)}
							</div>
							<br />
							<br />
							<br />
						</div>
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
