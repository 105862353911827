import React from "react";
import { Row, Col, Input } from "antd";
import Separator from "../../../components/separator";
import CreditScoreImage from "../../../assets/creditscore.png";

function CreditHistory(props) {
	return (
		<div className="text-center" style={{ marginTop: 50 }}>
			<Separator title />
			<h3>{props.messages.aplication.textN54}</h3>
			<br />
			<Row justify={"center"}>
				<Col xs={24} md={12}>
					<Input
						autoComplete="off"
						type="password"
						placeholder={props.messages.aplication.textN55}
						required
						defaultValue={props.ssn}
						onChange={e => props.onChange(e.target.value)}
					/>
				</Col>
			</Row>

			<p style={{ color: "#000" }} className="py-3">
				{props.messages.aplication.textN57} <b>{props.messages.aplication.textN58}</b> {props.messages.aplication.textN59}
				<br />
				<b>{props.messages.aplication.textN79}</b>
			</p>
			<img src={CreditScoreImage} style={{ width: "26%", minWidth: "200px", marginTop: 10, marginBottom: 10 }} alt={props.messages.aplication.textN60} />
		</div>
	);
}

export default CreditHistory;
