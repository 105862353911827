import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";
import occ from "../assets/occc.jpg";

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						{process.env.REACT_APP_TYPE === "title" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.occcNotice.textN01T}</h3>
								<br />
								<img src={occ} alt={"OCCC License"} />
								<p>
									{this.props.messages.occcNotice.textN02T}
									<br />
									<br />
									{this.props.messages.occcNotice.textN03T}
									<br />
									{this.props.messages.occcNotice.textN04T}
									<br />
									<br />
									{this.props.messages.occcNotice.textN05T}
									<br />
									{this.props.messages.occcNotice.textN06T}
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
						{process.env.REACT_APP_TYPE === "finance" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.occcNotice.textN01T}</h3>
								<br />
								<p>
									{this.props.messages.occcNotice.textN02T}
									<br />
									<br />
									{this.props.messages.occcNotice.textN03T}
									<br />
									{this.props.messages.occcNotice.textN04T}
									<br />
									<br />
									{this.props.messages.occcNotice.textN05T}
									<br />
									{this.props.messages.occcNotice.textN06T}
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up"></i>
						</a>
					</div>
				</main>
			</div>
		);
	}
}
