// @flow

import { Col, Row, Switch, Typography } from "antd";
import React, { Fragment } from "react";
import Separator from "./separator";

type Props = {
	name: string | null,
	active: boolean | null,
	title: string,
	subtitle: string,
	onChange: Function,
};

function Employment(props: Props): React$Node | null {
	const { name, active, title, subtitle } = props;

	return (
		<Fragment>
			<Separator title />
			<Typography.Title level={2}>{title.toUpperCase()}</Typography.Title>
			<br />

			<Row type="flex" justify={"center"}>
				<Col md={6} xs={10} className={"text-left"}>
					<Typography.Text>
						{subtitle.toUpperCase()} <Typography.Text strong>{name}</Typography.Text>?
					</Typography.Text>
				</Col>
				<Col md={3} xs={10} className={"text-right"}>
					<Switch defaultChecked={active} onChange={props.onChange} />
				</Col>
			</Row>

			<br />
			<Separator />
			<br />
			<br />
		</Fragment>
	);
}

export default Employment;
