import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						{process.env.REACT_APP_TYPE === "title" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.howItWorks.textN01T}</h3>
								<br />
								<p>
									{this.props.messages.howItWorks.textN02T}
									<br />
									<br />
									{this.props.messages.howItWorks.textN03T}
									<br />
									<br />
									{this.props.messages.howItWorks.textN04T}
									<br />
									<br />
									{this.props.messages.howItWorks.textN05T}
									<br />
									{this.props.messages.howItWorks.textN05Ta}
									<br />
									<br />
									{this.props.messages.howItWorks.textN05Tb}
									<br />
									{this.props.messages.howItWorks.textN05Tc}
									<br />
									<br />
									{this.props.messages.howItWorks.textN05Td}
									<br />
									{this.props.messages.howItWorks.textN05Te}
									<br />
									<br />
									{this.props.messages.howItWorks.textN05Tf}
									<br />
									{this.props.messages.howItWorks.textN05Tg}
									<br />
									<br />
									{this.props.messages.howItWorks.textN06T}
									<br />
									{this.props.messages.howItWorks.textN06Ta}
									<br />
									<br />
									{this.props.messages.howItWorks.textN06Tb}
									<br />
									{this.props.messages.howItWorks.textN06Tc}
									<br />
									<br />
									{this.props.messages.howItWorks.textN06T}
									<br />
									{this.props.messages.howItWorks.textN07T}
									<br />
									<br />
									{this.props.messages.howItWorks.textN08T}
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
						{process.env.REACT_APP_TYPE === "finance" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.howItWorks.textN01F}</h3>
								<br />
								<p>
									{this.props.messages.howItWorks.textN02F}
									<br />
									<br />
									{this.props.messages.howItWorks.textN03F}
									<br />
									<br />
									{this.props.messages.howItWorks.textN04F}
									<br />
									<br />
									{this.props.messages.howItWorks.textN05F}
									<br />
									{this.props.messages.howItWorks.textN05Fa}
									<br />
									<br />
									{this.props.messages.howItWorks.textN06F}
									<br />
									{this.props.messages.howItWorks.textN06Fa}
									<br />
									<br />
									{this.props.messages.howItWorks.textN07F}
									<br />
									{this.props.messages.howItWorks.textN07Fa}
									<br />
									<br />
									{this.props.messages.howItWorks.textN08F}
									<br />
									{this.props.messages.howItWorks.textN08Fa}
									<br />
									<br />
									{this.props.messages.howItWorks.textN09F}
									<br />
									{this.props.messages.howItWorks.textN09Fa}
									<br />
									<br />
									{this.props.messages.howItWorks.textN10F}
									<br />
									<br />
									{this.props.messages.howItWorks.textN11F}
									<br />
									<br />
									{this.props.messages.howItWorks.textN12F}
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
