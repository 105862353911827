import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import Page from "../components/page";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";
import moment from "moment";
import money from "../assets/money.png";
import users from "../assets/users.png";
import { useSelector } from "react-redux";

momentLocalizer();

export default props => {
	const uid = useSelector(state => state.uid);
	const loan = useSelector(state => state.loan);
	const store = useSelector(state => state.store);

	const [state, setState] = useState({
		active: 1,
		call: null,
		callDate: moment()
			.add("1", "d")
			.toDate(),
		callHour: moment()
			.hour(8)
			.minutes(0)
			.toDate(),
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if (!uid) {
			props.history.push("/login");
		}

		if (loan.status === 5) {
			window.gtag("event", "conversion", {
				send_to: "AW-974126037/8xDLCMfjpqgBENX3v9AD",
			});
		}

		firebase
			.firestore()
			.doc("applications/" + uid)
			.get()
			.then(app => {
				return setState(s => ({
					...s,
					call: app.data().call ? app.data().call.toDate() : null,
					active: app.data().active,
				}));
			})
			.catch();
	}, [loan, uid, props.history]);

	const submitForm = event => {
		event.preventDefault();
		const hour = moment(state.callHour).format("H");
		const minutes = moment(state.callHour).format("m");
		const date = moment(state.callDate)
			.hour(parseFloat(hour))
			.minute(parseFloat(minutes))
			.seconds(0);
		if (date.weekday() === 0) {
			return Swal.fire({
				title: props.messages.status.textN01,
				html: props.messages.status.textN02 + `<br/><br/><p><b>${props.messages.status.textN03}</b><br/><b>${props.messages.status.textN04}</b></p>`,
			});
		} else {
			return firebase
				.firestore()
				.doc("applications/" + props.uid)
				.update({
					timestamp: moment().toDate(),
					call: date.toDate(),
					lastUpdate: "User",
					status: 6,
				})
				.then(() => {
					setState({
						...state,
						call: date.toDate(),
					});
					return props.onUpdateLoan({
						...loan,
						status: 6,
					});
				});
		}
	};

	const logOut = () => {
		return firebase
			.auth()
			.signOut()
			.then(() => {
				return props.history.push(props.match.url);
			});
	};

	const review = () => {
		window.open("http://search.google.com/local/writereview?placeid=ChIJh3Pa51hZXIYRg9uPWnLylXI");
	};

	const changeAppointment = () => {
		props.onUpdateLoan({
			status: 5,
			vehicle: props.loan.vehicle,
			type: state.loanType,
			term: props.loan.term,
		});
	};

	// Handle Loan Succeeded
	if (state.active === 2 || loan.status === 9) {
		return (
			<Status {...props}>
				<div className="text-center">
					<img src={money} alt="Welcome!" style={{ maxWidth: "220px" }} />
					<br />
					<br />
					<h2>{props.messages.status.textN36}</h2>
					<p>
						{props.messages.status.textN37}
						<br />
						{props.messages.status.textN38}
					</p>
					<br />
					<hr style={{ width: 220, margin: "0 auto" }} />
					<br />
					<button className="btn btn--sm btn--primary btn--button col-12 col-md-3 mx-md-2 my-1" onClick={review}>
						{props.messages.status.textN48}
					</button>
				</div>
			</Status>
		);
	}

	// Handle No Loan
	if (state.active === 3) {
		return (
			<Status {...props}>
				<div className="container">
					<br />
					<p>{props.messages.status.textN39}</p>
					<p>{props.messages.status.textN40}</p>
					<br />
					<div className="row justify-content-center">
						<button className="btn btn--sm btn--secondary btn--button col-12 col-md-2 mx-md-2 my-1 order-2 order-md-1" onClick={logOut}>
							{props.messages.status.textN41}
						</button>
						<button
							className="btn btn--sm btn--color btn--button col-12 col-md-2 mx-md-2 my-1 order-1 order-md-2"
							onClick={props.history.push("/application")}>
							{props.messages.status.textN42}
						</button>
					</div>
				</div>
			</Status>
		);
	}

	return (
		<Page {...props}>
			<div className="container py-5">
				<h3>{props.messages.status.textN05}</h3>
				<br />
				<div className="yseparator center" />
				<br />
				{loan.status === 0 ? (
					<div className="container">
						<br />
						<p>{props.messages.status.textN06}</p>
						<p>{props.messages.status.textN07}</p>
						<br />
						<div className="row justify-content-center">
							<button className="btn btn--sm btn--secondary btn--button col-12 col-md-2 mx-md-2 my-1 order-2 order-md-1" onClick={logOut}>
								{props.messages.status.textN08}
							</button>
							<button
								className="btn btn--sm btn--color btn--button col-12 col-md-2 mx-md-2 my-1 order-1 order-md-2"
								onClick={() => props.history.push("/application")}>
								{props.messages.status.textN09}
							</button>
						</div>
					</div>
				) : null}
				{loan.status === 1 && loan.type === "Title Loan" ? (
					<div className="container">
						<br />
						<p>
							{props.messages.status.textN10} <br />
							{props.messages.status.textN11}
						</p>
						<br />
						<br />
						<div className="row justify-content-center">
							<button className="btn btn--sm btn--color btn--button col-12 col-md-6" onClick={() => props.history.push("/discounts")}>
								{props.messages.status.textN12}
							</button>
						</div>
					</div>
				) : null}
				{loan.status === 1 && loan.type === "Personal Loan" ? (
					<div className="container">
						<br />
						<p>
							{props.messages.status.textN13} <br />
							{props.messages.status.textN14}
						</p>
						<br />
						<br />
						<div className="row justify-content-center">
							<button className="btn btn--sm btn--color btn--button col-12 col-md-6" onClick={() => props.history.push("/credit")}>
								{props.messages.status.textN15}
							</button>
						</div>
					</div>
				) : null}
				{loan.status === 2 && loan.type === "Title Loan" ? (
					<div className="container">
						<br />
						<p>{props.messages.status.textN44}</p>
						<br />
						<br />
						<div className="row justify-content-center">
							<button className="btn btn--sm btn--color btn--button col-12 col-md-6" onClick={() => props.history.push("/offer")}>
								{props.messages.status.textN44}
							</button>
						</div>
					</div>
				) : null}
				{(loan.type === "Title Loan" && loan.status === 3) || (loan.type === "Personal Loan" && loan.status === 2) ? (
					<div className="container">
						<br />
						<p>{props.messages.status.textN16}</p>
						<br />
						<br />
						<div className="row justify-content-center">
							<button className="btn btn--sm btn--color btn--button col-12 col-md-6" onClick={() => props.history.push("/documents")}>
								{props.messages.status.textN17}
							</button>
						</div>
					</div>
				) : null}
				{(loan.type === "Title Loan" && loan.status === 4) || (loan.type === "Personal Loan" && loan.status === 3) ? (
					<div className="container">
						<br />
						<div className="check scale-up-center my-3">
							<i className="fas fa-check" />
						</div>
						<p>{props.messages.status.textN18}</p>
						<br />
						<p>
							<b>{props.messages.status.textN19}</b>
							<br />
							<b>{props.messages.status.textN20}</b>
						</p>
						<br />
						<p>{props.messages.status.textN21}</p>
						<Row justify={"center"}>
							<Col>
								<Button
									type={"primary"}
									onClick={() => {
										props.history.push("references");
									}}>
									{props.messages.status.fillReferences}
								</Button>
							</Col>
						</Row>
					</div>
				) : null}
				{(loan.type === "Title Loan" && loan.status === 5) || (loan.type === "Personal Loan" && loan.status === 4) ? (
					<div className="text-center">
						<br />
						<p>{props.messages.status.textN22}</p>
						<br />
						<p>{props.messages.status.textN23}</p>
						<br />
						<p>{props.messages.status.textN24}</p>
						<br />
						<h6>
							<b>{props.messages.status.textN25}</b>
						</h6>
						<div className="row justify-content-md-center mt-1">
							<DateTimePicker
								className="col-6 col-md-6"
								time={false}
								step={30}
								min={moment()
									.add("1", "h")
									.startOf("hour")
									.toDate()}
								max={moment(state.callDate)
									.add("10", "days")
									.toDate()}
								defaultValue={state.callDate}
								onChange={e => setState({ ...state, callDate: e })}
							/>
							<DateTimePicker
								className="col-6 col-md-6"
								date={false}
								step={60}
								defaultValue={state.callHour}
								min={moment()
									.hour(9)
									.minute(0)
									.toDate()}
								max={moment()
									.hour(18)
									.minute(0)
									.toDate()}
								onChange={e => setState({ ...state, callHour: e })}
							/>
						</div>
						<br />
						<br />
						<div className="row justify-content-center mx-2">
							<button
								type="submit"
								onClick={submitForm}
								className="col-12 my-1 col-md-4 mx-2 order-1 order-md-2 btn btn--sm btn--color btn--button">
								{props.messages.status.textN26}
							</button>
							<a
								href={"tel:+1 " + store.phone}
								style={{ width: "100%" }}
								className="col-12 my-1 col-md-4 mx-2 order-2 order-md-1 btn btn--secondary">
								<button type="button" className="btn btn--sm btn--secondary btn--button">
									{props.messages.status.textN27}
								</button>
							</a>
						</div>
					</div>
				) : null}
				{loan.status === 6 ? (
					<div className="text-center">
						<br />
						<p>{props.messages.status.textN28}</p>
						<p>
							{props.messages.status.textN29} <b>{state.call ? moment(state.call).format("lll") : null}</b>
						</p>
						<br />
						<br />
						<div className="row justify-content-center mx-2">
							<a href="/" className="col-12 my-1 col-md-4 mx-2 order-1 order-md-3 ">
								<button type="button" className="btn btn--sm btn--color btn--button">
									{props.messages.status.textN30}
								</button>
							</a>
							<button
								type="submit"
								onClick={changeAppointment}
								className="col-12 my-1 col-md-4 mx-2 order-1 order-md-2 btn btn--sm btn--color btn--button">
								{props.messages.status.textN31}
							</button>
							<a
								href={"tel:+1 " + store.phone}
								style={{ width: "100%" }}
								className="col-12 my-1 col-md-4 mx-2 order-2 order-md-1 btn btn--secondary">
								<button type="button" className="btn btn--sm btn--secondary btn--button">
									{props.messages.status.textN32}
								</button>
							</a>
						</div>
					</div>
				) : null}
				{loan.status === 7 ? (
					<div className="text-center">
						<img src={users} alt="Almost there" style={{ maxWidth: "220px" }} />
						<br />
						<br />
						<p>{props.messages.status.textN33}</p>
						<p>{props.messages.status.textN34}</p>
						<br />
						<br />
						<div className="row justify-content-center mx-2">
							<a href={"/references"} className="col-12 my-1 col-md-4 mx-2 order-1 order-md-3 ">
								<button type="button" className="btn btn--sm btn--color btn--button">
									{props.messages.status.textN35}
								</button>
							</a>
						</div>
					</div>
				) : null}
				{loan.status === 8 ? (
					<div className="text-center">
						<br />
						<i className={"fad fa-file-contract"} style={{ fontSize: 120 }} />
						<br />
						<br />
						<p>
							{props.messages.status.textN45 + " "}
							<br />
							{" " + props.messages.status.textN46}
						</p>
						<br />
						<div className="row justify-content-center mx-2">
							<a href={"/agreement"} className="col-12 my-1 col-md-4 mx-2 order-1 order-md-3">
								<button type="button" className="btn btn--sm btn--color btn--button">
									{props.messages.status.textN47}
								</button>
							</a>
						</div>
					</div>
				) : null}
			</div>
		</Page>
	);
};

const Status = props => {
	return (
		<Page {...props}>
			<div className="container py-5">
				<h3>{props.messages.status.textN05}</h3>
				<br />
				<div className="yseparator center" />
				<br />
				{props.children}
			</div>
		</Page>
	);
};
