const en = {
	commText: {
		oops: "Oops..",
		salvado: "Saved!",
		calculate: "Calculate",
		reset: "Reset",
		Success: "Success!",
		MissingFiles: "Missing Files",
		Upload: "Upload",
		Months: "Months",
		Optional: "Optional",
	},
	homepage: {
		title01: "FIND OUT HOW MUCH?",
		title02: "YOU CAN BORROW WITHOUT THE HASSLE",
		text01: "Loans from $125 to $10,000",
		text02: "Fixed payments that fit your budget",
		text03: "Same day funding available",
		text04: "Everything done from your phone",
		textbutt01: "Find Out What I can Get",
		title03: "NO OBLIGATION",
		title04: "ESTIMATE IN SECONDS!",
		textL01:
			"By answering a few questions, our lenders can share what other customers with your profile have borrowed recently. If it’s in the ballpark of what you need, then take a few more minutes and get a certified ready offer right now!",
		textbutt02: "Find Out What others get",
		title05: "3 SIMPLE STEPS",
		title06: "TO GET YOUR LOAN FUNDED",
		suTitle01: "APPLY ONLINE",
		textL02:
			"Fill out a brief application and get an immediate Certified Ready Offer. If you like the offer, upload a few photos, submit for final review, verification and loan approval",
		suTitle02: "EARN YOUR DISCOUNTS",
		textL03:
			"The more information you can provide our lenders, the bigger the discounts you can earn! BUT the single biggest discount our lenders provide is the Automatic Payment Discount - it's HUGE! All you need is a bank debit card to set up your monthly payments.",
		suTitle03: "REVIEW CONTRACT",
		textL04: "Review the loan contract with a customer service rep, e-sign and get instructions to file Form 130U (for the title loans).",
		suTitle04: "APPLY NOW",
		suTitle05: "ONLINE MEANS",
		suTitle06: "YOU SAVE TIME",
		suTitle07: "AND MONEY",
		suTitle08: "SAVINGS",
		textL05:
			"Discount Loan lenders offer fully amortizing, unsecured and secured loans from $125 to $10,000, with equal monthly payments, a minimum repayment period of 3 months and a maximum repayment period of 18 months. Along with discounted rates, these loans are offered with no penalties for paying your loan off early or for making partial prepayments",
		suTitle09: "PRINCIPAL REDUCTION",
		textL06:
			"Each monthly payment includes a principal portion which decreases the fees you owe the following month. Fully amortized loans means you're completely finished paying off the loan by the end of the loan term. No surprises. All of our lenders offer discounts when customers pay on time.",
		suTitle10: "PRICE COMPETITION",
		textL07:
			"Our on-line lending marketplace is a fast and easy way to compare options, find a good deal, and get what you need. You'll get access to a variety of consumer loan options and we'll save you the hassle of having to research everything on your own.",
	},
	homepageOter: {
		title01: "EXPERIENCE",
		title02: "DISCOUNT TITLE LOAN ON-LINE!",
		title02F: "DISCOUNT FINANCE ON-LINE!",
		text01: "Loans from $125 to $10,000",
		text01F: "Loans from $60 to $1,420",
		text02: "Fixed payments that fit your budget",
		text03: "Save 50% vs our competitors *",
		text03F: "Save up $100 when you refinace*",
		text04: "Everything done from your phone",
		text04ex: "Get your Cash Today!",
		textbutt01: "Find Out What I can Get",
		title03: "NO OBLIGATION",
		title04: "ESTIMATE IN SECONDS!",
		textL01:
			"By answering a few questions, we can share what other customers with your profile have borrowed recently. If it’s in the ballpark of what you need, then take a few more minutes and get a certified ready offer right now!",
		textbutt02: "Find Out What others get",
		title05: "3 SIMPLE STEPS",
		title06: "TO GET YOUR LOAN FUNDED",
		suTitle01: "APPLY ONLINE",
		textL02:
			"Fill out a brief application and get an immediate Certified Ready Offer. If you like the offer, upload a few photos, submit for final review, verification and loan approval",
		suTitle02: "EARN YOUR DISCOUNTS",
		textL03:
			"The more information you can provide us, the bigger the discounts you can earn! BUT the single biggest discount we offer is the Automatic Payment Discount - it's HUGE! All you need is a bank debit card to set up your monthly payments.",
		suTitle03: "REVIEW CONTRACT",
		textL04: "Review the loan contract with a customer service rep, e-sign and get instructions to file Form 130U (for the title loans).",
		suTitle04: "APPLY NOW",
		suTitle05: "ONLINE MEANS",
		suTitle06: "YOU SAVE TIME",
		suTitle07: "AND MONEY",
		suTitle08: "SAVINGS",
		textL05:
			"Discount Loan lenders offer fully amortizing, unsecured and secured loans from $125 to $10,000, with equal monthly payments, a minimum repayment period of 3 months and a maximum repayment period of 6 months. Along with discounted rates, these loans are offered with no penalties for paying your loan off early or for making partial prepayments",
		textL05F:
			"Our customers get tons of freebies! Discount Finance offers customers in good standing two free credit reports per year, free automatic payments and free instant funding! Never pay a check cashing fee again! Take more money home with you today!",
		suTitle09: "PRINCIPAL REDUCTION",
		textL06:
			"Each monthly payment includes a principal portion which decreases the fees you owe the following month. Fully amortized loans means you're completely finished paying off the loan by the end of the loan term. No surprises. Our customers earn their discounts when they pay on time.",
		suTitle09F: "PRINCIPAL PAYMENTS",
		textL06F:
			"Each monthly payment includes a principal portion which decreases the fees you owe the following month. Fully amortized loans means you're completely finished paying off the loan by the end of the loan term. No surprises.",
		suTitle10: "PRICE COMPETITION",
		textL07:
			"Our on-line lending marketplace is a fast and easy way to compare options, find a good deal, and get what you need. You'll get access to a variety of consumer loan options and we'll save you the hassle of having to research everything on your own.",
		suTitle10F: "FLEXIBLE LOAN TERMS",
		textL07F:
			"At Discount Finance, we always recommend the optimum loan term for our customers. Loan terms may vary between 3-12 months depending on your loan size.",
		textPersonal01: "APPLY ONLINE",
		textPersonal02:
			"Fill out a brief application and get an immediate Certified Ready Offer. If you like the offer, upload a few photos, submit for final review, verification and loan approval",
		textPersonal03: "BOOST YOUR CREDIT LIMIT",
		textPersonal04:
			"The more information you can provide us, the bigger the credit limit you can earn! BUT the single biggest credit limit bosst you can earn is the Automatic Payment Discount - it`s HUGE! All you need is a bank debit card to set up your monthly payments.",
		textPersonal05: "REVIEW CONTRACT",
		textPersonal06: "Review the loan contract with a customer service rep & sign electronically from your phone!",
	},
	menu: {
		textN01: "CALL",
		textN02: "APPLY",
		textN03: "ACCT",
		textN04: "LOG IN",
		textN05: "MENU",
		textN06: "Home",
		textN07: "Home",
		textN08: "How It Works",
		textN09: "How It Works",
		textN10: "FAQ",
		textN11: "About",
		textN12: "Contact",
		textN13: "Account",
		textN14: "Status",
		textN15: "Profile",
		textN16: "Application",
		textN17: "Discounts",
		textN18: "Credit Limit",
		textN19: "Offer",
		textN20: "Documents",
		textN21: "References",
		textN22: "Log Out",
		textN23: "Log In",
		textN24: "Apply Now!",
		textN25: "STORES",
		textN26: "Stores",
		textN27: "Carrers",
	},
	quickstart: {
		textN01: "FIND OUT HOW MUCH",
		textN02: "YOU CAN BORROW WITHOUT THE HASSLE",
		textN03: "How much money are you looking for?",
		textN04: "What is your personal credit score?",
		textN05: "Select One",
		textN06: "No Credit Score / I don't know",
		textN07: "Less than 520",
		textN08: "520 - 620",
		textN09: "More than 620",
		textN10: "Do you own a vehicle with clear title?",
		textN11: "Yes",
		textN12: "No",
		textN13: "Year",
		textN14: "Select One",
		textN15: "Make",
		textN16: "Select One",
		textN17: "Model",
		textN18: "Select One",
		textN19: "Trim",
		textN20: "Select One",
		textN21: "Mileage",
		textN22: "Back",
		textN23: "Next",
		textN24: "Will not impact your FICO credit score",
	},
	carForm: {
		textn01: "Select Year",
		textn02: "Select Make",
		textn03: "Select Model",
		textn04: "Select Trim",
	},
	copy: {
		textN01: "2019 Discount Loans Online.",
	},
	footer: {
		textN01: "Home",
		textN02: "Home",
		textN03: "How It Works",
		textN04: "How It Works",
		textN05: "Questions",
		textN06: "About Us",
		textN07: "Markets",
		textN08: "Markets we Serve",
		textN09: "Contact",
		textN10: "Discount-loans.com is not an actual lender.",
		textN11:
			"Our participating referral lenders offer personal loans and car title loans between $125 to $10,000, with a minimum period of repayment of 3 months and a maximum repayment of 18 months.",
		textN12:
			"The Annual Percentage Rates (APR), loan terms, loan amounts, origination fees and other terms provided in this website are estimated based on information you provided, data offered by lenders, and publicly available information. All information is presented without warranty, and the estimated APR, terms, and other features are not binding in any way. Lenders offer a range of APRs depending on your credit history, income, and other factors.",
		textN13:
			"Loans offered through this site with a maximum APR of 28.7% are available to qualified returning customers with a loan term of 6 months on loan amounts starting at $7,500. An example of the total amount paid on a loan of $6,000 repayable in 77 bi-weekly payments of $117 at an APR of 28.7% equals about $8,902. Only borrowers with excellent credit qualify for the lowest rates. Your actual APR will depend on your credit score, loan amount, term, income, credit history and the state in which the loan is originated. All loans must be reviewed and approved by the lenders. Not available in all states.",
		textN14: "On-line Marketplace.",
		textN15:
			"Our goal is to connect you with an approved lender from our personal loan and car title loan network. We also made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN16: "Get Money Fast.",
		textN17:
			"Our online loan service readily serves your need to connect with a quality personal loan or car title loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy to an authorized lender to approve. Once you submit your information, you will be redirected to the lender's website where you can review the terms of the personal loan or car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN18: "Important Disclosures.",
		textN19:
			"Please Read Carefully. The purpose of shorter duration loans is to provide the borrower temporary financial relief. Such loans are not a long-term financial solution. Persons facing serious financial difficulties should consider other alternatives or should seek out professional financial advice.",
		textN20: "This website is not an offer to lend.",
		textN21:
			"Discount Loans Online is not a lender and does not make loan or credit decisions. We connect interested persons with a lender from our approved personal loan and car title loan network. We do not control and are not responsible for the actions or inactions of any lender, we not an agent, representative or broker of any lender. Our referral service is always free to you.",
		textN22: "This service is not available in all areas.",
		textN23:
			"If you request a personal loan or car title loan in a particular area where such loans are prohibited, or in a location where we do not have an available lender, you will not be connected to a lender. You are urged to read and understand the terms of any personal loan or car title loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN24: "Credit checks for personal loans.",
		textN25:
			"By submitting your information via this website, you are authorizing discount loans and our referral lenders to initiate a credit check (for personal loans only), which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize discount loans to share your information and credit history with a network of approved lenders.",
		textN26: "Lender’s Disclosure of Terms.",
		textN27:
			"The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law. We have no knowledge of or control over the loan terms offered by a lender. You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN28: "Late Payments may Hurt Your Credit Score.",
		textN29:
			"Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
		textN10T: "* To be approved for a Title Loan.",
		textN11T: "You must be at least 18 years of age. Loan approval is subject to vehicle inspection and appraisal.",
		textN12T:
			"**In Texas, Vin-Title, LLC (d/b/a Discount Title Loan) Will act as a Credit Services Organization to assist you in obtaining a loan. Your loan may be made by an unaffiliated third party Lender. Please see store associate for details.",
		textN13T: "",
		textN14T: "This service is not available in all areas. ",
		textN15T:
			"If you request a car title loan in a particular area where such loans are prohibited, or in a location where we do not have an available lender, you will not be connected to a lender. You are urged to read and understand the terms of any car title loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN16T: "Get Money Fast. ",
		textN17T:
			"Our online loan service readily serves your need to connect with a quality car personal loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy for us to approve. Once you submit your information you will be able to review the terms of the personal loan, including details about all the applicable rates and fees. If you accept these terms, twe may deposit money directly into your bank account, in many cases, as soon as today!",
		textN18T: "Lender’s Disclosure of Terms.",
		textN19T:
			"The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law.  You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN20T: "Late Payments may Hurt Your Credit Score.",
		textN21T:
			"Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
		textN22T: "What we do.",
		textN23T:
			"Discount Title Laon is not a lender and does not make loan or credit decisions. We connect interested persons with lenders from our approved personal loan and car title loan network. We do not control and are not responsible for the actions or inactions of any lender, we not an agent, representative or broker of any lender. Our referral service is always free to you.",
		textN24T: "On-line Marketplace.",
		textN25T:
			"Our goal is to get you the best title loan rates and title loan terms in the market. We have also made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN26T: "SCHEDULE OF ALL FEES",
		textN27T: "OCCC NOTICE",
		textN10F: "* To be approved for a Personal Loan,",
		textN11F:
			" you must be a resident of the State of Texas and at least 18 years of age. We currently do not provide loan products to active military members.",
		textN12F: "",
		textN13F: "",
		textN14F: "On-line Marketplace.",
		textN15F:
			"Our goal is to get you the best personal loan rates and personal loan terms in the market. We have also made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN16F: "Get Money Fast.",
		textN17F:
			"Our online loan service readily serves your need to connect with a quality car personal loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy for us to approve. Once you submit your information you will be able to review the terms of the personal loan, including details about all the applicable rates and fees. If you accept these terms, twe may deposit money directly into your bank account, in many cases, as soon as today!",
		textN18F: "Important Disclosures. ",
		textN19F:
			"Please Read Carefully. The purpose of shorter duration loans is to provide the borrower temporary financial relief. Such loans are not a long-term financial solution. Persons facing serious financial difficulties should consider other alternatives or should seek out professional financial advice.",
		textN20F: "This service is not available in all areas.",
		textN21F:
			" If you request a personal loan in a particular area where such loans are prohibited, or in a location where we do not have an available lender, you may not be connected to a lender. You are urged to read and understand the terms of any personal loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN22F: "Credit checks.",
		textN23F:
			"By submitting your information via this website, you are authorizing Discount Finance & Online Personal Loans  to initiate a credit check, which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize Discount Finance & Online Personal Loans  to share your information and credit history with our approved lenders.",
		textN24F: "Lender’s Disclosure of Terms. ",
		textN25F:
			"The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law.  You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN26F: "Late Payments may Hurt Your Credit Score. ",
		textN27F:
			"Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
		textN28F: "What we do. ",
		textN29F:
			"Discount Finance & Online Personal Loans  connects interested persons with lenders from our approved personal loan and car title loan network. Our referral service is always free to you.",
		textN30F: "On-line Marketplace. ",
		textN31F:
			"Our goal is to get you the best personal loan rates and title loan terms in the market. We have also made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN30: "See",
		textN31: "Less",
		textN32: "More",
		textN33: "Careers",
	},
	contact: {
		textN01: "Contact Us",
		textN02: "Monday - Friday 9am-6pm",
		textN03: "Saturday 10am-6pm",
		textN04: "If you need help navigating the site or have any questions about your ",
		textN05: "certified loan offer please email us at:",
	},
	discounts: {
		textN01: "The biggest discount our lenders offer (by far) is the ",
		textN02: "Automatic Payment Discount",
		textN03: ". All you need is a debit card to set up your monthly payments.",
		textN04: "Your Discounts",
		textN05: "Do you want to get the biggest discount available by signing up for Automatic Payments?",
		textN06: "NO",
		textN07: "YES",
		textN08: "Here are some additional discounts you might qualify for.",
		textN09: "Are your home utilities in your name?",
		textN10: "Do you have a mortgage in your name?",
		textN11: "Have you had your job more than 2 years?",
		textN12: "Are you a first time customer of ours?",
		textN13: "Can you provide proof of income?",
		textN14: "See Offer",
		textN15:
			"Applicants must meet all eligibility requirements, including vehicle inspection and document authentication. Certain terms and conditions apply. Must be 18 years of age or older. In Texas, Vin-Title, LLC (d/b/a Discount Title Loan) acts as a Credit Services Organization/ Credit Access Business to assist customers in obtaining title loans through an unaffiliated third party lender.",
		textN16: "2. Discounts",
		textN17: "Step 2 of 3",
	},
	faqPage: {
		textN01: "Frequently Asked Questions",
		textN02: "General Questions",
		textN03: "Applying for Personal Loans & Title Loans",
		textN04: "Making Payments",
		textN05: "Questions on Credit Reporting",
		textN06: "Modifying a Certified Offer",
		textN07: "Certified ready offers",
		textN08: "Requirements",
		textN09: "Additional FAQ’s & History of Traditional Installment Loans (personal loans)",
	},
	markets: {
		textN01: "Markets we serve",
		textN02:
			"Service Areas. This service is not available in all areas. If you request an on-line personal loan or car title loan in a particular area where such loans are prohibited, or in a location where we do not have an available lender, you will not be connected to a lender. You are urged to read and understand the terms of any personal loan or car title loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN03: "We currently contract with personal loan and car title loan lenders in the following locations:",
		textN04: "Discount Loans",
		textN05: "Title Loans & Personal Loans- San Antonio Texas",
		textN06: "Discount Finance & Personal Loans",
		textN07: "Brownsville Texas",
		textN08: "Mission Texas",
		textN09: "McAllen Texas",
		textN10: "Discount Car Title Loan",
		textN11: "Brownsville Texas",
		textN12: "Harlingen Texas",
		textN13: "Weslaco Texas",
		textN14: "Alamo Texas",
		textN15: "Pharr Texas",
		textN16: "McAllen Texas",
		textN17: "McAllen Texas",
		textN18: "McAllen Texas",
		textN19: "Edinburg Texas",
		textN20: "Mission Texas",
		textN21: "Rio Grande City Texas",
		textN22: "Corpus Christi Texas",
		textN23: "Corpus Christi Texas",
		textN24: "Laredo Texas",
		textN25: "Laredo Texas",
		textN26: "Eagle Pass Texas",
		textN27: "Beeville Texas",
	},
	personalLoan: {
		textN01: "We are Sorry!",
		textN02: "We are having problems with your application please contact us for more information.",
		textN03: "Talk to an Expert",
		textN04: "Congratulations",
		textN05: "YOU HAVE A ",
		textN06: "CERTIFIED READY",
		textN07: " OFFER UP TO",
		textN08: "MONTHLY PAYMENT",
		textN08b: "BIMONTHLY PAYMENT",
		textN09: "LOAN TERM DISPLAYED",
		textN09b: "LOAN TERM",
		textN10: "CUSTOMIZE YOUR LOAN LENGTH",
		textN11: "Month",
		textN12: "Payment",
		textN13: "Principal",
		textN14: "Fees",
		textN15: "Your monthly payments could be as low as",
		textN15b: "Your bi-weekly payments could be as low as",
		textN16: "and there's no penalty if you pay it off sooner.",
		textN17: "The amortization table above is for illustrative purposes only. ",
		textN18: "It does not include the standard $28 title lien fee and it assumes that you will earn your discounted rate every month by paying on time. ",
		textN19:
			"Once your application is approved by the lender, you will be provided a complete TILA disclosure along with all the facts associated with the loan. ",
		textN20: "It will include the undiscounted APR (so you can see what the fees would be if you didn't earn the discounted rate every month), ",
		textN21: "all associated loan finance charges and all of the terms you will be agreeing to. ",
		textN22: "Make sure that you read all the information provided to you by the lender to ensure that you make an informed decision ",
		textN23: "prior to signing the final contract.",
		textN24: "APR Disclosure",
		textN25: "Talk to an Expert",
		textN26: "Complete next Step (3 of 3)",
		textN27: "Your certified ready offer is a reasonable estimate based on the information you provided but is not guaranteed.",
		textN28:
			"Having a certified-ready offer means you may be approved for a loan with any of our participating lenders, subject to verifying your identity,",
		textN29:
			"and confirming the information on the documentation you provide to us such as references, employment information, social security number and debit card information.",
		textN30: "You can have a certified-ready offer and still be turned down for a loan.",
	},
	start: {
		textN01: "what others are getting",
		textN02: "Based on your information",
		textN03: "Welcome!",
		textN04: "Title Loan:",
		textN05: "Personal Loan:",
		textN06: "Find out how much you can borrow.",
		textN07: "Get a custom quote",
		textN08: "Will not impact your FICO Score",
	},
	welcome: {
		textN01: "Oops..",
		textN02: "Your password is too weak",
		textN04: "We are sorry",
		textN05: "Our loan products are currently not available to active military members",
		textN07: "GETTING YOUR",
		textN08: "LOAN IS EASY!",
		textN09: "APPLY ONLINE",
		textN10: "Fill out a brief application and get an immediate Certified Ready Offer.",
		textN11: "If you like the offer, upload a few photos, submit for verification and loan funding",
		textN12: "Active member of the military?",
		textN13: "Yes",
		textN14: "No",
		textN15: "APPLY NOW!",
		textN16: "EXPERIENCE",
		textN17: "DISCOUNT LOANS",
		textN18: "ON-LINE",
		textN19: "Loans from $125 to $10,000",
		textN20: "Fixed payments that fit your budget",
		textN21: "Same day funding available",
		textN22: "Everything done from your phone",
		textN23: "CUSTOMER REVIEWS",
		textN24: "“Great customer service. All the staff in the office is amazing and very helpful”",
		textN25: "Shevawn Chacon",
		textN26: "“Great customer services, Mayra is amazing!! Great place to get a title loan, quick and easy Highly recommended...”",
		textN27: "Melinda Lamas",
		textN28:
			"Had a bad experience with auto title loans before. But discount title loan helped me when i really needed it. The payments are better than expected.”",
		textN29: "Sandra Baron",
		textN30: "FREQUENTLY ASKED QUESTIONS",
		textN31: "How fast is the application process?",
		textN32:
			"The application process at Discount Loans Online (discount-loans.com) typically takes 10 to 15 minutes to complete. If you can provide all the correct documentation to the lender, a decision can be made the same day of application.",
		textN33: "Do your lenders offer title loans for people with bad credit?",
		textN34:
			"Yes, Discount Loans Online (discount-loans.com) personal loan and car title loan lenders have applicants with bad or no credit. They understand that people make mistakes and they believe that FICO scores shouldn’t prevent people from getting help when they need it the most.",
		textN35: "What do I need to apply for a personal loan or a car title loan ?",
		textN36:
			"Discount Loans Online (discount-loans.com) lenders will need your personal information (name, phone, email, and zip code) and your vehicle information (make, model, year, and estimated mileage) and will require you to have a valid government-issued ID, a lien-free title, and proof of income.",
		textN37: "Do I need a job to get approved for a loan? What if I am on disability or retired?",
		textN38:
			"No, you don’t need to be employed to get a personal or a car title loan. However, you do need a source of regular income. Other forms of income such as disability, social security, child support, alimony, unemployment, and self-employment are accepted as long as you can provide proof.",
		textN39: "How do I get my personal loan or car title loan funds?",
		textN40:
			"You can get your loan proceeds, either in the form of a physical check, (at one of Discount Loans Online (discount-loans.com) lender’s retail locations) or via an electronic check which can be e-mailed to you. All you need is the ability to print that electronic check on regular paper and cash it at any bank or check cashing facility.",
		textN41: "We're Here to Help",
		textN42: "CALL US",
		textN43: "If you have any questions about your ",
		textN44: "certified loan offer please call us at:",
		textN45: "(210) 741 8021",
		textN46: "Monday - Friday 9am-6pm",
		textN47: "Saturday 10am-6pm",
		textN48: "Your password is too weak",
		textN49: "We are sorry",
		textN50: "Our loan products are currently not available to active military members",
	},
	aboutUS: {
		textN01: "About Us",
		textN02:
			"What we do. Discount Loans Online is not a lender and does not make loan or credit decisions. We connect interested persons with lenders from our approved personal loan and car title loan network. We do not control and are not responsible for the actions or inactions of any lender, we not an agent, representative or broker of any lender. Our referral service is always free to you.",
		textN03:
			"On-line Marketplace. Our goal is to connect you with an approved lender from our personal loan and car title loan lender network. We have made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN04:
			"Get Money Fast. Our online loan referral service readily serves your need to connect with a quality personal loan or car title loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy to an authorized lender to approve. Once you submit your information, you will be redirected to the lender's website where you can review the terms of the personal loan or car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN05:
			"Credit checks for personal loans. By submitting your information via this website, you are authorizing discount loans and our referral lenders to initiate a credit check (for personal loans only), which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize discount loans to share your information and credit history with a network of approved lenders.",
		textN06:
			"Lender’s Disclosure of Terms. The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law. We have no knowledge of or control over the loan terms offered by a lender. You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN07:
			"Late Payments may Hurt Your Credit Score. Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
		textN01T: "About Us",
		textN02T:
			"Discount Car Title Loan is a consumer finance company that originates and services car title loans, providing Texas consumers access to secured short-term credit at the most competitive market rates.",
		textN03T:
			"Our mission is to provide safer and more affordable loans. Quickly. Customers get rewarded for paying on time. At Discount Car Title Loan customers can get up to 50% savings on their loan fees when they pay on time. This is not a promotion, it´s how we do business.",
		textN04T:
			"Customers get to decide how much money to get and how long to pay it back. Core to the Discount Car Title Loan business model is our proprietary Loan Optimizer Software, an underwriting tool that helps customers select their ideal loan size and payment terms. Discount Car Title Loan offers 4 to 6 month amortized car title loans in the $125-$10,000 range.",
		textN05T:
			"Get Money Fast. Our online loan platform readily serves your need to connect with a quality car title loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy for us to approve. Once you submit your information you will be able to review the terms of the car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN06T:
			"Credit checks. By submitting your information via this website, you are authorizing Discount Title Loan to initiate a credit check, which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize Discount Title Loan to share your information and credit history with our third party lenders.",
		textN07T:
			"Lender’s Disclosure of Terms. Our third party lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law.  You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN08T:
			"Late Payments may Hurt Your Credit Score. Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact a Discount Title Loan representative immediately and discuss how to handle late payments.",
		textN01F: "About Us",
		textN02F:
			"Discount Finance & Online Personal Loans is a consumer finance company that originates and services small traditional installment loans, providing Texas consumers access to unsecured short-term credit at the most competitive market rates.",
		textN03F: "Our mission is to provide safer and more affordable loans. Quickly.",
		textN04F:
			"At Discount Finance & Online Personal Loans customers get all kinds of free stuff! Free credit score reports, free automatic payments and free instant funding! Never pay a check cashing fee again!!!",
		textN05F:
			"Core to the Discount Finance & Online Personal Loans business model is our proprietary Loan Optimizer Software, an underwriting tool that helps customers select their ideal loan size and payment terms. Discount Finance & Online Personal Loans offers 3 to 12 month personal loans in the $60-$1,420 range without any collateral requirement. Collateralized loans up to $10,000 are available through our referral loan network.",
		textN06F: "Are you ready to save some money? Give us a chance to let us earn your business. You've got nothing to lose, and a lot to save!",
		textN07F:
			"Get Money Fast. Our online loan platform readily serves your need to connect with a quality personal loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy for us to approve. Once you submit your information you will be able to review the terms of the car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN08F:
			"Amortized Personal Loans (traditional installment loans) are the way to go! The best loans we make are the ones customers can finish paying off on time and that´s why at Discount Finance & Online Personal Loans we exclusively offer amortized personal loans (traditional installment loans) to our customers. Quit rolling over those expensive interest only loans and get a roadmap out of debt today!",
		textN09F:
			"Credit checks. By submitting your information via this website, you are authorizing Discount Finance & Online Personal Loans  to initiate a credit check, which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize Discount Finance & Online Personal Loans  to share your information and credit history with our third party lenders.",
		textN10F:
			"Lender’s Disclosure of Terms. Our third party lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law.  You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN11F:
			"Late Payments may Hurt Your Credit Score. Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact a Discount Finance & Online Personal Loans  representative immediately and discuss how to handle late payments.",
	},
	aplication: {
		textN01: "Personal Loan",
		textN02: "Title Loan",
		textN03: "VIN numbers do not include 'i' or 'o'. Try using 1 or 0",
		textN04: "Loan Type Missing",
		textN05: "Please select the loan type you are interested in",
		textN06: "We were not able to find your VIN Number. Try finding your vehicle information manually",
		textN07: "Try finding your vehicle information manually",
		textN08: "We were not able to find your vehicle",
		textN09: "Your city does not look accurate",
		textN10: "Your Social Security Number does not look accurate",
		textN11: "Sorry for the inconvenience",
		textN12: "We are currently not serving your area on-line, but you can always try applying for a personal loan",
		textN13: "Which loan type interests you the most?",
		textN14t: "PERSONAL",
		textN15t: "CAR TITLE",
		textN14: "LOAN",
		textN15: "LOAN",
		textN16:
			"It only takes a few minutes to complete the brief questionnaire and get a certified loan offer. Filling out this application will not affect your credit score.",
		textN17: "Your Finances",
		textN18: "People living in your household",
		textN19: "5 or More",
		textN20: "How do you get paid?",
		textN21: "Cash",
		textN22: "Check",
		textN23: "Direct Deposit",
		textN24: "How do you get paid?",
		textN25: "Your Vehicle",
		textN26: "MUST BE OWNED WITH A CLEAR TITLE",
		textN27: "Enter Your VIN NUMBER",
		textN28: "Enter your VIN (Vehicle ID Number)",
		textN29:
			"We need an accurate VIN to give you the actual value of our car. Once we have your VIN, we will be able to provide your vertified ready offer.",
		textN30: "Enter Your Vehicle's Mileage",
		textN31: "Enter Vehicle Reliability (Select One)",
		textN32: "Very Reliable",
		textN33: "Somewhat Reliable",
		textN34: "Not Very Reliable",
		textN35: "Enter Vehicle Condition (Select One)",
		textN36: "Looks Great",
		textN37: "Looks OK",
		textN38: "Not Super Pretty",
		textN39: "Your Home",
		textN40: "HOW LONG HAVE YOU LIVED HERE?",
		textN41: "Less than 1 year",
		textN42: "Between than 1 and 2 years",
		textN43: "Over 2 years",
		textN44: "IS YOUR MAILING ADDRESS DIFFERENT?",
		textN45: "Yes",
		textN46: "No",
		textN47: "Mailing Address",
		textN48: "Enter your Mailing Address",
		textN49: "City",
		textN50: "Enter your City",
		textN51: "State",
		textN52: "Zip",
		textN53: "Enter your Zip Code",
		textN54: "Your Credit History",
		textN55: "Enter your social security number",
		textN56: "Enter your Social Security Number",
		textN57: "By entering your SSN you are agreeing to allow a discount-loans.com referred lender to run a free",
		textN58: "soft credit",
		textN59: "check.",
		textN60: "This won't affect your credit score",
		textN61: "Complete Next Step (2 of 3)",
		textN62: "Number of People living in your household",
		textN63: "$ Monthly Rent or Mortgage Amount",
		textN64: "Monthly rent or mortgage amount",
		textN65: "All Monthly Debt Payments (Minimums)",
		textN66: "All monthly debt payments",
		textN67: "Enter the sum of all your Monthly Debt Payments such as other loans, credit cards, furniture and car payments.",
		textN68: "Change Vehicle",
		textN68b: "Choose Vehicle",
		textN69: "Vehicle's Reliability",
		textN70: "Vehicle's Condition",
		textN71: "Home Address",
		textN72: "Enter your Home Address",
		textN73: "City",
		textN74: "Enter your City",
		textN75: "State",
		textN76: "Select your State",
		textN77: "Zip",
		textN78: "Enter your Zip Code",
		textN79: "This type of credit check won't affect your FICO Score.",
		textN80: "1. Application",
		textN81: "Step 1 of 3",
		textN82: "Monthly Income",
		textN83: "Enter the Gross Monthly Income before you pay any taxes.",
		textN84: "Additional income from household members",
		textN85: "Enter the Monthly Income from other household members like Spouse",
		textN86: "Marital Status",
		textN87: "Unmarried",
		textN88: "Married",
		textN89: "Separated",
	},
	credit: {
		textN01: "We were not able to find your VIN Number. Try finding your vehicle information manually",
		textN02: "We were not able to find your vehicle",
		textN03: "Try finding your vehicle information manually",
		textN04: "We were not able to find your vehicle",
		textN05: "One option that can significantly increase your credit limit is signing up for ",
		textN06: "Automatic Payments",
		textN07: ". All you need is a debit card to set up your monthly payments.",
		textN08: "Your Credit Limit",
		textN09: "Do you want to significantly increase your credit limit by signing up for Automatic Payments?",
		textN10: "NO",
		textN11: "YES",
		textN12: "Another option that can",
		textN13: "significantly increase",
		textN14: "your credit limit is signing up for a",
		textN15: "Title Boost",
		textN16: "and collateralizing your loan with a clear car title.",
		textN17: "Your Vehicle",
		textN18: "WOULD YOU LIKE TO INCREASE YOUR CREDIT LIMIT WITH A TITLE BOOST?",
		textN19: "Change Vehicle",
		textN20: "Enter Your VIN NUMBER",
		textN21: "Enter your VIN (Vehicle ID Number)",
		textN22:
			"We need an accurate VIN to give you the actual value of our car. Once we have your VIN, we will be able to provide your vertified ready offer.",
		textN23: "Enter Your Vehicle's Mileage",
		textN24: "Enter your Vehicle's Mileage",
		textN25: "Enter Vehicle Reliability (Select One)",
		textN26: "Very Reliable",
		textN27: "Somewhat Reliable",
		textN28: "Not Very Reliable",
		textN29: "Vehicle's Reliability",
		textN30: "Vehicle's Condition",
		textN31: "Here are some additional questions that could increase your credit limit.",
		textN32: "Are you a first time customer of ours?",
		textN33: "Can you provide proof of car liability insurance?",
		textN34: "Complete next step (3 of 3)",
		textN35: "Enter Vehicle Condition (Select One)",
		textN36: "Looks Great",
		textN37: "Looks OK",
		textN38: "Not Super Pretty",
		textN39: "2. Credit Limit",
		textN40: "Step 2 of 3",
		textN41: "Do you have a mortgage in your name?",
		textN42: "Employment",
		textN43: "Do you still work at ",
	},
	login: {
		textN01: "Your password is too weak",
		textN02: "Your passwords do not match",
		textN03: "We are sorry",
		textN04: "Our loan products are currently not available to active military members",
		textN05: "We will need to send you a verification email to reset your password",
		textN06: "Please enter you email",
		textN07: "You should receive an email with steps to reset your password",
		textN08: "Welcome Back",
		textN09: "Don't have an account?",
		textN10: "Sign up instead",
		textN11: "Create an account to get your custom loan offer",
		textN12: "Already have an account?",
		textN13: "Sign in instead",
		textN14: "Email",
		textN15: "Password",
		textN16: "Forgot Password?",
		textN17: "First Name",
		textN18: "Last Name",
		textN19: "Cell Phone",
		textN20: "Are you an active member of the military?",
		textN21: "Yes",
		textN22: "No",
		textN23: "Email Address (will be your login ID)",
		textN24: "Password",
		textN25: "Confirm Password",
		textN26: "8+ Characters",
		textN27: "1 lowercase letter",
		textN28: "1 uppercase letter",
		textN29: "1 number",
		textN30:
			"By clicking Next Step, you agree to our Terms of Service and Privacy Policy and you authorize Discount-Loans.com referral lenders, to place calls and texts for telemarketing purposes to the telephone numbers provided in the application.",
		textN31: "Send Email",
		textN32: "Next Step",
		textN33: "Log In",
		textN34: "Log In with Facebook",
		textN35: "Log In with Google",
		textN36:
			"By clicking Next Step, you agree to our Terms of Service and Privacy Policy and you authorize dtloans.com referral lenders, to place calls and texts for telemarketing purposes to the telephone numbers provided in the application.",
		textN37:
			"By clicking Next Step, you agree to our Terms of Service and Privacy Policy and you authorize discount-finance.com/ referral lenders, to place calls and texts for telemarketing purposes to the telephone numbers provided in the application.",
	},
	profile: {
		textN01: "Your password is too weak or does not match",
		textN02: "Please confirm your change",
		textN03: "Enter your current password",
		textN04: "Update Password",
		textN05: "Please enter your new email",
		textN06: "Change Email",
		textN07: "Please confirm your change",
		textN08: "Enter your current password",
		textN09: "Confirm Change",
		textN10: "Your Profile",
		textN11: "Welcome back",
		textN12: "Feel free to change your information anytime you need",
		textN13: "First Name",
		textN14: "Last Name",
		textN15: "Cell Phone",
		textN16: "How long have you had this number?",
		textN17: "Less than 2 years",
		textN18: "More than 2 years",
		textN19: "New Password",
		textN20: "Confirm Password",
		textN21: "Change my email address",
		textN22: "8+ Characters",
		textN23: "1 lowercase letter",
		textN24: "1 uppercase letter",
		textN25: "1 number",
		textN26: "Update Profile",
	},
	references: {
		textN01: "Looks like some phone numbers are incomplete",
		textN02: "Your references have been saved successfully",
		textN03: "Almost done!",
		textN04: "The last thing we need are references. ",
		textN05: "You may add family, friends, colleagues and neighbors",
		textN06: "Save",
		textN07: "First Reference",
		textN08: "Second Reference",
		textN09: "Third Reference",
		textN10: "Fourth Reference",
		textN10a: "Fifth Reference",
		textN11: "First Name *",
		textN12: "Last Name *",
		textN13: "Relation *",
		textN14: "Phone *",
		textN15: "Address*",
		textN16: "City*",
		textN17: "State*",
		textN18: "ZIP*",
	},
	status: {
		textN01: "We are sorry!",
		textN02: "Please schedule a time during normal bussiness hours.",
		textN03: "Monday - Friday 9am-6pm",
		textN04: "Saturday 10am-6pm",
		textN05: "APPLICATION STATUS",
		textN06: "Welcome to Discount Loans Online",
		textN07: "Looks like you do not have a pending application. Take a few minutes and get started.",
		textN08: "Log Out",
		textN09: "Start Now",
		textN10: "Thank for submitting your information,",
		textN11: "let's see if you qualify for some discounts.",
		textN12: "Find Discounts",
		textN13: "Thank for submitting your information,",
		textN14: "let's see if we can increase your credit limit.",
		textN15: "Increase Credit",
		textN16: "We are almost done. Let's continue.",
		textN17: "Upload Documents",
		textN18: "We have received your uploaded documents and will try our best to get your loan approved as soon as possible during normal business hours:",
		textN19: "Monday - Friday 9am-6pm",
		textN20: "Saturday 10am-6pm",
		textN21: "We strive to approve most loans in less than 2 hours.",
		textN22: "Your loan has been approved!",
		textN23:
			"The final step is getting any information that wasn't completed on the application, reviewing your contract over the phone (go over all required disclosures) and answering any questions that you might have.",
		textN24: "Please be prepared to provide five references",
		fillReferences: "Fill out References",
		textN25: "When are you available to talk?",
		textN26: "Schedule Appointment",
		textN27: "Call Us Now",
		textN28: "Thank you for creating an appointment.",
		textN29: "You will be called: ",
		textN30: "Confirm / Exit",
		textN31: "Change Date",
		textN32: "Call Us Now",
		textN33: "We are almost done with the process.",
		textN34: "Please provide 5 references to continue",
		textN35: "Continue",
		textN36: "Congratulations",
		textN37: "Your loan will be funded shortly.",
		textN38: "Thank your choosing Discount Loans Online.",
		textN39: "Welcome to Discount Loans Online",
		textN40: "Looks like your application expired. Take a few minutes and get started.",
		textN41: "Log Out",
		textN42: "Start Now",
		textN44: "You have a certified ready offer.",
		textN45: "The last step is to sign the agreement",
		textN46: "We won't take much of your time.",
		textN47: "See Agreement",
		textN48: "Leave Google Review",
	},
	titleLoanOffer: {
		textN01: "Enter your ideal monthly payment size",
		textN02: "We are not able to process your loan at this time. Our loan specialist will contact you soon.",
		textN03: "Modify Application",
		textN04: "The payment you requested is too low.",
		textN05: "Please choose another or reset it.",
		textN06: "Reset Payment",
		textN07: "Select Higher Payment",
		textN08: "Congratulations",
		textN09: "YOU HAVE A",
		textN10: "CERTIFIED READY",
		textN11: "OFFER UP TO",
		textN12: "YOU HAVE",
		textN13: "QUALIFIED FOR",
		textN14: "DISCOUNTS",
		textN15:
			"Discount Title Loan recommends the 6 month amortized option because it will give you plenty of time to pay back the loan and because every payment decreases the principal balance (which lowers your fees & interest).",
		textN16: "The fee to record a title lien varies by county, but will not exceed $33, and will be added to your final loan offer.",
		textN17: "Displaying a payment of",
		textN18: "Reset Monthly Payment",
		textN19: "Month",
		textN20: "Payment",
		textN21: "Principal",
		textN22: "Fees & Interest",
		textN23: "Your monthly payments (with discounts) could be as low as",
		textN24: "and there's no penalty if you pay it off sooner.",
		textN25: "APR Disclosure",
		textN26: "The amortization table above is for illustrative purposes only. ",
		textN27: "It does not include the standard $28 title lien fee and it assumes that you will earn your discounted rate every month by paying on time. ",
		textN28:
			"Once your application is approved by the lender, you will be provided a complete TILA disclosure along with all the facts associated with the loan. ",
		textN29: "It will include the undiscounted APR (so you can see what the fees would be if you didn’t earn the discounted rate every month),",
		textN30: "all associated loan finance charges and all of the terms you will be agreeing to. ",
		textN31: "Make sure that you read all the information provided to you by the lender to ensure that you make an informed decision ",
		textN32: "prior to signing the final contract.",
		textN33: "APR Disclosure",
		textN34: "Lower payment size",
		textN35: "Talk to an Expert",
		textN36: "Complete next Step (3 of 3)",
		textN37: "Your certified ready offer is a reasonable estimate based on the information you provided but is not guaranteed.",
		textN38:
			"Having a certified-ready offer means you may be approved for a loan with any of our participating lenders, subject to verifying your identity,",
		textN39:
			"and confirming the information on the documentation you provide to us. You can have a certified-ready offer and still be turned down for a loan.",
		textN40:
			"If you receive a certified-ready offer, and you continue the process, our lenders will complete your application over the phone and ask for additional information,",
		textN41: "such as references, employment information, social security number and debit card information.",
		textN42: "We are Sorry!",
		textN43: "Title Loan Offer",
		textN44: "Valid thru",
	},
	resetpassword: {
		textN01: "You should receive an email with steps to reset your password",
		textN02: "There is not an account with this email",
		textN03: "Success!",
		textN04: "Your password has been changed",
		textN05: "Don't worry. We are here to help.",
		textN06: "Please type in your new password",
		textN07: "We need to send you a verification email to reset your password.",
		textN08: "New Password",
		textN09: "Email",
	},
	documents: {
		textN01: "Please upload a Government Issued Photo ID",
		textN02: "Please upload a Proof of residence",
		textN03: "Please take a selfie holding your Photo ID",
		textN04: "Please upload a Proof of income",
		textN05: "Please upload two pictures of the vehicle title. Front and back",
		textN06:
			"Please upload eight pictures of the vehicle. Odometer, front, right side, left side, back, interior front seats, interior back seats and VIN number",
		textN07: "Please upload a Government Issued Photo ID",
		textN08: "Please upload a Proof of residence",
		textN09: "Please upload pictures of the vehicle's title",
		textN10: "Please upload pictures of the vehicle",
		textN11: "Please upload two pictures of the vehicle title. Front and back",
		textN12:
			"Please upload eight pictures of the vehicle. Odometer, front, right side, left side, back, interior front seats, interior back seats and VIN number",
		textN13: "Upload a few documents to confirm the information you have entered and ensure that you earn your discounts",
		textN14: "Upload a few documents to confirm the information you have entered and ensure that you maximize your credit limit",
		textN15: "Your Photos",
		textN16: "Government Issued Photo ID",
		textN17: "Please upload a photo ID",
		textN18: "for each owner listed on the title",
		textN19: "Proof of Residence",
		textN20: "Utility Bill, Bank Statement, Mortgage, etc.",
		textN21: "Proof of Income",
		textN22: "Please upload a check or recent bank statement",
		textN23: "2 Vehicle Title Pictures",
		textN24: "Please upload front and back side",
		textN25: "Registration",
		textN26: "Please upload photos of sticker on the vehicle or registration receipt",
		textN27: "8 Vehicle Pictures",
		textN28:
			"Please upload photos of: odometer, exterior of vehicle (front, back, left side & right side), 2 pictures of the interior of the vehicle (front seats & back seats) and VIN number (located on side door sticker or metal plate on dashboard)",
		textN29: "Vehicle Title",
		textN30: "Please upload front and back side. This is optional but can get you at least $500 more",
		textN31: "8 Vehicle Pictures",
		textN32:
			"Please upload photos of: odometer, exterior of vehicle (front, back, left side & right side), 2 pictures of the interior of the vehicle (front seats & back seats) and VIN number (located on side door sticker or metal plate on dashboard)",
		textN33: "Selfie Verification",
		textN34: "Take a selfie picture of yourself holding your ID",
		textN35: "Make sure both the ID and your face are clearly visible",
		textN36: "Your ID should be the same that you are using for Identity Identification.",
		textN37: "Discounts",
		textN38: "Credit",
		textN39: "mortgage bill, utilities bill and proof of income",
		textN40: "additional documents requested",
		textN41: "Submit Documents",
		textN42: "Please upload ",
		textN43: "3. Upload Photos",
		textN44: "Step 3 of 3",
		textN45: "(Optional)",
		textN46: "Additional",
		bankStatement: {
			title: "Bank Statement",
			description: "Please upload a recent bank statement",
		},
	},
	apiJs: {
		textN01: "You have been",
		textN02: "APPROVED",
		textN03: "to get a pre-certified car title loan offer from Discount Loans!",
		textN04: "All we need is a little more information to get you a pre-certified offer right now.",
		textN05:
			"If you like our offer all you'll need to do is upload a few pictures, review the loan contract with a customer service rep and get funded today.",
	},
	howItWorks: {
		textN01T: "How it works",
		textN02T:
			"We understand that life is full of unexpected challenges, many of which can only be overcome if you have access to fast cash. In an ideal world, you could drop by your neighborhood bank, talk to Roger your family’s lifelong banker, fill out a couple of forms and walk out with the money you need, at a super low interest rate…but that’s just not the way it works these days. Neighborhood banks are closing, no one knows their bankers anymore, the paperwork for bank loans is endless, their credit checks are unrealistic and you’ll never leave a bank with cash in hand (unless you find it on the floor).",
		textN03T:
			"This is why Discount Car Title Loan specializes in car title loans. Your car title can become a quick source of income, and at Discount Car Title Loan your short term loan can be safer, more affordable and fast.",
		textN04T:
			"Getting a Car Title Loan with Discount Car Title Loan is painless. The entire process takes less than 30 minutes.  And the best part is, you get the cash you need and can afford while continuing to use your vehicle. Customers have the option of getting their certified offer and funding 100% on-line or visiting their nearest Discount Car Title Loan location and get your cash there. You can also start the process on-line and finish the process at a store- you decide WHATEVER is easiest for you!",
		textN05T: "1.-Apply Today",
		textN05Ta:
			"Apply online or stop by our closest Discount Car Title Loan store. Make sure you have: a lien-free car title and a government-issued ID, such as a driver’s license.",
		textN05Tb: "2.-Get Approved in 5 Minutes",
		textN05Tc:
			'A Discount Car Title Loan loan advisor will help you figure out how much cash you can get, how much you can realistically afford, calculate your Discounts and recommend the product that saves you the most money. You also have the option of getting "optimized" with our on-line tool',
		textN05Td: "3.-Get Your Cash",
		textN05Te: "Discount Car Title Loan gets you safer and more affordable cash quickly.",
		textN05Tf: "4.-Get Your Car Title Back",
		textN05Tg:
			"Once you’ve paid back your car title loan, you have the option of getting your title back or you can leave your title on file with us to avoid having to pay the $28 TxDMV lien fee on future car title loans. ",
		textN06T: "THE REQUIREMENTS: What do I need to be approved for a cash loan with Discount Car Title Loan?",
		textN06Ta:
			"The process of getting a Discount Car Title Loan is pretty straight forward. After you give us some basic information about your finances and your vehicle, you and our highly trained customer service representative will decide on the loan amount that’s best for you and the product that saves you the most money, you take your cash and go along with your day. Your car stays with you. You get to keep driving your vehicle throughout the entire duration of your loan. Make sure to bring the required items as listed below to your local Discount Car Title Loan location and let us help you get the cash you need and can afford, in 30 minutes or less.",
		textN06Tb: "Texas Car Title Loan Requirements:",
		textN06Tc:
			"In the state of Texas, you must be at least 18 years old to get a car title loan and must present a government-issued ID, like a driver’s license. The only other item you need in order to be approved for a Discount Car Title Loan Texas car title loan is a clear, lien-free car title. When you bring those two items into your local Discount Car Title Loan store (or upload them if you use our online platform), it will take us less than 30 minutes to get you the money you need and can afford.",
		textN07T:
			"THE DISCOUNTS: DISCOUNT CAR TITLE LOAN offers many short term loan discounts that can further lower your loan costs when you pay on time. Find out which discounts you qualify for today. They could easily add up to 50% in savings!",
		textN08T:
			"Need help figuring out how many of our car title loan discounts apply to you? We're here to help. Contact us and one of our loan professionals will explain all of the car title loan discounts and other savings you'll enjoy with Discount Car Title Loan.",
		textN09T:
			"THE LOAN OPTIMIZER: Shopping around for short term loans can be very time consuming and a bit confusing. Once you start comparing legal requirements, origination fees, interest percentages, and different term lengths, it's not always easy to figure out how to choose the best short term loan. Fortunately, Discount Car Title Loan has developed high tech financial tools like the Loan Optimizer that does all the work for you. All you have to do is answer some basic questions about your car and your finances and the Loan Optimizer Software recommends the product that saves you the most money. Amazing! The best thing about it is that it takes less than 5 minutes to complete and it could save you hundreds of dollars this year.",
		textN01F: "How it works",
		textN02F:
			"We understand that life is full of unexpected challenges, many of which can only be overcome if you have access to fast cash. In an ideal world, you could drop by your neighborhood bank, talk to Roger your family’s lifelong banker, fill out a couple of forms and walk out with the money you need, at a super low interest rate…but that’s just not the way it works these days. Neighborhood banks are closing, no one knows their bankers anymore, the paperwork for bank loans is endless, their credit checks are unrealistic and you’ll never leave a bank with cash in hand (unless you find it on the floor).",
		textN03F:
			"This is why Discount Finance & Online Personal Loans specializes in personal loans. Your credit worthiness can become a quick source of income, and at Discount Finance & Online Personal Loans your short term loan can be safer, more affordable and fast.",
		textN04F:
			"Getting a personal loan online with Discount Finance & Online Personal Loans is painless. The entire process takes less than 30 minutes.   Customers have the option of getting their certified offer and funding 100% on-line or visiting their nearest Discount Finance & Online Personal Loans location and get your cash there. You can also start the process on-line and finish the process at a store- you decide WHATEVER is easiest for you!",
		textN05F: "1.-Apply Today",
		textN05Fa:
			"Apply online or stop by our closest Discount Finance & Online Personal Loans store. Make sure you have: proof of income and a government-issued ID, such as a driver’s license.",
		textN06F: "2.-Get Approved in 5 Minutes",
		textN06Fa:
			'A Discount Finance & Online Personal Loans loan advisor will help you figure out how much cash you can get, how much you can realistically afford, calculate your Discounts and recommend the product that saves you the most money. You also have the option of getting "optimized" with our on-line tool',
		textN07F: "3.-Get Your Cash",
		textN07Fa: "Discount Finance & Online Personal Loans gets you safer and more affordable cash quickly.",
		textN08F: "THE REQUIREMENTS: What do I need to be approved for a cash loan with Discount Finance & Online Personal Loans?",
		textN08Fa:
			"The process of getting a DDiscount Finance & Online Personal Loans is pretty straight forward. After you give us some basic information about you and your finances you and our highly trained customer service representative will decide on the loan amount that’s best for you and the product that saves you the most money, you take your cash and go along with your day. Make sure to bring the required items as listed below to your local Discount Finance & Online Personal Loans location and let us help you get the cash you need and can afford, in 30 minutes or less.",
		textN09F: "Texas Personal Loan Requirements:",
		textN09Fa:
			"In the state of Texas, you must be at least 18 years old to get a personal loan and must present a government-issued ID, like a driver’s license. The only other item you need in order to be approved at Discount Finance & Online Personal Loans is a proof of income. When you bring those two items into your local Discount Finance & Online Personal Loans store (or upload them if you use our online platform), it will take us less than 30 minutes to get you the money you need and can afford.",
		textN10F:
			"THE FREE STUFF: Discount Finance & Online Personal Loans offers the most competitive personal loan rates and lots of freebies. Our customers get free credit scores, free automatic payments and free instant funding. Never pay a check cashing fee again!",
		textN11F:
			"Need help figuring out how much money you can get? We're here to help. Contact us and one of our loan professionals will help address your questions.",
		textN12F:
			"THE LOAN OPTIMIZER: Shopping around for short term loans can be very time consuming and a bit confusing. Once you start comparing legal requirements, origination fees, interest percentages, and different term lengths, it's not always easy to figure out how to choose the best short term loan. Fortunately, Discount Finance & Online Personal Loans has developed high tech financial tools like the Loan Optimizer that does all the work for you. All you have to do is answer some basic questions about you and your finances and the Loan Optimizer Software recommends the product that saves you the most money. Amazing! The best thing about it is that it takes less than 5 minutes to complete and it could save you hundreds of dollars this year.",
	},
	scheduleOfAllFees: {
		textN01T: "Schedule of All Fees",
		textN02T:
			"Schedule of all fees to be charged for services performed by the Credit Access Business (CAB) in connection with motor vehicle title loans, as applicable in the following examples:",
		textN03T: "Auto Title 6 Month Single Payment Disclosure",
		textN04T: "Auto Title 4 Month Installment Disclosure",
		textN05T: "Auto Title 5 Month Installment Disclosure",
		textN06T: "Auto Title 6 Month Installment Disclosure",
		textN01F: "About Us",
		textN02F:
			"What we do. Discount Loans Online is not a lender and does not make loan or credit decisions. We connect interested persons with lenders from our approved personal loan and car title loan network. We do not control and are not responsible for the actions or inactions of any lender, we not an agent, representative or broker of any lender. Our referral service is always free to you.",
		textN03F:
			"On-line Marketplace. Our goal is to connect you with an approved lender from our personal loan and car title loan lender network. We have made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN04F:
			"Get Money Fast. Our online loan referral service readily serves your need to connect with a quality personal loan or car title loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy to an authorized lender to approve. Once you submit your information, you will be redirected to the lender's website where you can review the terms of the personal loan or car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN05F:
			"Credit checks for personal loans. By submitting your information via this website, you are authorizing discount loans and our referral lenders to initiate a credit check (for personal loans only), which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize discount loans to share your information and credit history with a network of approved lenders.",
		textN06F:
			"Lender’s Disclosure of Terms. The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law. We have no knowledge of or control over the loan terms offered by a lender. You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN07F:
			"Late Payments may Hurt Your Credit Score. Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
	},
	occcNotice: {
		textN01T: "OCCC Notice",
		textN02T:
			"This business is licensed and examined under Texas law by the Office of Consumer Credit Commissioner (OCCC), a state agency. If a complaint or question cannot be resolved by contacting the business, consumers can contact the OCCC to file a complaint or ask a general credit-related question. OCCC address: 2601 N. Lamar Blvd., Austin, Texas 78705. Phone: (800) 538-1579. Fax: (512) 936-7610. Website: occc.texas.gov. Email: consumer.complaints@occc.texas.gov",
		textN03T: "Notice:",
		textN04T:
			"An advance of money obtained through a payday loan or auto title loan is not intended to meet long-term financial needs. A payday loan or auto title loan should only be used to meet immediate short-term cash needs. Refinancing the loan rather than paying the debt in full when due will require the payment of additional charges.",
		textN05T: "Notice to Texas Residents:",
		textN06T:
			"In the fee schedule provided, and in many of our Texas stores, Vin-Title, LLC (d/b/a Discount Title Loan) operates as a registered Credit Access Business (CAB). The actual Lender is an unaffiliated third party.",
		textN01F: "About Us",
		textN02F:
			"What we do. Discount Loans Online is not a lender and does not make loan or credit decisions. We connect interested persons with lenders from our approved personal loan and car title loan network. We do not control and are not responsible for the actions or inactions of any lender, we not an agent, representative or broker of any lender. Our referral service is always free to you.",
		textN03F:
			"On-line Marketplace. Our goal is to connect you with an approved lender from our personal loan and car title loan lender network. We have made the process simple. No need to visit hundreds of websites and fill out numerous paperwork.",
		textN04F:
			"Get Money Fast. Our online loan referral service readily serves your need to connect with a quality personal loan or car title loan. The process takes a few minutes. Fill out our secure online loan questionaire and we will send an encrypted copy to an authorized lender to approve. Once you submit your information, you will be redirected to the lender's website where you can review the terms of the personal loan or car title loan, including details about all the applicable rates and fees. If you accept these terms, the lender may deposit money directly into your bank account, in many cases, as soon as today!",
		textN05F:
			"Credit checks for personal loans. By submitting your information via this website, you are authorizing discount loans and our referral lenders to initiate a credit check (for personal loans only), which may include verifying your social security number, driver license number or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Teletrack, DP Bureau or others. You also authorize discount loans to share your information and credit history with a network of approved lenders.",
		textN06F:
			"Lender’s Disclosure of Terms. The lenders will provide documents that contain all fees and rate information pertaining to the loan being offered, including any potential fees for late-payments and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender based on the lender’s internal policies, underwriting criteria and applicable law. We have no knowledge of or control over the loan terms offered by a lender. You are urged to read and understand the terms of any loan offered by any lender and to reject any particular loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.",
		textN07F:
			"Late Payments may Hurt Your Credit Score. Please be aware that missing a payment or making a late payment can negatively impact your credit score (for personal loans). To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss how to handle late payments.",
	},
	locations: {
		textN01: "Contact Information",
		textN02: "Address",
		textN03: "Phone",
		textN04: "Email",
		textN05: "Store Hours",
		textN06: "Locations",
		textN07:
			"Discount Car Title Loan is a consumer finance company that originates and services car title loans, providing Texas consumers access to secured short-term credit at the most competitive market rates.",
		textN07F:
			"Discount Finance is a consumer finance company that originates and services personal loans, providing Texas consumers access to unsecured short-term credit at the most competitive market rates.",
		textN08: "Monday - Friday:",
		textN09: "Saturday:",
	},
	carrers: {
		textN01: "Careers",
		textN02: "coming soon",
	},
};

export default en;
