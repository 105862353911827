import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

firebase.initializeApp({
	apiKey: "AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk",
	authDomain: "digital-loans.firebaseapp.com",
	databaseURL: "https://digital-loans.firebaseio.com",
	projectId: "digital-loans",
	storageBucket: "digital-loans.appspot.com",
	messagingSenderId: "691893147223",
});

const auth = firebase.auth;
const firestore = firebase.firestore;
const storage = firebase.storage;

export { auth, firestore, storage };
