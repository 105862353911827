import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateLoan } from "./redux/actions/updateLoan";
import { updatePixels } from "./redux/actions/pixels";
import { updateStore } from "./redux/actions/store";
import { changeLang } from "./redux/actions/lang";
import { referral } from "./redux/actions/referral";
import { Helmet } from "react-helmet";

import Homepage from "./pages/homepage";
import Faq from "./pages/faq";
import FaqTitle from "./pages/title/faq";
import FaqPersonal from "./pages/personal/faq";
import About from "./pages/about-us";
import Howitworks from "./pages/how-it-works";
import ScheduleOfAllFees from "./pages/schedule-of-all-fees";
import OcccNotice from "./pages/occc-notice";
import Markets from "./pages/markets";
import Contact from "./pages/contact";
import Careers from "./pages/careers";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import SMSTerms from "./pages/sms-terms";

import Start from "./pages/start";
import Profile from "./pages/profile";
import Application from "./pages/application";
import Discounts from "./pages/application/title/discounts";
import Credit from "./pages/application/personal/credit";
import PersonalOffer from "./pages/application/personal/offer";
import TitleOffer from "./pages/titleLoanOffer";

import Documents from "./pages/documents";
import References from "./pages/references";
import Agreement from "./pages/agreement";
import Login from "./pages/login";
import ResetPassword from "./pages/resetpassword";
import Status from "./pages/status";

import Welcome from "./pages/welcome";

import Locations from "./pages/locations";

import Location from "./pages/locations/location";

import "./styles/fonts.css";
import "./styles/loading.css";
import "./styles/quickstart.css";
import "./styles/start.css";
import "./styles/icons.css";
import "./styles/animations.css";
import "./styles/antd.css";
import "./App.css";

import en from "./localization/en";
import es from "./localization/es";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		es: {
			translation: es,
		},
	},
	lng: "en",
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

function selectMessages(lang) {
	if (lang === "es") {
		return es;
	}
	if (lang === "en") {
		return en;
	}
	return en;
}

function Store(props) {
	return <Location {...props} />;
}

class MainApp extends Component {
	constructor(props) {
		super();
		i18next.changeLanguage(props.lang);
	}
	render() {
		return (
			<Switch>
				{process.env.REACT_APP_TYPE === "all" ? <Route path={"/"} exact render={props => <Homepage {...props} {...this.props} />} /> : null}
				{process.env.REACT_APP_TYPE === "title" ? (
					<Route exact path="/">
						{<Redirect to="/home" />}
					</Route>
				) : null}
				{process.env.REACT_APP_TYPE === "finance" ? (
					<Route exact path="/">
						{<Redirect to="/home" />}
					</Route>
				) : null}
				<Route path={"/faq"} exact render={props => <Faq {...props} {...this.props} />} />
				<Route path={"/about-us"} exact render={props => <About {...props} {...this.props} />} />
				<Route path={"/how-it-works"} exact render={props => <Howitworks {...props} {...this.props} />} />
				<Route path={"/markets"} exact render={props => <Markets {...props} {...this.props} />} />
				<Route path={"/contact"} exact render={props => <Contact {...props} {...this.props} />} />
				<Route path={"/start"} exact render={props => <Start {...props} {...this.props} />} />
				<Route path={"/profile"} exact render={props => <Profile {...props} {...this.props} />} />
				<Route path={"/application"} exact render={props => <Application {...props} {...this.props} />} />
				<Route path={"/privacy"} exact render={props => <Privacy {...props} {...this.props} />} />
				<Route path={"/terms"} exact render={props => <Terms {...props} {...this.props} />} />
				<Route path={"/sms-terms"} exact render={props => <SMSTerms {...props} {...this.props} />} />
				<Route
					path={"/discounts"}
					exact
					render={props =>
						this.props.loan.type === "Personal Loan" ? <Credit {...props} {...this.props} /> : <Discounts {...props} {...this.props} />
					}
				/>
				<Route
					path={"/credit"}
					exact
					render={props =>
						this.props.loan.type === "Personal Loan" ? <Credit {...props} {...this.props} /> : <Discounts {...props} {...this.props} />
					}
				/>
				<Route
					path={"/offer"}
					exact
					render={props =>
						this.props.loan.type === "Personal Loan" ? <PersonalOffer {...props} {...this.props} /> : <TitleOffer {...props} {...this.props} />
					}
				/>
				<Route path={"/documents"} exact render={props => <Documents {...props} {...this.props} />} />
				<Route path={"/references"} exact render={props => <References {...props} {...this.props} />} />
				<Route path={"/agreement"} exact render={props => <Agreement {...props} {...this.props} />} />
				<Route path={"/login"} exact render={props => <Login {...props} {...this.props} />} />
				<Route path={"/reset-password"} exact render={props => <ResetPassword {...props} {...this.props} />} />
				<Route path={"/status"} exact render={props => <Status {...props} {...this.props} />} />
				<Route path={"/welcome"} exact render={props => <Welcome {...props} {...this.props} />} />
				<Route path={"/scheduleOfAllFees"} component={props => <ScheduleOfAllFees {...props} {...this.props} />} />
				<Route path={"/occcNotice"} component={props => <OcccNotice {...props} {...this.props} />} />
				<Route path={"/careers"} exact render={props => <Careers {...props} {...this.props} />} />

				<Route path={"/home"} exact render={props => <Homepage {...props} {...this.props} />} />
				{process.env.REACT_APP_TYPE === "title" ? <Route path={"/home/faq"} exact render={props => <FaqTitle {...props} {...this.props} />} /> : null}
				{process.env.REACT_APP_TYPE === "finance" ? (
					<Route path={"/home/faq"} exact render={props => <FaqPersonal {...props} {...this.props} />} />
				) : null}
				<Route path={"/home/aboutus"} exact render={props => <About {...props} {...this.props} />} />
				<Route path={"/home/howitworks"} exact render={props => <Howitworks {...props} {...this.props} />} />
				<Route path={"/home/locations"} exact render={props => <Locations {...props} {...this.props} />} />
				<Route path={"/home/mcallen-10th-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store01" />} />
				<Route path={"/home/mcallen-pecan-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store02" />} />
				<Route path={"/home/mcallen-nolana-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store03" />} />
				<Route path={"/home/weslaco-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store04" />} />
				<Route path={"/home/pharr-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store05" />} />
				<Route path={"/home/harlingen-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store06" />} />
				<Route path={"/home/edinburg-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store07" />} />
				<Route path={"/home/mission-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store08" />} />
				<Route path={"/home/brownsville-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store09" />} />
				<Route path={"/home/alamo-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store10" />} />
				<Route path={"/home/laredo-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store11" />} />
				<Route path={"/home/laredo-guadalupe-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store11" />} />
				<Route path={"/home/laredo-santa-ursula-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store12" />} />
				<Route path={"/home/corpus-ayers-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store13" />} />
				<Route path={"/home/corpus-south-padre-island-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store14" />} />
				<Route path={"/home/sanantonio-tx-title-loans"} component={props => <Store {...props} {...this.props} store="store15" />} />
				<Route path={"/home/riogrande-title-loans"} component={props => <Store {...props} {...this.props} store="store16" />} />
				<Route path={"/home/eagle-pass"} component={props => <Store {...props} {...this.props} store="store17" />} />
				<Route path={"/home/beeville"} component={props => <Store {...props} {...this.props} store="store18" />} />
				<Route path={"/home/mission"} component={props => <Store {...props} {...this.props} store="store19" />} />
				<Route path={"/home/brownsville"} component={props => <Store {...props} {...this.props} store="store20" />} />
				<Route path={"/home/mcallen"} component={props => <Store {...props} {...this.props} store="store21" />} />
				<Route path={"/home/sanantonio"} component={props => <Store {...props} {...this.props} store="store22" />} />
				<Route path={"/home/houston"} component={props => <Store {...props} {...this.props} store="store19t" />} />
				<Route path={"/home/houston-bellair"} component={props => <Store {...props} {...this.props} store="store20" />} />
			</Switch>
		);
	}
}

const mapStateToProps = state => ({
	uid: state.uid,
	loan: state.loan,
	referral: state.referral,
	blackbook: state.blackbook,
	pixels: state.pixels,
	store: state.store,
	lang: state.lang,
});

const mapActionsToProps = {
	onUpdateLoan: updateLoan,
	onUpdatePixels: updatePixels,
	onUpdateReferral: referral,
	onUpdateStore: updateStore,
	onChangeLang: changeLang,
};

class Wrapper extends Component {
	render() {
		return (
			<BrowserRouter>
				{process.env.REACT_APP_TYPE === "all" ? (
					<Helmet>
						{/*<meta charSet="utf-8" />*/}
						<title>
							Discount Loans Online - How to Get an Online Personal Loan or Car Title Loan from your Phone in less than 30 minutes. Now serving
							Texas, Mcallen, Weslaco, Pharr, Harlingen, Brownsville, Alamo, Edinburg, Mission, Corpus Christi, Laredo, San Antonio, Rio Grande
							City, Eagle Pass Discount Loans en Línea - Como sacar un Prestamo Personal en Línea o un Prestamo contra el Titulo de Auto desde tu
							celular en menos de 30 minutos. Ahora tenemos servicio en Texas, Mcallen, Weslaco, Pharr, Harlingen, Brownsville, Alamo, Edinburg,
							Mission, Corpus Christi, Laredo, San Antonio, Rio Grande City, Eagle Pass
						</title>
						<link rel="shortcut icon" href="iconAll/favicon.ico" />
						<link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="iconAll/favicon.ico" />
						<link rel="apple-touch-icon" sizes="152x152" href="iconAll/favicon-152-precomposed.png" />
						<link rel="apple-touch-icon" sizes="144x144" href="iconAll/favicon-144-precomposed.png" />
						<link rel="apple-touch-icon" sizes="120x120" href="iconAll/favicon-120-precomposed.png" />
						<link rel="apple-touch-icon" sizes="114x114" href="iconAll/favicon-114-precomposed.png" />
						<link rel="apple-touch-icon" sizes="180x180" href="iconAll/favicon-180-precomposed.png" />
						<link rel="apple-touch-icon" sizes="72x72" href="iconAll/favicon-72-precomposed.png" />
						<link rel="apple-touch-icon" href="iconAll/favicon-57.png" />
						<link rel="icon" href="iconAll/favicon-32.png" sizes="32x32" />
						<meta name="msapplication-TileColor" content="#FFFFFF" />
						<meta name="msapplication-TileImage" content="iconAll/favicon-144.png" />
						<meta name="theme-color" content="#ffffff" />
						<link rel="manifest" href="manifest.json" />
						<link rel="icon" sizes="192x192" href="iconAll/favicon-192.png" />
					</Helmet>
				) : null}
				{process.env.REACT_APP_TYPE === "title" ? (
					<Helmet>
						{/*<meta charSet="utf-8" />*/}
						<title>
							Discount Title Loan Online - Locations in Texas Mcallen, Weslaco, Pharr, Harlingen,Brownsville,Alamo, Edinburg, Mission, Corpus
							Christi, San Antonio
						</title>
						<link rel="shortcut icon" href="iconTitle/favicon.ico" />
						<link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="iconTitle/favicon.ico" />
						<link rel="apple-touch-icon" sizes="152x152" href="iconTitle/favicon-152-precomposed.png" />
						<link rel="apple-touch-icon" sizes="144x144" href="iconTitle/favicon-144-precomposed.png" />
						<link rel="apple-touch-icon" sizes="120x120" href="iconTitle/favicon-120-precomposed.png" />
						<link rel="apple-touch-icon" sizes="114x114" href="iconTitle/favicon-114-precomposed.png" />
						<link rel="apple-touch-icon" sizes="180x180" href="iconTitle/favicon-180-precomposed.png" />
						<link rel="apple-touch-icon" sizes="72x72" href="iconTitle/favicon-72-precomposed.png" />
						<link rel="apple-touch-icon" href="iconTitle/favicon-57.png" />
						<link rel="icon" href="iconTitle/favicon-32.png" sizes="32x32" />
						<meta name="msapplication-TileColor" content="#FFFFFF" />
						<meta name="msapplication-TileImage" content="iconTitle/favicon-144.png" />
						<meta name="theme-color" content="#ffffff" />
						<link rel="manifest" href="manifest.json" />
						<link rel="icon" sizes="192x192" href="iconTitle/favicon-192.png" />
					</Helmet>
				) : null}
				{process.env.REACT_APP_TYPE === "finance" ? (
					<Helmet>
						{/*<meta charSet="utf-8" />*/}
						<title>
							Discount Finance &amp; Personal Loan Company - How to Get a Safer, More Affordable Traditional Installment Loan in Mcallen, Weslaco,
							Pharr, Harlingen, Edinburg, Mission TX
						</title>
						<link rel="shortcut icon" href="iconPersonal/favicon.ico" />
						<link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="iconPersonal/favicon.ico" />
						<link rel="apple-touch-icon" sizes="152x152" href="iconPersonal/favicon-152-precomposed.png" />
						<link rel="apple-touch-icon" sizes="144x144" href="iconPersonal/favicon-144-precomposed.png" />
						<link rel="apple-touch-icon" sizes="120x120" href="iconPersonal/favicon-120-precomposed.png" />
						<link rel="apple-touch-icon" sizes="114x114" href="iconPersonal/favicon-114-precomposed.png" />
						<link rel="apple-touch-icon" sizes="180x180" href="iconPersonal/favicon-180-precomposed.png" />
						<link rel="apple-touch-icon" sizes="72x72" href="iconPersonal/favicon-72-precomposed.png" />
						<link rel="apple-touch-icon" href="iconPersonal/favicon-57.png" />
						<link rel="icon" href="iconPersonal/favicon-32.png" sizes="32x32" />
						<meta name="msapplication-TileColor" content="#FFFFFF" />
						<meta name="msapplication-TileImage" content="iconPersonal/favicon-144.png" />
						<meta name="theme-color" content="#ffffff" />
						<link rel="manifest" href="manifest.json" />
						<link rel="icon" sizes="192x192" href="iconPersonal/favicon-192.png" />
					</Helmet>
				) : null}
				<MainApp messages={selectMessages(this.props.lang)} {...this.props} />
			</BrowserRouter>
		);
	}
}

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);
