const faq = [
	{
		question: "How do I set up automatic payments?",
		answer:
			"Automatic payments are the easiest (and free!) way to make your loan payments on time. Enroll today by calling Discount Car Title Loan Online (dtloans.com). You will need your bank or credit union account and routing number (see below) for the one-time setup.",
	},
	{
		question: "How can I pay my personal loan or car title loan?",
		answer: "Customers can always make payments at any Discount Car Title Loan Online (dtloans.com) physical locations in person or via debit card.",
	},
	{
		question: "Can I cancel my loan after being approved?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) will allow applicants to cancel their loans within 3 days of receiving their advance. If the original principal is paid before the 3-day grace period, you won’t usually be liable for any interest charges.",
	},
	{
		question: "How long do I have to repay my personal loan or car title loan?",
		answer:
			"Personal loans and car title loan terms and due dates vary by state. Discount Car Title Loan Online (dtloans.com) will provide you with this information after you have been fully approved for a personal loan or a car title loan.",
	},
	{
		question: "Is there a prepayment penalty?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) does not charge prepayment penalties. A prepayment penalty is a fee that is accessed when you pay your loan before its due date.",
	},
	{
		question: "What happens if I am late with my payment?",
		answer:
			"If you are late with your personal loan or car title loan payment, you may be charged additional fees. The number of fees will vary by state and lender. In addition to being charged a fee, there may be other consequences if the loan remains unpaid. Some lenders may choose to report your delinquency to all or one of the three credit bureaus, submit your information to a collection service, or seize your vehicle and sell it to a third party.",
	},
	{
		question: "How do I get my title back? How long does it take?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) will return the vehicle title to you once you have finished paying off your title loan in full. If you complete your title loan with a lender that has a physical location, you will usually receive your title back immediately. If your title loan was completed online, it might take a few weeks to receive your title back in the mail.",
	},
	{
		question: "Can someone else make a loan payment for me?",
		answer:
			"Yes. However, the privacy laws limit what information lenders can share about the loan. Therefore, if you’d like someone to make a payment on your behalf, please either provide Discount Car Title Loan Online (dtloans.com) authorization to transact with that person on your loan, or make sure they have the following information in order to make a payment: the name and telephone number on the loan and the payment amount you would like to make.",
	},
];

export default faq;
