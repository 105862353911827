const faq = [
	{
		question: "¿Cómo puedo solicitar un préstamo personal o un préstamo sobre el título del vehículo?",
		answer:
			"Solicitar un préstamo personal o un préstamo sobre el título de un automóvil a través de Discount-Loans.com es rápido y fácil. Si desea presentar una solicitud en una tienda o por teléfono, nuestros prestamistas estarán encantados de guiarlo a través del proceso. También puede usar nuestra solicitud de préstamo en línea, a la que se puede acceder desde una computadora de escritorio, tableta o dispositivo móvil.",
	},
	{
		question: "¿Cómo puedo solicitar un préstamo?",
		answer:
			"Nuestros Los prestamistas de Discount-Loans.com ofrecen préstamos personales (préstamos a plazos) y préstamos de título de automóvil carro que oscilan entre $ 125 y $ 10,000. El monto máximo que puede pedir prestado depende de varios factores, incluidos sus ingresos, deudas existentes e historial de pagos anteriores.",
	},
	{
		question: "¿Cuánto tiempo tendré que pagar mi préstamo?",
		answer:
			"El plazo del préstamo personal o de un título de automóvil depende principalmente del tamaño del préstamo, la tasa de interés, los honorarios y cuánto creemos que puede pagar por mes. Los prestamistas de Discount-Loans.com ofrecen plazos de 3 a 18 meses; los préstamos más pequeños generalmente tienen plazos más cortos y los préstamos más grandes tienen plazos más largos.",
	},
	{
		question: "¿A quién puedo contactar si tengo preguntas sobre mi préstamo?",
		answer:
			"Si tiene preguntas sobre su préstamo personal o un préstamo sobre el título de su vehículo, puede comunicarse directamente con el prestamista o visitar una de sus oficinas más cercanas.",
	},
	{
		question: "¿Cuál es la diferencia entre un préstamo personal y un préstamo sobre el título del automóvil?",
		answer:
			"Un préstamo de título es un tipo de préstamo a corto plazo que utiliza el título de su vehículo como garantía. Es una opción de préstamo popular para los que necesitan efectivo rápido pero no califican para los medios tradicionales de financiamiento. A diferencia de los préstamos bancarios, calificar para un préstamo de título es simple y no requiere una verificación de crédito. Lo mejor de todo es que puede continuar usando su automóvil mientras el préstamo está pendiente. Un préstamo personal, también conocido como préstamo a plazos, es una opción de préstamo popular para los que desean establecer un historial crediticio. No se requiere garantía para préstamos personales y los términos del préstamo pueden ser mayores a 6 meses.",
	},
	{
		question: "¿Qué significa tener un título de automóvil libre de gravámenes?",
		answer:
			"Un título de automóvil libre de gravámenes es un título sin gravámenes. Un derecho de retención es un reclamo legal sobre una propiedad por parte de un acreedor. Tener un título de automóvil vehiculolibre de gravámenes significa que usted es dueño de su vehículo directamente sin la amenaza de recuperación o incautación.",
	},
	{
		question: "¿Puedo conducir mi automóvil con un préstamo sobre el título del automóvil o un préstamo personal respaldado por el título del automóvil?",
		answer: "Sí, definitivamente puede conducir su automóvil durante el curso de su préstamo sobre el título.",
	},
];

export default faq;
