const faq = [
	{
		question: "How can I build my credit with a loan referred through dtloans.com?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) personal loan lenders have the ability to report accounts to TransUnion, which is a major credit reporting agency. Personal loans are typically reported and Title loans are usually not reported by our lenders. When payments are reported, they will be reflected positively in your credit report if you make payments on or before your due date and repay your loan in full. Generally, it takes at least 6 months of positive payment reporting to create a credit score, although this may vary by credit reporting agency and score.",
	},
	{
		question: "How will my loan referred by dtloans.com be reported to the credit reporting agencies?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) personal loan products report accounts, including timely payments, incidents of delinquency, and the payoff of a loan, to TransUnion, a major credit reporting agency, every month.",
	},
	{
		question: "If I have bad credit, can I still get a loan through a Discount Car Title Loan Online (dtloans.com) lender?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) work with all types of credit! Discount Car Title Loan Online (dtloans.com) minimum requirements to obtain a loan are as follows: you are at least 18 years old, must have a steady source of recurring income payments, must reside in the state you choose for your loan application and have no active bankruptcies or presently intend to voluntarily file for bankruptcy relief. After filling out a loan application, which can be started here, you will get your personalized loan terms directly from the lender you select. Your loan agreement will show you the monthly payment, your interest and fees, your discounted rate, and your APR.",
	},
	{
		question: "Can I get a loan if I am on active military duty?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) currently does not service active military. Consumers may not not be a regular or reserve member of the Army, Navy, Marine Corps, Air Force, or Coast Guard, serving on active duty under a call or order that does not specify a period of 30 days or fewer or a dependent of a member of the Armed Forces on active duty.",
	},
];

export default faq;
