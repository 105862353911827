const faq = [
	{
		question: "¿Cómo puedo construir mi crédito con un préstamo referido a través de Discount-Loans.com?",
		answer:
			"Nuestros prestamistas de Discount-Loans.com de préstamos personales tienen la capacidad de reportar cuentas a TransUnion, que es una importante buro de crédito. Los préstamos personales generalmente si se reportan y los prestamistas de préstamos de título generalmente no reportan al buro de credito. Cuando se reportan los pagos, se reflejarán positivamente en su informe de crédito si realiza pagos en la fecha de vencimiento o antes y paga el préstamo en su totalidad. En general, se necesitan al menos 6 meses de informes de pagos positivos para crear un puntaje de crédito, aunque esto puede variar según la agencia y el puntaje del informe de crédito.",
	},
	{
		question: "¿Cómo se informará reportara mi préstamo referido por Discount-Loans.com a las agencias de informes del buró de crédito?",
		answer:
			"Nuestros prestamistas de préstamos personales reportan cuentas, incluyendo pagos oportunos, incidentes de morosidad y el pago de un préstamo, a TransUnion, una importante buro de crédito, cada mes.",
	},
	{
		question: "Si tengo mal crédito, ¿puedo obtener un préstamo a través de un prestamista de Discount-Loans.com?",
		answer:
			"¡Los prestamistas de Discount-Loans.com trabajan con todo tipo de crédito! Los requisitos mínimos de nuestro prestamista para obtener un préstamo son los siguientes: tener al menos 18 años, debe tener una fuente constante de pagos de ingresos recurrentes, debe residir en el estado que elija para su solicitud de préstamo y no tener bancarrotas activas o tener la intención actual de presentarse voluntariamente para alivio de bancarrota. Después de completar una solicitud de préstamo, que puede iniciarse aquí, obtendrá los términos de su préstamo personalizado directamente del prestamista que seleccione. Su contrato de préstamo le mostrará el pago mensual, sus intereses y tarifas, su tasa de descuento y su APR.",
	},
	{
		question: "¿Puedo obtener un préstamo si estoy en servicio militar activo?",
		answer:
			"Los prestamistas de de Discount-Loans.com nuestra red actualmente no prestan servicios a militares activos. Los consumidores clientes no pueden ser un miembro regular o de reserva del Ejército, la Armada, el Cuerpo de Marines, la Fuerza Aérea o la Guardia Costera, en servicio activo bajo una llamada u orden que no especifica un período de 30 días o menos, o un dependiente de un miembro de las Fuerzas Armadas en servicio activo.",
	},
];

export default faq;
