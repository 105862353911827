import { REFERRAL } from "../actions/referral";

export default (state = null, { type, payload }) => {
	switch (type) {
		case REFERRAL:
			return payload;
		case "CLEAR_REFERRAL":
			return null;
		default:
			return state;
	}
};
