const faq = [
	{
		question: "¿Qué necesito para solicitar un préstamo personal o un préstamo sobre el título de un automóvil?",
		answer:
			"Discount Car Title Loan Online (dtloans.com) necesitará su información personal (nombre, teléfono, correo electrónico y código postal) y la información de su vehículo (marca, modelo, año y millaje estimado) y requerirá que tenga un gobierno válido identificación emitida, un título libre de gravamen y comprobante de ingresos.",
	},
	{
		question: "¿Necesito un trabajo para obtener la aprobación de un préstamo? ¿Qué sucede si estoy discapacitado o estoy jubilado?",
		answer:
			"No, no necesita ser empleado para obtener un préstamo personal o de título de automóvil. Sin embargo, necesita una fuente de ingresos regulares. Se aceptan otras formas de ingresos, tales como discapacidad, seguridad social, manutención infantil, pensión alimenticia, desempleo y trabajo por cuenta propia, siempre que pueda proporcionar pruebas.",
	},
	{
		question: "¿Puedo obtener un préstamo de título sin una cuenta bancaria?",
		answer:
			"Sí, puede calificar para un préstamo personal o un préstamo sobre el título del automóvil sin tener una cuenta bancaria. El préstamo de título de automóvil con descuento en línea (dtloans.com) solo requerirá una cuenta bancaria si elige recibir su dinero a través de ACH.",
	},
	{
		question: "¿Puedo obtener un préstamo personal o un préstamo sobre el título del automóvil después de la bancarrota?",
		answer:
			"Sí, aún puede calificar para un préstamo personal o un préstamo sobre el título del automóvil después de declararse en quiebra. A diferencia de las opciones de préstamos tradicionales, los préstamos de título no están limitados por su estado de bancarrota ya que los préstamos de título son préstamos basados en garantías que dependen del valor de su vehículo para garantizar un préstamo. En esencia, el valor de su automóvil es su solvencia. Los prestamistas con descuento en préstamos para títulos de automóviles en línea (dtloans.com) estarán más que felices de procesar su adelanto de título como cualquier otro solicitante.",
	},
	{
		question: "¿El título del auto debe estar a mi nombre? ¿Qué pasa si hay dos nombres?",
		answer:
			"Sí, el título del vehículo debe estar a nombre de la persona que solicita el préstamo. Si su título tiene su nombre junto con otra persona, deberá analizarlo con nosotros para determinar los procedimientos necesarios para obtener un préstamo de título en su estado. Por lo general, los títulos con dos nombres se aceptan siempre que ambos nombres estén en la solicitud de préstamo de título.",
	},
	{
		question: "¿Qué sucede si mi vehículo no está registrado en el estado en el que resido?",
		answer:
			"No hay problema. En la mayoría de los casos, Discount Car Title Loan Online (dtloans.com) aún puede procesar su solicitud incluso si su vehículo está registrado en un estado en el que no reside. Sin embargo, hay instrucciones especiales que deben seguirse caso por caso. Un representante de Discount Car Title Loan Online (dtloans.com) puede guiarlo a través de ellos.",
	},
];

export default faq;
