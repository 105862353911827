import React, { Component } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Copy from "../../components/copy";
import locationDataTitle from "../../data/locationDataTitle";
import locationDataFinance from "../../data/locationDataFinance";

export default class extends Component {
	render() {
		const store = this.props.store.toString();

		let storeCount = null;

		if (process.env.REACT_APP_TYPE === "title") {
			storeCount = locationDataTitle[store];
		} else if (process.env.REACT_APP_TYPE === "finance") {
			storeCount = locationDataFinance[store];
		}

		return (
			<div>
				<Menu {...this.props} />
				<section className="section-wrap ">
					<div className="container">
						<div className=" justificado">
							<br />
							<br />
							<h3 className="section-title">{storeCount.nameInt}</h3>
							<br />
							<br />
							<div className="row my-2 col-lg-12 ">
								<div className="col-12 col-md-7 mt-5 mt-md-0 ">
									<div className="title-row text-center">
										<h3
											className="section-title"
											style={{
												fontSize: 22,
												textAlign: "left",
											}}>
											{this.props.messages.locations.textN01}
										</h3>
										<span className="callWord" />
										<div className="row my-5 col-lg-12 ">
											<div className="col-md-4">
												<li className="benefits__item">
													<i
														className="fas fa-map-marked benefits__item-icon"
														style={{
															color: "#000",
														}}
													/>
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
															fontWeight: "bold",
														}}>
														{this.props.messages.locations.textN02}
													</span>
												</li>
											</div>
											<div className="my-1 col-md-8">
												<li className="benefits__item">
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
														}}>
														{storeCount.adderss}
													</span>
												</li>
											</div>
											<div className="row my-2 col-lg-12 "></div>
											<div className="my-1 col-md-4">
												<li className="benefits__item">
													<i
														className="fas fa-phone benefits__item-icon"
														style={{
															color: "#000",
														}}
													/>
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
															fontWeight: "bold",
														}}>
														{this.props.messages.locations.textN03}
													</span>
												</li>
											</div>
											<div className="col-md-8">
												<li className="benefits__item">
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
														}}>
														<a href={"tel:" + storeCount.phone}>{storeCount.phone}</a>
													</span>
												</li>
											</div>
											<div className="row my-2 col-lg-12 "></div>
											<div className="my-1 col-md-4">
												<li className="benefits__item">
													<i
														className="fa fa-mail-bulk benefits__item-icon"
														style={{
															color: "#000",
														}}
													/>
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
															fontWeight: "bold",
														}}>
														{this.props.messages.locations.textN04}
													</span>
												</li>
											</div>
											<div className="col-md-8">
												<li className="benefits__item">
													<span
														className="benefits__item-title"
														style={{
															color: "#009ACD",
															textAlign: "left",
														}}>
														<a href={"mailto:" + storeCount.email}>{storeCount.email}</a>
													</span>
												</li>
											</div>
											<div className="row my-2 col-lg-12 "></div>
											<div className="my-1 col-md-4">
												<li className="benefits__item">
													<i
														className="fa fa-clock benefits__item-icon"
														style={{
															color: "#000",
														}}
													/>
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
															fontWeight: "bold",
														}}>
														{this.props.messages.locations.textN05}
													</span>
												</li>
											</div>
											<div className="col-md-8">
												<li className="benefits__item">
													<span
														className="benefits__item-title"
														style={{
															color: "#000",
															textAlign: "left",
														}}>
														{storeCount.storeHoursT}
														<br />
														{storeCount.storeHoursB}
													</span>
												</li>
											</div>
										</div>
									</div>
								</div>

								{process.env.REACT_APP_TYPE === "title" ? (
									<div className="col-12 col-md-5 my-5 my-md-0">
										<br />
										<img src={require("../../assets/title/" + storeCount.image + ".jpg")} alt="" />
										<br />
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "finance" ? (
									<div className="col-12 col-md-5 my-5 my-md-0">
										<br />
										<img src={require("../../assets/finance/" + storeCount.image + ".jpg")} alt="" />
										<br />
									</div>
								) : null}

								<div className="col-md-12">
									<li className="benefits__item">
										<span
											className="benefits__item-title"
											style={{
												color: "#000",
												textAlign: "left",
												fontWeight: "bold",
											}}>
											{storeCount.aboutTitle}
										</span>
									</li>
								</div>
								<div className="col-md-12">
									<br />
									<span style={{ fontSize: 10 }}>{storeCount.aboutData}</span>
								</div>
								<div className="col-md-12">
									<li className="benefits__item">
										<span
											className="benefits__item-title"
											style={{
												color: "#000",
												textAlign: "left",
												fontWeight: "bold",
											}}>
											{storeCount.aboutTitleSecond}
										</span>
									</li>
								</div>
								<div className="col-md-12">
									<br />
									<span style={{ fontSize: 10 }}>{storeCount.aboutDataSecond}</span>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
				<Copy {...this.props} />
			</div>
		);
	}
}
