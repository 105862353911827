import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

function CallToAction() {
	const { t } = useTranslation();
	return (
		<div className="nav__btn-holder nav--align-right">
			{process.env.REACT_APP_TYPE === "all" ? (
				<HashLink to={"/application"} className="btn nav__btn">
					<span className="nav__btn-phone">{t("menu.textN24")}</span>
				</HashLink>
			) : null}
			{process.env.REACT_APP_TYPE !== "all" ? (
				<HashLink to={"/home/locations"} className="btn nav__btn">
					<span className="nav__btn-phone">{t("menu.textN25")}</span>
				</HashLink>
			) : null}
		</div>
	);
}

export default CallToAction;
