import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import storage from "redux-persist/lib/storage";
import axios from "axios";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers, createStore } from "redux";

// Redux Reducers
import authReducer from "../redux/reducers/auth";
import loanReducer from "../redux/reducers/loan";
import blackbookReducer from "../redux/reducers/blackbook";
import referralReducer from "../redux/reducers/referral";
import pixelsReducer from "../redux/reducers/pixels";
import storeReducer from "../redux/reducers/store";
import langReducer from "../redux/reducers/lang";

// Redux Actions
import { updateUser } from "../redux/actions/updateUser";
import { updateLoan } from "../redux/actions/updateLoan";
import { updateStore } from "../redux/actions/store";
import { blackbook } from "../redux/actions/blackbook";

// Redux-Persist Config
const persistedReducer = persistReducer(
	{
		key: "root",
		storage,
	},
	combineReducers({
		uid: authReducer,
		loan: loanReducer,
		blackbook: blackbookReducer,
		referral: referralReducer,
		pixels: pixelsReducer,
		store: storeReducer,
		lang: langReducer,
	})
);
const defaultState = {
	uid: null,
	loan: {
		status: 0,
		type: "Title Loan",
		term: "sixMonths",
	},
};

const store = createStore(persistedReducer, defaultState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const persistor = persistStore(store);

// Auth Checker
firebase.auth().onAuthStateChanged(user => {
	if (user && process.env.REACT_APP_USER) {
		return firebase
			.firestore()
			.doc("applications/" + process.env.REACT_APP_USER)
			.get()
			.then(res => {
				if (res.exists) {
					store.dispatch(updateUser(process.env.REACT_APP_USER));
					store.dispatch(updateStore(res.data().store));
					return store.dispatch(
						updateLoan({
							status: res.data().status,
							type: res.data().loanType,
							vehicle: res.data().hasVehicle,
							term: res.data().term ? res.data().term : "sixMonths",
						})
					);
				} else {
					return store.dispatch(updateUser(user.uid));
				}
			});
	} else if (user) {
		return firebase
			.firestore()
			.doc("applications/" + user.uid)
			.get()
			.then(res => {
				if (res.exists) {
					store.dispatch(updateUser(user.uid));
					store.dispatch(updateStore(res.data().store));
					return store.dispatch(
						updateLoan({
							status: res.data().status,
							type: res.data().loanType,
							vehicle: res.data().hasVehicle,
							term: res.data().term ? res.data().term : "sixMonths",
						})
					);
				} else {
					return store.dispatch(updateUser(user.uid));
				}
			});
	} else {
		store.dispatch(updateUser(null));
		return store.dispatch(
			updateLoan({
				status: 0,
				type: "Title Loan",
				term: "sixMonths",
			})
		);
	}
});

// Blackbook
axios
	.get("https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/Drilldown/ALL?drilldeep=true&getclass=false&customerid=website", {
		headers: {
			Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
		},
	})
	.then(res => {
		return store.dispatch(blackbook(res.data["drilldown"]["class_list"][0]));
	})
	.catch(() => {
		return null;
	});

export default () => {
	return { store, persistor };
};
