const faq = [
	{
		question: "My certified ready loan offer is too low. What are my options??",
		answer:
			"Your certified ready loan offer is based on the information you entered into the loan application. Loan offers are based on your credit history, yearly income, appraisal of the vehicle and other factors. Some options to increase your personal loan or car title loan amount include: 1. Do you have a different vehicle (with a higher value) for the lender to consider as collateral? Discount Car Title Loan Online (dtloans.com) makes credit decisions primarily on “ability to pay” underwriting. However, sometimes what you can afford exceeds the value of your collateral. In other words, the lender might increase the loan amount if the vehicle was worth more (or if you had a different vehicle with a higher valuation). 2. Does someone else work in your household? Or, since yearly income is a factor, do you have any other verifiable income you can add to your loan application? If so, make sure to update that information. 3. Is there something on your credit report (other that your credit score) which might be a red flag to a lender that you have a good explanation for? Everyone goes through rough patches in life. A temporary loss of a job, loss of a loved one, a divorce, incarceration, etc.. Making the lender aware of any of these “life happens” situations may help increase your recommended loan amount.",
	},
	{
		question: "My certified ready loan offer payments seem too high. What are my options?",
		answer:
			"Your certified ready loan offer is the maximum loan size your personal loan or car title loan lender has determined that you can afford. However, if the payment size is higher than what you feel comfortable with, you have the option of modifying the payment size downwards and the on-line loan optimizer will adjust your loan size accordingly. You could also talk to the lender directly about additional discounts that you might qualify for which would help decease your monthly payments as well.",
	},
];

export default faq;
