const faq = [
	{
		question: "Mi oferta de préstamo pre-certificado es demasiado baja. ¿¿Cuáles son mis opciones??",
		answer:
			"Su oferta de préstamo pre-certificado se basa en la información que ingresó en la solicitud de préstamo. Las ofertas de préstamos se basan en su historial de crédito, ingresos anuales, valor del vehículo y otros factores. Algunas opciones para aumentar su préstamo personal o el monto del préstamo sobre el título del automóvil incluyen: 1. ¿Tiene un vehículo diferente (con un valor más alto) para que el prestamista lo considere como garantía? Nuestros prestamistas toman decisiones de crédito principalmente sobre su capacidad de pago. Sin embargo, a veces lo que puede pagar excede el valor de su garantía. En otras palabras, el prestamista podría aumentar el monto del préstamo si el vehículo valía más (o si tuviera un vehículo diferente con una valoración más alta). 2. ¿Alguien más trabaja en su hogar? O, dado que el ingreso anual es un factor, ¿tiene algún otro ingreso verificable que pueda agregar a su solicitud de préstamo? Si es así, asegúrese de actualizar esa información. 3. ¿Hay algo en su informe de crédito (aparte de su puntaje de crédito) que podría ser una señal de alerta para un prestamista para el que tiene una buena explicación? Todos pasan por momentos difíciles en la vida. La pérdida temporal de un trabajo, la pérdida de un ser querido, el divorcio, el encarcelamiento, etc. Al informar al prestamista de cualquiera de estas situaciones es posible aumentar el monto de su préstamo recomendado.",
	},
	{
		question: "Los pagos de mi oferta de préstamo pre-certificado parecen demasiado altos. ¿Cuáles son mis opciones?",
		answer:
			"Su oferta de préstamo pre-certificado es el monto máximo de préstamo que su prestamista personal o prestamista de título de automóvil ha determinado que puede pagar. Sin embargo, si el tamaño del pago es más alto de lo que se siente cómodo, tiene la opción de modificar el tamaño del pago hacia abajo y el optimizador de préstamos en línea ajustará el tamaño de su préstamo en consecuencia. También puede hablar directamente con el prestamista sobre descuentos adicionales para los que podría calificar, lo que también ayudaría a disminuir sus pagos mensuales.",
	},
];

export default faq;
