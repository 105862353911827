const ranges = [
	{
		min: 0,
		max: 500,
		value: 1.25,
	},
	{
		min: 500,
		max: 750,
		value: 0.83,
	},
	{
		min: 750,
		max: 1000,
		value: 0.75,
	},
	{
		min: 1000,
		max: 1250,
		value: 0.69,
	},
	{
		min: 1250,
		max: 1500,
		value: 0.65,
	},
	{
		min: 1500,
		max: 1750,
		value: 0.63,
	},
	{
		min: 1750,
		max: 2000,
		value: 0.57,
	},
	{
		min: 2000,
		max: 2250,
		value: 0.55,
	},
	{
		min: 2250,
		max: 2500,
		value: 0.54,
	},
	{
		min: 2500,
		max: 2750,
		value: 0.52,
	},
	{
		min: 2750,
		max: 3500,
		value: 0.49,
	},
	{
		min: 3500,
		max: 4000,
		value: 0.44,
	},
	{
		min: 4000,
		max: 5000,
		value: 0.42,
	},
	{
		min: 5000,
		max: 6000,
		value: 0.4,
	},
	{
		min: 6000,
		max: 7000,
		value: 0.37,
	},
	{
		min: 7000,
		max: 9000,
		value: 0.33,
	},
	{
		min: 9000,
		max: 100000000,
		value: 0.28,
	},
];

export default ranges;
