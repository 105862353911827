const faq = [
	{
		question: "¿Qué tan rápido es el proceso de solicitud?",
		answer:
			"El proceso de solicitud en Discount Finance & Online Personal Loans generalmente demora de 10 a 15 minutos en completarse. Si puede proporcionarnos toda la documentación correcta, se puede tomar una decisión el mismo día de la solicitud.",
	},
	{
		question:
			"¿Puedo solicitar un préstamo de Discount Finance & Online Personal Loans si vivo fuera de un mercado seleccionado (San Antonio MSA, Laredo MSA, Corpus Christi MSA, Rio Grande Valley MSA?",
		answer:
			"Posiblemente. Discount Finance & Online Personal Loans está expandiendo sus áreas de préstamo rápidamente. El mapa de cobertura de préstamos para títulos de automóviles con descuento en línea se actualiza con frecuencia.",
	},
	{
		question: "¿Puedo cambiar el monto que estoy pidiendo prestado?",
		answer:
			"Sí, en Discount Finance & Online Personal Loans puede pedir prestado un monto hasta el préstamo personal o el monto del préstamo sobre el título del automóvil para el cual ha sido aprobado. Puede cambiar el monto que está pidiendo prestado desde el momento en que se le notifica su aprobación hasta que se le emite el préstamo.",
	},
	{
		question: "¿Cuánto serán mis pagos?",
		answer:
			"Los montos de los pagos se basan en el tamaño del préstamo, la tasa de interés, las tarifas y el plazo del préstamo. Los prestamistas con descuento en préstamos para títulos de automóviles en línea calculan un monto de pago que creen que puede pagar en función de sus ingresos y deudas / gastos. También tiene la capacidad de cambiar el monto y / o plazo del préstamo, dentro de ciertos parámetros, antes de que se desembolse el préstamo si desea tener un pago más bajo. Los pagos se realizan en cuotas fijas, lo que significa que no cambian durante la vigencia del préstamo (excepto el último pago, que puede ser mayor o menor dependiendo de si realizó sus pagos a tiempo).",
	},
	{
		question: "¿Cómo sé que los documentos que he enviado se están procesando?",
		answer:
			"Puede llamar o visitar cualquier tienda minorista para presentar la documentación requerida (prueba de identidad, ingresos y residencia). También puede enviar sus documentos usted mismo a través de la solicitud en línea de Discount Finance & Online Personal Loans, y recibirá un aviso de confirmación por teléfono o correo electrónico. En cualquier caso, uno de nuestros representantes del cliente le notificará sobre el estado de aprobación de su préstamo poco después de la presentación de su solicitud.",
	},
	{
		question: "¿Cuál es el estado de mi solicitud de préstamo personal o de título de automóvil?",
		answer:
			"Puede estar seguro de que los agentes experimentados y conocedores de Discount Finance & Online Personal Loans están procesando su solicitud lo más rápido posible. Si tiene consultas específicas, puede visitar o comunicarse con una de las ubicaciones de Discount Finance & Online Personal Loans. Los agentes estarán encantados de ayudarlo.",
	},
	{
		question: "¿Cómo obtengo mi préstamo personal o los fondos del préstamo sobre el título del auto?",
		answer:
			"Puede obtener los ingresos de su préstamo, ya sea en forma de un cheque físico, (en una de las tiendas minoristas del prestamista de Discount Finance & Online Personal Loans) o mediante un cheque electrónico que se le puede enviar por correo electrónico. Todo lo que necesita es la capacidad de imprimir ese cheque electrónico en papel normal y cobrarlo en cualquier banco o centro de cambio de cheques.",
	},
	{
		question: "¿Los prestamistas inspeccionan los vehículos antes de aprobar un préstamo de título?",
		answer:
			"Discount Finance & Online Personal Loans basará la evaluación de su vehículo en su buena fe de que el automóvil está funcionando y que su condición está representada con precisión por las imágenes que le envía a su prestamista.",
	},
	{
		question: "¿Sus prestamistas ofrecen préstamos de título para personas con mal crédito?",
		answer:
			"Sí, Discount Finance & Online Personal Loans regularmente tiene solicitantes con mal crédito o sin crédito. Entendemos que las personas cometen errores y creemos que los puntajes FICO bajos no deberían evitar que las personas reciban ayuda cuando más la necesitan.",
	},
	{
		question: "¿Cuándo recibiré mi dinero?",
		answer:
			"Los agentes de préstamos personales y préstamos de título de automóvil con descuento en línea (dtloans.com) utilizan una variedad de métodos para obtener su dinero rápidamente. Dependiendo de cómo elija recibir su dinero, puede tomar desde unos minutos hasta algunos días hábiles. Si decide aceptar un cheque electrónico, recibirá su dinero en minutos.",
	},
	{
		question: "¿Qué pasa si ya tengo un préstamo de título con otra compañía?",
		answer:
			"Eso está perfectamente bien con los representantes de clientes de préstamos personales y préstamos para títulos de automóviles con descuento en línea de préstamos para títulos de automóviles. Discount Finance & Online Personal Loans puede conectarlo con un agente que puede refinanciar su préstamo existente y ahorrarle dinero. Refinanciar un préstamo personal o de título significa encontrar un nuevo prestamista que pagará la cantidad que le debe a un prestamista anterior. El saldo del antiguo préstamo personal o préstamo sobre el título del automóvil se transferirá a un nuevo préstamo sobre el título del automóvil con menor interés.",
	},
	{
		question: "¿Cómo funciona el proceso de préstamo de título de auto de Storefront?",
		answer:
			"Los prestamistas en la red de Discount Finance & Online Personal Loans como Discount Finance & Online Personal Loans hacen que el proceso de préstamo de título sea simple, y solo toma unos minutos obtener el efectivo que necesita. Esto es lo que debe hacer si prefiere obtener su préstamo en una tienda.",
	},
	{
		question: "¿Cómo funciona el proceso del préstamo personal amortizado (préstamo a plazos tradicional)?",
		answer:
			"Los prestamistas en la red Discount Finance & Online Personal Loans, hacen que el proceso de obtener un préstamo personal amortizado (préstamo a plazos tradicional) sea simple, y solo toma unos minutos obtener el efectivo que necesita. Esto es lo que debe hacer si prefiere obtener su préstamo en una tienda. 1. Complete una solicitud en línea o en una oficina local de Discount Finance & Online Personal Loans 2. Discount Finance & Online Personal Loans determinará el monto de una transacción en función de sus necesidades, su historial crediticio y lo que puede pagar de manera realista. 3. Una vez aprobado, recibirá el efectivo que necesita ese mismo día.",
	},
];

export default faq;
