import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";

import example1 from "../assets/disclosures/1.jpg";
import example4 from "../assets/disclosures/4.jpg";
import example5 from "../assets/disclosures/5.jpg";
import example6 from "../assets/disclosures/6.jpg";

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						{process.env.REACT_APP_TYPE === "title" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.scheduleOfAllFees.textN01T}</h3>
								<br />
								<p>
									{this.props.messages.scheduleOfAllFees.textN02T}
									<br />
									<br />
									<div className={"row"}>
										<div className={"col-12 col-md-6"}>
											<img src={example1} alt={"Example 1"} />
										</div>
										<div className={"col-12 col-md-6"}>
											<img src={example4} alt={"Example 4"} />
										</div>
										<div className={"col-12 col-md-6"}>
											<img src={example5} alt={"Example 5"} />
										</div>
										<div className={"col-12 col-md-6"}>
											<img src={example6} alt={"Example 6"} />
										</div>
									</div>
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
						{process.env.REACT_APP_TYPE === "finance" ? (
							<div className="container">
								<h3 className="section-title">{this.props.messages.scheduleOfAllFees.textN01T}</h3>
								<br />
								<p>
									{this.props.messages.scheduleOfAllFees.textN02T}
									<br />
									<br />
									<a href="https://dtloans.com/singlepayment.pdf">{this.props.messages.scheduleOfAllFees.textN03T}</a>
									<br />
									<br />
									<a href="https://dtloans.com/4month.pdf">{this.props.messages.scheduleOfAllFees.textN04T}</a>
									<br />
									<br />
									<a href="https://dtloans.com/5month.pdf">{this.props.messages.scheduleOfAllFees.textN05T}</a>
									<br />
									<br />
									<a href="https://dtloans.com/6month.pdf">{this.props.messages.scheduleOfAllFees.textN06T}</a>
								</p>
								<br />
								<br />
								<br />
							</div>
						) : null}
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up"></i>
						</a>
					</div>
				</main>
			</div>
		);
	}
}
