import React, { Component } from "react";
import { Link } from "react-router-dom";
import UUID from "uuid/v4";
import Menu from "../components/menu";
import Header from "../components/header";
import QuickStart from "../components/quickstart";
import Footer from "../components/footer";
import queryString from "query-string";
import Copy from "../components/copy";

import time from "../assets/time.png";
import phone from "../assets/phone.png";
import phoneTitle from "../assets/phoneTitle.png";
import phonePersonal from "../assets/phonePersonal.png";
import money from "../assets/money.png";

export default class extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		const referral = queryString.parse(this.props.location.search).r;
		const referralID = queryString.parse(this.props.location.search).ref;
		const referrerID = queryString.parse(this.props.location.search).u;
		const referralUUID = queryString.parse(this.props.location.search).uuid;
		if (referral) {
			this.props.onUpdateReferral({
				type: "referral",
				payload: referral,
			});
		}
		if (referralID) {
			this.props.onUpdateReferral({
				type: "referral",
				payload: "AUTO/" + referralID + "/" + UUID(),
			});
		}

		if (referrerID) {
			this.props.onUpdateReferral({
				type: "referral",
				payload: {
					source: "C2B",
					referrerID,
					referralUUID,
				},
			});
		}
	}
	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu home {...this.props} />
					<Header />
					<div id="applyNow" />
					<QuickStart history={this.props.history} {...this.props} />
					<div className="container">
						<div className="row">
							<div className="col-12 col-md-6 text-center pb-5 pb-md-0">
								{process.env.REACT_APP_TYPE === "all" ? <img src={phone} style={{ height: "260px" }} alt="Welcome!" /> : null}
								{process.env.REACT_APP_TYPE === "title" ? <img src={phoneTitle} style={{ height: "260px" }} alt="Welcome!" /> : null}
								{process.env.REACT_APP_TYPE === "finance" ? <img src={phonePersonal} style={{ height: "260px" }} alt="Welcome!" /> : null}

								{process.env.REACT_APP_TYPE === "all" ? (
									<div className="pt-0">
										<h2>
											{this.props.messages.homepage.title01}
											<br />
										</h2>
										<h5>{this.props.messages.homepage.title02}</h5>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "title" ? (
									<div className="pt-0">
										<h2>
											{this.props.messages.homepageOter.title01}
											<br />
										</h2>
										<h5 style={{ color: "#009ACD" }}>{this.props.messages.homepageOter.title02}</h5>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "finance" ? (
									<div className="pt-0">
										<h2>
											{this.props.messages.homepageOter.title01}
											<br />
										</h2>
										<h5 style={{ color: "#009ACD" }}>{this.props.messages.homepageOter.title02F}</h5>
									</div>
								) : null}

								{process.env.REACT_APP_TYPE === "all" ? (
									<div className="my-4 px-3 px-md-5 pl-0 pl-sm-0 pl-lg-5">
										<ul className="benefits__list pl-0 pl-sm-0 pl-lg-5">
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepage.text01}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepage.text02}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepage.text03}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#009ACD" }} />
												<span
													className="benefits__item-title"
													style={{
														color: "#009ACD",
													}}>
													{this.props.messages.homepage.text04}
												</span>
											</li>
										</ul>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "title" ? (
									<div className="my-4 px-3 px-md-5 pl-0 pl-sm-0 pl-lg-5">
										<ul className="benefits__list pl-0 pl-sm-0 pl-lg-5">
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text01}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text02}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text03}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#009ACD" }} />
												<span
													className="benefits__item-title"
													style={{
														color: "#009ACD",
													}}>
													{this.props.messages.homepageOter.text04}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text04ex}
												</span>
											</li>
										</ul>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "finance" ? (
									<div className="my-4 px-3 px-md-5 pl-0 pl-sm-0 pl-lg-5">
										<ul className="benefits__list pl-0 pl-sm-0 pl-lg-5">
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text01F}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text02}
												</span>
											</li>

											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#009ACD" }} />
												<span
													className="benefits__item-title"
													style={{
														color: "#009ACD",
													}}>
													{this.props.messages.homepageOter.text04}
												</span>
											</li>
											<li className="benefits__item">
												<i className="ui-check benefits__item-icon" style={{ color: "#000" }} />
												<span className="benefits__item-title" style={{ color: "#000" }}>
													{this.props.messages.homepageOter.text04ex}
												</span>
											</li>
										</ul>
									</div>
								) : null}

								<Link to={"/login?signup=true"}>
									<button className="btn btn--md btn--color btn--button">{this.props.messages.homepage.textbutt01}</button>
								</Link>
							</div>
							<div className="col-12 col-md-6 pt-5 pb-5 text-center">
								<img src={time} style={{ height: "260px" }} alt="Welcome!" />
								<div className="pt-3">
									<h2>{this.props.messages.homepage.title03}</h2>
									<h5 style={{ marginTop: "-10px" }}>{this.props.messages.homepage.title04}</h5>
								</div>
								{process.env.REACT_APP_TYPE === "all" ? (
									<div className="my-4 px-3 px-md-5 text-left myp">{this.props.messages.homepage.textL01}</div>
								) : null}
								{process.env.REACT_APP_TYPE === "title" ? (
									<div className="my-4 px-3 px-md-5 text-left myp">{this.props.messages.homepageOter.textL01}</div>
								) : null}
								{process.env.REACT_APP_TYPE === "finance" ? (
									<div className="my-4 px-3 px-md-5 text-left myp">{this.props.messages.homepageOter.textL01}</div>
								) : null}
								<Link to={"/login?signup=true"}>
									<button className="btn btn--md btn--color btn--button">{this.props.messages.homepage.textbutt02}</button>
								</Link>
							</div>
						</div>
						<br />
						<br />
						<br />
					</div>

					{process.env.REACT_APP_TYPE === "all" ? (
						<div className="container-fluid py-5 my-5 px-2 text-center" id="how">
							<div className="col-12">
								<h2>{this.props.messages.homepage.title05}</h2>
								<h6>{this.props.messages.homepage.title06}</h6>
							</div>
							<div className="container py-3">
								<div className="row">
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle01}</h5>
											<p className="text-left">{this.props.messages.homepage.textL02}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle02}</h5>
											<p className="text-left">{this.props.messages.homepage.textL03}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle03}</h5>
											<p className="text-left">{this.props.messages.homepage.textL04}</p>
										</div>
									</div>
								</div>
							</div>
							<Link to={"/login?signup=true"}>
								<button className="btn btn--md btn--white btn--button">{this.props.messages.homepage.suTitle04}</button>
							</Link>
						</div>
					) : null}
					{process.env.REACT_APP_TYPE === "title" ? (
						<div className="container-fluid py-5 my-5 px-2 text-center" style={{ backgroundColor: "#ff0000" }} id="how">
							<div className="col-12">
								<h2>{this.props.messages.homepage.title05}</h2>
								<h6>{this.props.messages.homepage.title06}</h6>
							</div>
							<div className="container py-3">
								<div className="row">
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle01}</h5>
											<p className="text-left">{this.props.messages.homepage.textL02}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle02}</h5>
											<p className="text-left">{this.props.messages.homepage.textL03}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepage.suTitle03}</h5>
											<p className="text-left">{this.props.messages.homepage.textL04}</p>
										</div>
									</div>
								</div>
							</div>
							<Link to={"/login?signup=true"}>
								<button className="btn btn--md btn--white btn--button">{this.props.messages.homepage.suTitle04}</button>
							</Link>
						</div>
					) : null}
					{process.env.REACT_APP_TYPE === "finance" ? (
						<div className="container-fluid py-5 my-5 px-2 text-center" style={{ backgroundColor: "#ffc63d" }} id="how">
							<div className="col-12">
								<h2>{this.props.messages.homepage.title05}</h2>
								<h6>{this.props.messages.homepage.title06}</h6>
							</div>
							<div className="container py-3">
								<div className="row">
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepageOter.textPersonal01}</h5>
											<p className="text-left">{this.props.messages.homepageOter.textPersonal02}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepageOter.textPersonal03}</h5>
											<p className="text-left">{this.props.messages.homepageOter.textPersonal04}</p>
										</div>
									</div>
									<div className="row col-12 col-md-4 py-3">
										<div className="col-1">
											<i className="fas fa-star" style={{ fontSize: "20px" }} />
										</div>
										<div className="col-10">
											<h5 className="text-left">{this.props.messages.homepageOter.textPersonal05}</h5>
											<p className="text-left">{this.props.messages.homepageOter.textPersonal06}</p>
										</div>
									</div>
								</div>
							</div>
							<Link to={"/login?signup=true"}>
								<button className="btn btn--md btn--white btn--button">{this.props.messages.homepage.suTitle04}</button>
							</Link>
						</div>
					) : null}

					<div className="container my-5 py-5" id="online">
						<div className="row text-left">
							<div className="col">
								<div className="row">
									<div className="col-7 col-md-10">
										<h6 className="text-left">{this.props.messages.homepage.suTitle05}</h6>
										<h3 className="text-left" align="center">
											{this.props.messages.homepage.suTitle06}
											<br />
											{this.props.messages.homepage.suTitle07}
										</h3>
										<div className="yseparator" />
									</div>
									<div className="col-5 col-md-2">
										<img src={money} alt="Welcome!" />
									</div>
								</div>
								{process.env.REACT_APP_TYPE === "all" ? (
									<div className="row my-5">
										<div className="col-12 col-md-4 mt-5 mt-md-0">
											<h5>{this.props.messages.homepage.suTitle08}</h5>
											<p>{this.props.messages.homepage.textL05}</p>
										</div>
										<div className="col-12 col-md-4 my-5 my-md-0">
											<h5>{this.props.messages.homepage.suTitle09}</h5>
											<p>{this.props.messages.homepage.textL06}</p>
										</div>
										<div className="col-12 col-md-4">
											<h5>{this.props.messages.homepage.suTitle10}</h5>
											<p>{this.props.messages.homepage.textL07}</p>
										</div>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "title" ? (
									<div className="row my-5">
										<div className="col-12 col-md-4 mt-5 mt-md-0">
											<h5>{this.props.messages.homepageOter.suTitle08}</h5>
											<p>{this.props.messages.homepageOter.textL05}</p>
										</div>
										<div className="col-12 col-md-4 my-5 my-md-0">
											<h5>{this.props.messages.homepageOter.suTitle09}</h5>
											<p>{this.props.messages.homepageOter.textL06}</p>
										</div>
										<div className="col-12 col-md-4">
											<h5>{this.props.messages.homepage.suTitle10}</h5>
											<p>{this.props.messages.homepage.textL07}</p>
										</div>
									</div>
								) : null}
								{process.env.REACT_APP_TYPE === "finance" ? (
									<div className="row my-5">
										<div className="col-12 col-md-4 mt-5 mt-md-0">
											<h5>{this.props.messages.homepageOter.suTitle08}</h5>
											<p>{this.props.messages.homepageOter.textL05F}</p>
										</div>
										<div className="col-12 col-md-4 my-5 my-md-0">
											<h5>{this.props.messages.homepageOter.suTitle09F}</h5>
											<p>{this.props.messages.homepageOter.textL06F}</p>
										</div>
										<div className="col-12 col-md-4">
											<h5>{this.props.messages.homepageOter.suTitle10F}</h5>
											<p>{this.props.messages.homepageOter.textL07F}</p>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>

					<Footer {...this.props} />
					<Copy {...this.props} />
				</main>
			</div>
		);
	}
}
