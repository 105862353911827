const faq = [
	{
		question: "How fast is the application process?",
		answer:
			"The application process at Discount Finance & Online Personal Loans  typically takes 10 to 15 minutes to complete. If you can provide us all the correct documentation, a decision can be made the same day of application.",
	},
	{
		question:
			"Can I apply for a loan from Discount Finance & Online Personal Loans  if I live outside of a selected market (San Antonio MSA, Laredo MSA, Corpus Christi MSA, Rio Grande Valley MSA?",
		answer:
			"Possibly. Discount Finance & Online Personal Loans  is expanding its lending areas rapidly. Discount Finance & Online Personal Loans  coverage map is updated frequently.",
	},
	{
		question: "Can I change the amount that I am borrowing?",
		answer:
			"Yes, at Discount Finance & Online Personal Loans  you can borrow an amount up to the personal loan or car title loan amount for which you’ve been approved. You can change the amount you are borrowing from the time you are notified of your approval up until you are issued your loan proceeds.",
	},
	{
		question: "How much will my payments be?",
		answer:
			"Payment amounts are based upon the loan size, interest rate, fees and term of the loan. Discount Finance & Online Personal Loans  lenders calculate a payment amount they think you can afford based upon your income and debts/expenses. You also have the ability to change the loan amount and/or term, within certain parameters, before the loan is disbursed if you’d like to have a lower payment. Payments are made in fixed installments which means they don’t change over the life of the loan (except the last payment, which may be larger or smaller depending upon whether you made your payments on time).",
	},
	{
		question: "How do I know that the documents I have submitted are being processed?",
		answer:
			"You may call or visit any retail location to submit the required documentation (proof of identity, income and residence). You may also submit your documents yourself through Discount Finance & Online Personal Loans  online application, and you will receive a confirmation notice by phone or email. In either case, you will be notified by one of our customer representatives regarding the approval status of your loan shortly following the submission of your application.",
	},
	{
		question: "What is the status of my personal loan or car title loan application?",
		answer:
			"You can be assured Discount Finance & Online Personal Loans experienced and knowledgeable agents are processing your application as quickly as possible. Should you have specific inquiries, you can visit or contact one of Discount Finance & Online Personal Loans  locations. Agents will be happy to assist you.",
	},
	{
		question: "How do I get my personal loan or car title loan funds?",
		answer:
			"You can get your loan proceeds, either in the form of a physical check, (at one of Discount Finance & Online Personal Loans  lender’s retail locations), via an electronic check which can be e-mailed to you or Instant Funding. ",
	},
	{
		question: "Do the lenders inspect vehicles before approving a title loan?",
		answer:
			"Discount Finance & Online Personal Loans  will base the appraisal of your vehicle on your good faith that the car is in working condition and that its condition is accurately represented by the pictures that you send your lender. ",
	},
	{
		question: "Do your lenders offer title loans for people with bad credit?",
		answer:
			"Yes, Discount Finance & Online Personal Loans  regularly have applicants with bad or no credit. We understand that people make mistakes and we believe that poor FICO scores shouldn’t prevent people from getting help when they need it the most.",
	},
	{
		question: "When will I receive my money?",
		answer:
			"Discount Finance & Online Personal Loans  personal loan and car title loan agents use a variety of methods to get you your money quickly. Depending on how you choose to receive your money, it can take as little as a few minutes to a few business days. If you decide to accept an electronic check or Instant Funding, you will get your money in minutes.",
	},
	{
		question: "What if I already have a title loan with another company?",
		answer:
			"That’s perfectly fine with Discount Finance & Online Personal Loans  personal loan and car title loan customer representatives. Discount Finance & Online Personal Loans  can connect you an agent that can refinance your existing loan and save you money. Refinancing a personal or title loan means finding a new lender that will pay off the amount you owe to an old lender. The balance from the old personal loan or car title loan will then be transferred over to a new car title loan with lower interest.",
	},
	{
		question: "How does the Storefront car title lending process work?",
		answer:
			"Lenders in Discount Finance & Online Personal Loans  network such as Discount Car Title Loan make the title lending process simple, and it only takes a few minutes to get the cash you need. Here’s what you´ll need to do if you prefer to get you loan at a storefront location.",
	},
	{
		question: "How does the amortized personal loan (traditional installment loan) process work?",
		answer:
			"Lenders in Discount Finance & Online Personal Loans  network such as the Discount Finance & Personal Loan Company make getting an amortized personal loan (traditional installment loan) process simple, and it only takes a few minutes to get the cash you need. Here’s what you´ll need to do if you prefer to get you loan at a storefront location. 1. Fill out an application on-line or at a local Discount Finance & Online Personal Loans location 2. Discount Finance & Online Personal Loans will determine a transaction amount based upon your need, your credit history and what you can realistically afford. 3. Upon approval, you will receive the cash you need that same day.",
	},
];

export default faq;
