import React, { Component } from "react";
import { Radio } from "antd";
import Separator from "../../../components/separator";
import Step from "../../../components/step";
import Page from "../../../components/page";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 2,
			utilityBill: false,
			mortgageBill: false,
			hired: false,
			autoPayment: true,
			incomeProof: false,
			liabilityProof: false,
		};
	}

	componentDidUpdate() {
		if (!this.props.uid) {
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (!this.props.uid) {
			return this.props.history.push("/login");
		} else {
			return this._loadData();
		}
	}

	_loadData = async () => {
		const res = await firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.get();

		return this.setState({
			utilityBill: res.data().utilityBill ? res.data().utilityBill : false,
			mortgageBill: res.data().mortgageBill ? res.data().mortgageBill : false,
			hired: res.data().hired ? res.data().hired : false,

			autoPayment: res.data().autoPayment !== undefined ? res.data().autoPayment : true,
			incomeProof: res.data().incomeProof ? res.data().incomeProof : false,
			liabilityProof: res.data().liabilityProof ? res.data().liabilityProof : false,
		});
	};

	submitForm = event => {
		event.preventDefault();
		return this._saveToFirebase();
	};

	_saveToFirebase = async () => {
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fourMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fiveMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/sixMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/nineMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/twelveMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/fifteenMonths")
			.delete();
		await firebase
			.firestore()
			.doc("applications/" + this.props.uid + "/data/eighteenMonths")
			.delete();

		return firebase
			.firestore()
			.doc("applications/" + this.props.uid)
			.update({
				status: 2,
				lastUpdate: "User",

				utilityBill: this.state.utilityBill,
				mortgageBill: this.state.mortgageBill,
				hired: this.state.hired,

				autoPayment: this.state.autoPayment,
				returning: false,
				incomeProof: this.state.incomeProof,
				liabilityProof: this.state.liabilityProof,

				timestamp: moment().toDate(),
			})
			.then(() => {
				window.gtag("event", "conversion", {
					send_to: "AW-974126037/oC-ECKWhq6gBENX3v9AD",
				});
				window.gtag("event", "conversion", {
					send_to: "AW-761506876/tHEfCI6EuqwBELzYjusC",
				});
				this.props.onUpdateLoan({
					status: 2,
					vehicle: this.props.loan.vehicle,
					type: this.props.loan.type,
					term: this.props.loan.term,
				});
				return this.props.history.push("/offer");
			});
	};

	_handleCheckChange = e => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};

	render() {
		return (
			<Page {...this.props}>
				<Step title={this.props.messages.discounts.textN16} subtitle={this.props.messages.discounts.textN17} />
				<div className="container pt-1 pb-5 text-center" id="discounts">
					<h6>
						{this.props.messages.discounts.textN01} <b>{this.props.messages.discounts.textN02}</b>
						{this.props.messages.discounts.textN03}
					</h6>
					<form onSubmit={this.submitForm} id="application">
						<div className="container">
							<br />
							<div className="yseparator center" />
							<br />
							<h3>{this.props.messages.discounts.textN04}</h3>
							<br />
							<h5>{this.props.messages.discounts.textN05}</h5>
							<br />
							<Radio.Group
								options={[
									{ value: false, label: this.props.messages.discounts.textN06 },
									{ value: true, label: this.props.messages.discounts.textN07 },
								]}
								value={this.state.autoPayment}
								onChange={e => this.setState({ autoPayment: e.target.value })}
								optionType="button"
								buttonStyle="solid"
							/>
							<br />
						</div>
						<Separator className="px-0" />
						<div className="container">
							<br />
							<h5>{this.props.messages.discounts.textN08}</h5>
							<br />
							<br />
							<div className="row text-left justify-content-center switches">
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN09}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input type="checkbox" name="utilityBill" checked={this.state.utilityBill} onChange={this._handleCheckChange} />
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN10}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input
													type="checkbox"
													name="mortgageBill"
													checked={this.state.mortgageBill}
													onChange={this._handleCheckChange}
												/>
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN11}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input type="checkbox" name="hired" checked={this.state.hired} onChange={this._handleCheckChange} />
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 my-2">
									<div className="row">
										<div className="col-9 px-0">
											<p>{this.props.messages.discounts.textN13}</p>
										</div>
										<div className="col-3">
											<label className="switch">
												<input type="checkbox" name="incomeProof" checked={this.state.incomeProof} onChange={this._handleCheckChange} />
												<span className="slider round" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="row justify-content-center">
								<button type="submit" className="btn btn--sm btn--color btn--button col-12 col-md-4 mt-5">
									{this.props.messages.discounts.textN14}
								</button>
							</div>
							<p className="text-left pt-5" style={{ fontSize: 12, textAlign: "left" }}>
								{this.props.messages.discounts.textN15}
							</p>
						</div>
					</form>
				</div>

				{/*	Trackers */}
				{this.props.pixels[2] ? <img alt={"uberAd"} src={"https://serve.uberads.com/convert/" + this.props.pixels[2]} width="1" height="1" /> : null}
				{/*Facebook Pixel Code*/}
				<img alt="pixel" height="1" width="1" src="https://www.facebook.com/tr?id=543189693101534&ev=PageView&noscript=1" />
			</Page>
		);
	}
}
