import React, { Component } from "react";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Copy from "../components/copy";

export default class extends Component {
	render() {
		return (
			<div>
				<main className="main-wrapper">
					<Menu {...this.props} />
					<section className="section-wrap margen_top">
						<div className="container">
							<br />
							<div className="title-row text-center">
								<h3 className="section-title">SMS Terms</h3>
								<br />
							</div>
							<br />
							<div className="row my-3 text-center">
								<div className="col-md-12">
									<p>
										By providing us with your mobile number and opting-in, you give
										{process.env.REACT_APP_TYPE === "all" ? " Discount Loans " : null}
										{process.env.REACT_APP_TYPE === "title" ? " Discount Title Loans " : null}
										{process.env.REACT_APP_TYPE === "finance" ? " Discount Finance " : null} permission to send you account-related text
										messages, like payment reminders and notifications in conjunction with the services you have requested. Message and data
										rates may apply. Message frequency will vary. To opt-out at any time, reply STOP to any message we send. For customer
										service, reply Help or call 956-230-4402.
									</p>
								</div>
							</div>
							<br />
							<br />
							<br />
						</div>
					</section>
					<Footer {...this.props} />
					<Copy {...this.props} />
					<div id="back-to-top">
						<a href="#top">
							<i className="ui-arrow-up" />
						</a>
					</div>
				</main>
			</div>
		);
	}
}
